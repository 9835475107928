import React from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import bgimage from "../../assets/img/bgimage.PNG";
import { buyAirtime } from "../../utilities/api";
import { getFees } from "../../utilities/api";
import shortid from "shortid";
import { netpluspayPOP } from "../../views/TopUps/netfun";

class Data extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			provider: "",
			transactionID: null,
			payload: null,
			isLoading: false,
			provider: [],
			email: "",
			phone: "",
			internetPackage: [],
			fullName: "",
			destinationAccount: "",
			confirmDestination: "",
			category: [],
			storedFee: null,
			storedProvider: null,
			modalResponse: "",
			selectedProvider: "Choose Product",
			selectedPackage: "Choose Package",
			amount: null,
			service: "internet",
			lineType: "virtual topup",
			convenienceFee: 100,
			formErrors: {
				provider: "",
				destinationAccount: "",
				confirmDestination: "",
				amount: null,
				email: "",
				phone: ""
			}
		};
	}

	componentDidMount = () => {
		this.setState({
			payload: 0,
			provider: [
				{
					name: "SMILE",
					internetPackage: [
						{
							name: "FLEXIDAILY(1GB) - 1DAY - N350",
							amount: 350
						},
						{
							name: "FLEXIWEEKLY(2GB) - 7 DAYS - N1000",
							amount: 1000
						},
						{
							name: "1.5GB VALUEPLUS - 30 DAYS- N1000",
							amount: 1000
						},
						{
							name: "5GB VALUEPLUS - 30 DAYS - N3000",
							amount: 3000
						},
						{
							name: "7GB VALUEPLUS - 30 DAYS - N4000",
							amount: 4000
						},
						{
							name: "11GB VALUEPLUS - 30 DAYS - N5000",
							amount: 5000
						},
						{
							name: "15GB VALUEPLUS - 30 DAYS - N6000",
							amount: 6000
						},
						{
							name: "21GB VALUEPLUS - 30AYS - N8000",
							amount: 8000
						},
						{
							name: "31GB VALUEPLUS - 30 DAYS - N11000",
							amount: 11000
						},
						{
							name: "80GB BUMPAVALUE - 120 DAYS - N40,000",
							amount: 40000
						},
						{
							name: "10GB ANYTIME - 1 YEAR - N8000",
							amount: 8000
						},
						{
							name: "20GB ANYTIME - 1 YEAR - N16000",
							amount: 16000
						},
						{
							name: "UNLIMITED ESSENTIAL - 30 DAYS - N15000",
							amount: 15000
						}
					]
				},
				{
					name: "SWIFT",
					internetPackage: [
						{
							name: "N/A",
							amount: 1230
						}
					]
				},
				{
					name: "SPECTRANET",
					internetPackage: [
						{
							name: "UNIFIED VALUE 8GB - N3070",
							amount: 3070
						},
						{
							name: "UNIFIED VALUE 4GB - N3070",
							amount: 3070
						},
						{
							name: "UNIFIED VALUE 10GB - N3580",
							amount: 3580
						},
						{
							name: "UNIFIED VALUE 6GB - N3580",
							amount: 3580
						},
						{
							name: "UNIFIED VALUE 7GB - N5115",
							amount: 5115
						},
						{
							name: "UNIFIED VALUE 14GB - N5115",
							amount: 5115
						},
						{
							name: "MAGIC VALUE 6K - N6140",
							amount: 6140
						},
						{
							name: "UNIFIED VALUE 24GB - N6140",
							amount: 6140
						},
						{
							name: "WEEKLY UNLIMITED - 6650",
							amount: 6650
						},
						{
							name: "UNIFIED VALUE 15GB - N7165",
							amount: 7165
						},
						{
							name: "UNIFIED VALUE 30GB - N7165",
							amount: 7165
						},
						{
							name: "NIGHT VALUE 20GB - N7675",
							amount: 7675
						},
						{
							name: "GOOD MORNING 15GB - N8190",
							amount: 8190
						},
						{
							name: "MEGA VALUE 15GB - N8190",
							amount: 8190
						},
						{
							name: "UNIFIED VALUE 25GB - N10235",
							amount: 10235
						},
						{
							name: "NIGHT VALUE 40GB - N11260",
							amount: 11260
						},
						{
							name: "UNIFIED VALUE 40GB - N12795",
							amount: 12795
						},
						{
							name: "UNIFIED VALUE 50GB (ABUJA ONLY) - N13305",
							amount: 13305
						},
						{
							name: "UNIFIED VALUE 60GB - N15355",
							amount: 15355
						},
						{
							name: "AbjUnltdLteGold (ABUJA ONLY) - N16380",
							amount: 16380
						},
						{
							name: "UNLIMITED GOLD - N18425",
							amount: 18425
						},
						{
							name: "UNLIMITED DIAMOND - N19450",
							amount: 19450
						},
						{
							name: "FREEDOM PRIME - N19960",
							amount: 19960
						},
						{
							name: "UNIFIED VALUE 55GB - N20475",
							amount: 20475
						},
						{
							name: "GALAXY VPN UNLIMITED - N30710",
							amount: 30710
						},
						{
							name: "FREEDOM PRIME PLUS - N38390",
							amount: 38390
						},
						{
							name: "FREEDOM ELITE - N56820",
							amount: 56820
						}
					]
				}
			]
		});
	};

	changeProvider = e => {
		this.setState({ selectedProvider: e.target.value });
		this.setState({
			internetPackage: this.state.provider.find(i => i.name === e.target.value)
				.internetPackage
		});
		 this.handleGetFees();
	};

	changeInternetPackage = e => {
		this.setState({ selectedPackage: e.target.value });
		const pro = this.state.provider.find(
			i => i.name === this.state.selectedProvider
		).internetPackage;
		this.setState({
			amount: pro.find(a => a.name === e.target.value).amount
		});
	};

	handleChangeEmail = e => {
		e.preventDefault();
		const value = e.target.value;

		this.setState({
			email: value
		});
	};

	handleChangeFullName = e => {
		e.preventDefault();
		const value = e.target.value;

		this.setState({
			fullName: value
		});
	};
	handleChangeAmount = e => {
		e.preventDefault();
		const value = e.target.value;

		this.setState({
			amount: value
		});
	};
	handleChangeDestination = e => {
		e.preventDefault();
		const value = e.target.value;

		this.setState({
			destinationAccount: value
		});
	};

	handleGetFees = () => {
		this.setState({
			isLoading: true
		});

		setTimeout(() => {
			let data = this.state.selectedProvider;

			if (sessionStorage.getItem("Service") === this.state.provider) {
				this.setState({
					payload: sessionStorage.getItem("Fee"),
					isLoading: false
				});
			} else {
				getFees(data).then(response => {
					if (response && response.status == 200) {
						setTimeout(() => {
							this.setState({
								payload: response.data.fees,
								isLoading: false,
								storedProvider: response.data.provider,
								storedFee: response.data.fees
							});

							const s = this.state.storedProvider;
							const fee = this.state.storedFee;

							sessionStorage.setItem("Fee", fee);
							sessionStorage.setItem("Service", s);
						}, 500);
					} else {
						let showMsg = document.getElementById("show-msg");
						showMsg.innerHTML = "Service Unavailable ";
						showMsg.style.display = "block";
						showMsg.style.fontSize = "18px";
						showMsg.style.color = "red";
						this.setState({
							isLoading: false
						});
					}
				});
			}
		}, 1000);
	};

	handlePayment = e => {
		e.preventDefault();
		// if (formValid(this.state.formErrors)) {
			if(this.state.payload === 0) {
				let showMsg = document.getElementById("show-msg");
						showMsg.innerHTML = "Service Unavailable ";
						showMsg.style.display = "block";
						showMsg.style.fontSize = "18px";
						showMsg.style.color = "red";
						this.setState({
							isLoading: false
						});
			} else {
		const finalamount =
			parseInt(this.state.amount) + parseInt(this.state.payload);
		
		netpluspayPOP.setup({
			merchant: `${process.env.REACT_APP_MERCHANT_ID}`,
			customer_name: this.state.fullName,
			email: this.state.email,
			amount: finalamount,
			currency_code: "NGN",
			narration: "Data Purchase",
			order_id: `DATA${shortid.generate()}`,
			container: "paymentFrame",
			callBack: function(resp) {
				if (resp && resp.status === "CAPTURED") {
					this.setState({
						modalResponse: resp.status,
						transactionID: resp.trans_id
					});
					
				} else if (resp && resp.status === "FAILED") {
					this.setState({
						modalResponse: resp.status,
						transactionID: resp.trans_id
					});
					
				}
				//this.closeIframe();
			}.bind(this),
			onClose: function() {
				this.setState({ isLoading: true });
				this.handleWindowClose();
			}.bind(this)
		});
		netpluspayPOP.prepareFrame();
		// } else {
		// 	let showMsg = document.getElementById("show-msg");
		// 	showMsg.innerHTML = "Invalid Form";
		// 	showMsg.style.display = "block";
		// 	showMsg.style.fontSize = "18px";
		// 	showMsg.style.color = "red";
		// 	this.setState({ isLoading: false });
		// }
			//}
		}
	};

	handleWindowClose = () => {
		if (this.state.modalResponse === "FAILED") {
			let showMsg = document.getElementById("show-msg");
			showMsg.innerHTML = "Transaction Failed";
			showMsg.style.display = "block";
			showMsg.style.fontSize = "18px";
			showMsg.style.color = "red";
			this.setState({ isLoading: false });
		} else if (this.state.modalResponse === "CAPTURED") {
			this.handleBuyAirtime();
		} else {
			let showMsg = document.getElementById("show-msg");
			showMsg.innerHTML = "Error";
			showMsg.style.display = "block";
			showMsg.style.fontSize = "18px";
			showMsg.style.color = "red";
			this.setState({ isLoading: false });
		}
	};

	handleBuyAirtime = () => {
		const finalamount =
			parseInt(this.state.amount) + parseInt(this.state.payload);

		let data = {
			destinationAccount: this.state.destinationAccount,
			amount: finalamount,
			service: this.state.service,
			provider: this.state.selectedProvider,
			trans_id: this.state.transactionID
		};
		
		setTimeout(() => {
			buyAirtime(data).then(response => {
				if (response && response.status === 200) {
					let showMsg = document.getElementById("show-msg");
					showMsg.innerHTML = "Transaction Successful";
					showMsg.style.display = "block";
					showMsg.style.fontSize = "18px";
					showMsg.style.color = "green";
					this.setState({ isLoading: false });
				} else if (response && response.status === 400) {
					let showMsg = document.getElementById("show-msg");
					showMsg.innerHTML = "Transaction Failed";
					showMsg.style.display = "block";
					showMsg.style.fontSize = "18px";
					showMsg.style.color = "red";
					this.setState({ isLoading: false });
				} else {
					let showMsg = document.getElementById("show-msg");
					showMsg.innerHTML = "Server Error";
					showMsg.style.display = "block";
					showMsg.style.fontSize = "18px";
					showMsg.style.color = "red";
					this.setState({ isLoading: false });
				}
			});
		}, 4000);
	};

	render() {
		const { formErrors, isLoading, payload } = this.state;
		return (
			<React.Fragment>
				<Navbar />

				<div id="paymentFrame" class="m-0 mx-auto"></div>
				<div id="netplusFrame" class="m-0 mx-auto"></div>

				<div className="container airtime-container">
					<div className="row">
						<SideBar />

						<div className="col-md-5 col-lg-5">
							<form className="data-form" onSubmit={this.handlePayment}>
								<p id="show-msg"></p>
								<span className="fee">
									{"Convenience Fee: " + this.state.payload}
								</span>

								<div class="form-group data-form-input">
									<select
										class="form-control custom-select"
										value={this.state.selectedProvider}
										onChange={this.changeProvider}
										disabled={isLoading}
										required
									>
										<option>CHOOSE PRODUCT</option>
										{this.state.provider.map((e, key) => {
											return <option key={key}>{e.name}</option>;
										})}
									</select>
								</div>

								<div class="form-group data-form-input">
									<select
										class="form-control custom-select"
										value={this.state.selectedPackage}
										onChange={this.changeInternetPackage}
										disabled={isLoading}
										required
									>
										<option>CHOOSE PACKAGE</option>
										{this.state.internetPackage.map((e, key) => {
											return <option key={key}>{e.name}</option>;
										})}
									</select>
								</div>

								<div class="form-group data-form-input">
									<input
										type="text"
										class="form-control"
										name="fullName"
										onChange={this.handleChangeFullName}
										value={this.state.fullName}
										placeholder="ENTER FULL NAME"
										disabled={isLoading}
										required
									/>
								</div>
								<div class="form-group data-form-input">
									<input
										type="email"
										class="form-control"
										name="email"
										onChange={this.handleChangeEmail}
										value={this.state.email}
										placeholder="ENTER EMAIL ADDRESS"
										disabled={isLoading}
										required
									/>
								</div>

								<div class="form-group data-form-input">
									<input
										type="text"
										class="form-control"
										name="destinationAccount"
										onChange={this.handleChangeDestination}
										value={this.state.destinationAccount}
										placeholder="ENTER CARD NUMBER"
										disabled={isLoading}
										required
									/>
								</div>
								<div class="form-group data-form-input">
									<input
										type="number"
										class="form-control"
										name="amount"
										onChange={this.handleChangeAmount}
										value={this.state.amount}
										placeholder="AMOUNT"
										disabled={isLoading}
										required
									/>
								</div>

								<button
									type="submit"
									className="btn btn-block airtime-form-input btn-style"
									disabled={isLoading}
								>
									{isLoading ? "LOADING…" : "PAY"}
								</button>
							</form>
						</div>

						<div className="col-md-5 col-lg-5">
							<img src={bgimage} className="img-container" />
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Data;