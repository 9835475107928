import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
  } from "reactstrap";
import Modal from "../../components/modals/Modals";
// import { Row, Col } from "reactstrap";
import { Table } from "reactstrap";
import { FormatTime } from "../../utilities/currency_time_formata";
import ErrorPage from "../../utilities/errorPage";
import { arrTypeCheck } from "../../utilities/dataTypeCheck";
import ToggleSwitch from "./ToggleSwitch";
import UpdateData from "./UpdateData";
import {getDevLevy, getServicePayments } from "../../utilities/api";

class ResidentInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: null,
      isLoading: false,
      value: 10,
      currentTransactions: [],
      currentPage: null,
      totalPages: null,
      dev: null,
      serviceCharge: null,
      fine: null
    };
  }

//   onPageChanged = (data) => {
//     const { payload } = this.state;
//     const { currentPage, totalPages, pageLimit } = data;
//     const offset = (currentPage - 1) * pageLimit;
//     const currentTransactions = payload.req.slice(offset, offset + pageLimit);
//     this.setState({ currentPage, currentTransactions, totalPages });
//   };

componentDidMount=()=> {
    console.log("info");
    console.log("payload", this.props.data);
    // this.getDevLevy();
    this.getServicePayment();
}

handleRefresh = () => {
  this.forceUpdate();
  // this.getDevLevy();
  this.getServicePayment();
}

getDevLevy=()=> {
    const param = {
        meter_number: this.props.data.meterNo
    }
    getDevLevy(param).then((response) => {
      if (response && response.status === 200) {
        console.log("resp", response.data);
        setTimeout(() => {
          this.setState({ isLoading: false , dev: response.data.amount, });
          localStorage.setItem("DevLevy", response.data.amount);
        }, 500);
       
        console.log("sss", this.state);
      } else if (response && response.status === 400) {
        setTimeout(() => {
          this.setState({ isLoading: false });
        }, 500);
        // throw new Error(response);
      } else {
        this.setState({ isLoading: false });
      }
    });
  }


getServicePayment = () => {
    const param = {
        meter_number: this.props.data.meterNo
    }
    getServicePayments(param).then((response) => {
      if(response && response.status === 200) {

        if(response.data === null) {
          const durationLeftDays = ""
          localStorage.setItem("duration", durationLeftDays);
          this.setState({
            serviceCharge: 0,
            // status: status
            // createdAt: response.data.createdAt
          })
        } else {
          console.log("success", response, response.data.devLevy);
          localStorage.setItem("serviceCharge", response.data.amount);
          this.setState({
            serviceCharge: response.data.serviceCharge,
            dev: response.data.devLevy,
            fine: response.data.fine
            // status: status
            // createdAt: response.data.createdAt
          })
          // this.getDevLevy();
        }
          
      }else {
        console.log("failure", response);
      }
    })
  }



  render() {
    const { props } = this.props.data;
    return (
      <div className="content">
        {/* {!props ? (
          <div>
            <ErrorPage />
          </div>
        ) : ( */}
          <div>
           <Row className="bg-white rounded d-flex h-80">
                <Col sm="6">
                  <Card className="card-user remove_shadow">
                    {/* <CardHeader>
                      <CardTitle>INFO</CardTitle>
                    </CardHeader> */}
                    <CardBody>
                      <Form className="form-profile">
                        <FormGroup>
                          <Label for="exampleFirst">FIRST NAME</Label>
                          <Input
                            type="text"
                            disabled
                            // name="first_name"
                            id="exampleFirst"
                            placeholder={this.props.data.firstName}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="exampleLast">LAST NAME</Label>
                          <Input
                            type="text"
                            disabled
                            // name="last_name"
                            id="exampleLast"
                            placeholder={this.props.data.lastName}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="exampleEmail">EMAIL</Label>
                          <Input
                            type="email"
                            disabled
                            // name="email"
                            id="exampleEmail"
                            placeholder={this.props.data.email}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="examplePhone">PHONE</Label>
                          <Input
                            type="tel"
                            disabled
                            // name="phone"
                            id="examplePhone"
                            placeholder={this.props.data.phone}
                          />
                        </FormGroup>
                        <FormGroup>
                      <Label for="exampleMeter">METER NUMBER</Label>
                      <Input
                        type="text"
                        disabled
                        // name="meter"
                        id="exampleMeter"
                        placeholder={this.props.data.meterNo}
                      />
                    </FormGroup>
                    {/* <FormGroup>
                      <Label for="exampleMeter">ESTATE </Label>
                      <Input
                        type="text"
                        disabled
                        name="meter"
                        id="exampleMeter"
                        placeholder={props.propertyName}
                      />
                    </FormGroup> */}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="6">
                  <Card body className="remove_shadow">
                    {/* <p>
                      <img
                        class="img-fluid mx-auto d-block"
                        src={user_avatar}
                        alt="User avatar"
                      />
                    </p> */}
                    <Row>
                    <Col sm="6">
                    <FormGroup>
                          <Label for="examplePhone">SERVICE CHARGE</Label>
                          <Input
                            type="tel"
                            disabled
                            // name="phone"
                            id="examplePhone"
                            placeholder={this.state.serviceCharge}
                          />
                            
                        </FormGroup>
                        <FormGroup>
                          <Label for="examplePhone">DEVELOPMENT LEVY</Label>
                          <Input
                            type="tel"
                            disabled
                            // name="phone"
                            id="examplePhone"
                            placeholder={this.state.dev}
                          />
                            
                        </FormGroup>
                        <FormGroup>
                          <Label for="examplePhone">FINE</Label>
                          <Input
                            type="tel"
                            disabled
                            // name="phone"
                            id="examplePhone"
                            placeholder={this.state.fine}
                          />
                            
                        </FormGroup>
                      <div className="update ml-auto mr-auto">
                        {/* <Modal
                          buttonLabel={"Update Profile"}
                        //   userObj={payload}
                          background={"#1DE054"}
                        /> */}
                        {/* <Button className="bg-green" round >
                      Update Profile
                    </Button> */}
                      </div>
                      </Col>
                    </Row>
                    <Row>
                      <div className="update ml-auto mr-auto">
                        {/* <Modal buttonLabel={"Change Password"} /> */}
                        {/* <Button round>
                      Change Password
                    </Button> */}
                      </div>
                    </Row>
                    <br />
                    <Row>
                    <Col sm="6"> 
                      <div className="update ml-auto mr-auto">
                        <UpdateData id = "servicecharge" buttonLabel={"Update Service Payments"} meterNo={this.props.data.meterNo}  handleRefresh={this.handleRefresh}/>
                        {/* <Button round>
                      Change Password
                    </Button> */}
                      </div>

                      {/* <div className="update ml-auto mr-auto">
                        <UpdateData id = "devlevy" buttonLabel={"Update Development Levy"} meterNo={this.props.data.meterNo} handleRefresh={this.handleRefresh} />
                       
                      </div> */}
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>



          </div>
        {/* )} */}
      </div>
    );
  }
}

export default ResidentInfo;
