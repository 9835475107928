import React from "react";

import { fetchEstatesDashboard } from "../../utilities/api";
import Placeholder from "../../utilities/placeholder";
import VewResident from "./EstateViewResult";
import EstateDashboardComponent from "./EstateDashboardComponent";
import ResidentDetails from "./EstateResDetails";

class EstateDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      payload: null,
      isLoading: true,
      value: "",
      emptyObj: "",
      viewTextInput: true,
    };

    this.menuToggle = this.menuToggle.bind(this);
    this.getDashboardMetrics = this.getDashboardMetrics.bind(this);
  }
  componentDidMount() {
    this.getDashboardMetrics();
  }

  menuToggle(e) {
    this.setState({
      viewTextInput: !this.state.viewTextInput,
    });
  }
  showMenuItem() {
    switch (this.state.viewEstate) {
      case "residentsResult":
        return <VewResident props={this.state.payload} />;
      case "residentsDetails":
        return <ResidentDetails props={this.state.payload}/>;
      // default: return <EstateDashboardComponent />;
      default:
        return <EstateDashboardComponent props={this.state.payload} />;
    }
  }

  getDashboardMetrics() {
    fetchEstatesDashboard().then((response) => {
      if (response && response.status === 200  || response && response.status === 304) {
        setTimeout(() => {
          this.setState({ payload: response, isLoading: false });
        }, 500);
      } else if (response && response.status === 400) {
        setTimeout(() => {
          this.setState({ isLoading: false, emptyObj: true });
        }, 500);
        // throw new Error(response);
      } else {
        this.setState({ isLoading: false });
      }
    });
  }

  render() {
    return (
      <div>
        {this.state.isLoading ? (
          <div>
            <Placeholder />
          </div>
        ) : (
          <div className="h-80 bg-white">
            <h6 className="w-100 bg-dark text-green p-3">
              VIEW ESTATE RESIDENTS
            </h6>
            {this.showMenuItem()}
            <div className="d-flex justify-content-center">
              <div className="centered-div"></div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default EstateDashboard;
