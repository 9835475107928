import React from "react";
import { Row, Col } from "reactstrap";
import { Table } from "reactstrap";
import { FormatTime } from "../../utilities/currency_time_formata";
import { filterResidentInactive } from "../../utilities/filterObj";
import ErrorPage from "../../utilities/errorPage";
import { arrTypeCheck } from "../../utilities/dataTypeCheck";

class TotalResidents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: null,
      isLoading: false,
      value: 10,
      currentTransactions: [],
      currentPage: null,
      totalPages: null,
    };
  }

  onPageChanged = (data) => {
    const { payload } = this.state;
    const { currentPage, totalPages, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    const currentTransactions = payload.req.slice(offset, offset + pageLimit);
    this.setState({ currentPage, currentTransactions, totalPages });
  };

  render() {
    const { props } = this.props;
    return (
      <div className="content">
        {!props ? (
          <div>
            <ErrorPage />
          </div>
        ) : (
          <div>
            {!arrTypeCheck(props.data.users) ? (
              <h6>There is no registered resident yet</h6>
            ) : (
              <div>
                {/*--------------------------------------------------------------------------------*/}
                {/*Start Inner Div*/}
                {/*--------------------------------------------------------------------------------*/}
                {/*--------------------------------------------------------------------------------*/}
                {/*Row*/}
                {/*--------------------------------------------------------------------------------*/}
{/*                <Row>
                  <Col md={12} className="mx-auto bg-white p-5 rounded">*/}
                    <Table bordered={true} responsive ={true}>
                      <thead className="bg-green">
                        <tr>
                          {/* <th>#</th> */}
                          <th>Name</th>
                          <th>Meter number</th>
                          <th>Registration Date</th>
                        </tr>
                      </thead>
                      {filterResidentInactive(props.data.users[0].users).map(
                        (user) => (
                          <tbody>
                            <tr key={user.id} role="row" className="odd">
                              <td className="sorting_1 pl-4">{`${user.first_name} ${user.last_name}`}</td>
                              <td>{user.meter_number}</td>
                              <td>{FormatTime(user.createdAt)}</td>
                            </tr>
                          </tbody>
                        )
                      )}
                    </Table>
{/*                  </Col>
                </Row>*/}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default TotalResidents;
