import React from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Button,
  Row,
  Col,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import { decodeJwt } from "../../utilities/processJWT";
import { fetchTransactions, searchTransaction } from "../../utilities/api";
import { CSVLink } from "react-csv";
import ErrorPage from "../../utilities/errorPage";
import Placeholder from "../../utilities/placeholder";
import Pagination from "../../utilities/Pagination";
import { arrTypeCheck } from "../../utilities/dataTypeCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormatTime } from "../../utilities/currency_time_formata";
import DevLevy from "./EstateMenu/OtherTransaction/DevLevy";
import PowerVending from "./EstateMenu/OtherTransaction/PowerVending";
import Fine from "./EstateMenu/OtherTransaction/Fine";
import ServicePayment from "./EstateMenu/OtherTransaction/ServicePayment";


class TransactionHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      headers: [],
      payload: null,
      isLoading: false,
      search: false,
      value: 10,
      listOfAgents: [],
      success: false,
      currentTransactions: [],
      currentPage: null,
      totalPages: null,
      toggleView: "power vending"
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.searchTransactions = this.searchTransactions.bind(this);
    this.getTransactions = this.getTransactions.bind(this);
    this.onPageChanged = this.onPageChanged.bind(this);
  }

  componentWillMount() {
    // this.getTransactions();
    {
      decodeJwt().role === "service-user" ? (
    this.setState({
      toggleView : "power vending"
    })
      ) : (
        this.setState({
          toggleView : "dev levy"
        })
      )
    }
  }

  handletoggleView =()=> {
    switch (this.state.toggleView) {
      case "power vending":
        return <PowerVending />;
      case "dev levy":
        return <DevLevy />;
        case "service":
        return <ServicePayment />;
        case "fine":
        return <Fine />;

     
    }
  }

  onPageChanged = (data) => {
    const { payload } = this.state;
    let sortedPayload = payload.transactions.slice().sort(function(a, b) {
      a = new Date(a.createdAt);
      b = new Date(b.createdAt);
      return a > b ? -1 : a < b ? 1 : 0;
    });
    const { currentPage, totalPages, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    const currentTransactions = sortedPayload.slice(
      offset,
      offset + pageLimit
    );
    this.setState({ currentPage, currentTransactions, totalPages });
  };

  onChangeStartDate = (event) => {
    this.setState({ startDate: event.target.value });
  };

  onChangeEndDate = (event) => {
    this.setState({ endDate: event.target.value });
  };
  searchTransactions = () => {
    const { startDate, endDate } = this.state;
    return searchTransaction({
      from: startDate,
      to: endDate,
    }).then((response) => {
      if (!response || response.status !== 200) {
        this.setState({
          isLoading: false,
          search: false,
          success: false,
        });
        setTimeout(() => {
          document.getElementById("show-error").innerHTML = "Error occured";
        }, 500);

        return;
      } else if (response.status == 200 || response && response.status === 304) {
        setTimeout(() => {
          const initialState = {
            cSelected: [],
            startDate: null,
            endDate: null,
            headers: [
              { label: "Transaction Amount", key: "amount" },
              { label: "Reference", key: "payment_transaction_id" },
              { label: "Meter Number", key: "meter_number" },
              { label: "Transaction Date", key: "createdAt" },
              { label: "Transaction Status", key: "transactionStatus" },
              { label: "Transaction Type", key: "transactionType" },
            ],
            data: [],
            payload: response.data,
            isLoading: false,
            search: false,
            value: 10,
            count: 0,
            currentTransactions: [],
            currentPage: null,
            totalPages: null,
            success: true,
          };
          this.setState(initialState);
          // let le = document.getElementById("download-option");
          // le.style.display = "block";
          if (arrTypeCheck(response.data)) {
            document.getElementById("csv-download").style.display = "inline";
          }
        }, 500);
      } else {
        document.getElementById("show-error").innerHTML = "Server error";
        // let le = document.getElementById("download-option");
        // le.style.display = "block";
        this.setState({ isLoading: false });
        return;
      }
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ search: true });
    setTimeout(() => {
      this.searchTransactions();
    }, 100);
  };

  getTransactions() {
    this.setState({ isLoading: true });
    fetchTransactions().then((response) => {
      if (response && response.status == 200 || response && response.status === 304) {
        setTimeout(() => {
          this.setState({
            payload: response.data,
            isLoading: false,
            headers: [
              { label: "Transaction Amount", key: "amount" },
              { label: "Reference", key: "topupRetryReference" },
              { label: "Meter Number", key: "meter_number" },
              { label: "Transaction Date", key: "createdAt" },
              { label: "Transaction Status", key: "topupStatus" },
              { label: "Transaction Type", key: "transaction_type" },
              { label: "Token", key: "token" },
            ],
          });
          // let le = document.getElementById("download-option");
          // le.style.display = "block";
        }, 500);
      } else {
        this.setState({ payload: response, isLoading: false });
      }
    });
  }

  render() {
    const {
      payload,
      isLoading,
      search,
      currentTransactions,
      currentPage,
      totalPages,
    } = this.state;
    return (
      <div>
        {/*--------------------------------------------------------------------------------*/}
        {/*Start Inner Div*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*Row*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* {!isLoading && !payload ? (
          <div>
            <ErrorPage />
          </div>
        ) : ( */}


          <div>

            {/* <Row>
              {isLoading ? (
                <div>
                  <Placeholder />
                </div>
              ) : ( */}

                <div>
                  <Row className="bg-dark pl-5 pr-5 d-flex justify-content-between">
                  <h5 className="text-font-bold-white pl-2 mt-3">
                    TRANSACTION HISTORY
                  </h5>
                  <UncontrolledButtonDropdown>
                    <DropdownToggle
                      className="bg-white text-body rounded border"
                      caret
                    >
                     MENU
                    </DropdownToggle>
                    <DropdownMenu>
                      {
                        decodeJwt().role === "service-user" ? (
                      <DropdownItem  onClick={(e) =>
                      this.setState({ toggleView: "power vending" })
                    }>
                        POWER VENDING
                      </DropdownItem>
                        ) : (null)
                      }

                      {
                        decodeJwt().role === "service-user" ? (
                          <DropdownItem  onClick={(e) =>
                            this.setState({ toggleView: "service" })
                          }>
                             SERVICE CHARGE
                            </DropdownItem>
                        ) : (null)
                      }
                      {/* <DropdownItem  onClick={(e) =>
                      this.setState({ toggleView: "power vending" })
                    }>
                        POWER VENDING
                      </DropdownItem> */}
                      {/* <DropdownItem  onClick={(e) =>
                      this.setState({ toggleView: "service" })
                    }>
                       SERVICE CHARGE
                      </DropdownItem> */}
                      <DropdownItem  onClick={(e) =>
                      this.setState({ toggleView: "dev levy" })
                    }>
                       DEVELOPMENT LEVY
                      </DropdownItem>

                      {
                        decodeJwt().role === "service-user" ? (
                          <DropdownItem  onClick={(e) =>
                            this.setState({ toggleView: "fine" })
                          }>
                             FINE
                            </DropdownItem>
                        ) : (null)
                      }

                      {/* <DropdownItem  onClick={(e) =>
                      this.setState({ toggleView: "fine" })
                    }>
                       FINE
                      </DropdownItem> */}
                    </DropdownMenu>
                    
                  </UncontrolledButtonDropdown>
                </Row>

                {this.handletoggleView()}
                </div>


              




            {/* //   )} */}
              
            {/* // </Row> */}
          </div>


        {/* )} */}



      </div>
    );
  }
}

export default TransactionHistory;
