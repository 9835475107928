import React from "react";
import { createChatBotMessage } from "react-chatbot-kit";
import BotAvatar from "./components/BotAvatar/BotAvatar";

import Options from "./components/Options/Options";
import Paymentissues from "./components/Paymentissues/Paymentissues";
import Tokenissues from "./components/Tokenissues/Tokenissues";



const config = {
  initialMessages: [
    createChatBotMessage(`Hello, How can i Help you today?`),
    createChatBotMessage("Please Respond by typing or clicking on the options below",
    {
        delay: 500,
       widget: "options",
    })
    ],
  botName : "Netplus Estate Bot",
  customComponents: {
      botAvatar: (props) => <BotAvatar {...props} />
  },
  // Defines an object of custom styles if you want to override styles
  customStyles: {
    // Overrides the chatbot message styles
    botMessageBox: {
      backgroundColor: "green",
    },
    // Overrides the chat button styles
    chatButton: {
      backgroundColor: "green",
    },
  },
  
  widgets: [
    {
      widgetName: "options",
      widgetFunc: (props) => <Options {...props}/>,
      
    },
    {
      widgetName:"tokenissues",
      widgetFunc: (props) => <Tokenissues {...props}/>,
    },
    {
      widgetName: "paymentissues",
      widgetFunc: (props) => <Paymentissues {...props}/>
    },
  ]
}


export default config