import React from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Button,
  Row,
  Col,
} from "reactstrap";
import { fetchAllEstates, getAdminEstates, fetchEstates} from "../../utilities/api";
import ErrorPage from "../../utilities/errorPage";
import Pagination from "../../utilities/Pagination";
import Metrics from "./estateMetricsComponent";

class TransactionHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      estateName: "",
      headers: [],
      payload: [],
      isLoading: false,
      search: false,
      value: 10,
      listOfAgents: [],
      success: false,
      currentEstates: [],
      currentPage: null,
      totalPages: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.searchEstates = this.searchEstates.bind(this);
    this.getEstates = this.getEstates.bind(this);
    this.onPageChanged = this.onPageChanged.bind(this);
  }

  componentDidMount() {
    this.getEstates();
  }

  onPageChanged = (data) => {
    const { payload } = this.state;
    const { currentPage, totalPages, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    const currentEstates = payload.estates.slice(offset, offset + pageLimit);
    this.setState({ currentPage, currentEstates, totalPages });
  };

  onChangeEstateName = (event) => {
    this.setState({ estateName: event.target.value });
  };

  searchEstates = () => {
    return;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ search: true, isLoading: true });
    setTimeout(() => {
      this.searchEstates();
    }, 1000);
  };

  getEstates() {
    this.setState({ isLoading: true });
    fetchEstates().then((response) => {
      if (response && response.status == 200 || response && response.status === 304) {
        console.log("respp", response.data);
        setTimeout(() => {
          this.setState({
            payload: response.data,
            isLoading: false,
          });
          let le = document.getElementById("download-option");
          le.style.display = "block";
        }, 500);
      } else {
        this.setState({ payload: response, isLoading: false });
      }
    });
  }

  render() {
    const {
      payload,
      isLoading,
      search,
      currentEstates,
      currentPage,
      totalPages,
    } = this.state;
    return (
      <div className="content">
        {/*--------------------------------------------------------------------------------*/}
        {/*Start Inner Div*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*Row*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* <div id="paymentFrame" class="m-0 mx-auto"></div> */}
        {!isLoading && !payload ? (
          <div>
            <ErrorPage />
          </div>
        ) : (
          <div>
            <Metrics />
            <Row>
              <Col md={12} className="mx-auto bg-white p-5 rounded">
                <Card className="demo-icons remove_shadow">
                  <CardHeader>
                    <h3 id="show-error">Server error, please try again</h3>
                  </CardHeader>
                  <CardBody className="">
                    <Form onSubmit={this.handleSubmit}>
                      <Row form>
                        <Col className="mt-2" md={5}>
                          <FormGroup>
                            {/* <Label className="font-weight-bolder text-dark" for="exampleFrom">FROM</Label> */}
                            <Input
                              type="text"
                              name="estateName"
                              required
                              id="estateName"
                              onChange={this.onChangeEstateName}
                              placeholder="ENTER ESTATE NAME"
                            />
                          </FormGroup>
                        </Col>
                        <Col md={5}>
                          {/* <FormGroup> */}
                          <Button
                            className="btn btn-md bg-green text-uppercase"
                            type="submit"
                            disabled={true}
                          >
                            {isLoading ? "Loading…" : "Search"}
                          </Button>
                          {/* <Label for="exampleTo" className="font-weight-bolder text-dark">To</Label>
                        <Input
                          type="date"
                          required
                          name="date"
                          id="exampleTo"
                          onChange={this.onChangeEndDate}
                          placeholder="date placeholder"
                        /> */}
                          {/* </FormGroup> */}
                        </Col>
                      </Row>
                      {/* <FormGroup>
                    <div class="text-end ml-5">
                      <Button
                        className="btn btn-lg bg-green text-uppercase"
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading ? "Loading…" : "Search"}
                      </Button>
                    </div>
                  </FormGroup> */}
                    </Form>
                  </CardBody>
                  {/* <CardBody className="all-icons text-center">
              </CardBody> */}
                </Card>

                <Card id="download-option">
                  <div className="bg-white rounded w-100">
                    {!isLoading && payload.estates && (
                      <div class="table-responsive">
                        <table class="table table-hover table-striped table-bordered w-100 ">
                          <thead class="thead-dark">
                            <tr>
                              <th>ID</th>
                              <th>Transaction Value</th>
                              <th>Meter Number</th>
                              <th>Ref</th>
                              <th>Transaction Date</th>
                              <th>Transaction Status</th>
                              <th>Transaction Type</th>
                            </tr>
                          </thead>
                          {currentEstates.map((estate) => (
                            <tbody>
                              <tr key={estate.id} role="row" className="odd">
                                <td className="sorting_1">{estate.id}</td>
                                <td>{estate.amount}</td>
                                <td>{estate.meter_number}</td>
                                <td>{estate.payment_transaction_id}</td>
                                <td>{estate.createdAt}</td>
                                <td>{estate.transactionStatus}</td>
                                <td>{estate.transaction_type}</td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      </div>
                    )}
                  </div>
                  <div className="pagination-wrapper mt-5">
                    <div
                      className="dataTables_info"
                      id="myTable_info"
                      role="status"
                      aria-live="polite"
                    >
                      {!isLoading && payload.estates && (
                        <div>
                          {!isLoading && currentPage && (
                            <span className="current-page d-inline-block h-100 pl-4 text-secondary">
                              Page{" "}
                              <span className="font-weight-bold">
                                {currentPage}
                              </span>{" "}
                              /{" "}
                              <span className="font-weight-bold">
                                {payload.estates.length === 0
                                  ? 1
                                  : Math.ceil(
                                      payload.estates.length / this.state.value
                                    )}
                              </span>
                            </span>
                          )}
                          {!isLoading &&
                            ` of ${payload.estates.length} entries`}
                        </div>
                      )}
                    </div>
                    {!isLoading && payload.estates && (
                      <div className="d-flex flex-row justify-content-end">
                        {
                          <Pagination
                            totalRecords={payload.estates.length}
                            pageLimit={this.state.value}
                            pageNeighbours={1}
                            onPageChanged={this.onPageChanged}
                          />
                        }
                      </div>
                    )}
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

export default TransactionHistory;
