import React from "react";
import Select from "react-select";
import {
  Form,
  FormGroup,
  Row,
  Col,
  CustomInput,
  ListGroup,
  ListGroupItem,
  Button,
  Table,
} from "reactstrap";
import { getUploadedMeters, getAdminEstates } from "../../utilities/api";
import Pagination from "../../utilities/Pagination";
import ErrorPage from "../../utilities/errorPage";
import Placeholder from "../../utilities/placeholder";

class UploadedMeter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      payload: null,
      isLoading: false,
      headers: [],
      value: 10,
      sort: "dsc",
      success: false,
      currentMeterNumbers: [],
      currentPage: null,
      totalPages: null,
      activePage: "",
	  estate_id : null,
	  estateArr: null
    };

    this.getUploadedMeterNumbers = this.getUploadedMeterNumbers.bind(this);
    this.onPageChanged = this.onPageChanged.bind(this);
    this.handleGetAdminEstate = this.handleGetAdminEstate.bind(this);
    this.handleEstateSelect = this.handleEstateSelect.bind(this);
  }

  componentWillMount() {
    // this.getUploadedMeterNumbers();
    this.handleGetAdminEstate();
  }

  onPageChanged = (data) => {
    const { payload } = this.state;

    const { currentPage, totalPages, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    const currentMeterNumbers = payload.slice(offset, offset + pageLimit);
    this.setState({ currentPage, currentMeterNumbers, totalPages });
  };

  handleEstateSelect = (event) => {
    this.setState({ estate_id: event.value });
  };

  handleGetAdminEstate() {
	this.setState({ isLoading: true });
    getAdminEstates().then((response) => {
      if (response && response.status == 200 || response && response.status === 304) {
        setTimeout(() => {
          this.setState({
           estateArr : response.data,
            isLoading: false,
          });
        }, 500);
      } else {
        this.setState({ isLoading: false });
      }
    });
  }
  getUploadedMeterNumbers(event) {
	event.preventDefault();
	if (!this.state.estate_id) return false;
    this.setState({ isLoading: true });
    getUploadedMeters({ property_id: this.state.estate_id  }).then(
      (response) => {
        if (response && response.status === 200 || response && response.status === 304) {
          setTimeout(() => {
            this.setState({ payload: response.data.array, isLoading: false });
          }, 500);
        } else if (response && response.status !== 200) {
		 this.setState({ isLoading: false });
          setTimeout(() => {
			document.getElementById("table-msg").innerHTML = "Oops! Data could not be fetched at the moment, try again";
			document.getElementById("table-msg").style.color = "red";
          }, 500);
        } else {
		  this.setState({ isLoading: false });
		  setTimeout(() => {
			document.getElementById("table-msg").innerHTML = "Oops! Data could not be fetched at the moment, try again";
			document.getElementById("table-msg").style.color = "red";
          }, 500);
        }
      }
    );
  }

  render() {
    const {
      isLoading,
      payload,
      currentMeterNumbers,
      currentPage,
      estateArr,
    } = this.state;
    const tableData = payload;

    return (
      <React.Fragment>
        <div className="mt-2">
		{isLoading ? (
          <div>
			  <Placeholder />
          </div>
        ) : (
          <Col md={"auto"} className="p-5">
            <form onSubmit={this.getUploadedMeterNumbers}>
              <FormGroup className="mx-auto mt-3">
			  
               {estateArr  && (
                  <Select
                    isLoading={isLoading}
                    isSearchable={true}
                    name="Available estate"
                    options={estateArr.map((obj) => {
                      let optionObj = {
                        value: obj.id,
                        label: obj.name,
                      };
                      return optionObj;
                    })}
                    // value={estate_id .filter(
                    //   (obj) => obj.value === this.state.property_id
                    // )}
                    onChange={this.handleEstateSelect}
                    placeholder="SELECT ESTATE"
                    required
                  />
                )}
              </FormGroup>
              <FormGroup className="mx-auto">
                <Button
				  type="submit"
				  disabled={isLoading}
                  style={{
                    color: "white",
                    backgroundColor: "#1DE054",
                    borderRadius: "5px",
                    textTransform: "uppercase",
                  }}
                  className="border"
                >
                 {isLoading ? "Searching" : "Search"}
                </Button>
              </FormGroup>
            </form>
          </Col>
		)}
        </div>
        {!isLoading && !tableData ? (
          <div>
            <p id="table-msg">
              Please select estate to view uploaded meters
            </p>
          </div>
        ) : (
          <div>
            {isLoading ? (
              <div>
                <Placeholder />
              </div>
            ) : (
              <div>
                <Table bordered className="mt-2 grey-bg table-hover">
                  <thead>
                    <tr>
                      <th>NAME</th>
                      <th>METER NUMBER</th>
                      {/* <th>PROPERTY ID</th>
                                            <th>PROPERTY NAME</th> */}
                      <th>ADDRESS</th>
                    </tr>
                  </thead>

                  {currentMeterNumbers.map((user) => (
                    <tbody>
                      <tr key={user.property_id} role="row" classname="odd">
                        <td>{user.landlord_name}</td>
                        <td>{user.meter_number}</td>
                        {/* <td>{user.property_id}</td>
                                                <td>{user.property_name}</td> */}
                        <td>{user.house_address}</td>
                      </tr>
                    </tbody>
                  ))}
                </Table>

                <div className="pagination-wrapper mt-5 row">
                  <div
                    className="dataTables_info col"
                    id="myTable_info"
                    role="status"
                    aria-live="polite"
                  >
                    {!isLoading && tableData && (
                      <div>
                        {!isLoading && currentPage && (
                          <span className="current-page d-inline-block h-100 pl-4 text-secondary">
                            Page{" "}
                            <span className="font-weight-bold">
                              {currentPage}
                            </span>{" "}
                            /{" "}
                            <span className="font-weight-bold">
                              {tableData.length === 0
                                ? 1
                                : Math.ceil(
                                    tableData.length / this.state.value
                                  )}
                            </span>
                          </span>
                        )}
                        {!isLoading && ` of ${tableData.length} entries`}
                      </div>
                    )}
                  </div>

                  {!isLoading && tableData && (
                    <div className="d-flex justify-content-end col">
                      {
                        <Pagination
                          totalRecords={tableData.length}
                          pageLimit={10}
                          pageNeighbours={1}
                          onPageChanged={this.onPageChanged}
                        />
                      }
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default UploadedMeter;
