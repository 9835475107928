import React from "react";

class DataForm extends React.Component {
    constructor(props){
        super(props);
    }

    render() {
        const { values } = this.props;
        return (
            <React.Fragment>
                <form className=""
                //  onSubmit={this.props.handlePayment}
                 >

<p className="show-msg">{values.showmsg}</p>
<p className="show-msg-success">{values.showmsgSuccess}</p>

<p className="form-header">BUY INTERNET</p>


<div class="form-group landing-form-input">
    <select
        class="form-control landing-form-control custom-select"
        id="exampleFormControlSelect1"
        placeholder="SELECT NETWORK"
        value={values.selectedProvider}
        onChange={this.props.changeProvider}
        disabled={values.isLoading}
        required
    >
        <option>SELECT SERVICE PROVIDER</option>
        {values.provider.map((e, key) => {
            return <option key={key}>{e.name}</option>;
        })}
    </select>
</div>

<div class="form-group landing-form-input">
    <input
        type="email"
        class="form-control landing-form-control"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
        placeholder="ENTER EMAIL ADDRESS"
        value={values.email}
        onChange={this.props.handleChangeEmail}
        disabled={values.isLoading}
        required
    />
</div>
<div class="form-group landing-form-input">
    <input
        type="text"
        class="form-control landing-form-control"
        placeholder="ACCOUNT ID/ USER ID"
        value={values.destinationAccount}
        onChange={this.props.handleChangeDestination}
        disabled={values.isLoading}
        required
    />
</div>

<div class="form-group landing-form-input">
    <select
        class="form-control landing-form-control custom-select"
        id="exampleFormControlSelect1"
        placeholder="SELECT A PRODUCT"
        value={values.selectedPackage}
        onChange={this.props.changeInternetPackage}
        disabled={values.isLoading}
        required
    >
        <option>SELECT PLAN</option>
        {values.internetPackage.map((e, key) => {
            return <option key={key}>{e.name}</option>;
        })}
    </select>
</div>

<span className="landing-fee">
    {"Convenience Fee: " + values.payload}
</span>

<button
    type="submit"
    className="btn btn-block btn-dark landing-form-input airtime-button"
    // disabled={values.isLoading}
    disabled={true}
>
    {values.isLoading ? "LOADING…" : "BUY INTERNET"}
</button>
</form>
            </React.Fragment>
        );
    }
}

export default DataForm