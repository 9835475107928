import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col
} from "reactstrap";

// import { thead, tbody } from "variables/general";
import ErrorPage from "../../utilities/errorPage";

class RegularTables extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      payload: [],
      isLoading: false,
      value: "",
      userSummary: []
    };

    // this.onDismiss = this.onDismiss.bind(this);
    this.getDashboardMetrics = this.getDashboardMetrics.bind(this);
  }
  componentDidMount() {
    this.getDashboardMetrics("daily");
  }

  getDashboardMetrics(params) {
    // fetchDashboardMetrics(params).then(response => {
    //   if (response.status === 200) {
    //     setTimeout(() => {
    //      let rankedAgents = this.getAgentRank(response.data.userSummary);
    //       this.setState({ payload: response, isLoading: false, userSummary: rankedAgents.slice(0,10) });
    //     }, 500);
    //   } else {
    //     this.setState({ payload: response, isLoading: false });
    //     // throw new Error(response);
    //   }
    // });
  }

  render() {
    return (
      <div className="content">
        {!this.state.isLoading && this.state.payload.status !== 200 ? (
          <div>
            <ErrorPage />
          </div>
        ) : (
          <div>
            <Row>
              <Col xs={12}>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Simple Table</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Table responsive>
                      {/* <thead className="text-primary">
                    <tr>
                      {thead.map((prop, key) => {
                        if (key === thead.length - 1)
                          return (
                            <th key={key} className="text-right">
                              {prop}
                            </th>
                          );
                        return <th key={key}>{prop}</th>;
                      })}
                    </tr>
                  </thead> */}
                      {/* <tbody>
                    {tbody.map((prop, key) => {
                      return (
                        <tr key={key}>
                          {prop.data.map((prop, key) => {
                            if (key === thead.length - 1)
                              return (
                                <td key={key} className="text-right">
                                  {prop}
                                </td>
                              );
                            return <td key={key}>{prop}</td>;
                          })}
                        </tr>
                      );
                    })}
                  </tbody> */}
                    </Table>
                  </CardBody>
                </Card>
              </Col>
              <Col xs={12}>
                <Card className="card-plain">
                  <CardHeader>
                    <CardTitle tag="h4">Table on Plain Background</CardTitle>
                    <p className="card-category">
                      {" "}
                      Here is a subtitle for this table
                    </p>
                  </CardHeader>
                  <CardBody>
                    <Table responsive>
                      {/* <thead className="text-primary">
                    <tr>
                      {thead.map((prop, key) => {
                        if (key === thead.length - 1)
                          return (
                            <th key={key} className="text-right">
                              {prop}
                            </th>
                          );
                        return <th key={key}>{prop}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {tbody.map((prop, key) => {
                      return (
                        <tr key={key}>
                          {prop.data.map((prop, key) => {
                            if (key === thead.length - 1)
                              return (
                                <td key={key} className="text-right">
                                  {prop}
                                </td>
                              );
                            return <td key={key}>{prop}</td>;
                          })}
                        </tr>
                      );
                    })}
                  </tbody> */}
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

export default RegularTables;
