import React from "react";
import { NavLink, Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ExistingEsates from "./ExistingEstates";
import RegisterEstate from "./RegisterEstate";

class Estates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      headerTitle: "",
      viewEstate: "VIEW EXISTING ESTATES",
    };

  }

  render() {
    return (
      <div className="content">
        <div>
          <UncontrolledDropdown>
            <DropdownToggle caret>Menu</DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={(e) =>
                  this.setState({ viewEstate: "VIEW EXISTING ESTATES" })
                }>
                VIEW EXISTING ESTATES
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={(e) =>
                  this.setState({ viewEstate: "REGISTER NEW ESTATES ADMIN" })
                }>
                REGISTER NEW ESTATES ADMIN
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        {/* <hr />
      <p>Link Based</p> */}
        <Row>
          <Col md={12}>
            <Card>
              <CardHeader>
                <h5 className="title">
                  {this.state.viewEstate === "VIEW EXISTING ESTATES"
                    ? "VIEW EXISTING ESTATES"
                    : "REGISTER NEW ESTATE ADMIN"}
                </h5>
                {/* <p className="category">Created using Montserrat Font Family</p> */}
              </CardHeader>
              <CardBody>
                {this.state.viewEstate === "VIEW EXISTING ESTATES" ? (
                  <ExistingEsates />
                ) : (
                  <RegisterEstate />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Estates;
