import React from "react";
import { netpluspayPOP } from "../../views/TopUps/netfun";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import bgimage from "../../assets/img/bgimage.PNG";
import { buyAirtime } from "../../utilities/api";
import { getFees } from "../../utilities/api";
import shortid from "shortid";

const formValid = formErrors => {
	let valid = true;

	// validate form errors being empty
	Object.values(formErrors).forEach(val => {
		val.length > 0 && (valid = false);
	});

	return valid;
};

class Airtime extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			windowsClosed: false,
			transactionID: null,
			provider: "",
			destinationAccount: "",
			confirmDestination: "",
			amount: null,
			service: "vtu",
			payload: null,
			fee: null,
			modalResponse: "",
			storedFee: null,
			storedProvider: null,
			formErrors: {
				provider: "",
				destinationAccount: "",
				confirmDestination: "",
				amount: null
			}
		};
	}

	componentDidMount() {
		this.setState({ payload: 0 });
	}

	// componentDidUpdate(prevProps, prevState) {
	// 	if (this.state.windowsClosed) {
	// 		this.setState({
	// 			isLoading: true
	// 		});
	// 		this.handleBuyAirtime();
	// 	}
	// }

	handleChange = e => {
		e.preventDefault();

		const name = e.target.name;
		const value = e.target.value;
		let formErrors = this.state.formErrors;

		switch (name) {
			// case "destinationAccount":
			// 	formErrors.destinationAccount =
			// 		value.length < 11 || value == "" ? "Invalid Phone Number" : "";
			// 	// this.handleGetFees();
			// 	break;

			// case "confirmDestination":
			// 	formErrors.confirmDestination =
			// 		value.length < 11 || value == "" ? "Phone Number Mismatch" : "";
			// 	break;

			case "amount":
				formErrors.amount =
					value.length < 0 || value == "" ? "Required Field" : "";
				break;
			default:
				break;
		}
		this.setState({ formErrors, [name]: value });

		this.setState({ name: value });
	};

	handleCheck = e => {
		e.preventDefault();

		this.setState({
			isLoading: true
		});

		if (this.state.confirmDestination !== this.state.destinationAccount) {
			let showMsg = document.getElementById("show-msg");
			showMsg.innerHTML = "Check Phone Number";
			showMsg.style.display = "block";
			showMsg.style.fontSize = "18px";
			showMsg.style.color = "red";
			this.setState({
				isLoading: false
			});
		}  else {
			return this.handlePayment();
		}
	};

	handleGetFees = e => {
		e.preventDefault();
		const p = e.target.value;
		this.setState({
			provider: p,
			isLoading: true
		});

		setTimeout(() => {
			let data = this.state.provider;

			if (sessionStorage.getItem("Service") === this.state.provider) {
				this.setState({
					payload: sessionStorage.getItem("Fee"),
					isLoading: false
				});
			} else {
				getFees(data).then(response => {
					if (response && response.status == 200) {
						setTimeout(() => {
							this.setState({
								payload: response.data.fees,
								isLoading: false,
								storedProvider: response.data.provider,
								storedFee: response.data.fees
							});

							const s = this.state.storedProvider;
							const fee = this.state.storedFee;

							sessionStorage.setItem("Fee", fee);
							sessionStorage.setItem("Service", s);
						}, 500);
					} else {
						let showMsg = document.getElementById("show-msg");
						showMsg.innerHTML = "Service Unavailable ";
						showMsg.style.display = "block";
						showMsg.style.fontSize = "18px";
						showMsg.style.color = "red";
						this.setState({
							isLoading: false
						});
					}
				});
			}
		}, 1000);
	};

	handleBuyAirtime = () => {
		const finalamount =
			parseInt(this.state.amount);

		let data = {
			destinationAccount: this.state.destinationAccount,
			amount: finalamount,
			service: this.state.service,
			provider: this.state.provider,
			trans_id: this.state.transactionID
		};

		setTimeout(() => {
			buyAirtime(data).then(response => {
				if (response && response.status === 200) {
					let showMsg = document.getElementById("show-msg");
					showMsg.innerHTML = "Transaction Successful";
					showMsg.style.display = "block";
					showMsg.style.fontSize = "18px";
					showMsg.style.color = "green";
					this.setState({ isLoading: false });
				} else if (response && response.status === 400) {
					let showMsg = document.getElementById("show-msg");
					showMsg.innerHTML = "Transaction Failed";
					showMsg.style.display = "block";
					showMsg.style.fontSize = "18px";
					showMsg.style.color = "red";
					this.setState({ isLoading: false });
				} else {
					let showMsg = document.getElementById("show-msg");
					showMsg.innerHTML = "Server Error";
					showMsg.style.display = "block";
					showMsg.style.fontSize = "18px";
					showMsg.style.color = "red";
					this.setState({ isLoading: false });
				}
			});
		}, 4000);
	};

	handleWindowClose = () => {
		if (this.state.modalResponse === "FAILED") {
			let showMsg = document.getElementById("show-msg");
			showMsg.innerHTML = "Transaction Failed";
			showMsg.style.display = "block";
			showMsg.style.fontSize = "18px";
			showMsg.style.color = "red";
			this.setState({ isLoading: false });
		} else if (this.state.modalResponse === "CAPTURED") {
			this.handleBuyAirtime();
		} else {
			let showMsg = document.getElementById("show-msg");
			showMsg.innerHTML = "Error";
			showMsg.style.display = "block";
			showMsg.style.fontSize = "18px";
			showMsg.style.color = "red";
			this.setState({ isLoading: false });
		}
	};

	handlePayment = () => {
		// if(this.state.payload === 0) {
		// 	let showMsg = document.getElementById("show-msg");
		// 			showMsg.innerHTML = "Service Unavailable ";
		// 			showMsg.style.display = "block";
		// 			showMsg.style.fontSize = "18px";
		// 			showMsg.style.color = "red";
		// 			this.setState({
		// 				isLoading: false
		// 			});
		// } else {
		if (formValid(this.state.formErrors)) {
			const finalamount =
				parseInt(this.state.amount) ;

			netpluspayPOP.setup({
				merchant: `${process.env.REACT_APP_MERCHANT_ID}`,
				customer_name: "Bill Payment Customer",
				email: "N/A",
				amount: finalamount,
				currency_code: "NGN",
				narration: "Airtime Purchase",
				order_id: `AIRTIME${shortid.generate()}`,
				container: "paymentFrame",
				callBack: function(resp) {
					if (resp && resp.status === "CAPTURED") {
						this.setState({
							modalResponse: resp.status,
							transactionID: resp.trans_id
						});
					} else if (resp && resp.status === "FAILED") {
						this.setState({
							modalResponse: resp.status,
							transactionID: resp.trans_id
						});
						
					}
					//this.closeIframe();
				}.bind(this),
				onClose: function() {
					this.setState({ isLoading: true });
					this.handleWindowClose();
				}.bind(this)
			});
			netpluspayPOP.prepareFrame();
		} else {
			let showMsg = document.getElementById("show-msg");
			showMsg.innerHTML = "Invalid Form";
			showMsg.style.display = "block";
			showMsg.style.fontSize = "18px";
			showMsg.style.color = "red";
			this.setState({ isLoading: false });
		}
	
	};

	render() {
		const { formErrors, isLoading, payload } = this.state;

		return (
			<React.Fragment>
				<Navbar />

				<div id="paymentFrame" class="m-0 mx-auto"></div>
				<div id="netplusFrame" class="m-0 mx-auto"></div>

				<div className="container airtime-container">
					<div className="row">
						<SideBar />

						<div className="col-md-5">
							<form className="airtime-form" onSubmit={this.handleCheck}>
								<p id="show-msg"></p>
								{/* <span className="fee">
									{"Service Charge: " + this.state.payload}
								</span> */}
								<div class="form-group airtime-form-input">
									<select
										className="custom-select"
										id="exampleFormControlSelect1"
										placeholder="SELECT NETWORK"
										name="provider"
										value={this.state.provider}
										onChange={this.handleChange}
										required
									>
										<option value="">SELECT NETWORK</option>
										<option value="airtel">AIRTEL</option>
										<option value="mtn">MTN</option>
										<option value="glo">GLO</option>
										<option value="etisalat">ETISALAT</option>
									</select>

									<span className="errorMessage">{formErrors.provider}</span>
								</div>
								<div class="form-group airtime-form-input">
									<input
										type="tel"
										class="form-control"
										id="number1"
										placeholder="ENTER MOBILE NUMBER"
										name="destinationAccount"
										value={this.state.destinationAccount}
										onChange={this.handleChange}
										disabled={isLoading}
										required
									/>

									<span className="errorMessage">
										{formErrors.destinationAccount}
									</span>
								</div>
								<div class="form-group airtime-form-input">
									<input
										type="tel"
										class="form-control"
										id="number2"
										name="confirmDestination"
										placeholder="CONFIRM MOBILE NUMBER"
										value={this.state.confirmDestination}
										onChange={this.handleChange}
										disabled={isLoading}
										required
									/>

									{formErrors.confirmDestination.value ==
										formErrors.destinationAccount.value && (
										<span className="errorMessage">
											{formErrors.confirmDestination}
										</span>
									)}
								</div>
								<div class="form-group airtime-form-input">
									<input
										type="number"
										class="form-control"
										placeholder="AMOUNT"
										name="amount"
										value={this.state.amount}
										onChange={this.handleChange}
										disabled={isLoading}
										required
									/>

									<span className="errorMessage">{formErrors.amount}</span>
								</div>

								<button
									type="submit"
									className="btn btn-block airtime-form-input btn-style"
									disabled={isLoading}
								>
									{isLoading ? "LOADING…" : "PAY"}
								</button>
							</form>
						</div>

						<div className="col-md-5">
							<img src={bgimage} className="img-container" />
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Airtime;