import React from "react";
import PayBills from "./PayBills";
import bills from "../../../assets/img/bills.jpg";

class MainBills extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<React.Fragment>
				<div className="row animate__animated animate__slideInRight">
					<div className="col left-col">
						<img className="bills-page-img" src={bills}></img>
					</div>

					<div className="col">
						<div className="form-container ">
							<PayBills />
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default MainBills;
