import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Modal from "../../components/modals/Modals";
import UpdateModal from "./UpdateMeter";
import user_avatar from "../../assets/img/user_avatar.png";
import ErrorPage from "../../utilities/errorPage";
import { fetchSingleUser } from "../../utilities/api";
import Placeholder from "../../utilities/placeholder";

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: null,
      isLoading: true,
      value: "",
      userSummary: [],
    };

    this.getUserProfile = this.getUserProfile.bind(this);
  }
  componentDidMount() {
    this.getUserProfile();
  }

  getUserProfile() {
    fetchSingleUser().then((response) => {
      if (response && response.status === 200 || response && response.status === 304) {
        setTimeout(() => {
          this.setState({ payload: response, isLoading: false });
        }, 500);
      } else {
        this.setState({ payload: response, isLoading: false });
        // throw new Error(response);
      }
    });
  }

  render() {
    const { payload, isLoading } = this.state;
    return (
      <div className="content">
        {isLoading && !payload ? (
          <div>
            <Placeholder />
            <Placeholder />
            <Placeholder />
          </div>
        ) : (
          <div>
            {(!isLoading && !payload) || payload.status !== 200 ? (
              <div>
                <ErrorPage />
              </div>
            ) : (
              <Row className="bg-white rounded d-flex h-80">
                <Col sm="6">
                  <Card className="card-user remove_shadow">
                    <CardHeader>
                      <CardTitle>Profile</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Form className="form-profile">
                        <FormGroup>
                          <Label for="exampleFirst">FIRST NAME</Label>
                          <Input
                            type="text"
                            disabled
                            name="first_name"
                            id="exampleFirst"
                            placeholder={payload.data.user.first_name}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="exampleLast">LAST NAME</Label>
                          <Input
                            type="text"
                            disabled
                            name="last_name"
                            id="exampleLast"
                            placeholder={payload.data.user.last_name}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="exampleEmail">EMAIL</Label>
                          <Input
                            type="email"
                            disabled
                            name="email"
                            id="exampleEmail"
                            placeholder={payload.data.user.email}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="examplePhone">PHONE</Label>
                          <Input
                            type="tel"
                            disabled
                            name="phone"
                            id="examplePhone"
                            placeholder={payload.data.user.phone}
                          />
                        </FormGroup>
                        {/* <FormGroup>
                      <Label for="exampleMeter">METER NUMBER</Label>
                      <Input
                        type="text"
                        disabled
                        name="meter"
                        id="exampleMeter"
                        placeholder={payload.data.meter_number}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleMeter">ESTATE </Label>
                      <Input
                        type="text"
                        disabled
                        name="meter"
                        id="exampleMeter"
                        placeholder={payload.data.property_name}
                      />
                    </FormGroup> */}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="6">
                  <Card body className="remove_shadow">
                    <p>
                      <img
                        class="img-fluid mx-auto d-block"
                        src={user_avatar}
                        alt="User avatar"
                      />
                    </p>
                    <Row>
                      <div className="update ml-auto mr-auto">
                        <Modal
                          buttonLabel={"Update Profile"}
                          userObj={payload}
                          background={"#1DE054"}
                        />
                        {/* <Button className="bg-green" round >
                      Update Profile
                    </Button> */}
                      </div>
                    </Row>
                    <Row>
                      <div className="update ml-auto mr-auto">
                        <Modal buttonLabel={"Change Password"} />
                        {/* <Button round>
                      Change Password
                    </Button> */}
                      </div>
                    </Row>
                    <Row>
                      <div className="update ml-auto mr-auto">
                        <UpdateModal buttonLabel={"Update Meter Number"} />
                        {/* <Button round>
                      Change Password
                    </Button> */}
                      </div>
                    </Row>
                  </Card>
                </Col>
              </Row>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default User;
