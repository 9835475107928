import React, { useState } from "react";
import SignUpForm from './SignUpForm';
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Input, FormGroup, Col, CustomInput } from "reactstrap";
import { userLogin, getServicePayments } from "../../../utilities/api";
import { decodeJwt } from "../../../utilities/processJWT";

export const SignIn = function () {

  const [showmsg, setShowMsg] = useState("");

  return (
    <Formik
      initialValues={{ email: "", password: "", isLoggedIn: false}}
      onSubmit={(values, { setSubmitting }, props) => {
        setTimeout(() => {
          userLogin({
            email: values.email,
            password: values.password,
          }).then((response) => {
            // let showError = document.querySelector(".show-error");
            if (response && response.status === 400) {
              setShowMsg("Email or password incorrect");
              // let showError = document.querySelector(".show-error1");
              // showError.style.display = "block";
              // showError.innerHTML = response.data;
              setSubmitting(false);
            } else if (response.data.token) {
              if (!decodeJwt(response.data.token)) {
                // let showError = document.querySelector(".show-error2");
                // showError.style.display = "block";
                // showError.innerHTML = "Opps! Server error";
                setShowMsg("Email or password incorrect")
                setSubmitting(false);
              } else if (
                decodeJwt(response.data.token).role === "service-user"
              ) {

                setShowMsg("Server Error, please contact Administrator")
                setSubmitting(false);


                // currently disabled service user login
               
                // localStorage.setItem("token", response.data.token);
                // localStorage.setItem("isLoggedIn", true);
                // localStorage.setItem("firstSignup", false);
              
                // localStorage.setItem("redirect", false);
                // window.location.reload(true);
              } else if (
                decodeJwt(response.data.token).role === "netplus-admin"
              ) {
                localStorage.setItem("isLoggedIn", true);
                localStorage.setItem("token", response.data.token);
                localStorage.setItem("redirect", false);
                window.location.reload(true);
              } else if (
                decodeJwt(response.data.token).role === "service-admin"
              ) {
                localStorage.setItem("isLoggedIn", true);
                localStorage.setItem("token", response.data.token);
                localStorage.setItem("redirect", false);
                window.location.reload(true);
              }else if (
                decodeJwt(response.data.token).role === "service-owner"
              ) {
                localStorage.setItem("isLoggedIn", true);
                localStorage.setItem("token", response.data.token);
                localStorage.setItem("redirect", false);
                window.location.reload(true);
              }  
              else {
                // let showError = document.querySelector("show-error6");
                // showError.style.display = "block";
                setShowMsg("Email or password incorrect")
                setSubmitting(false);
              }
            } else {
              // let showError = document.querySelector("show-error3");
              // showError.style.display = "block";
              // showError.innerHTML = "Opps! Server error";
              setShowMsg("There was a server error. This could be caused by a slow network connection or the service not responding to request at the moment. Please try again later");
              setSubmitting(false);
            }
          })
          .catch(function (error) {
            // document.querySelector(".show-error4").style.display = "block";
            // document.querySelector(".show-error5").innerHTML = "Opps! Server error";
            setShowMsg("There was a server error. This could be caused by a slow network connection or the service not responding to request at the moment. Please try again later");
            setSubmitting(false);
          });
        }, 1000);
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email("Invalid email address").required("Required"),
        password: Yup.string().required("No password provided."),
        // .min(8, "Password is too short - should be 8 chars minimum.")
        // .matches(/(?=.*[0-9])/, "Password must contain a number.")
      })}
    >
      {(props) => {
        const toggle = (event) => {
          event.preventDefault();
          let showPassword = document.querySelector("#toggle-password");
          let password = document.querySelector("#password");
          if (event.target.className === "fa fa-fw fa-eye field-icon") {
            showPassword.className = "fa fa-fw fa-eye-slash field-icon";
            password.type = "text";
          } else {
            showPassword.className = "fa fa-fw fa-eye field-icon";
            password.type = "password";
          }
        };

        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          nextStep
        } = props;

// class SignInForm extends React.Component {
// 	constructor(props) {
// 		super(props);
// 	}

// 	render() {
		return (
			
				<form onSubmit={handleSubmit} className="">
					{/* <p id="show-msg"></p> */}
					<p className="show-msg">{showmsg}</p>
					<p className="form-header">SIGN IN TO BUY POWER</p>

					<div class="form-group landing-form-input">
						<input
							type="email"
							class="landing-form-control form-control"
							placeholder="ENTER EMAIL ADDRESS"
							name="email"
							value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    //className={errors.email && touched.email}
							required
						/>
						 {errors.email && touched.email && (
                    <div className="input-feedback">{errors.email}</div>
                  )}
					</div>

					<div class="form-group landing-form-input">
						<input
							type="password"
							class="landing-form-control form-control"
							placeholder="PASSWORD"
							name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    //className={errors.password && touched.password}
							required
						/>
						 <span
                    id="toggle-password"
                    toggle=".password"
                    className="fa fa-fw fa-eye field-icon"
                    onClick={toggle}
                  ></span>
                  {errors.password && touched.password && (
                    <div
                      style={{ color: "red", fontSize: ".8em" }}
                      className="input-feedback"
                    >
                      {errors.password}
                    </div>
                  )}
					</div>
					<p className="forgot-text">
						Forgot Password?<Link to="/forgot-password" className="reset-text ml-1">Reset</Link>
					</p>

					<button
						type="submit"
						className="btn btn-block btn-dark landing-form-input2 airtime-button"
						disabled={isSubmitting}
            // disabled={true}
					>
						{isSubmitting ? "LOADING…" : "SIGN IN"}
						
					</button>
					{/* <p className="forgot-text">
						DON'T HAVE AN ACCOUNT YET?
						<Link to = "/sign-up">SIGN UP</Link>
							
							
						
					</p> */}
				</form>
			
		);
	}
}

</Formik>
  );
};

class SignInForm extends React.Component{
// function SignInForm(props) {
  constructor(props) {
    super(props);

    this.state = {
      showmsg: ""
    }
  }
  render() {
	return (
		<React.Fragment>
      <div className="landing-form">
			<SignIn showmsg={this.state.showmsg}/>
      <p className="forgot-text">
						DON'T HAVE AN ACCOUNT YET?
						<span><a className="reset-text ml-2" href="javascript:void(0)" onClick={this.props.nextStep}> SIGN UP</a></span>
							
							
						
					</p>
          </div>
		</React.Fragment>
	)
}
}
export default SignInForm;