import React from "react";

class MobileData extends React.Component {
	constructor(props) {
		super(props);
	}

	

	render() {
		const { values } = this.props;

		return (
			<React.Fragment>
				<form className=""
				//  onSubmit={this.props.handleCheck}
				 >
					<p className="show-msg">{values.showmsg}</p>
					<p className="show-msg-success">{values.showmsgSuccess}</p>
					<p className="form-header">BUY DATA</p>
					{/* <div class="form-group landing-form-input">
						<select
							className=" form-control landing-form-control custom-select"
							id="exampleFormControlSelect1"
							placeholder="SELECT CATEGORY"
							name="provider"
							value={values.category}
							onChange={this.props.handleFormChange}
							disabled={values.isLoading}
							required
						>
							<option value="">SELECT CATEGORY</option>
							<option value="mobile-airtime">MOBILE AIRTIME</option>
							<option value="mobile-data">MOBILE DATA</option>
						</select>

						{/* <span className="errorMessage">{formErrors.provider}</span> */}
					{/* </div> */}

					<div class="form-group landing-form-input">
						<select
							className=" form-control landing-form-control custom-select"
							id="exampleFormControlSelect1"
							placeholder="SELECT CATEGORY"
							name="provider"
							value={values.category}
							onChange={this.props.handleFormChange}
							disabled={values.isLoading}
							required
						>
							<option value="">SELECT CATEGORY</option>
							<option value="vtu">MOBILE AIRTIME</option>
							<option value="pay_bill">MOBILE DATA</option>
						</select>

						{/* <span className="errorMessage">{formErrors.provider}</span> */}
					</div>

					<div class="form-group landing-form-input">
						<select
							className=" form-control landing-form-control custom-select"
							id="exampleFormControlSelect1"
							placeholder="SELECT NETWORK"
							name="provider"
							value={values.provider}
							onChange={this.props.changeProvider}
							disabled={values.isLoading}
							required
						>
							<option>SELECT NETWORK</option>
							{values.dataProvider.map((e, key) => {
								return <option key={key}>{e.name}</option>;
							})}
						</select>

						{/* <span className="errorMessage">{formErrors.provider}</span> */}
					</div>

					<div class="form-group landing-form-input">
						<select
							className=" form-control landing-form-control custom-select"
							id="exampleFormControlSelect1"
							placeholder="SELECT PLAN"
							value={values.selectedDataPlan}
							onChange={this.props.changeDataPlan}
							disabled={values.isLoading}
							required
						>
							<option>SELECT PLAN</option>
							{values.dataPlan.map((e, key) => {
								return <option key={key}>{e.name}</option>;
							})}
						</select>

						{/* <span className="errorMessage">{formErrors.provider}</span> */}
					</div>

					<div class="form-group landing-form-input">
						<input
							type="tel"
							class="form-control landing-form-control"
							id="number1"
							placeholder="ENTER MOBILE NUMBER"
							name="destinationAccount"
							value={values.destinationAccount}
							onChange={this.props.handleChange}
							disabled={values.isLoading}
							required
						/>

						{/* <span className="errorMessage">
										{formErrors.destinationAccount}
									</span> */}
					</div>
					<div class="form-group landing-form-input">
						<input
							type="tel"
							class="form-control landing-form-control"
							id="number2"
							name="confirmDestination"
							placeholder="CONFIRM MOBILE NUMBER"
							value={values.confirmDestination}
							onChange={this.props.handleChange}
							disabled={values.isLoading}
							required
						/>

						{/* {formErrors.confirmDestination.value ==
										formErrors.destinationAccount.value && (
										<span className="errorMessage">
											{formErrors.confirmDestination}
										</span>
									)} */}
					</div>

					

					{/* <div class="form-group landing-form-input">
									<input
										type="number"
										class="form-control landing-form-control"
										placeholder="AMOUNT"
										name="amount"
										value={values.amount}
										onChange={this.props.handleChange}
										required
									/>

									{/* <span className="errorMessage">{formErrors.amount}</span> */}
					 {/* </div>  */}

					<button
						type="submit"
						className="btn btn-dark  btn-block landing-form-input"
						// disabled={values.isLoading}
						disabled={true}
					>
						{values.isLoading ? "LOADING…" : "PAY"}
					</button>
				</form>
			</React.Fragment>
		);
	}
}

export default MobileData;
