import React from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Button,
  Row,
  Spinner,
  Col,
} from "reactstrap";
import { searchPendingTransactions, retryTopup, getPendingTopups } from "../../utilities/api";
import { CSVLink } from "react-csv";
import ErrorPage from "../../utilities/errorPage";
import Placeholder from "../../utilities/placeholder";
import Pagination from "../../utilities/Pagination";
import { arrTypeCheck } from "../../utilities/dataTypeCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { currencyFormat, FormatTime } from "../../utilities/currency_time_formata";

class TransactionHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      topupRef: "",
      headers: [],
      payload: null,
      isLoading: false,
      search: false,
      value: 10,
      listOfAgents: [],
      success: false,
      currentTransactions: [],
      currentPage: null,
      totalPages: null,
      retrying: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.searchTransactions = this.searchTransactions.bind(this);
    this.getTransactions = this.getTransactions.bind(this);
    this.onPageChanged = this.onPageChanged.bind(this);
    this.handleRetry = this.handleRetry.bind(this);
  }

  componentWillMount() {
    this.getTransactions();
  }

  onPageChanged = (data) => {
    const { payload } = this.state;
    let sortedPayload = payload.pendingTransactions.slice().sort(function(a, b) {
      a = new Date(a.createdAt);
      b = new Date(b.createdAt);
      return a > b ? -1 : a < b ? 1 : 0;
    });
    const { currentPage, totalPages, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    const currentTransactions = sortedPayload.slice(
      offset,
      offset + pageLimit
    );
    this.setState({ currentPage, currentTransactions, totalPages });
  };

  onChangeStartDate = (event) => {
    this.setState({ startDate: event.target.value });
  };

  onChangeEndDate = (event) => {
    this.setState({ endDate: event.target.value });
  };


  onChangeTopupRef = (event) => {
    this.setState({ topupRef: event.target.value });
  };

  searchTransactions() {
    const {startDate, endDate, topupRef} = this.state;
    this.setState({ isLoading: true });
    searchPendingTransactions({
      from: startDate,
      to: endDate,
      topupRef: topupRef
    }).then((response) => {
      if (response && response.status === 200 || response && response.status === 304) {
        setTimeout(() => {
          this.setState({
            payload: response.data,
            isLoading: false,
            topupRef: ""
          });
        }, 500);
      } else {
        this.setState({ payload: response, isLoading: false, topupRef: "" });
      }
    });
  }

  handleRetry(topupRef, userId) {
    this.setState({ retrying: true });
    setTimeout(() => {
    return retryTopup({ topupRef: topupRef, userId: userId }).then((response) => {
      if (!response || response.status !== 200 ) {
        document.getElementById("show-error").innerHTML = response.data? response.data.reason : "Retry unsuccessful"
        document.getElementById("show-error").style.display = "block";
        this.setState({ retrying: false});
      } else if (response.status === 200 || response.status === 304) {
        document.getElementById("show-error").style.color = "green";
        document.getElementById("show-error").style.display = "block";
        this.setState({ retrying: false });
        document.getElementById("show-error").innerHTML = response.data.reason ? response.data.reason : response.data.topupToken;
      } else {
        this.setState({ retrying: false });
        document.getElementById("show-error").innerHTML = "There was a server error. This could be caused by a slow network connection or the service not responding to request at the moment. Please try again later";
        return;
        // netplus-admin
      }
    }) .catch(function (error) {
      let showMsg = document.getElementById("show-error");
      this.setState({
       retrying: false,
      })
      // setTimeout(() => {
      showMsg.innerHTML = "There was a server error. This could be caused by a slow network connection or the service not responding to request at the moment. Please try again later";
      showMsg.style.display = "block";
    // }, 100)
    });
  }, 100)
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ search: true });
    setTimeout(() => {
      this.searchTransactions();
      this.setState({ search: false });
    }, 100);
  };

  getTransactions() {
    this.setState({ isLoading: true });
    getPendingTopups().then((response) => {
      if (response && response.status == 200 || response && response.status === 304) {
        setTimeout(() => {
          this.setState({
            payload: response.data,
            isLoading: false
          });
        }, 500);
      } else {
        this.setState({ payload: response, isLoading: false });
      }
    });
  }

  render() {
    const {
      payload,
      isLoading,
      search,
      currentTransactions,
      currentPage,
      totalPages,
    } = this.state;
    return (
      <div>
        {/*--------------------------------------------------------------------------------*/}
        {/*Start Inner Div*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*Row*/}
        {/*--------------------------------------------------------------------------------*/}
        {!isLoading && !payload ? (
          <div>
            <ErrorPage />
          </div>
        ) : (
            <div>
              <Row>
                {isLoading ? (
                  <div>
                    <Placeholder />
                  </div>
                ) : (
                    <Col md={12} className="mx-auto p-5 rounded mt-5">
                      <Card className="demo-icons shadow-none">
                        <CardHeader>
                          <h6>
                            {this.state.success &&
                              payload.pendingTransactions.length < 1 &&
                              "No transaction yet"}
                          </h6>

                          <div>
              <Button
                onClick={() => window.location.reload(true)}
                type="button"
                style={{
                  color: "black",
                  textTransform: "none",
                  float: "right",
                  // display: "none",
                }}
                id="invalidRefreshBtn"
                className="border btn-link"
              >
                <FontAwesomeIcon icon={"sync"} />
                &nbsp;Refresh
              </Button>
            </div>
                        </CardHeader>
                        <CardBody className="mt-5">
                          <h3 id="show-error">Server error, please try again</h3>
                          {this.state.retrying && <div className="ml-5 mb-3"><Spinner animation="grow" /></div>}
                          <Form onSubmit={this.handleSubmit}>
                            <Row form>
                              <Col className="" md={5}>
                                <FormGroup>
                                  <Label
                                    className="font-weight-bolder text-dark"
                                    for="exampleFrom"
                                  >
                                    FROM
                              </Label>
                                  <Input
                                    type="date"
                                    name="date"
                                    id="exampleFrom"
                                    onChange={this.onChangeStartDate}
                                    placeholder="date placeholder"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={5}>
                                <FormGroup>
                                  <Label
                                    for="exampleTo"
                                    className="font-weight-bolder text-dark"
                                  >
                                    To
                              </Label>
                                  <Input
                                    type="date"
                                    name="date"
                                    id="exampleTo"
                                    onChange={this.onChangeEndDate}
                                    placeholder="date placeholder"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={5}>
                                <FormGroup>
                                  <Label  className="font-weight-bolder text-dark" for="topupRef">Transaction Reference</Label>
                                  <Input
                                    type="text"
                                    name="topupRef"
                                    value={this.state.topupRef}
                                    onChange={this.onChangeTopupRef}
                                    placeholder="Enter topup reference id"
                                    id="topupRef"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <FormGroup>
                              <div class="text-end">
                                <Button
                                  className="btn btn-lg bg-green text-uppercase"
                                  type="submit"
                                  disabled={search}
                                >
                                  {search ? "Loading…" : "Search"}
                                </Button>
                              </div>
                            </FormGroup>
                          </Form>
                        </CardBody>
                        {/* <CardBody className="all-icons text-center">
              </CardBody> */}
                      </Card>
                      <Card>
                        <div className="bg-white rounded w-100">
                          {!isLoading && payload.pendingTransactions && (
                            <div class="table-responsive">
                              <table class="table table-hover table-striped table-bordered w-100 ">
                                <thead class="thead-dark">
                                  <tr>
                                    <th>Name</th>
                                    <th>Transaction Amount</th>
                                    <th>Meter Number</th>
                                    <th>Transaction ID</th>
                                    <th>Retry Ref</th>
                                    <th>Date</th>
                                    <th>Initial Status</th>
                                    <th>Retry Status</th>
                                    <th>Message</th>
                                    <th>Pending</th>
                                  </tr>
                                </thead>
                                {currentTransactions.map((pending_transaction) => (
                                  <tbody>
                                    <tr
                                      key={pending_transaction.id}
                                      role="row"
                                      className="odd"
                                    >
                                    <td className="sorting_1">
                                    {pending_transaction.user_name}
                                  </td>
                                      <td>{currencyFormat(pending_transaction.amount)}</td>
                                      <td>{pending_transaction.meter_number}</td>
                                      <td>{pending_transaction.payment_transaction_id}</td>
                                      <td>{pending_transaction.topupRetryReference}</td>
                                      <td>{FormatTime(pending_transaction.createdAt)}</td>
                                      <td>{pending_transaction.transactionStatus}</td>
                                      <td>{pending_transaction.topupStatus}</td>
                                      <td>{pending_transaction.netpluspay_message}</td>
                                      <td><button className="btn btn-primary" onClick={() => this.handleRetry(pending_transaction.topupRetryReference, pending_transaction.user_id)}>Retry</button></td>
                                    </tr>
                                  </tbody>
                                ))}
                              </table>
                            </div>
                          )}
                        </div>
                        <div className="pagination-wrapper mt-5">
                          {/* <div
                        className="dataTables_info"
                        id="myTable_info"
                        role="status"
                        aria-live="polite"
                      >
                        <div
                          style={{
                            color: "blue",
                            fontWeight: "bolder",
                            fontSize: "larger",
                          }}
                        >
                          <i className="mdi mdi-file-xml ml-2">
                            {!isLoading && payload.transactions ? (
                              <CSVLink
                                data={this.state.payload.transactions}
                                headers={this.state.headers}
                                className="text-body small font-weight-bold"
                                filename={"electricity_bill_transactions.csv"}
                              >
                                <FontAwesomeIcon icon={"file-export"} />
                                &nbsp;EXPORT
                              </CSVLink>
                            ) : null}
                          </i>
                        </div>
                      </div> */}
                          {!isLoading && payload.pendingTransactions && (
                            <div className="d-flex flex-row justify-content-end">
                              {
                                <Pagination
                                  totalRecords={payload.pendingTransactions.length}
                                  pageLimit={this.state.value}
                                  pageNeighbours={1}
                                  onPageChanged={this.onPageChanged}
                                />
                              }
                            </div>
                          )}
                        </div>
                      </Card>
                    </Col>
                  )}
              </Row>
            </div>
          )}
      </div>
    );
  }
}

export default TransactionHistory;
