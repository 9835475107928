import React from "react";
import { Form, FormGroup, Input, Row, Col } from "reactstrap";

import { Formik } from "formik";
import * as Yup from "yup";
import { createServiceAdmin } from "../../utilities/api";

const CreateForm = function () {
  return (
    <Formik
      initialValues={{
        service_name: "",
      }}
      onSubmit={(values, { setSubmitting }, props) => {
        setTimeout(() => {
          createServiceAdmin({
            service_name: values.service_name,
          }).then((response) => {
            let showError = document.getElementById("show-error");
            if (response && response.status === 200 || response && response.status === 304) {
              showError.style.display = "block";
              showError.style.color = "green";
              showError.innerHTML = "Service created";
              setTimeout(() => {
                window.location.reload(true);
              }, 0);
            } else if (response && response.status === 400) {
              showError.style.display = "block";
              showError.innerHTML = "This service name already exist";
              setSubmitting(false);
            } else {
              showError.style.display = "block";
              showError.innerHTML = "Opps! Server error";
              setSubmitting(false);
            }
          });
        }, 1000);
      }}
      validationSchema={Yup.object().shape({
        service_name: Yup.string()
          .trim()
          .matches(
            /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!@-_?&%]{2,}$/,
            "At least two letters are expected"
          )
          .required("service name is required"),
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          // <div className="login-container">
          <Form onSubmit={handleSubmit} className="login-form">
            <h3 id="show-error">Input error</h3>
            <FormGroup>
              <Input
                name="service_name"
                type="text"
                placeholder="Enter estate name"
                value={values.service_name}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.service_name && touched.service_name && "error"
                }
              />
              {errors.service_name && touched.service_name && (
                <div
                  style={{ color: "red", fontSize: ".8em" }}
                  className="input-feedback"
                >
                  {errors.service_name}
                </div>
              )}
            </FormGroup>
            <button
              className="btn bg-green text-uppercase float-right"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Loading…" : "REQUEST"}
            </button>
          </Form>
          // </div>
        );
      }}
    </Formik>
  );
};

class CreateNewService extends React.Component {
  render() {
    return (
      <div>
        {/*--------------------------------------------------------------------------------*/}
        {/*Start Inner Div*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*Row*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* <div id="paymentFrame" class="m-0 mx-auto"></div> */}
        <Row className="bg-white center-div">
          <Col md={12} className="p-5 rounded">
            <h6>REQUEST NEW ESTATE</h6>
            <p>
              Please enter the name of the estate you will like to add and our
              admin will contact you within 3-5 days.
            </p>
            <CreateForm />
          </Col>
        </Row>
      </div>
    );
  }
}

export default CreateNewService;
