/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, { useState } from "react";
import {
  Button,
  Input,
  FormGroup,
  Col,
  Label,
  Container,
  Form,
  Row,
} from "reactstrap";
import { ForgotPassword } from "../../utilities/api";
import emailImage from "../../assets/img/emailImage.png";

class ForgotPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      email: "",
      formType: "form",
      isLoading: false,
      response: null,
      properties: [],
    };

    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toggleLogin = this.toggleLogin.bind(this);
    this.inputRef = React.createRef();
    this.handleForgotPassword = this.handleForgotPassword.bind(this);
    this.goHome = this.goHome.bind(this);
  }

  handleChange = (event) => {
    this.setState({
      email: event.target.value,
    });
  };
  handleForgotPassword(event) {
    const { email } = this.state;
    event.preventDefault();
    this.setState({ isLoading: true });
    localStorage.setItem("email", email);
    let resendEmail = localStorage.getItem("email");
    const getEmail = () => {
      return resendEmail ? resendEmail : email;
    };

    ForgotPassword({ email: getEmail() }).then((response) => {
      if (response && response.status === 200 && response.data.mailResponse == "succeeded") {
        setTimeout(() => {
          this.setState({
            formType: "responseMessage",
            response: response.data.mailResponse,
            isLoading: false,
          });
          document.getElementById("show-email-response").innerHTML = `We emailed reset password link to ${this.state.email}`;
          document.getElementById("show-email-response-OK-one").style.display = "block";
          document.getElementById("show-email-response-OK-two").style.display = "block";
          document.getElementById("show-email-response-OK-three").style.display = "block";
        }, 500);
      } else {
        setTimeout(() => {
          this.setState({
            formType: "responseMessage",
            response: response.mailResponse,
          });
          document.getElementById("show-email-response").innerHTML = `We couldn't email reset password link to ${this.state.email}. This could be as a result of network or server error, please try again later
          `
        }, 500);
      }
    });
  }

  goHome() {
    localStorage.clear();
    window.location.replace("/");
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  toggleNested() {
    window.location.replace("/#confirmEmail");
  }

  toggleLogin() {
    this.setState({
      formType: "form",
    });
  }

  render() {
    return (
      <div className="h-100 d-flex justify-content-center align-items-center email-bg h-100">
        <Container className="themed-container" fluid={true}>
          <Row>
            {/* <Col container-xl> */}
            <Col sm="4" className="remove_shadow">
              <Button
                className="btn btn-link text-success text-uppercase"
                type="submit"
                onClick={this.goHome}
              >
                <i className="nc-icon  nc-minimal-left" />
                Back
              </Button>
              <p>
                <img
                  class="img-fluid mx-auto d-block"
                  src={emailImage}
                  alt="User avatar"
                />
              </p>
            </Col>
            {this.state.formType === "form" ? (
              <Col md="6" className="remove_shadow">
                <h6 className="">Please enter your email below</h6>
                <Form inline onSubmit={this.handleForgotPassword}>
                  <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                    <Label for="exampleEmail" className="mr-sm-2">
                      Email
                    </Label>
                    <Input
                      type="email"
                      onChange={this.handleChange}
                      required
                      name="email"
                      id="exampleEmail"
                      placeholder="myemail@example.com"
                    />
                  </FormGroup>
                  <Button type="submit" className="bg-green float-right" round>
                    Submit
                  </Button>
                </Form>
              </Col>
            ) : (
              <Col sm="6" className="remove_shadow">
                <di className="show-error"></di>
                <h2 className="text-number-green">
                  {!this.state.isLoading && this.state.response}
                </h2>
                <p id="show-email-response" className="text-font-bold">
                </p>
                <p style={{display: "none"}} id="show-email-response-OK-one" className="lead header-ul">{`Kindly click on this verification link to reset your password`}</p>
                <p style={{display: "none"}} id="show-email-response-OK-two" className="lead header-ul">{`Make sure to check your spam folder too`}</p>
                <span style={{display: "none"}} id="show-email-response-OK-three" className="text-font-bold" style={{ display: "inline" }}>
                  Didn't receive it?
                  <button
                    className="btn-link"
                    style={{ color: "#1DE054", textTransform: "none" }}
                    onClick={this.toggleLogin}
                  >
                    Resend
                  </button>
                </span>
                <p className="header-ul">{`Have any question or concern, send us email at support@netpluspay.com or chat with us on our website. `}</p>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    );
  }
}

export default ForgotPasswordPage;
