import "./Tokenissues.css";
import React from "react";

function Tokenissues (props) {
    const tokenissue = [
        { text: "Token Not Received", handler: props.actionProvider.tokenNotReceivedHandler, id: 1},
        //{text: "Past", handler: props.actionProvider.pastHandler, id: 2},
    ];

    const tokenIssueOptions = tokenissue.map((token) => (
        <button key={token.id} onClick = {token.handler} className = "token-buttons">
            {token.text}
        </button>
    ));
    return <div className="token-container"> {tokenIssueOptions}</div>;
};

export default Tokenissues;