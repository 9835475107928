import React from "react";

class UserDetails extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { values } = this.props;

		return (
			<React.Fragment>
				<form className="data-form" onSubmit={this.props.billPayment}>
					<p id="show-msg"></p>
					<div className="form-group data-form-input">
						<input
							type="email"
							className="form-control"
							id="exampleInputEmail1"
							aria-describedby="emailHelp"
							placeholder="EMAIL ADDRESS"
							onChange={this.props.handleChange("email")}
							defaultValue={values.email}
							required
						/>
					</div>
					<div className="form-group data-form-input">
						<input
							type="text"
							className="form-control"
							placeholder="SMARTCARD NUMBER"
							onChange={this.props.handleChange("destinationAccount")}
							defaultValue={values.destinationAccount}
							required
						/>
					</div>
					<div className="form-group data-form-input">
						<input
							type="text"
							class="form-control"
							placeholder="ACCOUNT NAME"
							onChange={this.props.handleChange("accountName")}
							defaultValue={values.accountName}
							required
						/>
					</div>

					{/* <div className="form-group data-form-input">
						<select
							className="form-control custom-select"
							id="exampleFormControlSelect1"
							placeholder="SELECT NETWORK"
							onChange={this.props.handleChange("billPackage")}
							defaultValue={values.billPackage}
							required
						>
							<option selected>SELECT PACKAGE</option>
							<option value="access">ACCESS</option>
							<option value="family">FAMILY</option>
							<option value="compact">COMPACT</option>
							<option value="compact-plus">COMPACT PLUS</option>
							<option value="premium">PREMIUM</option>
						</select>
					</div> */}

					<div className="form-group data-form-input">
						<input
							type="text"
							className="form-control"
							placeholder="AMOUNT"
							onChange={this.props.handleChange("amount")}
							defaultValue={values.amount}
							required
						/>
					</div>

					<button
						type="submit"
						className="btn btn-block data-form-input btn-style"
						disabled={values.isLoading}
					>
						{values.isLoading ? "LOADING…" : "PAY"}
					</button>
				</form>
			</React.Fragment>
		);
	}
}

export default UserDetails;
