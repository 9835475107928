import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  CardText,
  Col,
  Button,
} from "reactstrap";

// import Placeholder from "../../utilities/placeholder";

import {
  currencyFormatNoFraction,
  FormatTime,
} from "../../utilities/currency_time_formata";
import calender_clock from "../../assets/img/calender_clock.png";
import save_money from "../../assets/img/save_money.png";
import home_icon from "../../assets/img/homes.png";
import meter_icon from "../../assets/img/meter_icon.png";
import total_money from "../../assets/img/total_money.png";
import number_transaction from "../../assets/img/number_transaction.png";
import { fetchDashboardMetrics, getServicePayments, getDevLevy} from "../../utilities/api";
import Placeholder from "../../utilities/placeholder";
import { decodeJwt } from "../../utilities/processJWT";
import moment from 'moment';
import Chatbot from "react-chatbot-kit";
import 'react-chatbot-kit/build/main.css';
import "../../components/Chatbot/App.css";
import config from "../../components/Chatbot/config";
import MessageParser from "../../components/Chatbot/MessageParser";
import ActionProvider from "../../components/Chatbot/ActionProvider";
import chatbot from "../../components/Chatbot/assets/chatbot.png";


class UserDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      payload: null,
      showBot: false,
      isLoading: true,
      value: "",
      emptyObj: "",
      duration: "",
      devLevyPayload: null,
      devLevy: "",
      fine: ""
    };

    // this.onDismiss = this.onDismiss.bind(this);
    this.getDashboardMetrics = this.getDashboardMetrics.bind(this);
  }
  componentDidMount() {
    this.getDashboardMetrics();
    this.getServicePayment();
    // this.getDevLevy();
    
  }

  getDevLevy() {
    getDevLevy().then((response) => {
      if (response && response.status === 200) {
        console.log("resp", response.data);
        setTimeout(() => {
          this.setState({ devLevyPayload: response.data, isLoading: false , dev: response.data.amount, paid:response.data.paid });
          localStorage.setItem("DevLevy", response.data.amount);
        }, 500);
       
        console.log("sss", this.state);
      } else if (response && response.status === 400) {
        setTimeout(() => {
          this.setState({ isLoading: false });
        }, 500);
        // throw new Error(response);
      } else {
        this.setState({ isLoading: false });
      }
    });
  }

  getServicePayment = () => {
    const token= localStorage.getItem('token');
    const decoded= decodeJwt(token);
    const address = decoded.address;
    localStorage.setItem("address", address);
    console.log("asd", decoded.status);
    const status = decoded.status;

    getServicePayments().then((response) => {
      if(response && response.status === 200) {

        if(response.data === null) {
          const durationLeftDays = ""
          localStorage.setItem("duration", durationLeftDays);
          this.setState({
            serviceCharge: 0,
            status: status,
            dev: 0
            // createdAt: response.data.createdAt
          })
        } else {
          console.log("success", response);
         
          localStorage.setItem("DevLevy", response.data.devLevy);
          localStorage.setItem("serviceCharge", response.data.serviceCharge);
          localStorage.setItem("fine", response.data.fine);
          
            this.setState({
              serviceCharge: response.data.serviceCharge,
              status: status,
              dev: response.data.devLevy,
              fine: response.data.fine,
              // createdAt: response.data.createdAt
            })
          // }

      
          
          // this.getDevLevy();
        }
          
      }else {
        console.log("failure", response);
      }
    })
  }

  getDashboardMetrics() {
    fetchDashboardMetrics().then((response) => {
      if (response && response.status === 200 || response && response.status === 304) {
        setTimeout(() => {
          this.setState({ payload: response, isLoading: false });
        }, 500);
      } else if (response && response.status === 400) {
        setTimeout(() => {
          this.setState({ isLoading: false, emptyObj: true });
        }, 500);
        // throw new Error(response);
      } else {
        this.setState({ isLoading: false });
      }
    });
  }

  render() {
    const { payload } = this.state;
    return (
      <div>
        {this.state.isLoading ? (
          <div>
            <Placeholder />
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <div className="centered-div">
              {/* <p>Service Charge: {this.state.serviceCharge}</p> */}
              <p>Apartment Status:  {this.state.status}</p>
              {/* <p>Development Levy:  {this.state.dev}</p> */}


              <Row>
                <Col sm="4">
                  <Card body>
                    <div class="d-flex bg-white">
                      <div class="p-2 flex-grow-1">
                        <CardText className="text-font-bold">
                          SERVICE CHARGE 
                        </CardText>
                        <CardText className="text-number-green">
                        {this.state.serviceCharge}
                        </CardText>
                      </div>
                      <div class="p-2">
                        <p>
                          <img
                            class="img-fluid mx-auto d-block"
                            src={number_transaction}
                            alt="User avatar"
                          />
                        </p>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col sm="4">
                  <Card body>
                    <div class="d-flex">
                      <div class="p-2 flex-grow-1">
                        <CardText className="text-font-bold">
                          DEV. LEVY
                        </CardText>
                        <CardText className="text-number-green">
                        {this.state.dev}
                        </CardText>
                      </div>
                      <div class="p-2">
                        <p>
                          <img
                            class="img-fluid mx-auto d-block"
                            src={total_money}
                            alt="User avatar"
                          />
                        </p>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col sm="4">
                  <Card body>
                    <div class="d-flex">
                      <div class="p-2 flex-grow-1">
                        <CardText className="text-font-bold">
                          FINE
                        </CardText>
                        <CardText className="text-number-green">
                        {this.state.fine}
                        </CardText>
                      </div>
                      <div class="p-2">
                        <p>
                          <img
                            class="img-fluid mx-auto d-block"
                            src={total_money}
                            alt="User avatar"
                          />
                        </p>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>


              <Row>
                <Col sm="6">
                  <Card body>
                    <div class="d-flex bg-white">
                      <div class="p-2 flex-grow-1">
                        <CardText className="text-font-bold">
                          NUMBER OF SUCCESSFUL TRANSACTIONS
                        </CardText>
                        <CardText className="text-number-green">
                          {payload && payload.data.transaction.numberOfSuccessfulTransactions
                            ? payload.data.transaction.numberOfSuccessfulTransactions
                            : "0"}
                        </CardText>
                      </div>
                      <div class="p-2">
                        <p>
                          <img
                            class="img-fluid mx-auto d-block"
                            src={number_transaction}
                            alt="User avatar"
                          />
                        </p>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col sm="6">
                  <Card body>
                    <div class="d-flex">
                      <div class="p-2 flex-grow-1">
                        <CardText className="text-font-bold">
                          TOTAL SUCCESSFUL TRANSACTION VALUE
                        </CardText>
                        <CardText className="text-number-green">
                          {payload && payload.data.transaction.totalSuccessfulTransaction
                            ? currencyFormatNoFraction(
                              parseInt(payload.data.transaction.totalSuccessfulTransaction)
                            )
                            : "0"}
                        </CardText>
                      </div>
                      <div class="p-2">
                        <p>
                          <img
                            class="img-fluid mx-auto d-block"
                            src={total_money}
                            alt="User avatar"
                          />
                        </p>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>



              <Row>
                <Col sm="6">
                  <Card body>
                    <div class="d-flex">
                      <div class="p-2 flex-grow-1">
                        <CardText className="text-font-bold">
                          LAST TRANSACTION DATE
                        </CardText>
                        <CardText className="text-number-green">
                          {payload && payload.data.transaction.lastTransactionDate
                            ? payload.data.transaction.lastTransactionDate
                            : "0" }
                        </CardText>
                      </div>
                      <div class="p-2">
                        <p>
                          <img
                            class="img-fluid mx-auto d-block"
                            src={calender_clock}
                            alt="User avatar"
                          />
                        </p>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col sm="6">
                  <Card body className="bg-green text-white">
                    <div class="d-flex">
                      <div class="p-2 flex-grow-1">
                        <CardText className="text-font-bold">
                          LAST TRANSACTION VALUE
                        </CardText>
                        <CardText className="text-number-white">
                          {payload && payload.data.transaction.lastTransactionValue
                            ? currencyFormatNoFraction(
                              payload.data.transaction.lastTransactionValue
                            )
                            : "0"}
                        </CardText>
                      </div>
                      <div class="p-2">
                        <p>
                          <img
                            class="img-fluid mx-auto d-block"
                            src={save_money}
                            alt="User avatar"
                          />
                        </p>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        )}
        <div className="Chatbot">
         {this.state.showBot && (<Chatbot config ={config} messageParser = {MessageParser} actionProvider = {ActionProvider}  />)}
        </div>
        <button className="chatbot-button" onClick={(e) => this.setState({showBot: !this.state.showBot})} style ={{backgroundColor: this.state.showBot ? '#28a745' : 'white'}}>
         {/* <img src="https://uploads.codesandbox.io/uploads/user/7a2de391-f332-46d7-87b3-6f582bb6f731/Yko8-Icon.png"/> */}
         <img src={chatbot}  alt="botIcon" className ="chatbot-icon"/>
        </button>
      </div>
    );
  }
}

export default UserDashboard;
