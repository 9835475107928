import React from "react";
import Select from "react-select";
import { Row, Col, Card, CardHeader, CardBody, CustomInput, Button, Form, FormGroup, } from "reactstrap";
import { uploadLogo, getAdminEstates, getPropertyLogo } from '../../utilities/api';


class LogoSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      estateArr: null,
      estate_id: null,
      logoFile: "",
      isLoading: false,
    };
  }

  componentDidMount() {
    this.handleGetAdminEstate()
  }
  // toggle = () => this.setState({
  //   modal: !this.state.modal,
  // });

  handleChange = (event) => {
    const showError = document.getElementById("showError");
    if (event.target.files[0].size > 300000) {
      showError.style.display = "block";
      showError.style.color = "red";
      showError.innerHTML = "Image should not be more than 300kb";
      this.setState({
        logoFile: "",
      });
      return;
    } else if (event.target.files[0].size <= 300000) {
      const file = event.target.files[0]
      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;
        this.setState({
          logoFile: reader.result,
        });
      }
      reader.readAsDataURL(file)
    } else {
      return;
    }

  };

  handleEstateSelect = (event) => {
    this.setState({
      estate_id: event.value,
    });
  };

  handleGetAdminEstate = () => {
    this.setState({
      isLoading: true
    });
    getAdminEstates().then((response) => {
      if (response && response.status == 200 || response && response.status === 304) {
        setTimeout(() => {
          this.setState({
            estateArr: response.data,
            isLoading: false
          });
        }, 500);
      } else {
        this.setState({
          isLoading: false
        });
      }
    });
  }

  handleSubmit = (event) => {
    const showError = document.getElementById("showError");
    event.preventDefault();
    if (!this.state.estate_id) {
      return;
    } else {
      this.setState({
        isLoading: true
      });
      uploadLogo({ property_logo: this.state.logoFile, property_id: this.state.estate_id })
        .then((response) => {
          if (
            response &&
            response.status === 200 || response && response.status === 304
          ) {
              this.handleGetLogo() 
          } else {
            showError.style.display = "block";
            showError.style.color = "red";
            showError.innerHTML = "Image upload unsuccessful, try again";
            this.setState({
              isLoading: false
            });
          }
        })
        .catch(function (error) {
          showError.style.display = "block";
          showError.innerHTML = "Image upload unsuccessful, try again";
          this.setState({
            isLoading: false
          });
        });
    }
  };

  handleGetLogo = () => {
    return getPropertyLogo()
      .then((response) => {
        if (response && response.status === 200 || response && response.status === 304) {
          setTimeout(() => {
            localStorage.setItem('logo',response.data.property_logo);
            window.location.reload(true);
          }, 500);
        } else {
          localStorage.setItem('logo', null);
          window.location.reload(true);
        }
      })
      .catch(function (error) {
        localStorage.setItem('logo', null);
        window.location.reload(true);
      });
  }

  render() {
    const { isLoading, estateArr } = this.state;
    return (
      <div className="content">
        <Row>
          <Col xs={12}>
            <Card className="ml-5 w-75">
              <CardHeader>Edit Company Logo</CardHeader>
              <CardBody>
                <Form onSubmit={this.handleSubmit}>
                  <p id="showError" className="muted"></p>
                  <FormGroup>
                    <CustomInput
                      type="file"
                      id="logo-file"
                      name="logoFile"
                      label="logo image"
                      accept="image/*"
                      disabled={isLoading}
                      onChange={this.handleChange}
                      required
                    />
                  </FormGroup>
                  <FormGroup className="mx-auto mt-3">
                    {estateArr && (
                      <Select
                        isLoading={isLoading}
                        isSearchable={true}
                        name="Available estate"
                        options={estateArr.map((obj) => {
                          let optionObj = {
                            value: obj.id,
                            label: obj.name,
                          };
                          return optionObj;
                        })}
                        onChange={this.handleEstateSelect}
                        placeholder="SELECT ESTATE"
                        required
                      />
                    )}
                  </FormGroup>
                  <Button                     
                  style={{
                      // float: "right",
                      color: "white",
                      backgroundColor: "#1DE054",
                      textTransform: "none",
                    }} disabled={isLoading} type="submit">{this.state.isloading ? "submitting" : "Upload"}</Button>{' '}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

}

export default LogoSetting;
