/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import SignUpForm from "./SignUpForm";
import SignInForm from "./SignInForm";
import { fetchEstates } from "../../utilities/api";

class AirtimeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      nestedModal: false,
      formType: this.props.formType,
      properties: [],
    };

    this.toggle = this.toggle.bind(this);
    this.togglePage = this.togglePage.bind(this);
    this.toggleLogin = this.toggleLogin.bind(this);
    this.inputRef = React.createRef();
    this.fetchAllProperties = this.fetchAllProperties.bind(this);
    this.showIcon = this.showIcon.bind(this);
  }

  componentDidMount() {
    this.fetchAllProperties();
  }
  fetchAllProperties() {
    fetchEstates().then((response) => {
      if (response && response.status === 200) {
        setTimeout(() => {
          localStorage.setItem("properties", JSON.stringify(response));
        }, 500);
      } else {
        localStorage.setItem("properties", response);
      }
    });
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  togglePage() {
    window.location.replace("/#forgot-password");
  }

  showIcon() {
    switch (this.props.buttonLabel) {
      case "Sign in":
        return "external-link-alt";
      case "Sign up":
        return "user-plus";
      case "Buy Power":
        return "bolt";
      case "Dashboard":
        return "home";
      case "FAQ":
        return "question-circle";
      default:
        return "";
    }
  }
  toggleLogin(event) {
    if (this.props.formType === "Airtime") {
      this.setState({
        formType: "Airtime",
      });
    } else if (this.props.formType === "Bills") {
      this.setState({
        formType: "Bills",
      });
    } 
    // else if (this.props.formType === "Bills") {
    //     this.setState({
    //       formType: "Bills",
    //     });
    //   }
    else {
      return;
    }
  }

  render() {
    const { buttonLabel, color, modalTitle } = this.props;

    return (
      <div>
        <Button
          style={{
            color: color,
            backgroundColor: "transparent",
            textTransform: "uppercase",
            fontSize: ".8rem",
          }}
          onClick={this.toggle}
        >
          <span className="icon-plus">
            <FontAwesomeIcon icon={this.showIcon()} />
          </span>
          <span className="icon-span">{buttonLabel}</span>
        </Button>

        {this.state.formType === "Airtime" ? (
          <Modal
            isOpen={this.state.modal}
            toggle={this.toggle}
            className="supreme-container"
            backdrop={"static"}
            keyboard={false}
            centered={false}
            scrollable={true}
          >
            <ModalHeader className="modal-title" toggle={this.toggle}>
              {modalTitle}
            </ModalHeader>
            <ModalBody
              style={{
                backgroundColor: "#1DE054",
                margin: "30px",
                borderRadius: "5px",
              }}
            >
              <SignInForm />
            </ModalBody>
            <ModalFooter
              style={{
                alignSelf: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <div class="text-center">
                <button
                  className="btn-link"
                  style={{ color: "#1DE054", textTransform: "none" }}
                  onClick={this.togglePage}
                >
                  Forgot password?
                </button>
              </div>
              <div class="text-center">
                <span style={{ display: "inline" }}>
                  Don't have an account? Sign up
                  <button
                   id="Sign up"
                    className="btn-link"
                    style={{ color: "#1DE054", textTransform: "none" }}
                    onClick={(e) =>
                      this.setState({ formType: "Sign up" })
                    }
                  >
                    here
                  </button>
                </span>
              </div>
            </ModalFooter>
          </Modal>
        ) : (
          <Modal
            isOpen={this.state.modal}
            toggle={this.toggle}
            className="supreme-container"
            backdrop={"static"}
            keyboard={false}
            centered={false}
            scrollable={true}
          >
            <ModalHeader className="modal-title" toggle={this.toggle}>
              {modalTitle}
            </ModalHeader>
            <ModalBody
              style={{
                backgroundColor: "white",
                margin: "30px",
                borderRadius: "5px",
              }}
            >
              <SignUpForm />
            </ModalBody>
            <ModalFooter
              style={{
                alignSelf: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <div class="text-center">
                <span style={{ display: "inline" }}>
                  Already have an account? Sign in
                  <button
                   id="Sign in"
                    className="btn-link"
                    style={{ color: "#1DE054", textTransform: "none" }}
                    onClick={(e) =>
                      this.setState({ formType: "Sign in" })
                    }
                  >
                    here
                  </button>
                </span>
              </div>
            </ModalFooter>
          </Modal>
        )}
      </div>
    );
  }
}

export default AirtimeModal;
