import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  CardText,
  Col,
  Button,
} from "reactstrap";

// import Placeholder from "../../utilities/placeholder";

import {
  currencyFormatNoFraction,
  FormatTime,
} from "../../utilities/currency_time_formata";
import calender_clock from "../../assets/img/calender_clock.png";
import save_money from "../../assets/img/save_money.png";
import home_icon from "../../assets/img/homes.png";
import meter_icon from "../../assets/img/meter_icon.png";
import total_money from "../../assets/img/total_money.png";
import number_transaction from "../../assets/img/number_transaction.png";
import { fetchSuperDashboardMetrics } from "../../utilities/api";
import Placeholder from "../../utilities/placeholder";
import { decodeJwt } from "../../utilities/processJWT";

class OtherDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      payload: null,
      isLoading: true,
      value: "",
      emptyObj: "",
    };

    // this.onDismiss = this.onDismiss.bind(this);
    this.getDashboardMetrics = this.getDashboardMetrics.bind(this);
  }
  componentDidMount() {
    this.getDashboardMetrics();
  }

  getDashboardMetrics() {
    fetchSuperDashboardMetrics().then((response) => {
      if (response && response.status === 200 || response && response.status === 304) {
        setTimeout(() => {
          this.setState({ payload: response, isLoading: false });
        }, 500);
      } else if (response && response.status === 400) {
        setTimeout(() => {
          this.setState({ isLoading: false, emptyObj: true });
        }, 500);
        // throw new Error(response);
      } else {
        this.setState({ isLoading: false });
      }
    });
  }

  render() {
    const { payload } = this.state;
    return (
      <div>
        {this.state.isLoading ? (
          <div>
            <Placeholder />
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <div className="centered-div">
              <Row>
                <Col sm="6">
                  <Card body>
                    <div class="d-flex bg-white">
                      <div class="p-2 flex-grow-1">
                        <CardText className="text-font-bold">
                          NUMBER OF SUCCESSFUL TRANSACTIONS
                        </CardText>
                        <CardText className="text-number-green">
                          {!payload
                            ? " - "
                            : payload.data.transactionsSummary.numberOfSuccessfulTransactions}
                        </CardText>
                      </div>
                      <div class="p-2">
                        <p>
                          <img
                            class="img-fluid mx-auto d-block"
                            src={number_transaction}
                            alt="User avatar"
                          />
                        </p>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col sm="6">
                  <Card body>
                    <div class="d-flex">
                      <div class="p-2 flex-grow-1">
                        <CardText className="text-font-bold">
                          TOTAL SUCCESSFUL TRANSACTION VALUE
                        </CardText>
                        <CardText className="text-number-green">
                          {!payload
                            ? " - "
                            : currencyFormatNoFraction(
                                parseInt(payload.data.transactionsSummary.totalSuccessfulTransaction)
                              )}
                        </CardText>
                      </div>
                      <div class="p-2">
                        <p>
                          <img
                            class="img-fluid mx-auto d-block"
                            src={total_money}
                            alt="User avatar"
                          />
                        </p>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
              {/* {decodeJwt().role === "netplus-admin" && ( */}
                <Row>
                  <Col sm="6">
                    <Card body>
                      <div class="d-flex">
                        <div class="p-2 flex-grow-1">
                          <CardText className="text-font-bold">
                            TOTAL METER NUMBERS
                          </CardText>
                          <CardText className="text-number-green">
                            {!payload
                              ? " - "
                              : payload.data.transactionsSummary.totalMeterNumber}
                          </CardText>
                        </div>
                        <div class="p-2">
                          <p>
                            <img
                              class="img-fluid mx-auto d-block"
                              src={meter_icon}
                              alt="User avatar"
                            />
                          </p>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col sm="6">
                    <Card body>
                      <div class="d-flex">
                        <div class="p-2 flex-grow-1">
                          <CardText className="text-font-bold">
                            REGISTERED ESTATES{" "}
                          </CardText>
                          <CardText className="text-number-green">
                            {!payload
                              ? " - "
                              : payload.data.transactionsSummary.registeredEstates}
                          </CardText>
                        </div>
                        <div class="p-2">
                          <p>
                            <img
                              class="img-fluid mx-auto d-block"
                              src={home_icon}
                              alt="User avatar"
                            />
                          </p>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
              {/* )} */}
              <Row>
                <Col sm="6">
                  <Card body>
                    <div class="d-flex">
                      <div class="p-2 flex-grow-1">
                        <CardText className="text-font-bold">
                          LAST TRANSACTION DATE
                        </CardText>
                        <CardText className="text-number-green">
                          {!payload
                            ? " - "
                            : payload.data.transactionsSummary.lastTransactionDate}
                        </CardText>
                      </div>
                      <div class="p-2">
                        <p>
                          <img
                            class="img-fluid mx-auto d-block"
                            src={calender_clock}
                            alt="User avatar"
                          />
                        </p>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col sm="6">
                  <Card body className="bg-green text-white">
                    <div class="d-flex">
                      <div class="p-2 flex-grow-1">
                        <CardText className="text-font-bold">
                          LAST TRANSACTION VALUE
                        </CardText>
                        <CardText className="text-number-white">
                          {!payload
                            ? " - "
                            : currencyFormatNoFraction(
                              payload.data.transactionsSummary.lastTransactionValue
                              )}
                        </CardText>
                      </div>
                      <div class="p-2">
                        <p>
                          <img
                            class="img-fluid mx-auto d-block"
                            src={save_money}
                            alt="User avatar"
                          />
                        </p>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default OtherDashboard;
