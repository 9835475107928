import React from "react";
import {
  Row,
  CardText,
  Col,
  Button,
  Form,
  FormGroup,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Label,
  Input,
  Table,
} from "reactstrap";
import { CSVLink } from "react-csv";
import ErrorPage from "../../../utilities/errorPage";
import { FormatTime } from "../../../utilities/currency_time_formata";
import {arrTypeCheck} from "../../../utilities/dataTypeCheck";
import Pagination from "../../../utilities/Pagination";
import Placeholder from "../../../utilities/placeholder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PowerVending from "./EstateTransactions/PowerVending";
import ServicePayment from "./EstateTransactions/ServicePayment";
import DevLevy from "./EstateTransactions/DevLevy";
import Fine from "./EstateTransactions/Fine";

import {
  fetchEstateTrHistory,
  fetchEstatesTrSummary,
  searchEstateTrHistory
} from "../../../utilities/api";

class EstateTransactions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: null,
      endDate: null,
      payload: null,
      headers: [],
      value: 10,
      isLoading: true,
      search: false,
      sort: "dsc",
      success: false,
      currentTransactions: [],
      currentPage: null,
      totalPages: null,
      activePage: "",
      name: "",
      meter_number: "",
      transactionSummary: {},
      toggleView: "power vending"
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.getTransactions = this.getTransactions.bind(this);
    this.searchTransactions = this.searchTransactions.bind(this);
    this.getSummary = this.getSummary.bind(this);
    this.onPageChanged = this.onPageChanged.bind(this);
  }

  componentDidMount() {
    console.log("togg", this.state.toggleView);
    this.getTransactions();
    this.getSummary();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.payload !== this.state.payload) {
      this.getSummary();
    }
  }


  handletoggleView =()=> {
    switch (this.state.toggleView) {
      case "power vending":
        return <PowerVending />;
      case "dev levy":
        return <DevLevy />;
        case "service":
        return <ServicePayment />;
        case "fine":
        return <Fine />;

     
    }
  }

  onPageChanged = (data) => {
    const { payload } = this.state;
    const { currentPage, totalPages, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    const currentTransactions = payload.slice(offset, offset + pageLimit);
    this.setState({ currentPage, currentTransactions, totalPages });
  };

  viewResident(e) {
    window.location.reload(true);
  }

  handleChange = (event) => {
    if (event.target.name === "startDate") {
      this.setState({ startDate: event.target.value });
    } else if (event.target.name === "endDate") {
      this.setState({ endDate: event.target.value });
    } else {
      return;
    }
  };

  handleRefresh(event) {
    event.preventDefault();
    let showMsg = document.getElementById("show-error");
    this.searchTransactions({ refresh: true });
  }

  handleSubmit(event) {
    event.preventDefault();
    let showMsg = document.getElementById("show-error");
    event.preventDefault();
    const { startDate, endDate, sort } = this.state;
    this.searchTransactions({ from: startDate, to: endDate, sort: sort });
  }

  getSummary() {
    this.setState({ isLoading: true });
    fetchEstatesTrSummary().then((response) => {
      if (response && response.status == 200 || response && response.status === 304) {
        setTimeout(() => {
          this.setState({
            transactionSummary: response.data,
            isLoading: false,
          });
        }, 500);
      } else {
        this.setState({ transactionSummary: response, isLoading: false });
      }
    });
  }

  getTransactions() {
    this.setState({ isLoading: true });
    fetchEstateTrHistory().then((response) => {
      if (response && response.status == 200 || response && response.status === 304) {
        setTimeout(() => {
          this.setState({
            payload: response.data.array,
            isLoading: false,
            headers: [
              { label: "Transaction Value", key: "amount" },
              { label: "Resident name", key: "user_name" },
              { label: "Meter Number", key: "meter_number" },
              { label: "Transaction Date", key: "createdAt" },
              { label: "Transaction Status", key: "transactionStatus" },
              { label: "Transaction Type", key: "transaction_type" },
              { label: "Transaction Ref", key: "topupRetryReference" },
              { label: "Token Status", key: "topupStatus" },
              { label: "Token", key: "token" },
              { lable: "Transaction ID", key: "payment_transaction_id" },
            ],
          });
          // let le = document.getElementById("download-option");
          // le.style.display = "block";
        }, 500);
      } else {
        this.setState({ payload: response, isLoading: false });
      }
    });
  }

  searchTransactions(params) {
    this.setState({ isLoading: true });
    searchEstateTrHistory(params).then((response) => {
      if (response && response.status == 200 || response && response.status === 304) {
        setTimeout(() => {
          this.setState({
            payload: response.data.array,
            isLoading: false,
            headers: [
              { label: "Transaction Value", key: "amount" },
              { label: "Resident name", key: "user_name" },
              { label: "Meter Number", key: "meter_number" },
              { label: "Transaction Date", key: "createdAt" },
              { label: "Transaction Status", key: "transactionStatus" },
              { label: "Transaction Type", key: "transaction_type" },
              { label: "Transaction Ref", key: "topupRetryReference" },
              { label: "Token Status", key: "topupStatus" },
              { label: "Token", key: "token" },
              { lable: "Transaction ID", key: "payment_transaction_id" },
            ],
          });
          // let le = document.getElementById("download-option");
          // le.style.display = "block";
        }, 500);
      } else {
        this.setState({ payload: response, isLoading: false });
      }
    });
  }


  menuToggle = (event) => {
    console.log("toggled", event.target.value );
  }

  render() {
    const { props } = this.props;
    const {
      payload,
      isLoading,
      search,
      currentTransactions,
      transactionSummary,
    } = this.state;
    return (
      <div className="p-5 bg-white">
        {!isLoading && !payload ? (
          <div>
            <ErrorPage />
          </div>
        ) : (
          <div>
            {isLoading ? (
              <div>
                <Placeholder />
              </div>
            ) : (
              <div>
                <Row className="bg-dark pl-5 pr-5 d-flex justify-content-between">
                  <h5 className="text-font-bold-white pl-2 mt-3">
                    TRANSACTION HISTORY
                  </h5>
                  <UncontrolledButtonDropdown>
                    <DropdownToggle
                      className="bg-white text-body rounded border"
                      caret
                    >
                     MENU
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem  onClick={(e) =>
                      this.setState({ toggleView: "power vending" })
                    }>
                        POWER VENDING
                      </DropdownItem>
                      <DropdownItem  onClick={(e) =>
                      this.setState({ toggleView: "service" })
                    }>
                       SERVICE CHARGE
                      </DropdownItem>
                      <DropdownItem  onClick={(e) =>
                      this.setState({ toggleView: "dev levy" })
                    }>
                       DEVELOPMENT LEVY
                      </DropdownItem>
                      <DropdownItem  onClick={(e) =>
                      this.setState({ toggleView: "fine" })
                    }>
                       FINE
                      </DropdownItem>
                    </DropdownMenu>
                    
                  </UncontrolledButtonDropdown>
                </Row>

                {this.handletoggleView()}
              </div>




            )}
          </div>
        )}
      </div>
    );
  }
}

export default EstateTransactions;
