import React from "react";
import Dashboard from "../views/Dashboard/Dashboard";
import Transaction from "../views/Transaction/Menu";
// import Icons from "../views/TopUps/Icons.jsx.js";
import Estates from "../views/Estates/Estates";
import TableList from "../views/TableList/TableList.jsx";
import CreateService from "../views/CreateService/CreateServiceMenu";
import RequestNewService from '../views/RequestNewService/RequestServiceMenu';
import RegisteredResidents from '../views/RegisteredResidents/Menu';
import SingleResidentsHist from '../views/Transaction/EstateMenu/ResidentTransaction';
import Maps from "../views/Maps/Maps.jsx";
import UserPage from "../views/UserPage/UserPage.jsx";
import PendingTransaction from "../views/PendingTransactions/PendingRetry";
import TopUps from "../views/TopUps/Topups";
import UpdateMeter from "../views/UploadMeter/MainPage";
import AdminSettings from "../views/AdminSettings/LogoSettings";
import PayForServices from "../views/PayForServices/PayForServices";
import ConvenienceFeeMenu from "../views/CreateConvenienceFee/ConvenienceFeeMenu";
import PayForDevLevy from "../views/PayForDevLevy/PayForDevLevy";
import UploadExistingServicePayments from "../views/UploadExistingServicePayments/MainPage.jsx";
import DevelopmentLevies from "../views/DevelopmentLevies/MainPage.js";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "DASHBOARD",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    adminType: "all"
  },
  {
    path: "/estates",
    name: "ESTATES",
    icon: "nc-icon nc-badge",
    component: Estates,
    adminType: "netplus-admin"
  },
  {
    path: "/user-page",
    name: "PROFILE",
    icon: "nc-icon nc-single-02",
    component: UserPage,
    adminType: "service-user"
  },
  {
    path: "/toUpPower",
    name: "BUY POWER",
    icon: "nc-icon nc-money-coins",
    component: TopUps,
    adminType: "service-user"
  },
  {
    path: "/pay-services",
    name: "PAY FOR SERVICES",
    icon: "nc-icon nc-simple-add",
    component: PayForServices,
    adminType: "service-user"
  },
  // {
  //   path: "/pay-services",
  //   name: "PAY FOR SERVICES",
  //   icon: "nc-icon nc-simple-add",
  //   component: PayForDevLevy,
  //   adminType: "service-owner"
  // },
  {
    path: "/pay-devLevy",
    name: "PAY FOR DEV LEVY",
    icon: "nc-icon nc-simple-add",
    component: PayForDevLevy,
    adminType: "service-owner"
  },
  // { path: "/maps", name: "Maps", icon: "nc-icon nc-pin-3", component: Maps },
  {
    path: "/transactionHistory",
    name: "TRANSACTION HISTORY",
    icon: "nc-icon nc-tile-56",
    component: Transaction,
    adminType: "all"
  },
  {
    path: "/tables",
    name: "More",
    icon: "nc-icon nc-bullet-list-67",
    component: TableList,
    adminType: "netplus-admin"
  },

  {
    path: "/new-service",
    name: "CREATE SERVICE",
    icon: "nc-icon nc-simple-add",
    component: CreateService,
    adminType: "netplus-admin"
  },

  {
    path: "/new-fee",
    name: "CONVENIENCE FEE",
    icon: "nc-icon nc-simple-add",
    component: ConvenienceFeeMenu,
    adminType: "netplus-admin"
  },

  // {
  //   path: "/request-new-service",
  //   name: "REQUEST NEW SERVICE",
  //   icon: "nc-icon nc-simple-add",
  //   component: RequestNewService,
  //   adminType: "service-admin"
  // },
  {
    path: "/upload-meter-number",
    name: "UPLOAD METER NUMBER",
    icon: "nc-icon nc-share-66",
    component: UpdateMeter,
    adminType: "service-admin"
  },

  {
    path: "/registered-residents",
    name: "REGISTERED RESIDENTS",
    icon: "nc-icon nc-badge",
    component: RegisteredResidents,
    adminType: "service-admin"
  },

  // {
  //   path: "/registered-residents-transHistory",
  //   name: "RESIDENT Transaction History",
  //   icon: "nc-icon nc-badge",
  //   component: SingleResidentsHist,
  //   adminType: "service-admin"
  // },
  {
    path: "/pending-transactions",
    name: "PENDING TRANSACTIONS",
    icon: "nc-icon nc-single-02",
    component: PendingTransaction,
    adminType: "netplus-admin"
  },

  {
    path: "/Settings",
    name: "Settings",
    icon: "nc-icon nc-settings",
    component: AdminSettings,
    adminType: "service-admin"
  },
  //route to the view of  upload existing service payments
  {
    path: "/upload-existing-service-payments",
    name: "UPLOAD PAYMENTS",
    icon:"nc-icon nc-share-66",
    component: UploadExistingServicePayments,
    adminType: "service-admin"
  },
  //route to the view of development levies
  // {
  //   path: "/development-levies",
  //   name: "DEVELOPMENT LEVIES",
  //   icon: "nc-icon nc-tile-56",
  //   component: DevelopmentLevies,
  //   adminType: "service-admin"
  // },
  // {
  //   pro: true,
  //   path: "/upgrade",
  //   name: "Upgrade to PRO",
  //   icon: "nc-icon nc-spaceship"
  // },
  { redirect: true, path: "/", pathTo: "/dashboard", name: "Dashboard" }
];
export default dashRoutes;