import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Placeholder from "../../utilities/Pagination";
import ErrorPage from "../../utilities/errorPage";
import { fetchEstateResidents, getServicePayments, getResidentsSummary } from "../../utilities/api";
import InactiveResidents from "./InactiveResidents";
import ActiveResidents from "./ActiveResidents";
import MeterNumber from "./MeterNumber";
import TotalResidents from "./TotalResidents";
import ResidentInfo from './ResidentInfo';


class Estates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: null,
      isOpen: false,
      isLoading: false,
      dropdownOpen: false,
      headerTitle: "",
      viewEstate: "TOTAL REGISTERED",
      firstName:"",
      lastName: "",
      status:"",
      meterNo:"",
      email: "",
      phone: ""
    };

    this.showMenuItem = this.showMenuItem.bind(this);
    this.getRegisteredResidents = this.getRegisteredResidents.bind(this);
  }

  componentWillMount() {
    this.getRegisteredResidents();
    this.getServicePayment();
  }

  handleRefresh = () => {
    this.forceUpdate();
    this. getRegisteredResidents();
  }

  getServicePayment = () => {
    // const param = {
    //     meter_number: this.props.data.meterNo
    // }
    getResidentsSummary().then((response) => {
      if(response && response.status === 200) {

        
          console.log("success", response);
         
         
          // localStorage.setItem("serviceCharge", response.data.amount);
          // this.setState({
          //   serviceCharge: response.data.serviceCharge,
          //   dev: response.data.devLevy,
          //   fine: response.data.fine
          //   // status: status
          //   // createdAt: response.data.createdAt
          // })
          // this.getDevLevy();
        
          
      }
      else {
        console.log("failure", response);
      }
    })
  }

  getRegisteredResidents() {
    this.setState({ isLoading: true });
    fetchEstateResidents().then((response) => {
      if (response && response.status === 200 || response && response.status === 304) {
        setTimeout(() => {
          this.setState({ payload: response, isLoading: false });
        }, 500);
      } else if (response && response.status === 400) {
        setTimeout(() => {
          this.setState({ isLoading: false, emptyObj: true });
        }, 500);
        // throw new Error(response);
      } else {
        this.setState({ isLoading: false });
      }
    });
  }

  handleResidentInfoView = (first_name, last_name, status, meter_number, email, phone)=> {
    console.log("resident");
    this.setState({
      viewEstate: "RESIDENT INFO",
      firstName: first_name,
      lastName: last_name,
      status:status,
      meterNo: meter_number,
      email: email,
      phone: phone
    })
    console.log("yes");
  }

  // handleToggle

  showMenuItem() {
    switch (this.state.viewEstate) {
      case "TOTAL REGISTERED":
        return <TotalResidents props={this.state.payload} handleRefresh={this.handleRefresh} handleResidentInfoView={this.handleResidentInfoView}/>;
      case "ACTIVE RESIDENTS":
        return <ActiveResidents props={this.state.payload} />;
      case "REGISTERED METERS":
        return <MeterNumber props={this.state.payload} />;
      case "INACTIVE RESIDENTS":
        return <InactiveResidents props={this.state.payload} />;
        case "RESIDENT INFO":
        return <ResidentInfo props={this.state.payload} handleResidentInfoView={this.handleResidentInfoView} data={this.state}/>;
      default:
        return <h2>dashboard</h2>;
    }
  }

  render() {
    const { isLoading, payload } = this.state;
    return (
      <div className="content">
        {!isLoading && !payload ? (
          <div>
            <ErrorPage />
          </div>
        ) : (
          <div>
            {isLoading ? (
              <div>
                <Placeholder />
              </div>
            ) : (
              <div>
                <div>
                  <UncontrolledDropdown>
                    <DropdownToggle caret>Menu</DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={(e) =>
                          this.setState({ viewEstate: "TOTAL REGISTERED" })
                        }
                      >
                        TOTAL REGISTERED
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem
                        onClick={(e) =>
                          this.setState({ viewEstate: "ACTIVE RESIDENTS" })
                        }
                      >
                        ACTIVE RESIDENTS
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem
                        onClick={(e) =>
                          this.setState({ viewEstate: "REGISTERED METERS" })
                        }
                      >
                        REGISTERED METERS
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem
                        onClick={(e) =>
                          this.setState({ viewEstate: "INACTIVE RESIDENTS" })
                        }
                      >
                        INACTIVE RESIDENTS
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
                <Row>
                  <Col md={12}>
                    <Card>
                      <CardHeader>
                        <h5 className="title">{this.state.viewEstate}</h5>
                      </CardHeader>
                      <CardBody>{this.showMenuItem()}</CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Estates;
