import React from "react";
import {
  Row,
  CardText,
  Col,
  Button,
  Form,
  FormGroup,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Label,
  Input,
  Table,
} from "reactstrap";
import VewResident from "./EstateViewResult";
import { searchResidents } from "../../utilities/api";

class EstateDashboardComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      payload: null,
      isLoading: true,
      viewTextInput: "name",
      activePage: "",
      name: "",
      meter_number: "",
      searching: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showMenuItem = this.showMenuItem.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleViewButton = this.handleViewButton.bind(this);
  }

  handleViewButton(e) {
    window.location.replace("/#registered-residents");
  }

  handleChange = (event) => {
    if (event.target.name === "name") {
      this.setState({ name: event.target.value });
    } else if (event.target.name === "meter_number") {
      this.setState({ meter_number: event.target.value });
    } else {
      return;
    }
  };

  showMenuItem() {
    switch (this.state.activePage) {
      case "residentsResult":
        return <VewResident props={this.state.payload} />;
      // default: return <EstateDashboardComponent />;
      default:
        return;
    }
  }
  handleSearch(params) {
    return searchResidents(params)
      .then((response) => {
        setTimeout(() => {
          if (response && response.status === 200 || response && response.status === 304) {
            this.setState({
              searching: false,
              payload: response.data,
              activePage: "residentsResult",
            });
          } else {
            let showMsg = document.getElementById("show-error");
            showMsg.innerHTML = "Opps! Server error";
            showMsg.style.display = "block";
            this.setState({
              searching: false,
            });
          }
        }, 1000);
      })
      .catch(function (error) {
        setTimeout(() => {
          let showMsg = document.getElementById("show-error");
          showMsg.innerHTML = "There was a server error. This could be caused by a slow network connection or the service not responding to request at the moment. Please try again later";
          showMsg.style.display = "block";
          this.setState({
            searching: false,
          });
        }, 1000);
      });
  }

  handleSubmit(event) {
    let showMsg = document.getElementById("show-error");
    event.preventDefault();
    const { name, meter_number } = this.state;
    if (name === "" && meter_number === "") {
      showMsg.innerHTML = "Please enter a name or meter number to search";
      showMsg.style.display = "block";
    } else if (meter_number !== "") {
      this.setState({
        searching: true,
      });
      this.handleSearch({ term: meter_number });
    } else {
      this.setState({
        searching: true,
      });
      this.handleSearch({ term: name });
    }
  }

  render() {
    const { props } = this.props;
    const { searching } = this.state;
    return (
      <div className="p-5">
        {this.state.activePage !== "" ? (
          this.showMenuItem()
        ) : (
          <div>
            <Row xs="1" sm="2" md="4">
              <Col className="remove_shadow">
                <div className="text-font-bold">
                  TOTAL NUMBER OF
                  <span className="text-font-bold-green btn-span">
                    REGISTERED RESIDENTS
                  </span>
                  <span className="text-number-bolder btn-span">
                    {!props ? " - " : props.data.total_registered_residents}
                  </span>
                  <span className="btn-span">
                    <Button
                      style={{
                        color: "white",
                        backgroundColor: "#1DE054",
                        textTransform: "none",
                      }}
                      onClick={this.handleViewButton}
                    >
                      View
                    </Button>
                  </span>
                </div>
              </Col>
              <Col>
                <div className="text-font-bold">
                  TOTAL NUMBER OF{" "}
                  <span className="text-font-bold-green btn-span">
                    ACTIVE RESIDENTS
                  </span>
                  <span className="text-number-bolder btn-span">
                    -
                    {/* {!props ? " - " : props.data.total_active_residents} */}
                  </span>
                  {/* <span className="btn-span"> */}
                    {/* <Button
                      style={{
                        color: "white",
                        backgroundColor: "#1DE054",
                        textTransform: "none",
                      }}
                      onClick={this.handleViewButton}
                    >
                      View
                    </Button> */}
                  {/* </span> */}
                </div>
              </Col>
              <Col>
                <div className="text-font-bold">
                  TOTAL NUMBER OF{" "}
                  <span className="text-font-bold-green btn-span">
                    INACTIVE RESIDENTS
                  </span>
                  <span className="text-number-bolder btn-span">
                    -
                    {/* {!props ? " - " : props.data.total_inactive_residents} */}
                  </span>
                  {/* <span className="btn-span"> */}
                    {/* <Button
                      style={{
                        color: "white",
                        backgroundColor: "#1DE054",
                        textTransform: "none",
                      }}
                      onClick={this.handleViewButton}
                    >
                      View
                    </Button> */}
                  {/* </span> */}
                </div>
              </Col>
              <Col>
                <div>
                  <p>
                    <span className="text-font-bold">TOTAL NUMBER OF </span>
                    <span className="text-font-bold-green btn-span">
                      REGISTERED METERS
                    </span>
                    <span className="text-number-bolder btn-span">
                      {!props ? " - " : props.data.total_meter_number}
                    </span>
                    <span className="btn-span">
                      <Button
                        style={{
                          color: "white",
                          backgroundColor: "#1DE054",
                          textTransform: "none",
                        }}
                        onClick={this.handleViewButton}
                      >
                        View
                      </Button>
                    </span>
                  </p>
                </div>
              </Col>
            </Row>
           {/*  <Form className="mt-5" onSubmit={this.handleSubmit}>*/}
             {/*<h3 id="show-error">Server error, please try again</h3>*/}
             {/* <Col md={2} className="my-auto">*/}
              {/*  <FormGroup>*/}
                {/*  <UncontrolledButtonDropdown>*/}
                    {/*<DropdownToggle caret>SEARCH BY</DropdownToggle>*/}
                    {/*<DropdownMenu>*/}
                     {/* <DropdownItem
                        onClick={(e) =>
                          this.setState({ viewTextInput: "name" })
                        }
                      >
                        Name
                      </DropdownItem>*/}
                      {/*<DropdownItem divider />*/}
                      {/* <DropdownItem
                        onClick={(e) =>
                          this.setState({ viewTextInput: "meter_number" })
                        }
                      >
                        Meter number
                      </DropdownItem> */}
                    {/*</DropdownMenu>*/}
                 {/* </UncontrolledButtonDropdown>*/}
                {/*</FormGroup>*/}
              {/*</Col>*/}
             {/* <Row form xs="3" sm="3" md="3">
                <Col md={7} className="mt-2 ml-3 my-auto">
                  {this.state.viewTextInput === "name" ? (
                    <FormGroup>
                      <Input
                        type="text"
                        name="name"
                        onChange={this.handleChange}
                        id="exampleState"
                        placeholder="Resident name"
                      />
                    </FormGroup>
                  ) : (
                    <FormGroup>
                      <Input
                        type="number"
                        name="meter_number"
                        id="exampleState"
                        onChange={this.handleChange}
                        placeholder="Resident meter number"
                      />
                    </FormGroup>
                  )}
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Button
                      type="submit"
                      disabled={searching}
                      style={{
                        color: "white",
                        backgroundColor: "#1DE054",
                        textTransform: "none",
                      }}
                    >
                      {searching ? "SEARCHING.." : "SEARCH"}
                    </Button>
                  </FormGroup>
                </Col>
                    </Row>*/}
           {/* </Form>*/}
          </div>
        )}
      </div>
    );
  }
}

export default EstateDashboardComponent;
