import React from "react";
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import user_avatar from "../../assets/img/user_avatar.png";
import ErrorPage from "../../utilities/errorPage";

class ResidentDetails extends React.Component {
  constructor(props) {
    super(props);
    this.viewHistory = this.viewHistory.bind(this);
  }

  viewHistory() {
    window.location.replace("/#transactionHistory");
  }

  render() {
    const { props } = this.props;
    return (
      <div>
        {!props ? (
          <div>
            <ErrorPage />
          </div>
        ) : (
          <div>
            <Row>
              <Col sm="6">
                <Card body className="remove_shadow">
                  <p>
                    <img
                      class="img-fluid mx-auto d-block"
                      src={user_avatar}
                      alt="User avatar"
                    />
                  </p>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <p>{`${props.first_name} ${props.last_name}`}</p>
                    </div>
                  </Row>
                  <Row>
                    {/* <div className="update ml-auto mr-auto">
                      <Button
                        className="bg-green"
                        // disabled={true}
                        onClick={() => {
                          this.viewHistory();
                        }}
                        round
                      >
                        TRANSACTION RECORD
                      </Button>
                    </div> */}
                  </Row>
                </Card>
              </Col>
              <Col sm="6">
                <Card className="card-user remove_shadow mr-3">
                  <CardBody>
                    <ul class="list-group font-weight-bolder">
                      <li class="list-group-item grey-bg">
                        <p>
                          DATE CREATED:{" "}
                          <span className="text-green">{props.createdAt}</span>
                        </p>
                      </li>
                      <li class="list-group-item grey-bg">
                        <p>
                          CREATED:{" "}
                          <span className="text-green">{`${props.first_name} ${props.last_name}`}</span>
                        </p>
                      </li>
                      <li class="list-group-item grey-bg">
                        <p>
                          ADDRESS: <span className="text-green"></span>
                        </p>
                      </li>
                      <li class="list-group-item grey-bg">
                        <p>
                          EMAIL:{" "}
                          <span className="text-green">{props.email}</span>
                        </p>
                      </li>
                      <li class="list-group-item grey-bg">
                        <p>
                          PHONE:{" "}
                          <span className="text-green">{props.phone}</span>
                        </p>
                      </li>
                      {/* <li class="list-group-item grey-bg">
                        <p>
                          LAST TRANSACTION DATE:{" "}
                          <span className="text-green">
                            {props.last_transaction_date}
                          </span>
                        </p>
                      </li>
                      <li class="list-group-item grey-bg">
                        <p>
                          TOTAL TRANSACTION VALUE:{" "}
                          <span className="text-green">
                            {props.transaction_value}
                          </span>
                        </p>
                      </li> */}
                    </ul>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

export default ResidentDetails;
