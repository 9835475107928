import React from "react";
import { CSVLink } from "react-csv";
import Select from "react-select";
import {
  Form,
  FormGroup,
  Row,
  Col,
  CustomInput,
  ListGroup,
  ListGroupItem,
  Button,
} from "reactstrap";
import Pagination from "../../utilities/Pagination";
import Placeholder from "../../utilities/placeholder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  uploadMeters,
  confirmMeterUpload,
  getAdminEstates,
  UploadDevLevy,
  confirmDevLevyUpload
} from "../../utilities/api";
import { Table } from "reactstrap";
import {
  filterFailedMeters,
  filterSuccessMeters,
} from "../../utilities/filterObj";
import { FormatTime } from "../../utilities/currency_time_formata";
import ErrorPage from "../../utilities/errorPage";

class UploadDevLevies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: null,
      isLoading: false,
      confirmLoading: false,
      value: 10,
      currentTransactions: [],
      currentPage: null,
      totalPages: null,
      csvFile: "",
      estateArr: null,
      startDate: undefined,
      headers: [],
      uploadStarted: false,
      property_id: null,
    };
    this.onPageChanged = this.onPageChanged.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleConfirmMeters = this.handleConfirmMeters.bind(this);
    this.handleGetAdminEstate = this.handleGetAdminEstate.bind(this);
    this.handleEstateSelect = this.handleEstateSelect.bind(this);
  }

  componentDidMount() {
    this.handleGetAdminEstate();
  }

  onPageChanged = (data) => {
    const { payload } = this.state;
    const { currentPage, totalPages, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    const currentTransactions = payload.csvData.slice(
      offset,
      offset + pageLimit
    );
    this.setState({ currentPage, currentTransactions, totalPages });
  };

  handleSubmit = (event) => {
    event.preventDefault();
   
    let fileInput = document.querySelector("#meter_csv-file");
    let showMsg = document.getElementById("show-msg");
    let uploadForm = document.getElementById("upload-form");
    let tableDiv = document.getElementById("upload-table");
    this.setState({ isLoading: true });

    // UploadDevLevy(fileInput)
    //   .then((response) => {
        // if (
        //   response &&
        //   response.status === 200 &&
        //   response.data.status === 200
        // ) {
          uploadForm.style.display = "none";
          tableDiv.style.display = "block";
          setTimeout(() => {
            this.setState({
            //   payload: response.data,
              isLoading: false,
              uploadStarted: true,
              headers: [
                
                { label: "Meter Number", key: "meterNumber" },
                { label: "Amount", key: "amount" },
               
              ],
            });
          }, 500);
        // } else if (
        //   (response && response.status === 400) ||
        //   (response && response.data.status === 400)
        // ) {
        //   uploadForm.style.display = "none";
        //   tableDiv.style.display = "block";
        //   showMsg.style.display = "block";
        //   showMsg.style.color = "red";
        //   showMsg.innerHTML =
        //     `Upload Failed! Your file contains invalid data.
        //     Click the download button below to view invalid data, 
        //     make sure to edit or remove them in the original file before you proceed`;
        //   setTimeout(() => {
        //     this.setState({
        //       payload: response.data,
        //       isLoading: false,
        //       uploadStarted: true,
        //       confirmLoading: true,
        //       headers: [
        //         { label: "Meter Number", key: "meterNumber" },
        //         { label: "Amount", key: "amount" },
               
        //       ],
        //     });
        //   }, 500);
        // } else {
        //   showMsg.style.display = "block";
        //   showMsg.innerHTML = "Server error, please try again";
        //   this.setState({ isLoading: false });
        // }
    //   })
    //   .catch(function (error) {
    //     showMsg.style.display = "block";
    //     showMsg.innerHTML = "Upload unsuccessful";
    //     this.setState({ isLoading: false });
    //   });
  };

  handleEstateSelect = (event) => {
    this.setState({ property_id: event.value });
    console.log("id", this.state.property_id);
  };

  handleChange = (event) => {
    var fileInput = document.querySelector("#meter_csv-file");
    var files = fileInput.files[0];
    var formData = new FormData();
    formData.append("meter_numbers", files, files.name);
    this.setState({ csvFile: formData });
  };

  handleConfirmMeters(event) {
    // console.log("payyy", this.state.payload.csvData);
    event.preventDefault();
    let fileInput = document.querySelector("#devlevy_csv-file");
    console.log("payyy", fileInput);
    // const validMeters = filterSuccessMeters(this.state.payload.csvData);
    let showMsg = document.getElementById("show-msg");
    // if (!this.state.property_id) {
    //   showMsg.style.display = "block";
    //   showMsg.innerHTML = "Please select an estate to complete your upload";
    //   return;
    // } else {
      this.setState({ confirmLoading: true });
      UploadDevLevy(fileInput).then((response) => {
        if (response && response.status == 200 || response && response.status === 304) {
            console.log("it wemt");
          showMsg.style.display = "block";
          showMsg.style.color = "green";
          showMsg.innerHTML = "Upload Successful...";
          this.setState({
            confirmLoading: false,
          });
          setTimeout(() => {
            window.location.reload(true);
          }, 2000);
        } else {
          setTimeout(() => {
            showMsg.style.display = "block";
            showMsg.style.color = "red";
            showMsg.innerHTML = "Upload Failed! The server couldn't complete the process, please try again";
            this.setState({ confirmLoading: false });
          }, 1000);
        }
      });
    // }
  }

  handleGetAdminEstate() {
    getAdminEstates().then((response) => {
      if (response && response.status == 200 || response && response.status == 304) {

        setTimeout(() => {
          this.setState({
            estateArr: response.data,
            isLoading: false,
          });
          console.log("arr", response.data);
        }, 500);
      } else {
        this.setState({ isLoading: false });
      }
    });
  }

  render() {
    const { isLoading, payload, uploadStarted, estateArr } = this.state;
    return (
      <div className="">
        {/*--------------------------------------------------------------------------------*/}
        {/*Start Inner Div*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*Row*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* <div id="paymentFrame" class="m-0 mx-auto"></div> */}
        {!estateArr ? (
          <div>
            <ErrorPage />
          </div>
        ) : (
        <Row>
          <Col>
            <div id="show-msg" className="d-flex w-100 justify-content-between">
              <div>
                <p style={{ display: "none", color: "red" }}>
                  Server error, please try again
                </p>
              </div>
            </div>
            <div>
              <div className="mt-5">
                <Col md={"auto"} className="p-5">
                  <Form>
                    <FormGroup className="mx-auto mt-1">
                      {estateArr && (
                        <Select
                          isLoading={isLoading}
                          isSearchable={true}
                          name="Available estate"
                          options={estateArr.map((obj) => {
                            let optionObj = {
                              value: obj.id,
                              label: obj.name,
                            };
                            return optionObj;
                          })}
                          // value={estateArr.filter(
                          //   (obj) => obj.value === this.state.property_id
                          // )}
                          onChange={this.handleEstateSelect}
                          placeholder="SELECT ESTATE"
                        />
                      )}
                    </FormGroup>
                    <FormGroup className="mx-auto">
                    </FormGroup>
                  </Form>
                </Col>
              </div>
              <Form
                id="upload-form"
                className=""
                onSubmit={this.handleConfirmMeters}
              >
                <Col md={"auto"} className="p-5">
                  <FormGroup>
                    <div
                      id="hide-progress"
                      className="progress mx-auto"
                      data-value={100}
                    >
                      <span className="progress-left">
                        <span className="progress-bar border-primary" />
                      </span>
                      <span className="progress-right">
                        <span className="progress-bar border-primary" />
                      </span>
                      <div className="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center">
                        <div
                          id="progress-output"
                          className="font-weight-bold"
                        ></div>
                      </div>
                    </div>
                  </FormGroup>
                  {/* </Col> */}
                  {/* <Col md={6} className="mt-2 my-auto"> */}
                  <FormGroup className="mx-auto">
                    <CustomInput
                      type="file"
                      id="devlevy_csv-file"
                      name="csvFile"
                      label=".csv format"
                      accept=".csv"
                      required
                    />
                  </FormGroup>
                  {/* </Col> */}
                  {/* </Row> */}
                  {/* <Row> */}
                  {/* <Col md={6} className="mt-2 my-auto"> */}
                  {/* </Col> */}
                  {/* <Col md={2} className="mt-2 ml-3 my-auto"> */}
                  <FormGroup className="mx-auto">
                    <Button
                      type="submit"
                      disabled={isLoading}
                      style={{
                        float: "right",
                        color: "white",
                        backgroundColor: "#1DE054",
                        textTransform: "none",
                      }}
                    >
                      {isLoading ? "Loading…" : "UPLOAD"}
                    </Button>
                  </FormGroup>
                  {/* </Col> */}
                </Col>
              </Form>
              {/* {!this.state.payload && <VerifyMeterTable />} */}
              {/* <div id="upload-table">
                {uploadStarted 
                && !payload ? (
                  <div>
                    <h6>Error Occurred! Please reload the page and try again</h6>
                    <Placeholder />
                  </div>
                ) : (

                    <div>
                      <Button
                        type="submit"
                        disabled={this.state.confirmLoading}
                        style={{
                          color: "white",
                          backgroundColor: "#1DE054",
                          borderRadius: "5px",
                          textTransform: "uppercase",
                        }}
                        onClick={() => {
                          this.handleConfirmMeters()
                        }}
                        className="border"
                      >
                        {this.state.confirmLoading
                          ? "Confirm update"
                          : "Confirm update"}
                      </Button>
                      <ListGroup>
                        <ListGroupItem className="text-body">
                          <mark>
                            Below is the status of each meter number. Note that upload will not be successful if there is an invalid number
                      </mark>
                        </ListGroupItem>
                        <ListGroupItem className="text-body">
                          <span className="true">
                            {payload &&
                              `${filterSuccessMeters(payload.csvData).length
                              } Valid `}
                          </span>
                      |{" "}
                          <span className="false">
                            {payload &&
                              `${filterFailedMeters(payload.csvData).length
                              } Invalid `}
                          </span>
                        </ListGroupItem>
                      </ListGroup>
                      <Table bordered className="mt-1 grey-bg table-hover">
                        <thead>
                          <tr>
                            <th className="pl-4">Landlord Name</th>
                            <th>Meter Number</th>
                            <th>Address</th>
                            <th>Status</th>
                            <th>Description</th>
                          </tr>
                        </thead>
                        {payload &&
                          payload.csvData.map((resident) => (
                            <tbody>
                              <tr
                                // onClick={() => {
                                //   localStorage.setItem("resident", transaction.user_id);
                                //   this.viewResident();
                                // }}
                                key={resident.meter_number}
                                role="row"
                                className="odd"
                              >
                                <td className={`pl-4 ${resident.valid}`}>
                                  {resident.landlord_name}
                                </td>
                                <td className={`${resident.valid}`}>
                                  {resident.meter_number}
                                </td>
                                <td className={`${resident.valid}`}>
                                  {resident.house_address}
                                </td>
                                <td className={`${resident.valid}`}>
                                  {resident.valid ? "Valid" : "Invalid"}
                                </td>
                                <td className={`${resident.valid}`}>
                                  {resident.message}
                                </td>
                              </tr>
                            </tbody>
                          ))}
                      </Table>


                      <div className="mt-5 d-flex">
                        <div className="d-flex">
                          {!isLoading && payload && (
                            <div
                              style={{
                                color: "blue",
                                fontWeight: "bolder",
                                fontSize: "larger",
                              }}
                            >
                              {!isLoading && payload ? (
                                <CSVLink
                                  data={filterFailedMeters(
                                    this.state.payload.csvData
                                  )}
                                  headers={this.state.headers}
                                  filename={"failed_meter_numbers.csv"}
                                  className="text-body small font-weight-bold"
                                >
                                  <FontAwesomeIcon icon={"file-export"} />
                              &nbsp;{" "}
                                  {`Download_failed_meter_numbers(${FormatTime(
                                    new Date()
                                  )})`}
                                </CSVLink>
                              ) : null}
                            </div>
                          )}
                        </div>
                        {!isLoading && payload && (
                          <div className="ml-auto">
                            {
                              <Pagination
                                totalRecords={payload.csvData.length}
                                pageLimit={this.state.value}
                                pageNeighbours={1}
                                onPageChanged={this.onPageChanged}
                              />
                            }
                          </div>
                        )}
                      </div>
                    </div>

                  )
                  
                  }

              </div> */}




            </div>
          </Col>
        </Row>
        )}
      </div>
    );
  }
}

export default UploadDevLevies;
