import React from "react";
import { buyAirtime } from "../../../utilities/api";
import { getFees } from "../../../utilities/api";
import shortid from "shortid";
import { netpluspayPOP } from "../../../views/TopUps/netfun";
import bgimage from "../../../assets/img/bgimage.PNG";
import Internet from "../../../assets/img/Internet.jpg";
import DataForm from "./DataForm";
import BillsTransactionSummary from "../PayBills/BillsTransactionSummary";
import BillsTransactionFailure from "../PayBills/BillsTransactionFailure";

class Data extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			step: 1,
			payload: 0,
			transactionID: null,
			payload: null,
			isLoading: false,
			provider: [],
			email: "",
			phone: "",
			internetPackage: [],
			fullName: "",
			destinationAccount: "",
			confirmDestination: "",
			category: [],
			storedFee: null,
			storedProvider: null,
			modalResponse: "",
			selectedProvider: "Choose Product",
			selectedPackage: "Choose Package",
			amount: null,
			service: "pay_bill",
			lineType: "virtual topup",
			showmsg: "",
			showmsgSuccess: "",

			formErrors: {
				provider: "",
				destinationAccount: "",
				confirmDestination: "",
				amount: null,
				email: "",
				phone: ""
			}
		};
	}

	componentDidMount = () => {
		this.setState({
			payload: 0,
			provider: [
				{
					name: "SMILE",
					internetPackage: [
						{
							name: "FLEXIDAILY(1GB) - 1DAY - N350",
							amount: 350
						},
						{
							name: "FLEXIWEEKLY(2GB) - 7 DAYS - N1000",
							amount: 1000
						},
						{
							name: "1.5GB VALUEPLUS - 30 DAYS- N1000",
							amount: 1000
						},
						{
							name: "5GB VALUEPLUS - 30 DAYS - N3000",
							amount: 3000
						},
						{
							name: "7GB VALUEPLUS - 30 DAYS - N4000",
							amount: 4000
						},
						{
							name: "11GB VALUEPLUS - 30 DAYS - N5000",
							amount: 5000
						},
						{
							name: "15GB VALUEPLUS - 30 DAYS - N6000",
							amount: 6000
						},
						{
							name: "21GB VALUEPLUS - 30AYS - N8000",
							amount: 8000
						},
						{
							name: "31GB VALUEPLUS - 30 DAYS - N11000",
							amount: 11000
						},
						{
							name: "80GB BUMPAVALUE - 120 DAYS - N40,000",
							amount: 40000
						},
						{
							name: "10GB ANYTIME - 1 YEAR - N8000",
							amount: 8000
						},
						{
							name: "20GB ANYTIME - 1 YEAR - N16000",
							amount: 16000
						},
						{
							name: "UNLIMITED ESSENTIAL - 30 DAYS - N15000",
							amount: 15000
						}
					]
				},
				{
					name: "SWIFT",
					internetPackage: [
						{
							name: "N/A",
							amount: 1230
						}
					]
				},
				{
					name: "SPECTRANET",
					internetPackage: [
						{
							name: "UNIFIED VALUE 8GB - N3070",
							amount: 3070
						},
						{
							name: "UNIFIED VALUE 4GB - N3070",
							amount: 3070
						},
						{
							name: "UNIFIED VALUE 10GB - N3580",
							amount: 3580
						},
						{
							name: "UNIFIED VALUE 6GB - N3580",
							amount: 3580
						},
						{
							name: "UNIFIED VALUE 7GB - N5115",
							amount: 5115
						},
						{
							name: "UNIFIED VALUE 14GB - N5115",
							amount: 5115
						},
						{
							name: "MAGIC VALUE 6K - N6140",
							amount: 6140
						},
						{
							name: "UNIFIED VALUE 24GB - N6140",
							amount: 6140
						},
						{
							name: "WEEKLY UNLIMITED - 6650",
							amount: 6650
						},
						{
							name: "UNIFIED VALUE 15GB - N7165",
							amount: 7165
						},
						{
							name: "UNIFIED VALUE 30GB - N7165",
							amount: 7165
						},
						{
							name: "NIGHT VALUE 20GB - N7675",
							amount: 7675
						},
						{
							name: "GOOD MORNING 15GB - N8190",
							amount: 8190
						},
						{
							name: "MEGA VALUE 15GB - N8190",
							amount: 8190
						},
						{
							name: "UNIFIED VALUE 25GB - N10235",
							amount: 10235
						},
						{
							name: "NIGHT VALUE 40GB - N11260",
							amount: 11260
						},
						{
							name: "UNIFIED VALUE 40GB - N12795",
							amount: 12795
						},
						{
							name: "UNIFIED VALUE 50GB (ABUJA ONLY) - N13305",
							amount: 13305
						},
						{
							name: "UNIFIED VALUE 60GB - N15355",
							amount: 15355
						},
						{
							name: "AbjUnltdLteGold (ABUJA ONLY) - N16380",
							amount: 16380
						},
						{
							name: "UNLIMITED GOLD - N18425",
							amount: 18425
						},
						{
							name: "UNLIMITED DIAMOND - N19450",
							amount: 19450
						},
						{
							name: "FREEDOM PRIME - N19960",
							amount: 19960
						},
						{
							name: "UNIFIED VALUE 55GB - N20475",
							amount: 20475
						},
						{
							name: "GALAXY VPN UNLIMITED - N30710",
							amount: 30710
						},
						{
							name: "FREEDOM PRIME PLUS - N38390",
							amount: 38390
						},
						{
							name: "FREEDOM ELITE - N56820",
							amount: 56820
						}
					]
				}
			]
		});

		const script = document.createElement("script");
		script.src = "https://js.netpluspay.com/inline.js";
		script.charset = "utf-8";
		script.async = true;
		script.onload = this.scriptLoaded();

		document.head.appendChild(script);
	};

	scriptLoaded = () => {
		this.setState({ netplusPayReady: true });
	};

	changeProvider = e => {
		this.setState({ selectedProvider: e.target.value });
		this.setState({
			internetPackage: this.state.provider.find(i => i.name === e.target.value)
				.internetPackage
		});
		this.handleGetFees();
	};

	changeInternetPackage = e => {
		this.setState({ selectedPackage: e.target.value });
		const pro = this.state.provider.find(
			i => i.name === this.state.selectedProvider
		).internetPackage;
		this.setState({
			amount: pro.find(a => a.name === e.target.value).amount
		});
	};

	handleChangeEmail = e => {
		e.preventDefault();
		const value = e.target.value;

		this.setState({
			email: value
		});
	};

	handleChangeFullName = e => {
		e.preventDefault();
		const value = e.target.value;

		this.setState({
			fullName: value
		});
	};
	handleChangeAmount = e => {
		e.preventDefault();
		const value = e.target.value;

		this.setState({
			amount: value
		});
	};
	handleChangeDestination = e => {
		e.preventDefault();
		const value = e.target.value;

		this.setState({
			destinationAccount: value
		});
	};

	nextStep = () => {
		const { step } = this.state;
		if (this.state.modalResponse === "FAILED") {
			this.setState({
				step: step + 2
			});
		} else
		if (this.state.modalResponse === "CAPTURED") {
			this.setState({
				step: step + 1
			});
		} 
		// else if (this.state.step === 1) {
		// 	this.setState({
		// 		step: step + 1
		// 	});
		// } 
		
		else {
		this.setState({
			step: step + 1
		});
	}
	};

	handleGetFees = () => {
		this.setState({
			isLoading: true
		});

		setTimeout(() => {
			let data = this.state.selectedProvider;

			if (sessionStorage.getItem("Service") === this.state.provider) {
				this.setState({
					payload: sessionStorage.getItem("Fee"),
					isLoading: false
				});
			} else {
				getFees(data).then(response => {
					if (response && response.status == 200) {
						setTimeout(() => {
							this.setState({
								payload: response.data.fees,
								isLoading: false,
								storedProvider: response.data.provider,
								storedFee: response.data.fees
							});

							const s = this.state.storedProvider;
							const fee = this.state.storedFee;
							sessionStorage.setItem("Fee", fee);
							sessionStorage.setItem("Service", s);
						}, 500);
					} else {
						this.setState({
							isLoading: false,
							showmsg: "Service Unavailable"
						});
					}
				});
			}
		}, 1000);
	};

	handlePayment = e => {
		e.preventDefault();
		// if (formValid(this.state.formErrors)) {
		if(this.state.payload === 0) {
			this.setState({
				isLoading: false,
				showmsg: "Service Unavailable"
			});
		} else {
		const finalamount =
			parseInt(this.state.amount) + parseInt(this.state.payload);

			const values = {
				// Values to give netpluspay class
				success: (message) => {
					this.setState({
						isLoading: true,
						modalResponse: message.status,
						transactionID: message.transId
					});
					this.handleWindowClose();
				},
				failed: (message) => {
					this.setState({
						isLoading: true,
						modalResponse: message.status,
						transactionID: message.transId
					});
					this.handleWindowClose();
				},
				cancel: (message) => {
					this.setState({ isLoading: false });
					this.handleWindowClose();
				},
	
				data: {
					merchantId: `${process.env.REACT_APP_MERCHANT_ID}`,
					name: "Customer", //Don't make it long so amount can show
					email: this.state.email,
					amount: finalamount,
					currency: "NGN",
					orderId: `POWER${shortid.generate()}`
				}
			};
	
			
			if (this.state.netplusPayReady) {
				// Check if netpluspay script is loaded
				
	
				let netplusPay = window.NetplusPay; // Call the netpluspay function
				let netplusPay2 = new netplusPay(values); // Create a new netpluspay class
				
				netplusPay2.beginTransaction();
	
				this.setState({
					isLoading: false
				});
			} else {
				setTimeout(() => {
					this.setState({
					  isLoading: false,
					  showmsg: "Oops! Server or Network Error"
					});
					
				}, 100);
			}

		// netpluspayPOP.setup({
		// 	merchant: `${process.env.REACT_APP_MERCHANT_ID}`,
		// 	customer_name: "Customer",
		// 	email: this.state.email,
		// 	amount: finalamount,
		// 	currency_code: "NGN",
		// 	narration: "Data Purchase",
		// 	order_id: `DATA${shortid.generate()}`,
		// 	container: "paymentFrame",
		// 	callBack: function(resp) {
		// 		if (resp && resp.status === "CAPTURED") {
		// 			this.setState({
		// 				modalResponse: resp.status,
		// 				transactionID: resp.trans_id
		// 			});
		// 		} else if (resp && resp.status === "FAILED") {
		// 			this.setState({
		// 				modalResponse: resp.status,
		// 				transactionID: resp.trans_id
		// 			});
		// 		}
		// 		//this.closeIframe();
		// 	}.bind(this),
		// 	onClose: function() {
		// 		this.setState({ isLoading: true });
		// 		this.handleWindowClose();
		// 	}.bind(this)
		// });
		// netpluspayPOP.prepareFrame();
		// } else {
		// 	let showMsg = document.getElementById("show-data-msg");
		// 	showMsg.innerHTML = "Invalid Form";
		// 	showMsg.style.display = "block";
		// 	showMsg.style.fontSize = "18px";
		// 	showMsg.style.color = "red";
		// 	this.setState({ isLoading: false });
		// }
	}
	};

	handleWindowClose = () => {
		if (this.state.modalResponse === "FAILED") {
			this.setState({
				isLoading: false,
				showmsg: "Transaction Failed"
			});
			this.nextStep();
		} else if (this.state.modalResponse === "CAPTURED") {
			this.handleBuyAirtime();
		} else {
			this.setState({
				isLoading: false,
				
			});
		}
	};

	handleBuyAirtime = () => {
		const finalamount =
			parseInt(this.state.amount) + parseInt(this.state.payload);

		let data = {
			destinationAccount: this.state.destinationAccount,
			amount: finalamount,
			service: this.state.service,
			provider: this.state.selectedProvider,
			trans_id: this.state.transactionID,
			email: this.state.email
		};

		setTimeout(() => {
			buyAirtime(data).then(response => {
				if (response && response.status === 200) {
					this.setState({
						isLoading: false,
						showmsg: "",
						showmsgSuccess: "Transaction Successful"
					});
					this.nextStep();
				} else if (response && response.status === 400) {
					this.setState({
						isLoading: false,
						showmsg: "Transaction Failed"
					});
					
				} else if (response && response.status === 500) {
					this.setState({
						isLoading: false,
						showmsg: "Server Error"
					});
					
				} 
				else {
					
					this.setState({ isLoading: false ,
						showmsg: "Server Error"
					});
					
				}
			});
		}, 4000);
	};

	render() {
		const { step } = this.state;
		const {
			payload,
			token,
			isLoading,
			category,
			provider,
			email,
			phone,
			billPackage,
			selectedPackage,
			internetPackage,
			firstName,
			lastName,
			accountName,
			destinationAccount,
			amount,
			service,
			selectedProvider,
			selectedCategory,
			billAccountId,
			billAccountDetailsId,
			billAccountIdDetails,
			formErrors,
			showmsg,
			showmsgSuccess
		} = this.state;
		const values = {
			payload,
			isLoading,
			category,
			token,
			provider,
			email,
			phone,
			billPackage,
			selectedPackage,
			internetPackage,
			firstName,
			lastName,
			accountName,
			destinationAccount,
			amount,
			service,
			selectedProvider,
			selectedCategory,
			billAccountId,
			billAccountDetailsId,
			billAccountIdDetails,
			formErrors,
			showmsg,
			showmsgSuccess
		};

		switch (step) {
			case 1: 
			return (
				<DataForm 
				nextStep={this.nextStep}
				changeProvider={this.changeProvider}
				values={values}
				handlePayment={this.handlePayment}
				handleChangeEmail= {this.handleChangeEmail}	
				handleChangeDestination= {this.handleChangeDestination}
				changeInternetPackage = {this.changeInternetPackage}
						
				/>
			);

			case 2: 
			return (
				<BillsTransactionSummary 
				
				/>
			);

			case 3: 
			return (
				<BillsTransactionFailure 
				
				/>
			);
		}
	}
}

export default Data;