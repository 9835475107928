import React from "react";
import { NavLink, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class SideBar extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<React.Fragment>
				<div className="col-md-2 side-bar">
					<NavLink
						className="side-bar-link"
						to="/airtime"
						//activeClassName="navUl__active"
						//activeClassName="selected"
						style={{ textDecoration: "none", color: "white" }}
						activeStyle={{
							fontWeight: "bold",
							background: "#1DE054",
							color: "#1DE054"
						}}
					>
						<div className="side-bar-button">
							<FontAwesomeIcon icon={"mobile-alt"} className="icon-style" />

							<p>AIRTIME</p>
						</div>
					</NavLink>
					<NavLink
						className="side-bar-link"
						to="/data"
						style={{ textDecoration: "none", color: "white" }}
						activeStyle={{
							fontWeight: "bold",
							background: "#1DE054",
							color: "#1DE054"
						}}
					>
						<div className="side-bar-button1">
							<FontAwesomeIcon icon={"globe"} className="icon-style" />

							<p>DATA</p>
						</div>
					</NavLink>
					<NavLink
						className="side-bar-link"
						to="/pay-bills"
						style={{ textDecoration: "none", color: "white" }}
						activeStyle={{
							fontWeight: "bold",
							background: "#1DE054",
							color: "#1DE054"
						}}
					>
						<div className="side-bar-button2">
							<FontAwesomeIcon icon={"receipt"} className="icon-style" />
							<p>PAY BILLS</p>
						</div>
					</NavLink>
				</div>
			</React.Fragment>
		);
	}
}

export default SideBar;
