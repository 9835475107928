export function filterObj(params) {
  let filteredParams = Object.keys(params)
    .filter(
      (e) => params[e] !== null && params[e] !== "null" && params[e] !== ""
    )
    .reduce((o, e) => {
      o[e] = params[e];
      return o;
    }, {});
  if (JSON.stringify(filteredParams) === "{}") {
    //This will check if the object is empty
    return "empty";
  } else {
    return filteredParams;
  }
}

export function filterRoutesUser(params) {
  const filterArr = params.filter(
    (x) => x.adminType === "service-user" || x.adminType === "all"
  );
  return filterArr;
}

export function filterRoutesOwner(params) {
  const filterArr = params.filter(
    (x) => x.adminType === "service-owner" || x.adminType === "all"
  );
  return filterArr;
}

export function filterRoutesNetplus(params) {
  const filterArr = params.filter(
    (x) => x.adminType === "netplus-admin" || x.adminType === "all"
    //  ||  x.adminType === "admin"
  );
  return filterArr;
}

export function filterRoutesService(params) {
  const filterArr = params.filter(
    (x) => x.adminType === "service-admin" || x.adminType === "all" 
    // ||  x.adminType === "admin"
  );
  return filterArr;
}

export function filterResidentInactive(params) {
  const filterArr = params.filter((x) => x.is_verified === false);
  return filterArr;
}

export function filterResidentActive(params) {
  const filterArr = params.filter((x) => x.is_verified === true);
  return filterArr;
}

export function filterFailedMeters(params) {
  const filterArr = params.filter((x) => x.valid === false);
  return filterArr;
}

export function filterSuccessMeters(params) {
  const filterArr = params.filter((x) => x.valid === true);
  const validParams = filterArr.map(
    ({ message, valid, ...keepAttrs }) => keepAttrs
  );
  return validParams;
}
