import React from "react";
import "./Paymentissues.css";

function Paymentissues (props) {
    const paymentissue = [
        {text: "No Meter Number", handler: props.actionProvider.noMeterNumberhandler, id: 1},
    ];

    const paymentIssuesOptions = paymentissue.map((payment) => (
        <button key={payment.id} onClick = {payment.handler} className = "payment-buttons">
            {payment.text}
        </button>
    ));
    return <div className="payment-container"> {paymentIssuesOptions}</div>;
};

export default Paymentissues;