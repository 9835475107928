import React from "react";
import PropTypes from "prop-types";
import { fetchEstateResidents, updateStatus } from "../../utilities/api";



class ToggleSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // isChecked: null,
      showEnabled: false,
      showDisabled: true,
      isChecked: this.props.isChecked,
    };
  }

  componentDidMount= () => {
    // console.log("aasssa", this.props.isChecked)
    const checked= this.props.isChecked;
    setTimeout(()=> {
        if(this.props.isChecked === "unoccupied") {
            this.setState({
                isChecked: 0
              })
        } else
        if(this.props.isChecked === "occupied") {
            this.setState({
                isChecked: 1
              })
        }
      
    },1500)
   
  }

  handleCheckboxChange = (e) => {
    var checked = e.target.checked;
    console.log("checked", checked);

    this.setState({
      isChecked: checked,
    });
    this.handleDisableAdmin();
  };

  handleDisableAdmin = () => {
    console.log("disableeee");
    const meterno = this.props.meterNo;
    if (this.state.isChecked) {
      const param = {
        meter_number: meterno,
        status: "unoccupied"
      };

      const reason= "activate";
    //   const mail = email1;
      updateStatus(param).then((response) => {
        if (response && response.status == 200) {
          this.props.handleRefresh();
        } else {
          this.setState({
            isChecked: false,
          });
        }
      });
    } else {
        const param = {
            meter_number: meterno,
            status: "occupied"
          };

      const reason= "deactivate";
    //   const mail = email1;
      updateStatus(param).then((response) => {
        if (response && response.status == 200) {
          this.props.handleRefresh();
        } else {
          this.setState({
            isChecked: true,
          });
        }
      });
    }
  };

  render() {
    const { selected, toggleSelected } = this.props;
    return (
      <React.Fragment>
        <label className="switch">
          <input
            type="checkbox"
            id={this.props.id}
            onChange={this.handleCheckboxChange}
            checked={this.state.isChecked}
          />
          <span className="slider" />
        </label>
      </React.Fragment>
    );
  }
}

export default ToggleSwitch;
