import React from "react";

class UserDetails extends React.Component {
	constructor(props) {
		super(props);
	}


    back = e => {
		e.preventDefault();
		this.props.prevStep();
    };
    

    render() {
        const { values } = this.props;
        return(
            <React.Fragment>
                <form className="" onSubmit={this.props.handlePayment}>
								<p className="show-msg">{values.showmsg}</p>
								<p className="show-msg-success">{values.showmsgSuccess}</p>
								<p className="form-header">BUY POWER</p>
								<div class="form-group landing-form-input">
									<select
										className=" form-control landing-form-control custom-select"
										id="exampleFormControlSelect1"
										placeholder="SELECT CATEGORY"
										name="provider"
										value={values.provider}
										onChange={this.handleChangeProvider}
										disabled={values.isLoading}
										required
									>
										<option value="">SELECT SERVICE PROVIDER</option>
										<option value="phcneko">PHCNEKO</option>
										<option value="phcnkano">PHCNKANO</option>
										<option value="phcnjos">PHCNJOS</option>
										<option value="phcnabuja">PHCNABUJA</option>
										<option value="phcnibadan">PHCNIBADAN</option>
										<option value="phcnenugu">PHCNENUGU</option>
									</select>

									{/* <span className="errorMessage">{formErrors.provider}</span> */}
								</div>

								<div class="form-group landing-form-input">
									<input
										type="tel"
										class="form-control landing-form-control"
										id="number1"
									 placeholder="METER NUMBER"
										name="destinationAccount"
										value={values.billAccountId}
										onChange={this.props.handleChange}
										disabled={true}
										required
									/>

									{/* <span className="errorMessage">
										{formErrors.destinationAccount}
									</span> */}
								</div>
								<div class="form-group landing-form-input">
									<input
										type="text"
										class="form-control landing-form-control"
										// id="number2"
										// name="confirmDestination"
										 placeholder="ACCOUNT NAME"
										value={values.billAccountIdDetails}
										onChange={this.props.handleChange}
										disabled={true}
										required
									/>
								</div>
								<div class="form-group landing-form-input">
									<input
										type="number"
										class="form-control landing-form-control"
										placeholder="AMOUNT"
										name="amount"
										min = "0"
										value={values.amount}
										onChange={this.props.handleChange}
										disabled={values.isLoading}
										required
									/>

									{/* <span className="errorMessage">{formErrors.amount}</span> */}
								</div>
								<span className="landing-fee">
									{"Convenience Fee: " + values.payload}
								</span>
                                <button
									type="submit"
									className="btn btn-dark landing-form-input "
                                    disabled={values.isLoading}
                                    onClick={this.back}
								>
									{values.isLoading ? "BACK" : "BACK"}
								</button>
								<button
									type="submit"
									className="btn btn-dark landing-form-input right-button"
									disabled={values.isLoading}
								>
									{values.isLoading ? "LOADING…" : "PAY"}
								</button>
							</form>
            </React.Fragment>
        )
    }
}

    export default UserDetails;
    