import React from "react";
import { Jumbotron, Button } from "reactstrap";

class ErrorPage extends React.Component {
  render() {
    return (
      <div class="text-center">
        <div className="jumbotron">
          <h1 className="display">Sorry, we couldn't find this page</h1>
          <h3 className="lead">
            This may be because of a technical error that we're working to get
            fixed. Try reloading the page
          </h3>
          <hr className="my-2" />
          {/* <p>It uses utility classes for typography and spacing to space content out within the larger container.</p> */}
          {/* <p className="lead">
            <Button color="primary">Reload</Button>
          </p> */}
        </div>
      </div>
    );
  }
}
export default ErrorPage;
