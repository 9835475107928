/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, { useState } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import {
  registerUserDefault,
  confirmEstate,
  registerUserExisting,
} from "../../../utilities/api";
import Placeholder from "../../../utilities/placeholder";

const SignUp = function (properties, loading) {
  const [nestedModal, setNestedModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showmsg, setShowMsg] = useState("");
  const [values, setValues] = useState({});
  const [estate_id, setEstateId] = useState("");
  const toggleNested = () => {
    setNestedModal(!nestedModal);
  };

  const handleConfirmEstate = () => {
    setIsLoading(true);
    setNestedModal(!nestedModal);
    if (estate_id == 1) {
      console.log("val", values);
      // setShowMsg("This meter number is not registered in this platform, please check and try again. To pay outside an estate, use the second BUY POWER(EKEDC...) tab on the home page")
      // showError.style.display = "block";
      // showError.innerHTML = response.data;
      setIsLoading(false);
      setTimeout(() => {
        // let showError = document.getElementById("show-error");
        registerUserDefault({
          first_name: values.first_name,
          last_name: values.last_name,
          phone: values.phone,
          email: values.email,
          password: values.password,
          property_id: estate_id,
          meter_number: values.meter_number,
          role: values.role,
        }).then((response) => {
          if (
            response &&
            response.status === 200 &&
            response.data.isFound === false
          ) {
            setShowMsg("Meter number is invalid, check and try again");
            // showError.style.display = "block";
            // showError.innerHTML = "Meter number is invalid, check and try again";
            setIsLoading(false);
          } else if (response && response.status === 200) {
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("firstSignup", false);
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("redirect", false);
            window.location.reload(true);
          } else if (response && response.status === 400) {
            setShowMsg(response.data);
            // showError.style.display = "block";
            // showError.innerHTML = response.data;
            setIsLoading(false);
          } else {
            setShowMsg("Opps! Server error");
            // showError.style.display = "block";
            // showError.innerHTML = "Opps! Server error";
            setIsLoading(false);
          }
        });
      }, 2000);
    } else {
      setTimeout(() => {
        // let showError = document.getElementById("show-error");
        registerUserExisting({
          first_name: values.first_name,
          last_name: values.last_name,
          phone: values.phone,
          email: values.email,
          password: values.password,
          property_id: estate_id,
          meter_number: values.meter_number,
          role: values.role,
        }).then((response) => {
          if (response && response.status === 200) {
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("firstSignup", false);
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("redirect", false);
            window.location.reload(true);
          } else if (response && response.status === 400) {
            setShowMsg(response.data);
            // showError.style.display = "block";
            // showError.innerHTML = response.data;
            setIsLoading(false);
          } else {
            setShowMsg(
              "There was a server error. This could be caused by a slow network connection or the service not responding to request at the moment. Please try again later"
            );
            // showError.style.display = "block";
            // showError.innerHTML = "Opps! Server error";
            setIsLoading(false);
          }
        });
      }, 2000);
    }
  };

  return (
    <Formik
      initialValues={{
        first_name: "",
        last_name: "",
        phone: "",
        password: "",
        confirmPassword: "",
        email: "",
        property_id: "",
        meter_number: "",
        role: "",
        isLoggedIn: false,
      }}
      onSubmit={(values, { setSubmitting }, props) => {
        // let showMsg = document.getElementById("show-error");
        // showMsg.style.display = "none";

        setValues(values);
        return confirmEstate({ meter_number: values.meter_number })
          .then((response) => {
            if (response && response.status === 200) {
              setSubmitting(false);
              setTimeout(() => {
                setNestedModal(!nestedModal);
                setEstateId(response.data.id);
                let confirmDiv = document.getElementById("confirm-div");
                let estateResult = document.getElementById("estate");
                confirmDiv.style.display = "block";
                estateResult.style.display = "block";
                estateResult.innerHTML = response.data.name;
              }, 100);
            } else {
              setNestedModal(nestedModal);
              setSubmitting(false);
              setTimeout(() => {
                // showMsg.innerHTML = "Opps! We couldn't verify your meter at this time, try again";
                // showMsg.style.display = "block";
                setShowMsg(
                  "We couldn't verify your meter at this time, try again"
                );
              }, 1000);
            }
          })
          .catch(function (error) {
            // showMsg.innerHTML = "Opps! Server error";
            // showMsg.style.display = "block";
            setShowMsg("Opps! Server Error");
            setTimeout(() => {
              setSubmitting(false);
              setNestedModal(nestedModal);
            }, 100);
          });
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email("Invalid email address").required("Required"),
        first_name: Yup.string()
          .trim()
          .matches(
            /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!@-_?&%]{2,}$/,
            "At least two letters are expected"
          )
          .required("first name is required"),
        last_name: Yup.string()
          .trim()
          .matches(
            /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!@-_?&%]{2,}$/,
            "At least two letters are expected"
          )
          .required("last name is required"),
        phone: Yup.number(),
        meter_number: Yup.string()
          .matches(
            /^(\d{11,13})$/,
            "Digits must be between 11 and 13 characters long"
          )
          .required("METER number is required"),
        password: Yup.string().required("No password provided."),
        confirmPassword: Yup.string().when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string()
            .oneOf([Yup.ref("password")], "Passwords must match")
            .required("Passwords doesn't match"),
        }),
        // .min(8, "Password is too short - should be 8 chars minimum.")
        // .matches(/(?=.*[0-9])/, "Password must contain a number.")
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <div>
            <h3 id="show-error">Server error</h3>
            <form onSubmit={handleSubmit} className="">
              {/* <p id="show-msg"></p> */}
              <p className="show-msg">{showmsg}</p>

              <p className="form-header">SIGN UP</p>
              <div class="form-group landing-form-input1">
                <select
                  name="role"
                  type="text"
                  class=" form-control landing-form-control custom-select"
                  placeholder="SELECT ROLE"
                  value={values.role}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // className={
                  //   errors.role && touched.role && "error"
                  // }
                >
                  <option value="">SELECT ROLE</option>
                  <option value="service-user">RESIDENT</option>
                  <option value="service-owner">
                    HOME OWNER (NON-RESIDENT)
                  </option>
                </select>
              </div>
              <div class="form-group landing-form-input1">
                <input
                  name="first_name"
                  type="text"
                  class=" form-control landing-form-control"
                  placeholder="FIRST NAME"
                  value={values.first_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // className={
                  //   errors.first_name && touched.first_name && "error"
                  // }
                />
                {errors.first_name && touched.first_name && (
                  <div
                    style={{ color: "red", fontSize: ".8em" }}
                    className="input-feedback"
                  >
                    {errors.first_name}
                  </div>
                )}
              </div>

              <div class="form-group landing-form-input1">
                <input
                  name="last_name"
                  class=" form-control landing-form-control"
                  type="text"
                  placeholder="LAST NAME"
                  value={values.last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // className={
                  //   errors.last_name && touched.last_name && "error"
                  // }
                />
                {errors.last_name && touched.last_name && (
                  <div
                    style={{ color: "red", fontSize: ".8em" }}
                    className="input-feedback"
                  >
                    {errors.last_name}
                  </div>
                )}
              </div>
              <div class="form-group landing-form-input1">
                <input
                  name="email"
                  type="text"
                  class="form-control landing-form-control"
                  placeholder="EMAIL"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  //className={errors.email && touched.email && "error"}
                />
                {errors.email && touched.email && (
                  <div
                    style={{ color: "red", fontSize: ".8em" }}
                    className="input-feedback"
                  >
                    {errors.email}
                  </div>
                )}
              </div>

              <div class="form-group landing-form-input1">
                <input
                  name="meter_number"
                  type="text"
                  class="form-control landing-form-control"
                  placeholder="METER NUMBER"
                  value={values.meter_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // className={
                  //   errors.meter_number && touched.meter_number && "error"
                  // }
                />
                {errors.meter_number && touched.meter_number && (
                  <div
                    style={{ color: "red", fontSize: ".8em" }}
                    className="input-feedback"
                  >
                    {errors.meter_number === "meter number is required"
                      ? errors.meter_number
                      : "Digits must be between 11 and 13 characters long"}
                  </div>
                )}
              </div>
              <div class="form-group landing-form-input1">
                <input
                  name="password"
                  id="password"
                  type="password"
                  class="landing-form-control form-control"
                  placeholder="PASSWORD"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  //className={errors.password && touched.password && "error"}
                />
                {errors.password && touched.password && (
                  <div
                    style={{ color: "red", fontSize: ".8em" }}
                    className="input-feedback"
                  >
                    {errors.password}
                  </div>
                )}
              </div>
              <div class="form-group landing-form-input1">
                <input
                  name="confirmPassword"
                  id="confirmPassword"
                  type="password"
                  class="landing-form-control form-control"
                  placeholder="CONFIRM PASSWORD"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // className={
                  //   errors.confirmPassword &&
                  //   touched.confirmPassword &&
                  //   "error"
                  // }
                />
                {errors.confirmPassword && touched.confirmPassword && (
                  <div
                    style={{ color: "red", fontSize: ".8em" }}
                    className="input-feedback"
                  >
                    {errors.confirmPassword}
                  </div>
                )}
              </div>
              <div class="form-group landing-form-input1">
                <input
                  name="phone"
                  type="text"
                  placeholder="PHONE NUMBER"
                  class="landing-form-control form-control"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // className={errors.phone && touched.phone && "error"}
                />
                {errors.phone && touched.phone && (
                  <div
                    style={{ color: "red", fontSize: ".8em" }}
                    className="input-feedback"
                  >
                    {errors.phone === "phone number is required"
                      ? errors.phone
                      : "Letters are not allowed"}
                  </div>
                )}
              </div>
              <div class="text-center">
                <button
                  className="btn btn-block btn-dark landing-form-input1 airtime-button"
                  // type="submit"
                  disabled={isLoading}
                  // disabled={true}
                  onClick={handleSubmit}
                >
                  {isLoading ? "Loading…" : "Sign up"}
                </button>
              </div>
              <Modal
                isOpen={nestedModal}
                toggle={toggleNested}
                // onClosed={closeAll ? toggle : undefined}
              >
                <ModalBody>
                  <p>
                    This meter number belongs to this estate:{" "}
                    <span id="estate" className="font-weight-bolder"></span>
                  </p>
                  <div class="row">
                    <Col sm={{ size: "auto", offset: 1 }}>
                      <Button
                        id="confirm-div"
                        className="btn btn-md bg-green text-uppercase"
                        onClick={handleConfirmEstate}
                      >
                        Yes, continue
                      </Button>
                    </Col>
                    <Col sm={{ size: "auto", offset: 1 }}>
                      <Button color="secondary" onClick={toggleNested}>
                        No, try another
                      </Button>
                    </Col>
                  </div>
                </ModalBody>
              </Modal>
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

class SignUpForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true,
      payload: [],
      value: 10,
      isLoading: false,
      currentAgents: [],
      currentPage: null,
      totalPages: null,
      scroll: true,
      searchInput: {},
      properties: [],
      estateSelected: "",
      showmsg: "",
    };

    this.onDismiss = this.onDismiss.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ estateSelected: event.target.value });
  }

  onDismiss() {
    this.setState({
      visible: false,
    });
  }

  render() {
    const { properties, isLoading, showmsg } = this.state;

    return (
      <div>
        {isLoading ? (
          <div>
            <Placeholder />
          </div>
        ) : (
          // <div className="modal-body">
          //   <div className="d-flex flex-column flex-wrap">
          <div>
            <SignUp
              properties={this.props.properties}
              loading={this.state.isLoading}
              showmsg={this.state.showmsg}
            />
            <p className="forgot-text">
              ALREADY HAVE AN ACCOUNT?
              <span>
                <a
                  className="reset-text ml-1"
                  href="javascript:void(0)"
                  onClick={this.props.prevStep}
                >
                  SIGN IN
                </a>
              </span>
            </p>
          </div>
          // </div>
        )}
      </div>
    );
  }
}

export default SignUpForm;
