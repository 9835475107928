import React from "react";
import Internet from "../../../assets/img/Internet.jpg";
import Data from "./Data";


class DataContainer extends React.Component {
	constructor(props) {
		super(props);
	}

    render() {
        return(
            <React.Fragment>
                <div className="row animate__animated animate__slideInRight">
					<div className="col left-col">
                    <img className="internet-page-img" src={Internet}></img>
					</div>
					<div className="col">
						<div className="form-container">
							<Data />
						</div>
					</div>
				</div>
            </React.Fragment>
        )
    }
}

    export default DataContainer;
    