import React from "react";
import LandingPage from "../LandingPage/LandingPage";
import Logo from "../../../assets/img/Logo.png";
import success from "../../../assets/img/success.jpg";

class TransactionSummary extends React.Component {
	constructor(props) {
		super(props);
	}

	backHome = e => {
		e.preventDefault();
		// <LandingPage />
	};

	render() {
		const { values } = this.props;
		return (
			<React.Fragment>
                            <div className="summary-container">
                                <img src= {success} className="summary-success" />
                                <h5 className="summary-header">Yay! Transaction Successful</h5>
                                <div className="summary-content">
                                    <p>Thank you for using Bills by NetPlusPay.</p>
                                    <p>We've sent a receipt to your email address.</p>
                                </div>
                                <div>
									<p className= "token">Token: {values.token}</p>
								</div>

								<div className="summary-content">
									<p>Didn't get a receipt?</p>
									<p>Check your spam folder or send again</p>
								</div>

								<button
									type="submit"
									className="btn btn-dark landing-form-input summary-button"
									onClick={() => window.location.reload(false)}
								>
									BACK TO HOME
								</button>

								<div>
									<img src={Logo} className="summary-logo" />
								</div>
							
				</div>
			</React.Fragment>
		);
	}
}

export default TransactionSummary;
