import React from 'react';
import BuyPowerEstates from './BuyPowerEstates';
import power from "../../../assets/img/Power.jpg";

class FormContainer extends React.Component {
    render () {
        return (
			<React.Fragment>
				<div className="row animate__animated animate__slideInRight">
					<div className="col left-col">
						<img className="power-page-img" src={power}></img>
					</div>
					<div className="col">
						<div className="form-container ">

							<BuyPowerEstates />
						</div>
					</div>
				</div>
			</React.Fragment>
		);
    }
}

export default FormContainer;