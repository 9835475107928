import React from "react";
import { useState } from "react";
//import './MainPage.scss';
//import UploadMeter from "./UploadMeter";
//import UploadedMeter from "./UploadedMeter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Card,
  CustomInput,
  CardBody,
  CardHeader,
} from "reactstrap";
import EstateTemplate from "./EstateTemplate.csv";
import axios from "axios";
import{tosinUpload} from "../../utilities/api";
import api from "../../utilities/api";

const token = localStorage.getItem("token");
const REACT_APP_BASE_URL_ESTATES = process.env.REACT_APP_BASE_URL_ESTATES;

class MainPage extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          selectedFile: null,
          csvFile: ""
        };
      
        this.onFileChange = this.onFileChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
  }

  //***********************************

  handleSubmit = (event) =>{
    event.preventDefault();
    let fileInput = document.querySelector("#meter_csv-file");
    let showMsg = document.getElementById("show-error")
    let showMsg2 =document.getElementById("show-msg");

    if (this.state.selectedFile == null){
      showMsg.innerHTML = "Please Select A File";
      showMsg.style.display = "block";
    } else if (this.state.selectedFile){
      tosinUpload(fileInput)
      .then((response)=> {
        if (
          response.status == 200
        ) {
            showMsg2.innerHTML = "UPLOADED!"
            showMsg2.style.display = "block";
            console.log(response);
        }
        else if (
          (response.status == 400) || (response.status == 500) 

        ) {
          showMsg.innerHTML = "Not Uploaded"
          showMsg.style.display = "block";
          console.log(response);
        }else{
          console.log(response);
        }
      })
      .catch(function (error) {
        showMsg2.innerHTML = "upload Unsuccessful";
      });
    }
   

  };

  //************************************

  onFileChange =  event => {
    this.setState({ selectedFile: event.target.files[0]});
  };

  onFileUpload = (event) => {
    event.preventDefault();


    let showMsg = document.getElementById("show-error");

      if(this.state.selectedFile == null){
          //alert("Please Choose A File");
          showMsg.innerHTML = "Please Select A File";
          showMsg.style.display = "block";

      }
      
      else if(this.state.selectedFile){
      showMsg.innerHTML ="";
      showMsg.style.display = "block";
      const formData = new FormData();
      formData.append(
      "myFile",
      this.state.selectedFile,
      this.state.selectedFile.name
    );
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'Authorization': 'Bearer ' + token,
      },
    };
    console.log(this.state.selectedFile);

    axios.post(`${process.env.REACT_APP_BASE_URL_ESTATES}/api/electricity-service/upload-service-payments`,formData, config)
    .then((response) => {
      console.log(response.data);
    });

    }


  };

  fileData = () => {
    if (this.state.selectedFile) {
      return(
        <div>
            <CardBody>
            <h2>FILE DETAILS:</h2>

           <p>File Name: {this.state.selectedFile.name}</p>
           <p>File Type: {this.state.selectedFile.type}</p>

              <p>
               Last Modified: {" "}
               {this.state.selectedFile.lastModifiedDate.toDateString()}
               </p>
               </CardBody>


        </div>
      );
    } else {
      return (
        <div>
          <CardBody>
          <br />

           <p> Choose Before Clicking The Upload Button</p>
           <p style={{color:"red"}}>Note: File must be in .CSV format</p>
  
          </CardBody>

        </div>
      );
    }
  };

  render () {

    return (
      <div className="content">
        <Card className="ml-5 w-75">
          <CardHeader className="w-100 bg-dark text-green p-3"> UPLOAD FILE</CardHeader>
          <CardBody>
        <p>
          <a style={{color:"1DE054", textDecorationLine:"underline"}} href={EstateTemplate} download = "Estate Template">Download</a> The File Template
        </p>
        <p>
          Please Select The File You Want To Upload
        </p>
        <div>
          <CustomInput
           type="file" 
           id="meter_csv-file"
           accept='.csv' 
           onChange = {this.onFileChange}
           />
          <br/>
          <br />
          <Button style={{color: "white", backgroundColor: "#1DE054", textTransform: "none"}} onClick={this.handleSubmit}>
            Upload!
          </Button>
          <p id="show-error" style={{color:"red"}}></p>
          <p id= "show-msg" style={{color: "#1DE054"}} ></p>
          <h3 id="progress-output"></h3>
        </div>
        {this.fileData()}
        </CardBody>
        </Card>
      </div>
    );
  }
}

export default MainPage
