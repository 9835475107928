import React from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import bgimage from "../../assets/img/bgimage.PNG";
import MainBillForm from "./MainBillForm";

class Bills extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<React.Fragment>
				<Navbar />

				<div className="container airtime-container">
					<div className="row">
						<SideBar />

						<div className="col-md-5">
							<MainBillForm />
						</div>

						<div className="col-md-5">
							<img src={bgimage} className="img-container" />
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Bills;
