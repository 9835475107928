import React from "react";
import { Transition } from "react-transition-group";
import woman from "../../../assets/img/Woman_img.jpg";
import phone from "../../../assets/img/Phone.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import callcenter from "../../../assets/img/call-center.png";
import Logo from "../../../assets/img/Logo.png";
import DataContainer from "../BuyData/DataContainer";
import FormContainer from "../BuyPowerEstates/FormContainer";
import MainBills from "../PayBills/MainBills";
import BuyPowerPublicContainer from "../BuyPowerPublic/BuyPowerPublicContainer";
import LandingPageContent from "./LandingPageContent";
import AirtimeDataContainer from "../BuyAirtime/AirtimeDataContainer";
import SignInForm from "../BuyPowerEstates/SignInForm";

class Menu extends React.Component {
    constructor(props) {
		super(props);

		this.state = {
			toggleView: "Buy Power Estates",
			button: true,
			bgColor: "",
			bool: false
		};
	}

	toggle = () => {
		this.setState(prevState => ({
			bool: !prevState.bool
		}));
	};

// 	handleToggleView = () => {
// 		switch (this.state.toggleView) {
// 			// case "Landing Page Content":
// 			// 	return <LandingPageContent />;
// 			case "Airtime Form":
// 				return <AirtimeDataContainer />;
// 			case "Data Form":
// 				return <Data />;
// 			case "Buy Power Estates":
// 				return <FormContainer />;
// 			case "Main Bills":
// 				return <MainBills />;
// 			case "Buy Power Public":
// 				return <BuyPowerPublicContainer />;
// 		}
// } 

render() {
    return (
        <React.Fragment>

{/* <div id="paymentFrame" class="m-0 mx-auto"></div>
                <div id="netplusFrame" class="m-0 mx-auto"></div> */}
                

				<div className="container landing-container menu-toggle">
					<div className="landing-child-container">
						<div className="content-inside">
							{/* <img src={Logo} className="net-logo"></img> */}

							<div className="nav-container">
								<nav class="navbar navbar-expand-lg">
									{/* <button
										class="navbar-toggler"
										type="button"
										data-toggle="collapse"
										data-target="#navbarSupportedContent"
										aria-controls="navbarSupportedContent"
										aria-expanded="false"
										aria-label="Toggle navigation"
									>
										<span class="navbar-toggler-icon">
											<FontAwesomeIcon icon={"bars"} />
										</span>
									</button> */}
									{/* <div
										class="collapse navbar-collapse"
										id="navbarSupportedContent"
									>
										<a class="navbar-brand " href="#"></a> */}
		                            <img src={Logo} className="net-logo"></img>
										<ul class=" menu-container navbar-nav mr-auto ">
                                            {/* <div className="left-menu"> */}
                                            
											<li className="nav-item ">
												<button
													type="button"
                                                    className="btn nav-item-style "
                                                    data-toggle="collapse" 
                                                    data-target="#collapseOne" 
                                                    aria-expanded="true" 
                                                    aria-controls="collapseOne"
											
												>
													<FontAwesomeIcon
														icon={"bolt"}
														size="3x"
														className="fa-icon"
													/>

													<p className="menu-option">BUY POWER </p>
													<p className="menu-tag">(PRIVATE ESTATES) </p>
												</button>

                                                <div class="collapse" id="collapseOne">
                                                    <FormContainer />
                                                </div>
											</li>

											<li className="nav-item ">
												<button
													type="button"
                                                    className="btn nav-item-style "
                                                    data-toggle="collapse" 
                                                    data-target="#collapseTwo" 
                                                    aria-expanded="true" 
                                                    aria-controls="collapseTwo"
													
												>
													<FontAwesomeIcon
														icon={"mobile-alt"}
														size="3x"
														className="fa-icon"
													/>

													<p className="menu-option">Airtime & Data</p>
													<p className="menu-tag">(MTN,GLO,AIRTEL,9MOBILE) </p>
												</button>
                                                <div class="collapse" id="collapseTwo">
                                                    <AirtimeDataContainer />
                                                </div>
											</li>

											<li className="nav-item">
												<button
                                                    type="button"
                                                    className="btn nav-item-style "
													data-toggle="collapse" 
                                                    data-target="#collapseThree" 
                                                    aria-expanded="true" 
                                                    aria-controls="collapseThree"
												>
													<FontAwesomeIcon
														icon={"globe"}
														size="3x"
														className="fa-icon fa-icon-pay"
													/>

													<p className="menu-option">Buy Internet</p>
													<p className="menu-tag">(SPECTRANET,SMILE,SWIFT) </p>
												</button>
                                                <div class="collapse" id="collapseThree">
                                                    <DataContainer />
                                                </div>
											</li>
											
											<li className="nav-item">
												<button
													type="button"
                                                    className="btn nav-item-style "
                                                    data-toggle="collapse" 
                                                    data-target="#collapseFour" 
                                                    aria-expanded="true" 
                                                    aria-controls="collapseFour"
													
												>
													<FontAwesomeIcon
														icon={"scroll"}
														size="3x"
														className="fa-icon1"
													/>

													<p className="menu-option">Pay Bills</p>
													<p className="menu-tag">(CABLE TV,TRAVELS) </p>
												</button>
                                                <div class="collapse" id="collapseFour">
                                                    <MainBills />
                                                </div>
											</li>

											<li className="nav-item">
												<button
													type="button"
													className="btn nav-item-style"
													data-toggle="collapse" 
                                                    data-target="#collapseFive" 
                                                    aria-expanded="true" 
                                                    aria-controls="collapseFive"
												>
													<FontAwesomeIcon
														icon={"bolt"}
														size="3x"
														className="fa-icon"
													/>
													<div className="mo">
														<p className="menu-option">Buy Power</p>
														<p className="menu-tag">(EKEDC,IKEDC,IBEDC) </p>
													</div>
												</button>

                                                <div class="collapse" id="collapseFive">
                                                    <BuyPowerPublicContainer />
                                                </div>
											</li>
											<li className="nav-item ">
												<button type="button" className="btn nav-item-style ">
													<FontAwesomeIcon
														icon={"question-circle"}
														size="4x"
														className="fa-icon1"
													/>

													<p className="menu-option">FAQ</p>
												</button>
											</li>
											{/* </div> */}
										</ul>
									{/* </div> */}
								</nav>
							</div>

							
						</div>

						{/* <img src={callcenter} className="call-center" /> */}
						<div className="landing-footer">
							<div className="row">
								<div className="col">
									<a className="footer-item">ABOUT |</a>
									{/* <a className="footer-item">USE THIS SERVICE |</a> */}
									<a className="footer-item">PRIVACY POLICY |</a>
									<a className="footer-item">CONTACT </a>
								</div>
								{/* <div className="col">
									<img src={callcenter} className="call-center" />
								</div> */}
							</div>
						</div>
					</div>
				</div>

        </React.Fragment>
    )
}

}

export default Menu;