import React from "react";
import SignInForm from "./SignInForm";
import SignUpForm from "./SignUpForm";
import {SignIn} from "./SignInForm";
import { buyAirtime } from "../../../utilities/api";
import power from "../../../assets/img/Power.jpg";

const formValid = formErrors => {
	let valid = true;

	// validate form errors being empty
	Object.values(formErrors).forEach(val => {
		val.length > 0 && (valid = false);
	});

	return valid;
};

class BuyPowerEstates extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			toggleView: "Sign In",
			step: 1
		};
	}

	nextStep = () => {
		const { step } = this.state;
		this.setState({
			step: step + 1
		});
	};

	prevStep = () => {
		const { step } = this.state;
		this.setState({
			step: step - 1
		});
	};

	render() {
		const { step } = this.state;

		switch (step) {
			case 1:
				return <SignInForm nextStep={this.nextStep} prevStep={this.prevStep} />;

			case 2:
				return <SignUpForm nextStep={this.nextStep} prevStep={this.prevStep} />;

		}
	}
}

export default BuyPowerEstates;
