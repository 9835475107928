import React from "react";

class ConfirmNumber extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
        const { values } = this.props;
		return (
			<React.Fragment>
				<p className="show-msg">{values.showmsg}</p>
					<p className="show-msg-success">{values.showmsgSuccess}</p>
                <div >
					<div className="confirm-div">
                    <p  className="confirm-header">Please Confirm Details</p>
                    <p><span className="confirm">Network:</span> {values.provider}</p>
        <p><span className="confirm">Phone Number:</span> {values.destinationAccount}</p>
        <p><span className="confirm">Amount:</span> {values.amount}</p>
		
                    <button
						// type="submit"
						type="button"
						className="btn confirm-btn1 btn-dark power-btn"
						disabled={values.isLoading}
						onClick={
							this.props.prevStep
						}
					>
						{/* {values.isLoading ? "LOADING…" : "BACK"} */}
                        BACK
					</button>
                    <button
						type="button"
						onClick={
							this.props.handlePayment
						}
						className="btn confirm-btn2 btn-dark power-btn"
						disabled={values.isLoading}
						
					>
                        {/* CONFIRM */}
						{values.isLoading ? "LOADING…" : "CONFIRM"}
					</button>
					</div>
                </div>
				<div
					class="modal fade"
					id="exampleModal"
					tabindex="-1"
					role="dialog"
					aria-labelledby="exampleModalLabel"
					aria-hidden="true"
				>
					<div class="modal-dialog" role="document">
						<div class="modal-content">
							<div class="modal-header">
								<h5 class="modal-title" id="exampleModalLabel">
									Modal title
								</h5>
								<button
									type="button"
									class="close"
									data-dismiss="modal"
									aria-label="Close"
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div class="modal-body">...</div>
							<div class="modal-footer">
								<button
									type="button"
									class="btn btn-secondary"
									data-dismiss="modal"
								>
									Close
								</button>
								<button type="button" class="btn btn-primary">
									Save changes
								</button>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default ConfirmNumber;
