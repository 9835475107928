/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Input, FormGroup, Col, CustomInput } from "reactstrap";
import { userLogin } from "../../utilities/api";
import { decodeJwt } from "../../utilities/processJWT";

const SignIn = function () {
  return (
    <Formik
      initialValues={{ email: "", password: "", isLoggedIn: false }}
      onSubmit={(values, { setSubmitting }, props) => {
        setTimeout(() => {
          userLogin({
            email: values.email,
            password: values.password,
          }).then((response) => {
            let showError = document.getElementById("show-error");
            if (response && response.status === 400) {
              showError.style.display = "block";
              showError.innerHTML = response.data;
              setSubmitting(false);
            } else if (response.data.token) {
              if (!decodeJwt(response.data.token)) {
                showError.style.display = "block";
                showError.innerHTML = "Opps! Server error";
                setSubmitting(false);
              } else if (
                decodeJwt(response.data.token).role === "service-user"
              ) {
                localStorage.setItem("isLoggedIn", true);
                localStorage.setItem("firstSignup", false);
                localStorage.setItem("token", response.data.token);
                localStorage.setItem("status", decodeJwt(response.data.token).status);
                localStorage.setItem("redirect", false);
                // window.location.reload(true);
              } else if (
                decodeJwt(response.data.token).role === "netplus-admin"
              ) {
                localStorage.setItem("isLoggedIn", true);
                localStorage.setItem("token", response.data.token);
                localStorage.setItem("redirect", false);
                window.location.reload(true);
              } else if (
                decodeJwt(response.data.token).role === "service-admin"
              ) {
                localStorage.setItem("isLoggedIn", true);
                localStorage.setItem("token", response.data.token);
                localStorage.setItem("redirect", false);
                window.location.reload(true);
              } else {
                showError.style.display = "block";
                setSubmitting(false);
              }
            } else {
              showError.style.display = "block";
              showError.innerHTML = "Opps! Server error";
              setSubmitting(false);
            }
          })
          .catch(function (error) {
            document.getElementById("show-error").style.display = "block";
            document.getElementById("show-error").innerHTML = "Opps! Server error";
            setSubmitting(false);
          });
        }, 1000);
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email("Invalid email address").required("Required"),
        password: Yup.string().required("No password provided."),
        // .min(8, "Password is too short - should be 8 chars minimum.")
        // .matches(/(?=.*[0-9])/, "Password must contain a number.")
      })}
    >
      {(props) => {
        const toggle = (event) => {
          event.preventDefault();
          let showPassword = document.querySelector("#toggle-password");
          let password = document.querySelector("#password");
          if (event.target.className === "fa fa-fw fa-eye field-icon") {
            showPassword.className = "fa fa-fw fa-eye-slash field-icon";
            password.type = "text";
          } else {
            showPassword.className = "fa fa-fw fa-eye field-icon";
            password.type = "password";
          }
        };

        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <div className="bg-green">
            <form onSubmit={handleSubmit} className="form-login">
              <Col md={12}>
                <FormGroup>
                  <h3 id="show-error">Username or password incorrect</h3>
                  <Input
                    name="email"
                    type="text"
                    placeholder="Enter your email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={errors.email && touched.email && "error"}
                  />
                  {errors.email && touched.email && (
                    <div className="input-feedback">{errors.email}</div>
                  )}
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Input
                    name="password"
                    id="password"
                    type="password"
                    placeholder="Enter password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={errors.password && touched.password && "error"}
                  />
                  <span
                    id="toggle-password"
                    toggle=".password"
                    className="fa fa-fw fa-eye field-icon"
                    onClick={toggle}
                  ></span>
                  {errors.password && touched.password && (
                    <div
                      style={{ color: "red", fontSize: ".8em" }}
                      className="input-feedback"
                    >
                      {errors.password}
                    </div>
                  )}
                </FormGroup>
              </Col>
              {/* <div className="remember-me">
                <CustomInput
                className="white-text font-weight-bold"
                  type="checkbox"
                  id="exampleCustomInline"
                  label="Remember me"
                  inline
                />
              </div> */}
              <div class="">
                <Button
                  className="btn btn-lg text-uppercase login-btn float-right"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Loading…" : "Login"}
                </Button>
              </div>
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

function SignInForm() {
  return (
    <div>
      <div className="modal-body">
        <div className="d-flex flex-column flex-wrap">
          <SignIn />
        </div>
      </div>
    </div>
  );
}

export default SignInForm;
