import React from "react";
import { Row, Col, Button, Form, FormGroup, Input, Card } from "reactstrap";
import { Table } from "reactstrap";
import { FormatTime } from "../../utilities/currency_time_formata";
import ErrorPage from "../../utilities/errorPage";
import { arrTypeCheck } from "../../utilities/dataTypeCheck";
import ToggleSwitch from "./ToggleSwitch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSVLink } from "react-csv";
import "./RegisteredResidentsMobileView.css";
// import {arrTypeCheck} from "../../utilities/dataTypeCheck";


class TotalResidents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: [],
      summary: [],
      isLoading: false,
      value: 10,
      currentTransactions: [],
      currentPage: null,
      totalPages: null,
      meter_number: "",
      searching: false
    };
  }

  componentWillMount=()=> {
    const { props } = this.props;
    console.log("hereee", props.data.users);
    const userData = props.data.users[0].users;
    const duplicates = [];
    const uniqueUsers = userData.filter((user, index, self) => {
      if (self.findIndex(u => u.id === user.id) !== index) {
        duplicates.push(user);
        return false;
      }
      return true;
    });
    const cleanedUsers = uniqueUsers.filter(user => !duplicates.includes(user));
    this.setState({
      payload: cleanedUsers//props.data.users[0].users
    })

    setTimeout(()=> {
      console.log("payload", this.state.payload);
    }, 500)
  }

  // handleRefresh = () => {
  //   this.forceUpdate();
  //   // this.getDevLevy();
  //   this.getServicePayment();
  // }

  

  onPageChanged = (data) => {
    const { payload } = this.state;
    const { currentPage, totalPages, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    const currentTransactions = payload.req.slice(offset, offset + pageLimit);
    this.setState({ currentPage, currentTransactions, totalPages });
  };

  handleChange = (event) => {
    if (event.target.name === "name") {
      this.setState({ name: event.target.value });
    } else if (event.target.name === "meter_number") {
      this.setState({ meter_number: event.target.value });
    } else {
      return;
    }
  };


  handleSubmit = (e)=> {
    
e.preventDefault();
console.log("mm", this.state.meter_number);
let meterNo = this.state.meter_number;
    const { props } = this.props;
    this.setState({
      searching: true
    })
    let residents = props.data.users[0].users;
    console.log("residents", residents);

    let newArray = residents.filter(function (x) {
      return x.meter_number === meterNo;
    });

    this.setState({
      payload: newArray,
      searching: false
    })
  }

  render() {
    console.log(this.state.payload);
    const { props } = this.props;
    const {payload} = this.state.payload;
  
    return (
      <div className="content">
        {!props ? (
          <div>
            <ErrorPage />
          </div>
        ) : (
          <div>
            {!arrTypeCheck(props.data.users) ? (
              <h6>There is no registered resident yet</h6>
            ) : (

              
              <div>

<div className="mt-5">
                  <Button
                    onClick={this.props.handleRefresh}
                    type="button"
                    style={{
                      color: "white",
                      backgroundColor: "black",
                      textTransform: "none",
                    }}
                    className="border"
                  >
                    <FontAwesomeIcon icon={"sync"} />
                    &nbsp;Refresh
                  </Button>
                </div>

 <Form className="mt-5" onSubmit={this.handleSubmit} id="formView">
                  <Row form xs="3" sm="3" md="3">
                <Col md={7} className="mt-2 ml-3 my-auto">
                  
                    <FormGroup>
                      <Input
                        type="number"
                        name="meter_number"
                        id="exampleState"
                        onChange={this.handleChange}
                        placeholder="Resident meter number"
                      />
                    </FormGroup>
                
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Button
                      type="submit"
                      disabled={this.state.searching}
                      style={{
                        color: "white",
                        backgroundColor: "#1DE054",
                        textTransform: "none",
                      }}
                    >
                      {this.state.searching ? "SEARCHING.." : "SEARCH"}
                    </Button>
                  </FormGroup>
                </Col>
                    </Row>
                    </Form>
                {/*--------------------------------------------------------------------------------*/}
                {/*Start Inner Div*/}
                {/*--------------------------------------------------------------------------------*/}
                {/*--------------------------------------------------------------------------------*/}
                {/*Row*/}
                {/*--------------------------------------------------------------------------------*/}
                {/* <div id="paymentFrame" class="m-0 mx-auto"></div> */}
                {/*<Row>
                  <Col md={12} className="mx-auto bg-white p-5 rounded">*/}
                    <Table bordered ={true} responsive ={true}>
                      <thead className="bg-green">
                        <tr>
                          {/* <th>#</th> */}
                          <th>Name</th>
                          <th>Meter number</th>
                          <th>Registration Date</th>
                          
                          <th>Role</th>
                          <th>Occupied</th>
                          <th></th>
                        </tr>
                      </thead>
                      {this.state.payload.map((user) => (
                        <tbody>
                          <tr key={user.id} role="row" className="odd">
                            <td className="sorting_1 pl-4">{`${user.first_name} ${user.last_name}`}</td>
                            <td>{user.meter_number}</td>
                            <td>{FormatTime(user.createdAt)}</td>
                            <td>{user.role}</td>
                            <td>
                              <ToggleSwitch
                                isChecked={user.status}
                                id={user.user_id}
                                meterNo={user.meter_number}
                                handleRefresh={this.props.handleRefresh}
                              />
                            </td>
                            <td  style={{textDecorationLine: 'underline', color: '#1DE054', cursor: 'pointer'}} id = {user.meter_number} onClick={()=>this.props.handleResidentInfoView(user.first_name, user.last_name, user.status, user.meter_number, user.email, user.phone )}>View</td>
                          </tr>
                        </tbody>
                      ))}
                    </Table>
 {/*                 </Col>
                </Row>*/}

                <div className="d-flex">
                    {/* {!this.state.isLoading && this.state.payload && ( */}
                      <div
                        style={{
                          color: "blue",
                          fontWeight: "bolder",
                          fontSize: "larger",
                        }}
                      >
                        {!this.state.isLoading && arrTypeCheck(this.state.payload) ? (
                          <CSVLink
                            data={this.state.payload}
                            headers={this.state.headers}
                            filename={"Transactions.csv"}
                            className="text-body small font-weight-bold"
                          >
                            <FontAwesomeIcon icon={"file-export"} />
                            &nbsp;DOWNLOAD RESIDENT SUMMARY
                          </CSVLink>
                        ) : null}
                      </div>
                    {/* )} */}
                  </div>

              </div>
            )}
          </div>
        )}


                 

      </div>
    );
  }
}

export default TotalResidents;
