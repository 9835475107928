import axios from "axios";

const REACT_APP_BASE_URL_ESTATES = process.env.REACT_APP_BASE_URL_ESTATES;
const REACT_APP_STORM_UTILITIES_URL = process.env.REACT_APP_STORM_UTILITIES_URL;
const REACT_APP_AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN;
const REACT_APP_STORM_BASE_URL = process.env.REACT_APP_STORM_BASE_URL;
const REACT_APP_STORM_APP_NAME = process.env.REACT_APP_STORM_APP_NAME;
const REACT_APP_STORM_APP_PASSWORD = process.env.REACT_APP_STORM_APP_PASSWORD;

const token = localStorage.getItem("token");

export function userLogin(params) {
  return axios
    .post(
      `${REACT_APP_BASE_URL_ESTATES}/api/utility-service-account/login`,
      { email: params.email, password: params.password },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}
// registerUserDefault, confirmEstate,  registerUserExixting
export function registerUserDefault(params) {
  const meter_number = params.meter_number;
  // return axios
  //   .get(
  //     `${REACT_APP_BASE_URL_ESTATES}/api/electricity-service/verify-meter-number`,
  //     {
  //       params: { meter_number: meter_number },
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     }
  //   )
  //   .then(function (response) {
  //     if (response && response.data.isFound === true) {
        return axios
          .post(
            `${REACT_APP_BASE_URL_ESTATES}/api/utility-service-account/signup`,
            {
              first_name: params.first_name,
              last_name: params.last_name,
              phone: params.phone,
              email: params.email,
              password: params.password,
              property_id: params.property_id,
              meter_number: params.meter_number,
              role: params.role
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then(function (response) {
            return response;
          })
          .catch(function (error) {
            return error.response;
          });
      // } else {
      //   return response;
      // }
    // })
    // .catch(function (error) {
    //   return error.response;
    // });
}

export function registerUserExisting(params) {
  return axios
    .post(
      `${REACT_APP_BASE_URL_ESTATES}/api/utility-service-account/signup`,
      {
        first_name: params.first_name,
        last_name: params.last_name,
        phone: params.phone,
        email: params.email,
        password: params.password,
        property_id: params.property_id,
        meter_number: params.meter_number,
        role: params.role
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

// export function registerUser(params) {
//   const meter_number = params.meter_number;
//   return axios
//     .get(
//       `${REACT_APP_BASE_URL_ESTATES}/api/electricity-service/verify-meter-number`,
//       {
//         params: { meter_number: meter_number },
//         headers: {
//           "Content-Type": "application/json",
//         },
//       }
//     )
//     .then(function (response) {
//       if (response && response.data.isFound === true) {
//         return axios
//           .post(
//             `${REACT_APP_BASE_URL_ESTATES}/api/utility-service-account/signup`,
//             {
//               first_name: params.first_name,
//               last_name: params.last_name,
//               phone: params.phone,
//               email: params.email,
//               password: params.password,
//               property_id: params.property_id,
//               meter_number: params.meter_number,
//             },
//             {
//               headers: {
//                 "Content-Type": "application/json",
//               },
//             }
//           )
//           .then(function (response) {
//             return response;
//           })
//           .catch(function (error) {
//             return error.response;
//           });
//       } else {
//         return response;
//       }
//     })
//     .catch(function (error) {
//       return error.response;
//     });
// }

export function topUpPower(params) {
  return axios
    .post(
      `${REACT_APP_BASE_URL_ESTATES}/api/electricity-service/topup-power`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function verifyMeterNumber(params) {
  return axios
    .get(
      `${REACT_APP_BASE_URL_ESTATES}/api/electricity-service/verify-meter-number`,
      {
        params: params,
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function pingMommas(params) {
  return axios
    .get(
      `${REACT_APP_BASE_URL_ESTATES}/api/electricity-service/ping-mommas`,
      {
        params: params,
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export async function fetchEstates() {
  try {
    const response = await axios.get(
      `${REACT_APP_BASE_URL_ESTATES}/api/users/properties`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export function resetPassword(params) {
  let filteredParams = Object.keys(params)
    .filter(
      (e) => params[e] !== null && params[e] !== "null" && params[e] !== ""
    )
    .reduce((o, e) => {
      o[e] = params[e];
      return o;
    }, {});

  return axios
    .put(
      `${REACT_APP_BASE_URL_ESTATES}/api/utility-service-account/reset-password`,
      filteredParams,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function getAllTopups(params) {
  return axios
    .get(`${REACT_APP_BASE_URL_ESTATES}/api/reports/topups`, {
      params: params,
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function fetchDashboardMetrics() {
  return axios
    .get(`${REACT_APP_BASE_URL_ESTATES}/api/users/dashboard`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}


export function getUserDevLevy() {
  return axios
    .get(`${REACT_APP_BASE_URL_ESTATES}/api/electricity-service/get-user-devlevy-transactions`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}


export function getUserFine() {
  return axios
    .get(`${REACT_APP_BASE_URL_ESTATES}/api/electricity-service/get-user-fine-transactions`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function getUserServicePayment() {
  return axios
    .get(`${REACT_APP_BASE_URL_ESTATES}/api/electricity-service/get-user-service-transactions`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}



export function fetchSuperDashboardMetrics() {
  return axios
    .get(`${REACT_APP_BASE_URL_ESTATES}/api/users/dashboard-netplus-admin`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}



export function fetchSingleUser() {
  return axios
    .get(`${REACT_APP_BASE_URL_ESTATES}/api/users`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function updateProfile(params) {
  return axios
    .put(`${REACT_APP_BASE_URL_ESTATES}/api/users`, params, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function fetchAdminResidentDashboard(params) {
  return axios
    .get(
      `${REACT_APP_BASE_URL_ESTATES}/api/users/service-admin/user-dashboard-metrics`,
      {
        params: params,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function fetchAdminResidentHistory(params) {
  return axios
    .get(
      `${REACT_APP_BASE_URL_ESTATES}/api/utility-admin/estate-admin-transactions-history`,
      {
        params: params,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function fetchTransactions() {
  return axios
    .get(`${REACT_APP_BASE_URL_ESTATES}/api/users/transactions-history`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function searchTransaction(params) {
  let filteredParams = Object.keys(params)
    .filter(
      (e) => params[e] !== null && params[e] !== "null" && params[e] !== ""
    )
    .reduce((o, e) => {
      o[e] = params[e];
      return o;
    }, {});

  return axios
    .get(`${REACT_APP_BASE_URL_ESTATES}/api/users/transactions-history`, {
      params: filteredParams,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function ForgotPassword(params) {
  return axios
    .post(
      `${REACT_APP_BASE_URL_ESTATES}/api/utility-service-account/forgot-password`,
      {
        email: params.email,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function ResetPassword(params) {
  console.log("hy ", params);
  return axios
    .post(
      `${REACT_APP_BASE_URL_ESTATES}/api/utility-service-account/replace-password`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(function (response) {
      console.log("re response", response);
      return response;
    })
    .catch(function (error) {
      console.log("re response", error.response);
      return error.response;
    });
}

export function ContactSupport(params) {
  console.log("hy ", params);
  return axios
    .post(
      `${REACT_APP_BASE_URL_ESTATES}/api/utility-service-account/contact-support`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(function (response) {
      console.log("re response", response);
      return response;
    })
    .catch(function (error) {
      console.log("re response", error.response);
      return error.response;
    });
}

export function updateMeterNumber(params) {
  return axios
    .put(`${REACT_APP_BASE_URL_ESTATES}/api/users/meter-number`, params, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function getUploadedMeters(params) {
  return axios
    .get(`${REACT_APP_BASE_URL_ESTATES}/api/service-admin/meter-uploads`, {
      params,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

// NETP_ADMIN
export function createServiceAdmin(params) {
  return axios
    .post(
      `${REACT_APP_BASE_URL_ESTATES}/api/utility-admin/register-estate`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}


export function createService(params) {
  return axios
    .post(
      `${REACT_APP_BASE_URL_ESTATES}/api/utility-admin/create`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}


export function createConvenienceFee(params) {
  return axios
    .post(
      `${REACT_APP_BASE_URL_ESTATES}/api/utility-admin/create-fees`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function payServiceFee(params) {
  return axios
    .post(
      `${REACT_APP_BASE_URL_ESTATES}/api/electricity-service/service-payment`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function payDevLevy(params) {
  return axios
    .post(
      `${REACT_APP_BASE_URL_ESTATES}/api/electricity-service/dev-levy-payment`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function getServicePayments(params) {
  return axios
    .get(`${REACT_APP_BASE_URL_ESTATES}/api/electricity-service/get-service-payments`, {
      params,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function getResidentsSummary(params) {
  return axios
    .get(`${REACT_APP_BASE_URL_ESTATES}/api/electricity-service/get-resident-summary`, {
      params,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}


export function getServices() {
  return axios
    .get(`${REACT_APP_BASE_URL_ESTATES}/api/service-admin/get-services`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function getUserServices() {
  return axios
    .get(`${REACT_APP_BASE_URL_ESTATES}/api/users/get-user-services`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}


export function getDevLevy(params) {
  return axios
    .get(`${REACT_APP_BASE_URL_ESTATES}/api/electricity-service/get-dev-levy-meter`, 
    {
      params,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function getUserFees() {
  return axios
    .get(`${REACT_APP_BASE_URL_ESTATES}/api/users/get-user-fees`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}



export function fetchAllEstates() {
  return axios
    .get(`${REACT_APP_BASE_URL_ESTATES}/api/users/allestates`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function fetchEstatesDashboard() {
  return axios
    .get(`${REACT_APP_BASE_URL_ESTATES}/api/service-admin/dashboard-metrics`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function searchResidents(params) {
  let filteredParams = Object.keys(params)
    .filter(
      (e) => params[e] !== null && params[e] !== "null" && params[e] !== ""
    )
    .reduce((o, e) => {
      o[e] = params[e];
      return o;
    }, {});

  return axios
    .get(
      `${REACT_APP_BASE_URL_ESTATES}/api/utility-admin/search-service-user`,
      {
        params: filteredParams,
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function fetchEstatesTrSummary() {
  return axios
    .get(
      `${REACT_APP_BASE_URL_ESTATES}/api/users/properties/transactions-summary`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function fetchEstateTrHistory() {
  return axios
    .get(
      `${REACT_APP_BASE_URL_ESTATES}/api/users/properties/transactions-history`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}


export function fetchServiceTrHistory() {
  return axios
    .get(
      `${REACT_APP_BASE_URL_ESTATES}/api/electricity-service/get-service-payment-transactions`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function fetchDevLevyTrHistory() {
  return axios
    .get(
      `${REACT_APP_BASE_URL_ESTATES}/api/electricity-service/get-devlevy-transactions`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function fetchFineTrHistory() {
  return axios
    .get(
      `${REACT_APP_BASE_URL_ESTATES}/api/electricity-service/get-fine-transactions`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function searchEstateTrHistory(params) {
  let filteredParams = Object.keys(params)
    .filter(
      (e) => params[e] !== null && params[e] !== "null" && params[e] !== ""
    )
    .reduce((o, e) => {
      o[e] = params[e];
      return o;
    }, {});
  return axios
    .get(
      `${REACT_APP_BASE_URL_ESTATES}/api/users/properties/transactions-history`,
      {
        params: filteredParams,
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function fetchEstateResidents() {
  return axios
    .get(`${REACT_APP_BASE_URL_ESTATES}/api/service-admin/estate-users`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function confirmEstate(params) {
  return axios
    .get(`${REACT_APP_BASE_URL_ESTATES}/api/properties/get-estate`, {
      params,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function uploadMeters(params, property_id) {
  var outputDiv = document.querySelector("#hide-progress");
  var output = document.querySelector("#progress-output");
  outputDiv.style.display = "block";
  var formData = new FormData(); // Currently empty
  formData.append("property_id", property_id);
  formData.append("dataset", params.files[0], params.files[0].name); ////The name attribute of the type="file" in html should match the upload.single('name') in server code.
  const config = {
    onUploadProgress: function (progressEvent) {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      output.innerHTML = `${percentCompleted}%`;
    },
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  };
  return axios
    .post(
      `${REACT_APP_BASE_URL_ESTATES}/api/service-admin/validate-upload-meter-details`,
      formData,
      config
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}
//Upload existing service payments

export function tosinUpload(params) {
  var output = document.querySelector("#progress-output");
  output.style.display ="block";
  var formData = new FormData(); // Currently empty
  formData.append("dataset", params.files[0], params.files[0].name);
  const config = {
    onUploadProgress: function (progressEvent) {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      output.innerHTML = `${percentCompleted}%`;
    },
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  };
  return axios
  .post(`${REACT_APP_BASE_URL_ESTATES}/api/electricity-service/upload-service-payments`,
  formData,
  config
  )
  .then(function (response) {
    return response;
  })
  .catch(function (error) {
    return error.response;
  });
}


//Upload Dev Levy

export function UploadDevLevy(params) {
  console.log("params", params);
  var outputDiv = document.querySelector("#hide-progress");
  var output = document.querySelector("#progress-output");
  outputDiv.style.display = "block";
  var formData = new FormData(); // Currently empty
  // formData.append("property_id", property_id);
  formData.append("dataset", params.files[0], params.files[0].name); ////The name attribute of the type="file" in html should match the upload.single('name') in server code.
  const config = {
    onUploadProgress: function (progressEvent) {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      output.innerHTML = `${percentCompleted}%`;
    },
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  };
  return axios
    .post(
      `${REACT_APP_BASE_URL_ESTATES}/api/electricity-service/upload-dev-levy`,
      formData,
      config
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

// Get Property Logo

export function getPropertyLogo() {
  return axios
    .get(`${REACT_APP_BASE_URL_ESTATES}/api/users/property-logo`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

// update logo
export function uploadLogo(params) {
  return axios
    .put(`${REACT_APP_BASE_URL_ESTATES}/api/service-admin/property-logo`, params, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

// NETP_ADMIN
export function confirmMeterUpload(params) {
  return axios
    .post(
      `${REACT_APP_BASE_URL_ESTATES}/api/service-admin/upload-meter-details`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function confirmDevLevyUpload(params) {
  return axios
    .post(
      `${REACT_APP_BASE_URL_ESTATES}/api/electricity-service/upload-dev-levy`,
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function getAdminEstates(params) {
  return axios
    .get(`${REACT_APP_BASE_URL_ESTATES}/api/service-admin/get-properties`, {
      params,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

//AIRTIME AND BILLS
export function getAppToken() {
  return axios
    .post(
      `${REACT_APP_STORM_BASE_URL}/api/token`,

      {
        appname: REACT_APP_STORM_APP_NAME,
        password: REACT_APP_STORM_APP_PASSWORD,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(function (response) {
      return axios
        .post(
          `${REACT_APP_STORM_BASE_URL}/api/auth`,

          {
            username: REACT_APP_STORM_APP_NAME,
            password: REACT_APP_STORM_APP_PASSWORD,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          return response;
        })
        .catch(function (error) {
          return error.response;
        });
    })
    .catch(function (error) {
      return error.response;
    });
}

export function buyAirtime(params) {
  return axios
    .post(
      `${REACT_APP_BASE_URL_ESTATES}/api/utility/pay_bill`,
      
      params,

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token

        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function getFees(param) {
  return axios
    .get(
      `${REACT_APP_BASE_URL_ESTATES}/api/utility/single_provider_fees/${param}`,
     
      {
        headers: {
          Authorization: "Bearer " + token
        }
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}


export function fetchUserInfo(params) {
  return axios
    .post(
      `${REACT_APP_BASE_URL_ESTATES}/api/utility/validate_bill`,
      params,
      {

        headers: {
          "Content-Type": "application/json"
          // Authorization: "Bearer " + token

        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}


export function retryTopup(params) {
  return axios
    .get(`${REACT_APP_BASE_URL_ESTATES}/api/utility-admin/retry-topup`, {
      params,
      headers: {
        Authorization: "Bearer " + token,
      }
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}


export function getPendingTopups() {
  return axios
    .get(
      `${REACT_APP_BASE_URL_ESTATES}/api/utility-admin/pending-topup-transactions`,
      {
        headers: {
          Authorization: "Bearer " + token
        }
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}


export function searchPendingTransactions(params) {
  let filteredParams = Object.keys(params)
    .filter(
      (e) => params[e] !== null && params[e] !== "null" && params[e] !== ""
    )
    .reduce((o, e) => {
      o[e] = params[e];
      return o;
    }, {});
  return axios
    .get(
      `${REACT_APP_BASE_URL_ESTATES}/api/utility-admin/pending-topup-transactions`,
      {
        params: filteredParams,
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}


export function updateStatus(params) {
  console.log("param", params);
  return axios
    .put(`${REACT_APP_BASE_URL_ESTATES}/api/service-admin/update-status`, params, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function updateServiceCharge(params) {
  return axios
    .put(`${REACT_APP_BASE_URL_ESTATES}/api/electricity-service/update-service-payments`, params, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function updateDevLevy(params) {
  return axios
    .put(`${REACT_APP_BASE_URL_ESTATES}/api/electricity-service/update-devlevy`, params, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function fetchAdminServiceTrHistory() {
  return axios
    .get(
      `${REACT_APP_BASE_URL_ESTATES}/api/utility-admin/estate-admin-service-payment-history`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}

export function fetchAdminDevLevyTrHistory() {
  return axios
    .get(
      `${REACT_APP_BASE_URL_ESTATES}/api/utility-admin/estate-admin-dev-levy-history`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
}