import React from "react";
import UploadMeter from "./UploadMeter";
import UploadedMeter from "./UploadedMeter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

class MainPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      payload: null,
      toggleView: "UPLOAD METER",
    };

    this.handletoggleView = this.handletoggleView.bind(this);
  }

  handletoggleView() {
    switch (this.state.toggleView) {
      case "UPLOAD METER":
        return <UploadMeter />;
      case "UPLOADED METER NUMBERS":
        return <UploadedMeter />;
      default:
        return <h2>dashboard</h2>;
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="content">
          <Row>
            <div>
              <Button
                onClick={() => window.location.reload(true)}
                type="button"
                style={{
                  color: "black",
                  textTransform: "none",
                  float: "right",
                  // display: "none",
                }}
                id="invalidRefreshBtn"
                className="border btn-link"
              >
                <FontAwesomeIcon icon={"sync"} />
                &nbsp;Refresh
              </Button>
            </div>
            <Col md={10} className="bg-white p-5 rounded mt-5">
              <UncontrolledDropdown>
                <DropdownToggle caret>MENU</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={(e) =>
                      this.setState({ toggleView: "UPLOAD METER" })
                    }
                  >
                    Upload Meter Number
                  </DropdownItem>

                  <DropdownItem
                    onClick={(e) =>
                      this.setState({
                        toggleView: "UPLOADED METER NUMBERS",
                      })
                    }
                  >
                    Uploaded Meter Numbers
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              {this.handletoggleView()}
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default MainPage;