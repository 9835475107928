import React, { Component } from "react";
import UserDetails from "./UserDetails";
import PayBills from "./PayBills";
import DstvBill from "./Dstv";
import BuyPower from "./BuyPower";
import { buyAirtime } from "../../utilities/api";
import { getFees } from "../../utilities/api";
import shortid from "shortid";
import { netpluspayPOP } from "../../views/TopUps/netfun";

class MainBillForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			step: 1,
			isLoading: false,
			service: "pay_bill",
			payload: null,
			transactionID: null,
			// transaction_type: "bill payment",
			provider: [],
			email: "",
			phone: "",
			billPackage: [],
			firstName: "",
			lastName: "",
			destinationAccount: "",
			accountName: "",
			amount: null,
			category: [],
			storedFee: null,
			storedProvider: null,
			modalResponse: "",
			selectedProvider: "Choose Product",
			selectedCategory: "Choose Category",
			selectedBillPackage: "Choose Package",
			convenienceFee: 100,
			formErrors: {
				//category: "",
				provider: "",
				email: "",
				phone: "",
				billPackage: "",
				destinationAccount: "",
				accountName: "",
				amount: null
			}
		};
	}

	
	componentDidMount = () => {
		this.setState({
			payload: 0,
			category: [
				{
					name: "CABLE-TV",
					provider: [
						{
							name: "DSTV",
							billPackage: [
								{
									name: "PREMIUM - 15800",
									amount: 15800
								},

								{
									name: "COMPACT PLUS - 10650",
									amount: 10650
								},

								{
									name: "COMPACT - 6800",
									amount: 6800
								},

								{
									name: "CONFAM - 4500",
									amount: 4500
								},
								{
									name: "YANGA - 2500",
									amount: 2500
								},
								{
									name: "PADI - 1800",
									amount: 1800
								},
								{
									name: "PREMIUMASIA - 17700",
									amount: 17700
								},
								{
									name: "PREMIUMASIA + XTRAVIEW- 19900",
									amount: 19900
								},
								{
									name: "PREMIUM + FRENCH - 22200",
									amount: 22200
								},
								{
									name: "PREMIUM + FRENCH + XTRAVIEW - 24400",
									amount: 24400
								},
								{
									name: "COMPACTPLUS + ASIA - 16050",
									amount: 16050
								},
								{
									name: "COMPACTPLUS + ASIA + XTRAVIEW - 18250",
									amount: 18250
								},
								{
									name: "COMPACTPLUS + FRENCH TOUCH- 12120",
									amount: 12120
								},
								{
									name: "COMPACTPLUS + FRENCH PLUS- 17010",
									amount: 17010
								},
								{
									name: "COMPACTPLUS + FRENCH PLUS + XTRAVIEW - 19210",
									amount: 19210
								},
								{
									name: "COMPACTPLUS + XTRAVIEW - 12800",
									amount: 12800
								},
								{
									name: "COMPACT + ASIA - 12200",
									amount: 12200
								},
								{
									name: "COMPACT + FRENCH TOUCH- 8270",
									amount: 8270
								},
								{
									name: "COMPACT + XTRAVIEW - 9000",
									amount: 9000
								},
								{
									name: "COMPACT + FRENCHTOUCH + XTRAVIEW - 10470",
									amount: 10470
								},
								{
									name: "COMPACT + ASIA + XTRAVIEW - 14400",
									amount: 14400
								},

								{
									name: "COMPACT + FRENCH PLUS- 13160",
									amount: 13160
								},
								{
									name: "CONFAM + XTRAVIEW - 6700",
									amount: 6700
								},
								{
									name: "YANGA + XTRAVIEW - 4700",
									amount: 4700
								},
								{
									name: "PADI + XTRAVIEW - 4000",
									amount: 4000
								}
							]
						},
						{
							name: "GOTV",
							billPackage: [
								{
									name: "MAX - 3200",
									amount: 3200
								},
								{
									name: "JOLLI - 2400",
									amount: 2400
								},
								{
									name: "JINJA - 1600",
									amount: 1600
								},
								{
									name: "LITE - 400",
									amount: 400
								},
								{
									name: "LITE (QUARTERLY) - 1050",
									amount: 1050
								},
								{
									name: "LITE (ANNUAL) - 3100",
									amount: 3100
								}
							]
						},
						{
							name: "STARTIMES",
							billPackage: [
								{
									name: "DIT NOVA (DAILY) - N90",
									amount: 90
								},
								{
									name: "DIT NOVA (WEEKLY) - N300",
									amount: 300
								},
								{
									name: "DIT NOVA (MONTHLY) - N900",
									amount: 900
								},
								{
									name: "DIT BASIC (DAILY) - N120",
									amount: 120
								},
								{
									name: "DIT BASIC (WEEKLY) - N450",
									amount: 450
								},
								{
									name: "DIT BASIC (MONTHLY) - N1300",
									amount: 1300
								},
								{
									name: "DIT CLASSIC (DAILY) - N240",
									amount: 240
								},
								{
									name: "DIT CLASSIC (WEEKLY) - N900",
									amount: 900
								},
								{
									name: "DIT CLASSIC (MONTHLY) - N2600",
									amount: 2600
								},
								{
									name: "DTH NOVA (DAILY) - N90",
									amount: 90
								},
								{
									name: "DTH NOVA (WEEKLY) - N300",
									amount: 300
								},
								{
									name: "DTH NOVA (MONTHLY) - N900",
									amount: 900
								},
								{
									name: "DTH SMART (DAILY) - N180",
									amount: 180
								},
								{
									name: "DTH SMART (WEEKLY) - N600",
									amount: 600
								},
								{
									name: "DTH SMART (MONTHLY) - N1900",
									amount: 1900
								},
								{
									name: "DTH SUPER (DAILY) - N360",
									amount: 360
								},
								{
									name: "DTH SUPER (WEEKLY) - N1300",
									amount: 1300
								},
								{
									name: "DTH SUPER (MONTHLY) - N3800",
									amount: 3800
								},
								{
									name: "COMBO SMART BASIC (DAILY) - N180",
									amount: 180
								},
								{
									name: "COMBO SMART BASIC (WEEKLY) - N600",
									amount: 600
								},
								{
									name: "COMBO SMART BASIC (MONTHLY) - N1900",
									amount: 1900
								},
								{
									name: "COMBO SUPER CLASSIC (DAILY) - N360",
									amount: 360
								},
								{
									name: "COMBO SUPER CLASSIC (WEEKLY) - N1300",
									amount: 1300
								},
								{
									name: "COMBO SUPER CLASSIC (MONTHLY) - N3800",
									amount: 3800
								}
							]
						}
					]
				},
				{
					name: "BUY POWER",
					provider: [
						{
							name: "PHCNEKO",
							billPackage: [
								{
									name: "N/A",
									amount: 0
								}
							]
						},
						{
							name: "PHCNKANO",
							billPackage: [
								{
									name: "N/A",
									amount: 0
								}
							]
						},
						{
							name: "PHCNJOS",
							billPackage: [
								{
									name: "N/A",
									amount: 0
								}
							]
						},
						{
							name: "PHCNABUJA",
							billPackage: [
								{
									name: "N/A",
									amount: 0
								}
							]
						},
						{
							name: "PHCNIBADAN",
							billPackage: [
								{
									name: "N/A",
									amount: 0
								}
							]
						},
						{
							name: "PHCNENUGU",
							billPackage: [
								{
									name: "N/A",
									amount: 0
								}
							]
						}
					]
				}
			]
		});
	};

	changeService = e => {
		this.setState({ selectedCategory: e.target.value });
		this.setState({
			provider: this.state.category.find(
				sProvider => sProvider.name === e.target.value
			).provider
		});
	};

	changeProvider = e => {
		this.setState({ selectedProvider: e.target.value });
		if(this.state.selectedCategory === "BUY POWER") {
			this.handleGetFees();
			//this.nextStep();
		} else {
		const pro = this.state.category.find(
			sProvider => sProvider.name === this.state.selectedCategory
		).provider;
		this.setState({
			billPackage: pro.find(bpackage => bpackage.name === e.target.value)
				.billPackage
		});
		//this.nextStep();
		this.handleGetFees();
	}
	};

	changebillPackage = e => {
		this.setState({ selectedBillPackage: e.target.value });
		const pro = this.state.category.find(
			sProvider => sProvider.name === this.state.selectedCategory
		).provider;
		const pack = pro.find(
			bpackage => bpackage.name === this.state.selectedProvider
		).billPackage;
		this.setState({
			amount: pack.find(a => a.name === e.target.value).amount
		});
	};

	nextStep = () => {
		const { step } = this.state;
		if(this.state.selectedCategory === "CABLE-TV") {
			this.setState({
				step: step + 2
			});
			
			
		} 
		else  {
			this.setState({
				step: step + 1
			});
		}
		
	};

	userNextStep = () => {
		const { step } = this.state;
		this.setState({
			step: step + 1
		});
	}

	prevStep = () => {
		const { step } = this.state;
		if(this.state.selectedCategory === "CABLE-TV") {
			this.setState({
				step: step - 2
			});
		} else {
		this.setState({
			step: step - 1
		});
	}
	};

	handleChange = input => e => {
		e.preventDefault();

		this.setState({ [input]: e.target.value });
	};

	handleGetFees = () => {
		this.setState({
			isLoading: true
		});

		setTimeout(() => {
			let data = this.state.selectedProvider;

			if (sessionStorage.getItem("Service") === this.state.selectedProvider) {
				this.setState({
					payload: sessionStorage.getItem("Fee"),
					isLoading: false
				});
			} else {
				getFees(data).then(response => {
					if (response && response.status == 200) {
						setTimeout(() => {
							this.setState({
								payload: response.data.fees,
								isLoading: false,
								storedProvider: response.data.provider,
								storedFee: response.data.fees
							});
							
							const s = this.state.storedProvider;
							const fee = this.state.storedFee;

							sessionStorage.setItem("Fee", fee);
							sessionStorage.setItem("Service", s);

							this.nextStep();
						}, 500);
					} else {
						let showMsg = document.getElementById("bills-msg");
						showMsg.innerHTML = "Service Unavailable";
						showMsg.style.display = "block";
						showMsg.style.fontSize = "18px";
						showMsg.style.color = "red";
						this.setState({
							isLoading: false
						});
					}
				});
			}
		}, 1000);
	 };

	handlePayBill = () => {
		const finalamount =
			parseInt(this.state.amount) + parseInt(this.state.payload);
		let data = {
			destinationAccount: this.state.destinationAccount,
			amount: finalamount,
			service: this.state.service,
			provider: this.state.selectedProvider,
			trans_id: this.state.transactionID
		};
		
		setTimeout(() => {
			buyAirtime(data).then(response => {
				if (response && response.status === 200) {
					let showMsg = document.getElementById("show-msg");
					showMsg.innerHTML = "Transaction Successful";
					showMsg.style.display = "block";
					showMsg.style.fontSize = "18px";
					showMsg.style.color = "green";
					this.setState({ isLoading: false });
				} else if (response && response.status === 400) {
					let showMsg = document.getElementById("show-msg");
					showMsg.innerHTML = "Transaction Failed";
					showMsg.style.display = "block";
					showMsg.style.fontSize = "18px";
					showMsg.style.color = "red";
					this.setState({ isLoading: false });
				} else {
					let showMsg = document.getElementById("show-msg");
					showMsg.innerHTML = "Server Error";
					showMsg.style.display = "block";
					showMsg.style.fontSize = "18px";
					showMsg.style.color = "red";
					this.setState({ isLoading: false });
				}
			});
		}, 4000);
	};

	handleBillPayment = e => {
		e.preventDefault();

		this.setState({
			isLoading: true
		});
		if(this.state.payload === 0) {
			let showMsg = document.getElementById("show-msg");
					showMsg.innerHTML = "Service Unavailable ";
					showMsg.style.display = "block";
					showMsg.style.fontSize = "18px";
					showMsg.style.color = "red";
					this.setState({
						isLoading: false
					});
		} else {
		
		const finalamount =
			parseInt(this.state.amount) + parseInt(this.state.payload);

		netpluspayPOP.setup({
			merchant: `${process.env.REACT_APP_MERCHANT_ID}`,
			customer_name: this.state.accountName,
			email: this.state.email,
			amount: finalamount,
			currency_code: "NGN",
			narration: "Bills Payment",
			order_id: `AIRTIME${shortid.generate()}`,
			container: "paymentFrame",
			callBack: function(resp) {
				if (resp && resp.status === "CAPTURED") {
					this.setState({
						modalResponse: resp.status,
						transactionID: resp.trans_id
					});
				} else if (resp && resp.status === "FAILED") {
					this.setState({
						modalResponse: resp.status,
						transactionID: resp.trans_id
					});
					
				}
			}.bind(this),
			onClose: function() {
				this.setState({ isLoading: true });
				this.handleWindowClose();
			}.bind(this)
		});
		netpluspayPOP.prepareFrame();
		}
	};

	handleWindowClose = () => {
		if (this.state.modalResponse === "FAILED") {
			let showMsg = document.getElementById("show-msg");
			showMsg.innerHTML = "Transaction Failed";
			showMsg.style.display = "block";
			showMsg.style.fontSize = "18px";
			showMsg.style.color = "red";
			this.setState({ isLoading: false });
		} else if (this.state.modalResponse === "CAPTURED") {
			this.handlePayBill();
		} else {
			let showMsg = document.getElementById("show-msg");
			showMsg.innerHTML = "Error";
			showMsg.style.display = "block";
			showMsg.style.fontSize = "18px";
			showMsg.style.color = "red";
			this.setState({ isLoading: false });
		}
	};

	render() {
		const { step } = this.state;
		const {
			isLoading,
			payload,
			category,
			provider,
			email,
			phone,
			billPackage,
			firstName,
			lastName,
			accountName,
			destinationAccount,
			amount,
			service,
			selectedProvider,
			selectedCategory,
			selectedBillPackage,
			convenienceFee,
			formErrors
		} = this.state;
		const values = {
			isLoading,
			payload,
			category,
			provider,
			email,
			phone,
			billPackage,
			firstName,
			lastName,
			accountName,
			destinationAccount,
			amount,
			service,
			selectedProvider,
			selectedCategory,
			selectedBillPackage,
			convenienceFee,
			formErrors
		};
		switch (step) {
			case 1:
				return (
					<PayBills
						nextStep={this.nextStep}
						handleChange={this.handleChange}
						values={values}
						changeService={this.changeService}
						changeProvider={this.changeProvider}
						handleGetFees={this.handleGetFees}
					/>
				);
				case 2:
				return (
					<BuyPower
						nextStep={this.nextStep}
						prevStep={this.prevStep}
						values={values}
						handleChange={this.handleChange}
						billPayment={this.handleBillPayment}
						changebillPackage={this.changebillPackage}
					/>
				);

			case 3:
				return (
					<DstvBill
						nextStep={this.userNextStep}
						prevStep={this.prevStep}
						changebillPackage={this.changebillPackage}
						handleChange={this.handleChange}
						values={values}
					/>
				);
			case 4:
				return (
					<UserDetails
						nextStep={this.nextStep}
						prevStep={this.prevStep}
						values={values}
						handleChange={this.handleChange}
						billPayment={this.handleBillPayment}
						changebillPackage={this.changebillPackage}
					/>
				);
		}
	}
}

export default MainBillForm;