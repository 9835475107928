/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from "react";
import { Formik } from "formik";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Input,
  Toast,
  ToastBody,
  ToastHeader,
  FormFeedback,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmEstate, updateMeterNumber } from "../../utilities/api";

const UpdateMeter = (props) => {
  const { buttonLabel, className } = props;

  const [modal, setModal] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [meter_number, setMeterNumber] = useState("");

  const toggle = () => setModal(!modal);
  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  };
  const toggleAll = () => {
    setNestedModal(!nestedModal);
    setCloseAll(true);
  };

  const handleConfirmEstate = (param) => {
    // let hideDiv = document.getElementById("hide-div");
    let showMsg = document.getElementById("show-error");
    let confirmDiv = document.getElementById("show-confirm-error");
    let estateResult = document.getElementById("estate");
    setNestedModal(!nestedModal);
    setCloseAll(false);
    return confirmEstate(param)
      .then((response) => {
        setIsLoading(false);
        if (
          (response && response.status === 200) ||
          (response && response.status === 304)
        ) {
          setTimeout(() => {
            // hideDiv.style.display = "none";
            // confirmDiv.style.display = "block";
            estateResult.style.display = "block";
            estateResult.innerHTML = response.data.name;
            // setNestedModal(nestedModal);
          }, 100);
        } else {
          setNestedModal(!nestedModal);
          setCloseAll(false);
          confirmDiv.style.display = "block";
          confirmDiv.innerHTML = "Server error! Exit and try again";
          confirmDiv.style.display = "block";
          setIsLoading(false);
          // setTimeout(() => {
          //   // toggleAll();
          // }, 1000);
        }
      })
      .catch(function (error) {
        setNestedModal(!nestedModal);
        setCloseAll(false);
        confirmDiv.innerHTML = "Server error! Exit and try again";
        confirmDiv.style.display = "block";
        setIsLoading(false);
        // setTimeout(() => {
        //   // toggleAll();
        // }, 1000);
      });
  };

  const handleMeterSubmit = (event) => {
    let showError = document.getElementById("show-error");
    event.preventDefault();
    setIsLoading(true);
    setTimeout(() => {
      updateMeterNumber({ meter_number: meter_number }).then((response) => {
        if (
          (response && response.status === 200) ||
          (response && response.status === 304)
        ) {
          localStorage.setItem("isLoggedIn", false);
          localStorage.clear();
          window.location.replace("/");
        } else if (response && response.status === 400) {
          showError.style.display = "block";
          showError.innerHTML =
            response.data || "Error updating meter number try again";
          setIsLoading(false);
          setNestedModal(!nestedModal);
          setCloseAll(false);
        } else {
          showError.style.display = "block";
          showError.innerHTML = "Error updating meter number try again";
          setIsLoading(false);
          setNestedModal(!nestedModal);
          setCloseAll(false);
        }
      });
    }, 2000);
  };

  return (
    <div>
      <Button color="danger" onClick={toggle}>
        {buttonLabel}
      </Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Update Meter Number</ModalHeader>
        <ModalBody>
          <p id="show-error"></p>
          <div>
            <Formik
              initialValues={{ meter_number: "" }}
              validate={(values) => {
                const errors = {};
                if (!values.meter_number) {
                  errors.meter_number = "Required";
                } else if (!/^(\d{11,14})$/i.test(values.meter_number)) {
                  errors.meter_number =
                    "Digits must be between 11 and 14 characters long";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setMeterNumber(values.meter_number);
                setTimeout(() => {
                  setNestedModal(!nestedModal);
                  handleConfirmEstate({ meter_number: values.meter_number });
                  // setSubmitting(false);
                }, 400);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Input
                    type="text"
                    name="meter_number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.meter_number}
                    placeholder="00000000000"
                  />
                  {errors.meter_number && touched.meter_number && (
                    <div
                      style={{ color: "red", fontSize: ".8em" }}
                      className="input-feedback"
                    >
                      {errors.meter_number === "meter number is required"
                        ? errors.meter_number
                        : "Digits must be 11 or 14 characters long"}
                    </div>
                  )}
                  <div className="d-flex justify-content-around">
                    <Button
                      className="btn btn-lg bg-green text-uppercase"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading…" : "Submit"}
                    </Button>
                    <Button
                      className="btn btn-lg text-uppercase"
                      color="secondary"
                      onClick={toggle}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <br />
          <Modal
            isOpen={nestedModal}
            toggle={toggleNested}
            onClosed={closeAll ? toggle : undefined}
          >
            <ModalHeader>Confirm Meter</ModalHeader>
            <p
              className="p-5"
              style={{ display: "none" }}
              id="show-confirm-error"
            ></p>
            <ModalBody>
              This meter number belongs to this estate:{" "}
              <span id="estate" className="font-weight-bolder"></span>
            </ModalBody>
            <div className="d-flex justify-content-around">
              <Button
                className="btn btn-md bg-green text-uppercase"
                disabled={isLoading}
                onClick={handleMeterSubmit}
              >
                {isLoading ? "Updating…" : "Confirm"}
              </Button>
              <Button color="secondary" onClick={toggleAll}>
                Do not update
              </Button>
            </div>
          </Modal>
        </ModalBody>
        <div className="p-3">
          <p className="muted bold">
            Please note{" "}
            <span>
              that you will be required to sign in again after this action!
            </span>
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default UpdateMeter;
