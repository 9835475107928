/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from 'react';
import { Formik } from "formik";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Input, Toast, ToastBody, ToastHeader, FormFeedback } from 'reactstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmEstate, updateMeterNumber, updateServiceCharge, updateDevLevy } from "../../utilities/api";
import './UpdateData.css';

const UpdateData = (props) => {
  const {
    buttonLabel,
    className, id, meterNo, handleRefresh
  } = props;

  const [modal, setModal] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [meter_number, setMeterNumber] = useState("");
  const [servicePayment, setServicePayment] = useState("");

  const toggle = () => setModal(!modal);
  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  }
  const toggleAll = () => {
    setNestedModal(!nestedModal);
    setCloseAll(true);
  }

  const handleConfirmEstate = () => {
    const param = {
        meter_number: meterNo,
        amount: meter_number
    }
    // let hideDiv = document.getElementById("hide-div");
    let showMsg = document.getElementById("show-error");
    let confirmDiv = document.getElementById("show-confirm-error");
    let estateResult = document.getElementById("estate");
    // setNestedModal(!nestedModal);
    // setCloseAll(false);
    console.log("props", props, id, meterNo);
    return confirmEstate(param)
      .then((response) => {
        setIsLoading(false);
        
        if(response && response.status === 200 || response && response.status === 304) {
        setTimeout(() => {
          // hideDiv.style.display = "none";
          // confirmDiv.style.display = "block";
          estateResult.style.display = "block";
          estateResult.innerHTML = response.data.name;
          // setNestedModal(nestedModal);
        }, 100);
      } else {
        // setNestedModal(!nestedModal);
        // setCloseAll(false);
        confirmDiv.style.display = "block";
        confirmDiv.innerHTML = "Server error! Exit and try again";
        confirmDiv.style.display = "block";
        setIsLoading(false);
        // setTimeout(() => {
        //   // toggleAll();
        // }, 1000);
      }
      })
      .catch(function (error) {
        // setNestedModal(!nestedModal);
        // setCloseAll(false);
        confirmDiv.innerHTML = "Server error! Exit and try again";
        confirmDiv.style.display = "block";
        setIsLoading(false);
        // setTimeout(() => {
        //   // toggleAll();
        // }, 1000);
      });
  };

  const handleMeterSubmit = (event) => {
    let showError = document.getElementById("show-error");
    event.preventDefault();
    setIsLoading(true);

    setTimeout(() => {
      updateMeterNumber({ meter_number: meter_number }).then((response) => {
        if (response && response.status === 200 || response && response.status === 304) {
          localStorage.setItem("isLoggedIn", false);
          localStorage.clear();
          window.location.replace("/");
        } else if (response && response.status === 400) {
          showError.style.display = "block";
          showError.innerHTML = response.data || "Error updating meter number try again";
          setIsLoading(false);
          setNestedModal(!nestedModal);
          setCloseAll(false);
        } else {
          showError.style.display = "block";
          showError.innerHTML = "Error updating meter number try again";
          setIsLoading(false);
          setNestedModal(!nestedModal);
          setCloseAll(false);
        }
      });
    }, 2000);
  }

  const handleUpdateData = (meter_number, servicePayment) => {
    let showError = document.getElementById("show-error");
    let showSuccess = document.getElementById("show-success");
    // event.preventDefault();
    setIsLoading(true);
    console.log("props", id, meterNo, meter_number, servicePayment);
    const param = {
        meter_number: meterNo,
        amount: meter_number,
        servicePayment: servicePayment
    }

    if (servicePayment === "servicecharge") {
        updateServiceCharge(param).then((response) => {
            console.log("resp", response);
            if(response && response.status === 200) {
                console.log("respy", response);
                showSuccess.style.display = "block";
                showSuccess.innerHTML = response.data || "SUccessfully Updated";
                setIsLoading(false);
                setCloseAll(true);
                handleRefresh();
                // window.location.replace("/");
            }
            else if (response && response.status === 400) {
                console.log("respo", response);
                showError.style.display = "block";
                showError.innerHTML = response.data || "Error updating try again";
                setIsLoading(false);
                // setNestedModal(!nestedModal);
                setCloseAll(false);
              } else {
                console.log("respa", response);
                showError.style.display = "block";
                showError.innerHTML = "Error updating try again";
                setIsLoading(false);
                // setNestedModal(!nestedModal);
                setCloseAll(false);
              }
        })
    } else if (servicePayment === "devlevy"){
      updateServiceCharge(param).then((response) => {
            console.log("resp", response);
            if(response && response.status === 200) {
                console.log("respy", response);
                showSuccess.style.display = "block";
                showSuccess.innerHTML = response.data || "Successfully Updated";
                setIsLoading(false);
                setCloseAll(true);
                handleRefresh();
                // window.location.replace("/");
            }
            else if (response && response.status === 400) {
                console.log("respo", response);
                showError.style.display = "block";
                showError.innerHTML = response.data || "Error updating try again";
                setIsLoading(false);
                // setNestedModal(!nestedModal);
                setCloseAll(false);
              } else {
                console.log("respa", response);
                showError.style.display = "block";
                showError.innerHTML = "Error updating try again";
                setIsLoading(false);
                // setNestedModal(!nestedModal);
                setCloseAll(false);
              }
        })
    }
    else if (servicePayment === "fine"){
      updateServiceCharge(param).then((response) => {
          console.log("resp", response);
          if(response && response.status === 200) {
              console.log("respy", response);
              showSuccess.style.display = "block";
              showSuccess.innerHTML = response.data || "Successfully Updated";
              setIsLoading(false);
              setCloseAll(true);
              handleRefresh();
              // window.location.replace("/");
          }
          else if (response && response.status === 400) {
              console.log("respo", response);
              showError.style.display = "block";
              showError.innerHTML = response.data || "Error updating try again";
              setIsLoading(false);
              // setNestedModal(!nestedModal);
              setCloseAll(false);
            } else {
              console.log("respa", response);
              showError.style.display = "block";
              showError.innerHTML = "Error updating try again";
              setIsLoading(false);
              // setNestedModal(!nestedModal);
              setCloseAll(false);
            }
      })
  } else {
    showError.style.display = "block";
              showError.innerHTML = "Please Select Service Payment";
              setIsLoading(false);
  }
    
  }

  return (
    <div>
      <Button color="danger" onClick={toggle}>{buttonLabel}</Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Update</ModalHeader>
        <ModalBody>
        <p id="show-error"></p>
        <p id="show-success"></p>
        <div>
            <Formik
              initialValues={{ meter_number: "" , servicePayment: ""}}
            //   validate={(values) => {
            //     const errors = {};
            //     if (!values.meter_number) {
            //       errors.meter_number = "Required";
            //     } else if (!/^(\d{11}|\d{13})$/i.test(values.meter_number)) {
            //       errors.meter_number =
            //         "Digits must be 11 or 14 characters long";
            //     }
            //     return errors;
            //   }}
              onSubmit={(values, { setSubmitting }) => {
                console.log("values", values);
                setMeterNumber(values.meter_number);
                setServicePayment(values.servicePayment);
                setTimeout(() => {
                //   setNestedModal(!nestedModal);
                //   handleConfirmEstate()
                  handleUpdateData(values.meter_number, values.servicePayment);
                    // setSubmitting(false);
                }, 400);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit}>

                      <select
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="servicePayment"
                        value= {values.servicePayment}
                        >
                        <option value="">SELECT SERVICE PAYMENT</option>
                        <option value="servicecharge">SERVICE CHARGE</option>
                        <option value="devlevy">DEVELOPMENT LEVY</option>
                        <option value="fine">FINE</option>
							
                          {/* {
                            estates.estates.map((obj) => (    
							               <option value={obj.id}>{obj.name}</option>
                            ))
                          } */}
                        </select>
                      <br />
                      <br />
                  <Input
                    type="text"
                    name="meter_number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.meter_number}
                    placeholder="Amount"
                  />
                  {/* {errors.meter_number && touched.meter_number && (
                    <div
                      style={{ color: "red", fontSize: ".8em" }}
                      className="input-feedback"
                    >
                      {errors.meter_number === "meter number is required"
                        ? errors.meter_number
                        : "Digits must be 11 or 14 characters long"}
                    </div>
                  )} */}
                  <div className="d-flex justify-content-around">
                  <Button
                    className="btn btn-lg bg-green text-uppercase"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading…" : "Submit"}
                  </Button>
                  <Button className="btn btn-lg text-uppercase" color="secondary" onClick={toggle}>Cancel</Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <br />
          <Modal isOpen={nestedModal} toggle={toggleNested} onClosed={closeAll ? toggle : undefined}>
            <ModalHeader>Confirm Meter</ModalHeader>
            <p className="p-5" style={{display: "none"}} id="show-confirm-error"></p>
            {/* <p className="p-5" style={{display: "none"}} id="show-success"></p> */}
            <ModalBody>
              This meter number belongs to this estate:{" "}
              <span id="estate" className="font-weight-bolder"></span>
              </ModalBody>
              <div className="d-flex justify-content-around">
              <Button
                className="btn btn-md bg-green text-uppercase"
                disabled={isLoading}
                onClick={handleUpdateData}
              >
                {isLoading ? "Updating…" : "Confirm"}
              </Button>
              <Button color="secondary" onClick={toggleAll}>Do not update</Button>
            </div>
          </Modal>
        </ModalBody>
        {/* <div className="p-3">
        <p className="muted bold">Please note <span>that you will be required to sign in again after this action!</span></p>
        </div> */}
      </Modal>
    </div>
  );
}

export default UpdateData;