export const arrTypeCheck = (arr) => {
  if (Array.isArray(arr) && arr.length !== 0) {
    return true;
  } else {
    return false;
  }
};

export const objTypeCheck = (obj) => {
  if (Array.isArray(obj) && obj.length !== 0) {
    return true;
  } else {
    return false;
  }
};
export const includesWord = (str, word) => {
  return str.match(/word/g);
};
