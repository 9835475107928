import React from "react";

class Bills extends React.Component {
	constructor(props) {
		super(props);
	}

	saveAndContinue = e => {
		e.preventDefault();
		
			this.props.nextStep();
		
		
	};

	back = e => {
		e.preventDefault();
		this.props.prevStep();
	};

	render() {
		const { values } = this.props;
		return (
			<React.Fragment>
				<form
					className=" bills-form-container"
					// onSubmit={this.saveAndContinue}
				>
					<p className="show-msg">{values.showmsg}</p>
					<p className="show-msg-success">{values.showmsgSuccess}</p>
					<p id="dstv-msg"></p>
					{/* <span className="fee">{"Convenience Fee: " + values.payload}</span>
					 */}

					<p className="form-header">PAY BILLS</p>
					<div class="form-group landing-form-input">
						<select
							class="landing-form-control form-control custom-select"
							id="exampleFormControlSelect1"
							placeholder="SELECT NETWORK"
							onChange={this.props.changeProvider}
							value={values.selectedProvider}
							disabled={values.isLoading}
							//multiple={true}
							required
						>
							<option>SELECT BILLER</option>
							{values.provider.map((e, key) => {
								return <option key={key}>{e.name}</option>;
							})}
						</select>
					</div>

					<button
						type="submit"
						className="btn btn-dark landing-form-input bill-btn"
						// disabled={values.isLoading}
						disabled={true}
					>
						{values.isLoading ? "LOADING…" : "CONTINUE"}
					</button>
				</form>
			</React.Fragment>
		);
	}
}

export default Bills;
