import React from "react";
import EstateDashboard from "./EstateDashboard";
import OtherDashboard from "./OtherDashboard";
import UserDashboard from "./UsersDashboard";
import OwnersDashboard from "./OwnersDashboard";
import { decodeJwt } from "../../utilities/processJWT";

class Dashboard extends React.Component {
  render() {
    return (
      <div className="content">
        {decodeJwt().role === "service-admin" ? (
          <div>
            <EstateDashboard />
          </div>
        ) : (
            decodeJwt().role === "service-user" ?
             (<UserDashboard />)
              :(
                decodeJwt().role === "service-owner" ? (
                  <div>
                    <OwnersDashboard />
                  </div>
                ) : (
                   <div>
                <OtherDashboard />
              </div>
                )
              )
              // <div>
              //   <OtherDashboard />
              // </div>
          )}
          {/* {
            decodeJwt().role === "service-owner" ? (
              <div>
                <OwnersDashboard />
              </div>
            ) : (null)
          } */}
      </div>
    );
  }
}

export default Dashboard;
