import React from "react";
import { render } from 'react-dom';
import {
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Button,
  Row,
  Col,
} from "reactstrap";
import { fetchTransactions, searchTransaction, getUserDevLevy } from "../../../../utilities/api";
import { CSVLink } from "react-csv";
import ErrorPage from "../../../../utilities/errorPage";
import Placeholder from "../../../../utilities/placeholder";
import Pagination from "../../../../utilities/Pagination";
import { arrTypeCheck } from "../../../../utilities/dataTypeCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormatTime } from "../../../../utilities/currency_time_formata";
import {ReceiptMarkup} from "../../../../utilities/ReceiptMarkup";
import { BlobProvider } from "@react-pdf/renderer";


class DevLevy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      headers: [],
      payload: null,
      isLoading: false,
      search: false,
      value: 10,
      listOfAgents: [],
      success: false,
      currentTransactions: [],
      currentPage: null,
      totalPages: null,
      showReceipt: false,
			receiptData: {},
			newPartners: [],
			text: "Download"
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.searchTransactions = this.searchTransactions.bind(this);
    this.getTransactions = this.getTransactions.bind(this);
    this.onPageChanged = this.onPageChanged.bind(this);
  }

  componentWillMount() {
    this.getTransactions();
  }

  showReceiptBtn(user) {
    console.log("user", user, user + 1);
		var x = document.getElementById(user + 1);
    console.log("x", x.style.display);
		if (x.style.display === "none") {
		  x.style.display = "none";
      
		} else {
		  x.style.display = "none";
		}
	}

  async buildPDF(transId, ) {
		await this.handleGetReceipt(transId);
		setTimeout(() => {
      console.log(" receipt data",this.state.receiptData);
			const stringifyData = JSON.stringify(this.state.receiptData);
		console.log("stringify", stringifyData);
		if ( stringifyData === "{}"|| this.state.receiptData === null) {
			alert("Receipt not available");
		}else {
			setTimeout(()=> {
			this.setState({ loading: true}, () => {
				console.log("buildPdf",
				);
				this.createAndDownloadPDF(
						this.generatePDF(),
						`Receipt${transId}.pdf`,
						`${transId}`,
						transId,
						() => this.setState({ loading: false })
				);
				
			  });
			},500)
		}
		
	}, 600);
	  }

    handleGetReceipt = (x) => {
      const transId = x;
      // const rrn = y;

      const data = this.state.payload;

      let newArray = data.filter(function (x) {
        return x.payment_transaction_id === transId;
      });

      console.log("newaa", newArray);
      if (newArray) {
        this.setState({ 
          showReceipt: true,
          receiptData: newArray
        });
      } else {
        this.setState({ 
          showReceipt: false,
        });
        alert("Receipt not available");
      }

      // return getReceipt(merchantId, rrn).then((response) => {
      //   if (response && response.status == 200) {
      //     this.setState({ 
      //       showReceipt: true,
      //       receiptData: response.data
      //     });
      //   } else {
      //     this.setState({ 
      //       showReceipt: false,
      //     });
      //     // alert("Receipt not available");
      //   }
      // }).catch(() => {
      //   this.setState({ 
      //     showReceipt: false,
      //   });
      // })
    
    };

    createAndDownloadPDF = (pdfContent, filename, divId, user, callback) => {
      console.log("Trans", pdfContent, filename, divId, user);
      // this.setState({
      //   showReceipt: true 
      // })
      setTimeout(() => {
        const link = (
        <div id={divId}>
     <BlobProvider document={pdfContent}>
        {({ blob, url, loading, error }) => {

        this.showReceiptBtn(user);
      return <a href={url} download={filename}>{"View"}</a>
        }}
      </BlobProvider>
        </div>
        );
        const elem = document.createElement('div');
        document.getElementById(user).appendChild(elem);
        render(link, elem);
      }, 500);
      }

      generatePDF = () => {
        console.log("ddd", this.state.receiptData);
        return <ReceiptMarkup data={this.state.receiptData} type={"Development Levy"}/>;
        }

  onPageChanged = (data) => {
    const { payload } = this.state;
    let sortedPayload = payload.slice().sort(function(a, b) {
      a = new Date(a.createdAt);
      b = new Date(b.createdAt);
      return a > b ? -1 : a < b ? 1 : 0;
    });
    const { currentPage, totalPages, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    const currentTransactions = sortedPayload.slice(
      offset,
      offset + pageLimit
    );
    this.setState({ currentPage, currentTransactions, totalPages });
  };

  onChangeStartDate = (event) => {
    this.setState({ startDate: event.target.value });
  };

  onChangeEndDate = (event) => {
    this.setState({ endDate: event.target.value });
  };
  searchTransactions = () => {
    const { startDate, endDate } = this.state;
    return searchTransaction({
      from: startDate,
      to: endDate,
    }).then((response) => {
      if (!response || response.status !== 200) {
        this.setState({
          isLoading: false,
          search: false,
          success: false,
        });
        setTimeout(() => {
          document.getElementById("show-error").innerHTML = "Error occured";
        }, 500);

        return;
      } else if (response.status == 200 || response && response.status === 304) {
        setTimeout(() => {
          const initialState = {
            cSelected: [],
            startDate: null,
            endDate: null,
            headers: [
              { label: "Transaction Amount", key: "amount" },
              { label: "Reference", key: "payment_transaction_id" },
              { label: "Meter Number", key: "meter_number" },
              { label: "Transaction Date", key: "createdAt" },
              { label: "Transaction Status", key: "transactionStatus" },
              { label: "Transaction Type", key: "transactionType" },
            ],
            data: [],
            payload: response.data,
            isLoading: false,
            search: false,
            value: 10,
            count: 0,
            currentTransactions: [],
            currentPage: null,
            totalPages: null,
            success: true,
          };
          this.setState(initialState);
          let le = document.getElementById("download-option");
          le.style.display = "block";
          if (arrTypeCheck(response.data)) {
            document.getElementById("csv-download").style.display = "inline";
          }
        }, 500);
      } else {
        document.getElementById("show-error").innerHTML = "Server error";
        let le = document.getElementById("download-option");
        le.style.display = "block";
        this.setState({ isLoading: false });
        return;
      }
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ search: true });
    setTimeout(() => {
      this.searchTransactions();
    }, 100);
  };

  getTransactions() {
    this.setState({ isLoading: true });
    getUserDevLevy().then((response) => {
      if (response && response.status == 200 || response && response.status === 304) {
        setTimeout(() => {
          this.setState({
            payload: response.data,
            isLoading: false,
            headers: [
                { label: "Transaction Value", key: "amount" },
                { label: "Meter Number", key: "meterNumber" },
                { label: "Transaction Date", key: "createdAt" },
                { label: "Transaction Status", key: "transactionStatus" },
                { label: "Transaction ID", key: "payment_transaction_id" },
            ],
          });
          let le = document.getElementById("download-option");
          le.style.display = "block";
        }, 500);
      } else {
        this.setState({ payload: response, isLoading: false });
      }
    });
  }

  render() {
    const {
      payload,
      isLoading,
      search,
      currentTransactions,
      currentPage,
      totalPages,
    } = this.state;
    return (
      <div>
        {/*--------------------------------------------------------------------------------*/}
        {/*Start Inner Div*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*Row*/}
        {/*--------------------------------------------------------------------------------*/}
        {!isLoading && !payload ? (
          <div>
            <ErrorPage />
          </div>
        ) : (
          <div>
            <Row>
              {isLoading ? (
                <div>
                  <Placeholder />
                </div>
              ) : (
                <Col md={12} className="mx-auto bg-white p-5 rounded">
                  <Card className="demo-icons shadow-none">
                    <h3 id="show-error">Server error, please try again</h3>
                    <CardHeader>
                      <h6>
                        {this.state.success &&
                          payload.length < 1 &&
                          "No transaction yet"}
                      </h6>
                    </CardHeader>

                    {/* <CardBody className="">
                      <Form onSubmit={this.handleSubmit}>
                        <Row form>
                          <Col className="" md={5}>
                            <FormGroup>
                              <Label
                                className="font-weight-bolder text-dark"
                                for="exampleFrom"
                              >
                                FROM
                              </Label>
                              <Input
                                type="date"
                                name="date"
                                id="exampleFrom"
                                onChange={this.onChangeStartDate}
                                placeholder="date placeholder"
                              />
                            </FormGroup>
                          </Col>
                          <Col md={5}>
                            <FormGroup>
                              <Label
                                for="exampleTo"
                                className="font-weight-bolder text-dark"
                              >
                                To
                              </Label>
                              <Input
                                type="date"
                                name="date"
                                id="exampleTo"
                                onChange={this.onChangeEndDate}
                                placeholder="date placeholder"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <div class="text-end">
                            <Button
                              className="btn btn-lg bg-green text-uppercase"
                              type="submit"
                              disabled={search}
                            >
                              {search ? "Loading…" : "Search"}
                            </Button>
                          </div>
                        </FormGroup>
                      </Form>
                    </CardBody> */}

                    <h6 className="pl-2 mt-3">
                    DEVELOPMENT LEVY
                  </h6>

                    {/* <CardBody className="all-icons text-center">
              </CardBody> */}
                  </Card>
                  <Card id="download-option" className="shadow-none">
                    <div className="bg-white rounded w-100">
                      {!isLoading && payload && (
                        <div class="table-responsive">
                          <table class="table table-hover table-striped table-bordered w-100 ">
                            <thead class="thead-dark">
                              <tr>
                                {/* <th>ID</th> */}
                                <th>METER NUMBER</th>
                      <th>AMOUNT</th>
                      
                      <th>STATUS</th>
                      <th>TRANSACTION ID</th>
                      <th>DATE</th>
                      <th>RECEIPT</th>
                              </tr>
                            </thead>
                            {currentTransactions.map((transaction) => (
                              <tbody>
                                <tr
                                  key={transaction.id}
                                  role="row"
                                  className="odd"
                                >
                                  {/* <td className="sorting_1">
                                    {transaction.id}
                                  </td> */}
                                  <td>{transaction.meterNumber}</td>
                        <td>{`₦${transaction.amount}`}</td>
                        {/* <td>{transaction.result}</td> */}

                        <td>{transaction.netpluspay_message}</td>
                        
                        <td>{transaction.payment_transaction_id}</td>
                        
                        <td>{FormatTime(transaction.createdAt)}</td>
                        <td>
                        <div id={transaction.payment_transaction_id}>
											<button className="btn btn-link" id={transaction.payment_transaction_id +1} onClick={() => this.buildPDF(transaction.payment_transaction_id)}>
											{!this.state.showReceipt ? this.state.text: "Download"}
											
										  	</button>
													</div>
                        </td>
                                </tr>
                              </tbody>
                            ))}
                          </table>
                        </div>
                      )}
                    </div>
                    <div className="pagination-wrapper mt-5">
                      <div
                        className="dataTables_info"
                        id="myTable_info"
                        role="status"
                        aria-live="polite"
                      >
                        <div
                          style={{
                            color: "blue",
                            fontWeight: "bolder",
                            fontSize: "larger",
                          }}
                        >
                          <i className="mdi mdi-file-xml ml-2">
                            {!isLoading && payload ? (
                              <CSVLink
                                data={this.state.payload}
                                headers={this.state.headers}
                                className="text-body small font-weight-bold"
                                filename={"development_levy.csv"}
                              >
                                <FontAwesomeIcon icon={"file-export"} />
                                &nbsp;EXPORT
                              </CSVLink>
                            ) : null}
                          </i>
                        </div>
                      </div>
                      {!isLoading && payload && (
                        <div className="d-flex flex-row justify-content-end">
                          {
                            <Pagination
                              totalRecords={payload.length}
                              pageLimit={this.state.value}
                              pageNeighbours={1}
                              onPageChanged={this.onPageChanged}
                            />
                          }
                        </div>
                      )}
                    </div>
                  </Card>
                </Col>
              )}
            </Row>
          </div>
        )}
      </div>
    );
  }
}

export default DevLevy;
