import React from "react";
import { buyAirtime } from "../../../utilities/api";
import { getFees } from "../../../utilities/api";
import { fetchUserInfo } from "../../../utilities/api";
import shortid from "shortid";
import { netpluspayPOP } from "../../../views/TopUps/netfun";
import UserDetails from "./UserDetails";
import BuyPowerPublicForm from "./BuyPowerPublicForm";
import TransactionSummary from "./TransactionSummary";
import TransactionFailure from "./TransactionFailure";

const formValid = formErrors => {
	let valid = true;

	// validate form errors being empty
	Object.values(formErrors).forEach(val => {
		val.length > 0 && (valid = false);
	});

	return valid;
};

class BuyPowerPublic extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			step: 1,
			showmsg: "",
			token: "",
			serviceType: "electricity",
			showmsgSuccess: "",
			payload: 0,
			isLoading: false,
			category: "",
			provider: "",
			destinationAccount: "",
			phone: null,
			email: "",
			amount: null,
			service: "pay_bill",
			transactionID: null,
			modalResponse: "",
			billAccountId: null,
			billAccountIdDetails: null,
			formErrors: {
				provider: "",
				destinationAccount: "",
				confirmDestination: "",
				amount: null
			}
		};
	}

	componentDidMount = () => {
		const script = document.createElement("script");
		script.src = "https://js.netpluspay.com/inline.js";
		script.charset = "utf-8";
		script.async = true;
		script.onload = this.scriptLoaded();

		document.head.appendChild(script);
	};

	scriptLoaded = () => {
		this.setState({ netplusPayReady: true });
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevState.windowsClosed !== this.state.windowsClosed) {
			this.setState({
				isLoading: true
			});
			//this.handleCheck();
		}
	}

	nextStep = () => {
		const { step } = this.state;
		if (this.state.modalResponse === "FAILED") {
			this.setState({
				step: step + 2
			});
		} 
		 else {
		this.setState({
			step: step + 1
		});
	}
	};

	checkVerifyUser = () => {
		if(this.state.billAccountIdDetails === "" ) {
			this.setState({
				isLoading: false,
				showmsg: "Please Check Card Number"
			});
		} else {
			this.nextStep();
		}
	}

	prevStep = () => {
		const { step } = this.state;
		this.setState({
			step: step - 1
		});
	};

	handleChange = e => {
		e.preventDefault();
		const { name, value } = e.target;

		let formErrors = this.state.formErrors;

		switch (name) {
			case "provider":
				formErrors.provider =
					value.length < 0 || value == "" ? "Required Field" : "";
				break;

			case "amount":
				formErrors.amount =
					value.length < 0 || value == "" ? "Required Field" : "";
				break;
			default:
				break;
		}
		this.setState({ formErrors, [name]: value });

		this.setState({ name: value });
	};

	handleChangeProvider = e => {
		e.preventDefault();

		this.setState({
			provider: e.target.value
		});
		this.handleGetFees();
	};

	handleGetFees = () => {
		this.setState({
			isLoading: true
		});

		setTimeout(() => {
			let data = this.state.provider;

			if (sessionStorage.getItem("Service") === this.state.provider) {
				this.setState({
					payload: sessionStorage.getItem("Fee"),
					isLoading: false

				});
			} else {
				getFees(data).then(response => {
					if (response && response.status == 200) {
						setTimeout(() => {
							this.setState({
								payload: response.data.fees,
								isLoading: false,
								storedProvider: response.data.provider,
								storedFee: response.data.fees,
								showmsg: ""
							});

							const s = this.state.storedProvider;
							const fee = this.state.storedFee;

							sessionStorage.setItem("Fee", fee);
							sessionStorage.setItem("Service", s);
						}, 500);
					} else {
						let showMsg = document.getElementById("show-power-msg");
						// showMsg.innerHTML = "Service Unavailable ";
						// showMsg.style.display = "block";
						// showMsg.style.fontSize = "18px";
						// showMsg.style.color = "red";

						this.setState({
							isLoading: false,
							showmsg: "Service Unavailable"
						});
					}
				});
			}
		}, 1000);
	};

	verifyUser = e => {
		e.preventDefault();

		this.setState({
			isLoading: true
		});
		let data = {
			destinationAccount: this.state.destinationAccount,
			provider: this.state.provider
		};

		setTimeout(() => {
			fetchUserInfo(data).then(response => {
				if (response && response.status == 200) {
					setTimeout(() => {
						this.setState({
							billAccountId: response.data.BillAccountId,
							billAccountIdDetails: response.data.BillAccountIdDetails,
							isLoading: false,
							showmsg: ""
						});
						this.checkVerifyUser();
					}, 500);
				} else if (response && response.status == 400) {
					setTimeout(() => {
						this.setState({
							showmsg: "Invalid Number",
							isLoading: false
						});
					}, 500);
				} else {
					this.setState({
						isLoading: false,
						showmsg: "Service Unavailable"
					});
					this.nextStep();
				}
			});
		}, 500);
	};

	handlePayment = e => {
		e.preventDefault();
		// if (formValid(this.state.formErrors)) {
		if (this.state.payload === 0) {
			this.setState({
				isLoading: false,
				showmsg: "Service Unavailable"
			});
		 }
		// else if (this.state.billAccountIdDetails === "" || null) {
		// 	this.setState({
		// 		showmsg: "Invalid Card Number",
		// 		isLoading: false
		// 	});
		// }
		 else {
			const finalamount =
				parseInt(this.state.amount) + parseInt(this.state.payload);

			const values = {
				// Values to give netpluspay class
				success: message => {
					this.setState({
						isLoading: true,
						modalResponse: message.status,
						transactionID: message.transId
					});

					this.handleWindowClose();
				},
				failed: message => {
					this.setState({
						isLoading: true,
						modalResponse: message.status,
						transactionID: message.transId
					});

					this.handleWindowClose();
				},
				cancel: message => {
					this.setState({ isLoading: false });

					this.handleWindowClose();
				},

				data: {
					merchantId: `${process.env.REACT_APP_MERCHANT_ID}`,
					name: this.state.billAccountIdDetails, //Don't make it long so amount can show
					email: this.state.email,
					amount: finalamount,
					currency: "NGN",
					orderId: `POWER${shortid.generate()}`
				}
			};

			if (this.state.netplusPayReady) {
				// Check if netpluspay script is loaded

				let netplusPay = window.NetplusPay; // Call the netpluspay function
				let netplusPay2 = new netplusPay(values); // Create a new netpluspay class

				netplusPay2.beginTransaction();

				this.setState({
					isLoading: false
				});
			} else {
				setTimeout(() => {
					this.setState({
						isLoading: false,
						showmsg: "Oops! Server or Network Error"
					});
				}, 100);
			}

			// netpluspayPOP.setup({
			// 	merchant: `${process.env.REACT_APP_MERCHANT_ID}`,
			// 	customer_name: "Buy Power Customer",
			// 	email: "N/A",
			// 	amount: finalamount,
			// 	currency_code: "NGN",
			// 	narration: "Data Purchase",
			// 	order_id: `DATA${shortid.generate()}`,
			// 	container: "paymentFrame",
			// 	callBack: function(resp) {
			// 		if (resp && resp.status === "CAPTURED") {
			// 			this.setState({
			// 				modalResponse: resp.status,
			// 				transactionID: resp.trans_id
			// 			});
			// 		} else if (resp && resp.status === "FAILED") {
			// 			this.setState({
			// 				modalResponse: resp.status,
			// 				transactionID: resp.trans_id
			// 			});
			// 		}
			// 		//this.closeIframe();
			// 	}.bind(this),
			// 	onClose: function() {
			// 		this.setState({ isLoading: true });
			// 		this.handleWindowClose();
			// 	}.bind(this)
			// });
			// netpluspayPOP.prepareFrame();
			// } else {
			// 	let showMsg = document.getElementById("show-power-msg");
			// 	showMsg.innerHTML = "Invalid Form";
			// 	showMsg.style.display = "block";
			// 	showMsg.style.fontSize = "18px";
			// 	showMsg.style.color = "red";
			// 	this.setState({ isLoading: false });
			// }
		}
	};

	handleWindowClose = () => {
		if (this.state.modalResponse === "FAILED") {
			this.setState({
				isLoading: false,
				showmsg: "Transaction Failed"
			});
			this.nextStep();
		} else if (this.state.modalResponse === "CAPTURED") {
			this.handleBuyPower();
		} else {
			this.setState({
				isLoading: false
			});
		}
	};

	handleBuyPower = () => {
		//	if (formValid(this.state.formErrors)) {
		const amount1 = parseInt(this.state.amount) + parseInt(this.state.payload);

		let data = {
			destinationAccount: this.state.destinationAccount,
			amount: amount1,
			service: this.state.service,
			provider: this.state.provider,
			trans_id: this.state.transactionID,
			serviceType: this.state.serviceType,
			email: this.state.email
		};

		setTimeout(() => {
			buyAirtime(data).then(response => {
				if (response && response.status === 200) {
					this.setState({
						token: response.data.token,
						isLoading: false,
						showmsg: "",
						showmsgSuccess: "Transaction Successful"
					});
					this.nextStep();
					
				} else if (response && response.status === 400) {
					this.setState({
						isLoading: false,
						showmsg: "Transaction Failed",
						//powerResponse: response[1]
					});
					// this.nextStep();
					// console.log(this.state);
				} else if (response && response.status === 500) {
					this.setState({
						isLoading: false,
						showmsg: "Server Error"
					});
					//this.nextStep();
				} else {
					
					this.setState({
						isLoading: false,
						showmsg: "Server Error"
					});
					//this.nextStep();
				}
			});
		}, 4000);
	};
	// else {

	// 		let showMsg = document.getElementById("show-power-msg");
	// 		showMsg.innerHTML = "Invalid Form";
	// 		showMsg.style.display = "block";
	// 		showMsg.style.fontSize = "18px";
	// 		showMsg.style.color = "red";
	// 		this.setState({ isLoading: false });
	// 	}


	// launchModal = () => {
	// 	return <TransactionSummary />
	// }

	render() {
		const { step } = this.state;
		const {
			payload,
			token,
			isLoading,
			category,
			provider,
			email,
			phone,
			billPackage,
			firstName,
			lastName,
			accountName,
			destinationAccount,
			amount,
			service,
			selectedProvider,
			selectedCategory,
			billAccountId,
			billAccountDetailsId,
			billAccountIdDetails,
			formErrors,
			showmsg,
			showmsgSuccess
		} = this.state;
		const values = {
			payload,
			isLoading,
			category,
			token,
			provider,
			email,
			phone,
			billPackage,
			firstName,
			lastName,
			accountName,
			destinationAccount,
			amount,
			service,
			selectedProvider,
			selectedCategory,
			billAccountId,
			billAccountDetailsId,
			billAccountIdDetails,
			formErrors,
			showmsg,
			showmsgSuccess
		};
		switch (step) {
			case 1:
				return (
					<BuyPowerPublicForm
						nextStep={this.nextStep}
						handleChangeProvider={this.handleChangeProvider}
						values={values}
						handleChange={this.handleChange}
						//toggleDropdown = {this.toggleDropdown}
						changeService={this.changeService}
						verifyUser={this.verifyUser}
						changeProvider={this.changeProvider1}
					/>
				);

			case 2:
				return (
					<UserDetails
						nextStep={this.nextStep}
						prevStep={this.prevStep}
						handleChangeProvider={this.handleChangeProvider}
						values={values}
						handleChange={this.handleChange}
						verifyUser={this.verifyUser}
						handlePayment={this.handlePayment}
						
					/>
				);

				case 3:
				return (
					<TransactionSummary
						nextStep={this.nextStep}
						prevStep={this.prevStep}
						handleChangeProvider={this.handleChangeProvider}
						values={values}
						handleChange={this.handleChange}
						verifyUser={this.verifyUser}
						handlePayment={this.handlePayment}
					/>
				);

				case 4:
				return (
					<TransactionFailure
						nextStep={this.nextStep}
						prevStep={this.prevStep}
						handleChangeProvider={this.handleChangeProvider}
						values={values}
						handleChange={this.handleChange}
						verifyUser={this.verifyUser}
						handlePayment={this.handlePayment}
					/>
				);
		}
	}
}

export default BuyPowerPublic;
