import React from "react";
import DatePicker from "react-date-picker";
import {
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Button,
  Row,
  Col,
} from "reactstrap";
import { getAllTopups } from "../../utilities/api";
import RegisterForm from './Form';

class TransactionHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      headers: [],
      payload: [],
      isLoading: false,
      search: false,
      value: 10,
      listOfAgents: [],
      success: false,
      currentTransactions: [],
      currentPage: null,
      totalPages: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.searchTransaction = this.searchTransaction.bind(this);
  }

  onPageChanged = (data) => {
    const { payload } = this.state;
    const { currentPage, totalPages, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    const currentTransactions = payload.req
      .slice(offset, offset + pageLimit)
      // .sort(function (a, b) {
      //   a = new Date(a.createdAt);
      //   b = new Date(b.createdAt);
      //   return a > b ? -1 : a < b ? 1 : 0;
      // });
    this.setState({ currentPage, currentTransactions, totalPages });
  };

  onChangeStartDate = (event) => {
    this.setState({ startDate: event.target.value });
  };

  onChangeEndDate = (event) => {
    this.setState({ endDate: event.target.value });
  };

  searchTransaction = () => {
    let showError = document.getElementById("show-error");
    const { startDate, endDate } = this.state;
    return getAllTopups({
      from: startDate,
      to: endDate,
    }).then((response) => {
      if (response && response.status === 200 || response && response.status === 304) {
        showError.style.display = "none";
        setTimeout(() => {
          this.setState({
            payload: response.data,
            isLoading: false,
            search: false,
            success: true,
            headers: [
              { label: "ID", key: "id" },
              { label: "Transaction Value", key: "amount" },
              { label: "Ref", key: "referenceCode" },
              { label: "Meter Number", key: "meter_number" },
              { label: "Transaction Date", key: "createdAt" },
              { label: "Transaction Status", key: "transactionStatus" },
              { label: "Transaction Type", key: "transaction_type" },
            ],
          });
          let le = document.getElementById("download-option");
          le.style.display = "block";
        }, 500);
      } else {
        // throw new Error(response);
        this.setState({ isLoading: false });
        showError.style.display = "block";
      }
    }).catch(function(error) {
      this.setState({ isLoading: false });
      showError.style.display = "block";
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ search: true, isLoading: true });
    setTimeout(() => {
      this.searchTransaction();
    }, 1000);
  };

  render() {
    const {
      payload,
      isLoading,
      search,
      currentTransactions,
      currentPage,
      totalPages,
    } = this.state;
    return (
      <div className="content">
        {/*--------------------------------------------------------------------------------*/}
        {/*Start Inner Div*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*Row*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* <div id="paymentFrame" class="m-0 mx-auto"></div> */}
        <Row>
          <Col md={12} className="mx-auto bg-white p-5 rounded">
          <RegisterForm />
          </Col>
        </Row>
      </div>
    );
  }
}

export default TransactionHistory;
