import React from "react";
import { buyAirtime } from "../../../utilities/api";
import { getFees } from "../../../utilities/api";
import shortid from "shortid";
import { netpluspayPOP } from "../../../views/TopUps/netfun";
import phone from "../../../assets/img/Phone.jpg";
import MobileData from "./MobileData";
import MobileAirtime from "./MobileAirtime";
import BillsTransactionFailure from "../PayBills/BillsTransactionFailure";
import AirtimeSummary from "../BuyAirtime/AirtimeSummary";
import ConfirmNumber from "./ConfirmNumber";

// const formValid = formErrors => {
// 	let valid = true;

// 	// validate form errors being empty
// 	Object.values(formErrors).forEach(val => {
// 		val.length > 0 && (valid = false);
// 	});

// 	return valid;
// };

class Airtime extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			step: 1,
			showmsg: "",
			isLoading: false,
			// transaction_type: "bill payment",
			category: "",
			dataProvider: [],
			dataPlan: [],
			selectedDataPlan: "Choose Plan",
			provider: "",
			destinationAccount: "",
			confirmDestination: "",
			amount: null,
			service: "vtu",
			dataService: "pay_bill",
			transactionID: null,
			modalResponse: "",
			netplusPayReady: false,
			showmsgSuccess: "",
			// lineType: "virtual topup",
			formErrors: {
				provider: "",
				destinationAccount: "",
				confirmDestination: "",
				amount: null
			}
		};
	}

	componentDidMount = () => {
		this.setState({
			dataProvider: [
				{
					name: "9MOBILE",
					dataPlan: [
						{
							name: "N110,000 - 365 DAYS - 120GB",
							amount: 110000
						},
						{
							name: "N84,992 - 100 DAYS - 10GB",
							amount: 84992
						},
						{
							name: "N55,000 - 180 DAYS - 60GB",
							amount: 55000
						},
						{
							name: "27,500 - 90 DAYS - 30GB",
							amount: 27500
						},
						{
							name: "N15,000 - 30 DAYS - 75GB",
							amount: 15000
						},
						{
							name: "N10,000 - 30 DAYS - 40GB",
							amount: 10000
						},
						{
							name: "N5,000 - 30 DAYS - 15GB",
							amount: 5000
						},
						{
							name: "N4,000 - 30 DAYS - 11GB",
							amount: 4000
						},
						{
							name: "N2,000 - 30 DAYS - 4.5GB",
							amount: 2000
						},
						{
							name: "N1,500 - 7 DAYS - 7GB",
							amount: 1500
						},
						{
							name: "N1,000 - 30 DAYS - 1.5GB",
							amount: 1000
						},
						{
							name: "N500 - 30 DAYS - 500MB",
							amount: 500
						},
						{
							name: "N200 - 24 HRS - 650MB",
							amount: 200
						},
						{
							name: "N100 - 24 HRS - 100MB",
							amount: 100
						},
						{
							name: "N50 - 24 HRS - 25MB",
							amount: 50
						}
					]
				},
				{
					name: "AIRTEL",
					dataPlan: [
						{
							name: "N19,999.02 - 30 DAYS - 110GB",
							amount: 19999.02
						},
						{
							name: "N14,999.00 - 30 DAYS - 75GB",
							amount: 14999.0
						},
						{
							name: "N9,999.00 - 30 DAYS - 40GB",
							amount: 9999.0
						},
						{
							name: "N4,999.00 - 30 DAYS - 15GB",
							amount: 4999.0
						},
						{
							name: "N3,999.01 - 30 DAYS - 11GB",
							amount: 3999.01
						},
						{
							name: "N2,999.02 - 30 DAYS - 8GB",
							amount: 2999.02
						},
						{
							name: "N2,499.01 - 30 DAYS - 6GB",
							amount: 2499.01
						},
						{
							name: "N1,999.01 - 30 DAYS - 4.5GB",
							amount: 1999.01
						},
						{
							name: "N1,499.01 - 30 DAYS - 3GB",
							amount: 1499.01
						},
						{
							name: "N1,199.00 - 30 DAYS - 2GB",
							amount: 1199.0
						},
						{
							name: "N999.00 - 30 DAYS - 1.5GB",
							amount: 999.0
						},
						{
							name: "N499.00 - 14 DAYS - 750MB",
							amount: 499.0
						},
						{
							name: "N299.02 - 7 DAYS - 350MB",
							amount: 299.02
						},
						{
							name: "N299.03 - 1 DAY - 1GB",
							amount: 299.03
						},
						{
							name: "N199.03 - 3 DAYS - 200MB",
							amount: 199.03
						},
						{
							name: "N99.00 - 1 DAY - 100MB",
							amount: 99.0
						}
					]
				},
				{
					name: "GLO",
					dataPlan: [
						{
							name: "N18,OOO - 30 DAYS - 119GB",
							amount: 18000
						},
						{
							name: "N15,OOO - 30 DAYS - 93GB",
							amount: 15000
						},
						{
							name: "N10,OOO - 30 DAYS - 50GB",
							amount: 10000
						},
						{
							name: "N8,OOO - 30 DAYS - 29.5GB",
							amount: 8000
						},
						{
							name: "N5,OOO - 30 DAYS - 18.25GB",
							amount: 5000
						},
						{
							name: "N4,OOO - 30 DAYS - 13.35GB",
							amount: 4000
						},
						{
							name: "N3,OOO - 30 DAYS - 10GB",
							amount: 3000
						},
						{
							name: "N2,5OO - 30 DAYS - 7.7GB",
							amount: 2500
						},
						{
							name: "N2,OOO - 30 DAYS - 5.8GB",
							amount: 2000
						},
						{
							name: "N1,OOO - 30 DAYS - 2.5GB",
							amount: 1000
						},
						{
							name: "N1,5OO - 30 DAYS - 1GB",
							amount: 1500
						},
						{
							name: "N5OO - 14 DAYS - 1.05GB",
							amount: 500
						},
						{
							name: "N2OO - 4 DAYS - 350MB",
							amount: 200
						},
						{
							name: "N1OO - 2 DAYS - 105MB",
							amount: 100
						},
						{
							name: "N5O - 1 DAY - 32MB",
							amount: 50
						}
					]
				},
				{
					name: "MTN",
					dataPlan: [
						{
							name: "N450,000 - 365 DAYS - 1500GB",
							amount: 450000
						},
						{
							name: "N300,000 - 365 DAYS - 1000GB",
							amount: 300000
						},
						{
							name: "N120,000 - 365 DAYS - 400GB",
							amount: 120000
						},
						{
							name: "N100,000 - 180 DAYS - 325GB",
							amount: 100000
						},
						{
							name: "N75,000 - 90 DAYS - 250GB",
							amount: 75000
						},
						{
							name: "N50,000 - 90 DAYS - 150GB",
							amount: 50000
						},
						{
							name: "N30,000 - 60 DAYS - 120GB",
							amount: 30000
						},
						{
							name: "N20,000 - 60 DAYS - 75GB",
							amount: 20000
						},
						{
							name: "N15,000 - 30 DAYS - 75GB",
							amount: 15000
						},
						{
							name: "N6,000 - 30 DAYS - 20GB",
							amount: 6000
						},
						{
							name: "N5,000 - 30 DAYS - 15GB",
							amount: 5000
						},
						{
							name: "N3,500 - 30 DAYS - 10GB",
							amount: 3500
						},
						{
							name: "N2,500 - 30 DAYS - 6GB",
							amount: 2500
						},
						{
							name: "N2,000 - 30 DAYS - 4.5GB",
							amount: 2000
						},
						{
							name: "N1,000 - 30 DAYS - 1.5GB",
							amount: 1000
						},
						{
							name: "N150 - 30 DAYS - 160MB",
							amount: 150
						},
						{
							name: "N50 - 7 DAYS - 50MB",
							amount: 50
						},
						{
							name: "N25 - 1 DAY - 20MB",
							amount: 25
						}
					]
				}
			]
		});

		const script = document.createElement("script");
		script.src = "https://js.netpluspay.com/inline.js";
		script.charset = "utf-8";
		script.async = true;
		script.onload = this.scriptLoaded();

		document.head.appendChild(script);
	};

	scriptLoaded = () => {
		this.setState({ netplusPayReady: true });
	};

	changeProvider = e => {
		this.setState({ provider: e.target.value });
		this.setState({
			dataPlan: this.state.dataProvider.find(
				dplan => dplan.name === e.target.value
			).dataPlan
		});

		//this.handleGetFees();
	};

	changeDataPlan = e => {
		this.setState({ selectedDataPlan: e.target.value });
		const pro = this.state.dataProvider.find(
			dplan => dplan.name === this.state.provider
		).dataPlan;
		this.setState({
			amount: pro.find(a => a.name === e.target.value).amount
		});
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevState.windowsClosed !== this.state.windowsClosed) {
			this.setState({
				isLoading: true
			});
			this.handleCheck();
		}
	}

	handleChange = e => {
		e.preventDefault();
		const { name, value } = e.target;

		let formErrors = this.state.formErrors;

		switch (name) {
			case "provider":
				formErrors.provider =
					value.length < 0 || value == "" ? "Required Field" : "";
				break;

			case "destinationAccount":
				formErrors.destinationAccount =
					value.length < 11 || value == "" ? "Invalid Phone Number" : "";
				break;

			case "confirmDestination":
				formErrors.confirmDestination =
					value.length < 11 || value == "" ? "Phone Number Mismatch" : "";
				break;

			case "amount":
				formErrors.amount =
					value.length < 0 || value == "" ? "Required Field" : "";
				break;
			default:
				break;
		}
		this.setState({ formErrors, [name]: value });

		this.setState({ name: value });
	};

	handleFormChange = e => {
		this.setState({ category: e.target.value });
		const { step } = this.state;
		setTimeout(() => {
			if (this.state.category === "pay_bill") {
				this.setState({
					step: step + 1
				});
			} else {
				this.setState({ step: 1 });
			}
		}, 1000);
	};

	nextStep = () => {
		const { step } = this.state;
		if(this.state.modalResponse === "CAPTURED" && this.state.service === "vtu") {
			this.setState({
				step: step + 1
			})
		} else if(this.state.modalResponse === "FAILED" && this.state.service === "vtu"){
			this.setState({
				step: step + 2
			})
		}	else if(this.state.modalResponse === "FAILED" && this.state.category === "pay_bill"){
			this.setState({
				step: step + 2
			})
		}
		else if(this.state.modalResponse === "CAPTURED" && this.state.category === "pay_bill"){
			this.setState({
				step: step + 1
			})
		}		
	}

	prevStep = () => {
		const { step } = this.state;
		this.setState({
			showmsg: "",
			step: step - 1
		})
	}


	handleConfirmNumber=()=> {
		const { step } = this.state;
		this.setState({
			step: step + 1
		})
		
	}

	handleCheck = e => {
		e.preventDefault();

		this.setState({
			isLoading: true,
			showmsg: ""
		});

		console.log(this.state);

		var phoneNumber = this.state.destinationAccount;
		var phoneRegex = /^[0]{1}[7-9]{1}[0-1]{1}[0-9]{8}$/;
		var phoneResult = phoneRegex.test(phoneNumber);

		let mtn = [
			"0703",
			"0706",
			"0803",
			"0806",
			"0810",
			"0813",
			"0814",
			"0816",
			"0903",
			"0906"
		];
		let airtel = [
			"0701",
			"0708",
			"0802",
			"0808",
			"0812",
			"0902",
			"0907",
			"0901"
		];
		let eti = ["0809", "0817", "0818", "0908", "0909"];
		let glo = ["0705", "0805", "0807", "0811", "0815", "0905"];

		let firstFourDigitsArray = phoneNumber.split("", 4);
		let identifier = firstFourDigitsArray.join("");
console.log(this.state);

		if (phoneResult === false) {
			this.setState({
				isLoading: false,
				showmsg: "Phone Number is not correct"
			});
		} else if (this.state.provider === "airtel") {
			console.log(this.state);
			if  (
				this.state.confirmDestination !== this.state.destinationAccount
			) {
				this.setState({
					isLoading: false,
					showmsg: "Phone Number does not match"
				});

			} 
			 else 
			if(!airtel.includes(identifier)) {
				this.setState({
					isLoading: false,
				// 	showmsg: "This is not an Airtel Number"
				});
				return this.handleConfirmNumber();
			} 
			
			else {
				this.setState({
					showmsg: "",
					isLoading: false
				});
				return this.handleConfirmNumber();
				// return this.handlePayment();
				// return this.handleBuyAirtime();
			}
		} else if (this.state.provider === "mtn") {
			if (
				this.state.confirmDestination !== this.state.destinationAccount
			) {
				this.setState({
					isLoading: false,
					showmsg: " Phone Number does not match"
				});
			} 
			else if (!mtn.includes(identifier)) {
				this.setState({
					isLoading: false,
					// showmsg: "This is not an MTN Number"
				});
				return this.handleConfirmNumber();
			} 
			 else {
				this.setState({
					showmsg: "",
					isLoading: false,
				});
				return this.handleConfirmNumber();
				// return this.handlePayment();
			}
		} else if (this.state.provider === "glo") {
			if (
				this.state.confirmDestination !== this.state.destinationAccount
			) {
				this.setState({
					isLoading: false,
					showmsg: "Phone Number does not match"
				});
			} 
			 
			else if (!glo.includes(identifier)) {
				this.setState({
					isLoading: false,
					// showmsg: "This is not a Glo Number"
				});
				return this.handleConfirmNumber();
			} 
			else {
				this.setState({
					showmsg: "",
					isLoading: false,
				});
				return this.handleConfirmNumber();
				// return this.handlePayment();
			}
		} else if (this.state.provider === "etisalat") {
			if  (
				this.state.confirmDestination !== this.state.destinationAccount
			) {
				this.setState({
					isLoading: false,
					showmsg: "Phone Number does not match"
				});
			} 
			else if (!eti.includes(identifier)) {
				this.setState({
					isLoading: false,
					// showmsg: "This is not a 9mobile Number"
				});
				return this.handleConfirmNumber();
			} 
			else {
				this.setState({
					showmsg: "",
					isLoading: false,
				});
				return this.handleConfirmNumber();
				// return this.handlePayment();
				// return this.handleBuyAirtime();
			}
		}
	};

	// handleCheck = e => {
	// 	e.preventDefault();

	// 	this.setState({
	// 		isLoading: true
	// 	});

	// 	if (this.state.confirmDestination !== this.state.destinationAccount) {
	// 		this.setState({
	// 			isLoading: false,
	// 			showmsg: "Check Phone Number"
	// 		});
	// 	} else {
	// 		return this.handlePayment();
	// 	}
	// };

	handlePayment = () => {
		//e.preventDefault();
		//if (formValid(this.state.formErrors)) {
		// if(this.state.payload === 0) {
		// 	let showMsg = document.getElementById("show-msg");
		// 			showMsg.innerHTML = "Service Unavailable ";
		// 			showMsg.style.display = "block";
		// 			showMsg.style.fontSize = "18px";
		// 			showMsg.style.color = "red";
		// 			this.setState({
		// 				isLoading: false
		// 			});
		// } else {
		const finalamount = parseInt(this.state.amount);
		const values = {
			// Values to give netpluspay class
			success: (message) => {
				this.setState({
					isLoading: true,
					modalResponse: message.status,
					transactionID: message.transId
				});
				this.handleWindowClose();
			},
			failed: (message) => {
				this.setState({
					modalResponse: message.status,
					transactionID: message.transId
				});
				this.handleWindowClose();
			},
			cancel: (message) => {
				this.setState({ isLoading: false });
				this.handleWindowClose();
			},

			data: {
				merchantId: `${process.env.REACT_APP_MERCHANT_ID}`,
				name: "Customer", //Don't make it long so amount can show
				email: "N/A",
				amount: finalamount,
				currency: "NGN",
				orderId: `POWER${shortid.generate()}`
			}
		};

		

		if (this.state.netplusPayReady) {
			
			let netplusPay = window.NetplusPay; // Call the netpluspay function
			let netplusPay2 = new netplusPay(values); // Create a new netpluspay class
			netplusPay2.beginTransaction();
			this.setState({
				isLoading: false,
			  });
		} else {
			setTimeout(() => {
				this.setState({
				  isLoading: false,
				  showmsg: "Opps! Server or network error"
				});
			}, 100);
		}

		// netpluspayPOP.setup({
		// 	merchant: `${process.env.REACT_APP_MERCHANT_ID}`,
		// 	customer_name: "Customer",
		// 	email: "N/A",
		// 	amount: finalamount,
		// 	currency_code: "NGN",
		// 	narration: "Airtime & Data Purchase",
		// 	order_id: `DATA${shortid.generate()}`,
		// 	container: "paymentFrame",
		// 	callBack: function(resp) {
		// 		if (resp && resp.status === "CAPTURED") {
		// 			this.setState({
		// 				modalResponse: resp.status,
		// 				transactionID: resp.trans_id
		// 			});
		// 		} else if (resp && resp.status === "FAILED") {
		// 			this.setState({
		// 				modalResponse: resp.status,
		// 				transactionID: resp.trans_id
		// 			});
		// 		}
		// 		//this.closeIframe();
		// 	}.bind(this),
		// 	onClose: function() {
		// 		this.setState({ isLoading: true });
		// 		this.handleWindowClose();
		//
		// 	}.bind(this)
		// });
		// netpluspayPOP.prepareFrame();
		//	}
		//else {
		// 	let showMsg = document.getElementById("show-msg");
		// 	showMsg.innerHTML = "Invalid Form";
		// 	showMsg.style.display = "block";
		// 	showMsg.style.fontSize = "18px";
		// 	showMsg.style.color = "red";
		// 	this.setState({ isLoading: false });
		// }
	};

	handleWindowClose = () => {
		if (this.state.modalResponse === "FAILED") {
			this.setState({
				isLoading: false,
				showmsg: "Transaction Failed"
			});
			this.nextStep();
		} else if (this.state.modalResponse === "CAPTURED") {
			this.handleBuyAirtime();
		} else if (this.state.modalResponse === "" || null){
			// let showMsg = document.getElementById("show-msg");
			// showMsg.innerHTML = "Error";
			// showMsg.style.display = "block";
			// showMsg.style.fontSize = "18px";
			// showMsg.style.color = "red";
			this.setState({ isLoading: false });
		}
	};

	handleBuyAirtime = () => {
		//if (formValid(this.state.formErrors)) {
			const amount1 = parseInt(this.state.amount);

			let data = {
				destinationAccount: this.state.destinationAccount,
				amount: amount1,
				service: this.state.service,
				provider: this.state.provider,
				trans_id: this.state.transactionID
			};

			setTimeout(() => {
				buyAirtime(data).then(response => {
					if (response && response.status === 200) {
						this.setState({
							isLoading: false,
							showmsgSuccess: "Transaction Successful"
						});
						this.nextStep();
					} else if (response && response.status === 400) {
						this.setState({
							isLoading: false,
							showmsg: "Transaction Failed"
						});
					} else {
						this.setState({
							isLoading: false,
							showmsg: "Server Error"
						});
					}
				});
			}, 4000);
		// } else {
		// 	this.setState({
		// 		isLoading: false,
		// 		showmsg: "Invalid Form"
		// 	});
		// }
	};

	render() {
		const { step } = this.state;
		const {
			formErrors,
			isLoading,
			category,
			provider,
			destinationAccount,
			confirmDestination,
			amount,
			service,
			selectedDataPlan,
			dataProvider,
			dataPlan,
			showmsg,
			showmsgSuccess
		} = this.state;

		const values = {
			formErrors,
			isLoading,
			category,
			provider,
			destinationAccount,
			confirmDestination,
			amount,
			service,
			selectedDataPlan,
			dataProvider,
			dataPlan,
			showmsg,
			showmsgSuccess
		};

		switch (step) {
			case 1:
				return (
					<MobileAirtime
						values={values}
						handleChange={this.handleChange}
						handleCheck={this.handleCheck}
						handlePayment={this.handlePayment}
						handleFormChange={this.handleFormChange}
					/>
				);

				case 2:
					return (
						<ConfirmNumber
							values={values}
							prevStep={this.prevStep}
							handleChange={this.handleChange}
							handleCheck={this.handleCheck}
							handlePayment={this.handlePayment}
							// changeProvider={this.changeProvider}
							// changeDataPlan={this.changeDataPlan}
							handleFormChange={this.handleFormChange}
							handleBuyAirtime={this.handleBuyAirtime}
						/>
					);

			// case 2:
			// 	return (
			// 		<MobileData
			// 			values={values}
			// 			handleChange={this.handleChange}
			// 			handleCheck={this.handleCheck}
			// 			handlePayment={this.handlePayment}
			// 			changeProvider={this.changeProvider}
			// 			changeDataPlan={this.changeDataPlan}
			// 			handleFormChange={this.handleFormChange}
			// 		/>
			// 	);

				case 3:
				return (
					<AirtimeSummary
						values={values}
						handleChange={this.handleChange}
						handleCheck={this.handleCheck}
						handlePayment={this.handlePayment}
						changeProvider={this.changeProvider}
						changeDataPlan={this.changeDataPlan}
						handleFormChange={this.handleFormChange}
					/>
				);

				case 4:
				return (
					<BillsTransactionFailure
						values={values}
						handleChange={this.handleChange}
						handleCheck={this.handleCheck}
						handlePayment={this.handlePayment}
						changeProvider={this.changeProvider}
						changeDataPlan={this.changeDataPlan}
						handleFormChange={this.handleFormChange}
					/>
				);
		}
	}
}

export default Airtime;
