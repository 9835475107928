import React from "react";

class BuyPower extends React.Component {
	constructor(props) {
		super(props);
	}


	render() {
		const { values } = this.props;

		return (
			<React.Fragment>
				<form className="airtime-form" onSubmit={this.props.billPayment}>
					<p id="show-msg"></p>
					<span className="fee">{"Convenience Fee: " + values.convenienceFee}</span>
					<div class="form-group airtime-form-input">
						<input
							type="text"
							class="form-control"
							name="accountName"
							onChange={this.props.handleChange("accountName")}
							value={values.accountName}
							placeholder="ENTER FULL NAME"
							disabled={values.isLoading}
							required
						/>
					</div>

					<div class="form-group airtime-form-input">
						<input
							type="email"
							class="form-control"
							id="exampleInputEmail1"
							aria-describedby="emailHelp"
							placeholder="EMAIL ADDRESS"
							onChange={this.props.handleChange("email")}
							defaultValue={values.email}
							disabled= {values.isLoading}
							required
						/>
					</div>
					<div class="form-group airtime-form-input">
						<input
							type="number"
							class="form-control"
							placeholder="METER NUMBER"
							name= "destinationAccount"
							onChange={this.props.handleChange("destinationAccount")}
							defaultValue={values.destinationAccount}
							disabled= {values.isLoading}
							required
						/>
					</div>

					<div class="form-group airtime-form-input">
						<input
							type="number"
							class="form-control"
							placeholder="AMOUNT"
							name="amount"
							onChange={this.props.handleChange("amount")}
							defaultValue={values.amount}
							disabled= {values.isLoading}
							required
						/>
					</div>


					<button
						type="submit"
						className="btn btn-block airtime-form-input btn-style"
						disabled={values.isLoading}
					>
						{values.isLoading ? "LOADING…" : "PAY"}
					</button>
				</form>
			</React.Fragment>
		);
	}
}

export default BuyPower;