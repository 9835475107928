import React, {useState} from "react";
import shortid from "shortid";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormText,
  FormFeedback
} from "reactstrap";
import { decodeJwt } from "../../utilities/processJWT";
// import { netpluspayPOP } from "./netfun";
import failedIcon from "../../assets/img/danger-512.png";
import successIcon from "../../assets/img/successIcon.png";
import { topUpPower, pingMommas, getServices, getUserServices, payServiceFee } from "../../utilities/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Chatbot from "react-chatbot-kit";
import 'react-chatbot-kit/build/main.css';
import "../../components/Chatbot/App.css";
import config from "../../components/Chatbot/config";
import MessageParser from "../../components/Chatbot/MessageParser";
import ActionProvider from "../../components/Chatbot/ActionProvider";
import chatbot from "../../components/Chatbot/assets/chatbot.png";
import { boolean } from "yup";


class PayForServices extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      payload: [],
      showBot: false,
      isLoading: false,
      value: "",
      userSummary: [],
      amount: "",
      serviceCharge: 50,
      removeTimeOut: null,
      isComplete: false,
      modal: false,
      transfer_reference: null,
      transferFinalStatus: null,
      formType: {},
      modalTitle: "",
      firstText: "",
      secondText: "",
      threeText: "",
      buttonText: "",
      windowsClosed: false,
      meterNumber: decodeJwt().meter_number,
      validate: {
        amountState: '',
      },
      services: [],
      disableFields: true,
      service: "",
      duration: "",
      amountToPay: "",
      status: "",
      fine: null
    };

    this.handleTopUps = this.handleTopUps.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleNteplusPay = this.handleNteplusPay.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    const token= localStorage.getItem('token');
    const fine= localStorage.getItem('fine');
    const decoded= decodeJwt(token);
    console.log("asd", decoded.status);
    const status = decoded.status;

    this.setState({
      isLoading: true,
      disableFields: true,
      status: status,
      fine: fine
    })
    // On component mount import netpluspay script
    const script = document.createElement("script");
    script.src = 'https://js.netpluspay.com/inline.js';
    script.charset = "utf-8";
    script.async = true;
    script.onload = this.scriptLoaded();
    document.head.appendChild(script);


   

    this.handleGetAllServices();
  }
  scriptLoaded = () => {
    this.setState({ netplusPayReady: true });
  };

  componentDidUpdate(prevProps, prevState) {

    // if (this.state.windowsClosed) {
    //   this.handleTopUps();
    // }
    if (prevState.isComplete !== this.state.isComplete) {

      clearTimeout(this.state.removeTimeOut); // clear the timeoutID
    }
  }

  handleGetAllServices = () => {
    console.log("i'm lost", this.state.status);
    // this.setState({ isLoading: true });
    getUserServices().then((response) => {
      if (response && response.status == 200 || response && response.status === 304) {
        console.log("resp", response.data);
        setTimeout(() => {
          this.setState({
            services: response.data,
            isLoading: false
          })
        }, 500);
      } else {
        this.setState({ payload: response, isLoading: false });
      }
    });
  }

  handleChangeService = (event) => {
    console.log("pick", event.target.value, this.state.services);
    this.setState({
      amount: "",
      amountToPay: ""
    })
    
    const val = event.target.value;

    if(val === "fine") {
      this.setState({
        service: val,
        isLoading: false
      })
      console.log(this.state);
    } else {
      const services= this.state.services;
      console.log("ddd", services);
      var result= services.filter(x=> {return x.name == event.target.value})
      console.log("result", result[0]);
      setTimeout(() => {
        this.setState({
          service: val,
          amount: result[0].amount,
          isLoading: false
        })
        console.log(this.state);
      }, 2000)
    }
    
    
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  validateAmount(e) {
    const amountRex = /^([2-8][0-9]{2}|9[0-8][0-9]|99[0-9]|[1-8][0-9]{3}|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9]|[1-8][0-9]{4}|9[0-8][0-9]{3}|99[0-8][0-9]{2}|999[0-8][0-9]|9999[0-9]|[1-8][0-9]{5}|9[0-8][0-9]{4}|99[0-8][0-9]{3}|999[0-8][0-9]{2}|9999[0-8][0-9]|99999[0-9]|[1-8][0-9]{6}|9[0-8][0-9]{5}|99[0-8][0-9]{4}|999[0-8][0-9]{3}|9999[0-8][0-9]{2}|99999[0-8][0-9]|999999[0-9]|[1-8][0-9]{7}|9[0-8][0-9]{6}|99[0-8][0-9]{5}|999[0-8][0-9]{4}|9999[0-8][0-9]{3}|99999[0-8][0-9]{2}|999999[0-8][0-9]|9999999[0-9]|100000000)$/i;
    const { validate } = this.state
      if (amountRex.test(e.target.value)) {
        validate.amountState = 'has-success'
      } else {
        validate.amountState = 'has-danger'
      }
      this.setState({ validate })
 }

  handleChange(event) {
    // this.setState({
    //   amount: ""
    // })
    this.setState({ amount: event.target.value });
  }

  handleChangeDuration =(event) =>{
    console.log(this.state);
    const val = event.target.value;
    const am = this.state.amount;
    console.log("nawa", val);
    let amountToPay = (parseInt(val) * am);

    console.log("amy", amountToPay);
    setTimeout(() => {
      this.setState({ duration: val, amountToPay: amountToPay });
    }, 1000)
    
  }




  handleTopUps (params) {
    console.log("called");
  //   e.preventDefault();
  //   const params = {
        
  //     code: '00',
  //     status: 'CAPTURED',
  //     transId: 'NP0906987h43y6t84j4hs09',
  //     result: 'SUCCESS',
  //     orderId: 'POWsd0edh73209b',
  //     amount: 5000,
  //     message: 'Approved',
  //     gtb_transaction_number: 351710944
    

  // }
    if (typeof params === "object" && params !== null) {
      console.log("here");
      this.setState({
        isLoading: true,
      });
      const serviceId= this.state.service;
      // const serviceId = "fine";
      const duration = parseInt(this.state.duration);
      const days = 30 * duration;
      const durationInMs = days; 
      // * 24*60*60*1000;
     console.log("call pay service fee", durationInMs);
     if(serviceId === "Service Charge"){
      payServiceFee({ transfer_reference: params, serviceId, duration: durationInMs, serviceCharge: parseFloat(params.amount)-50 }).then((response) => {
        console.log("even here", typeof(response.status), response.status);
        this.state.removeTimeOut = setTimeout(() => {
        if (response && response.status === 200) {
          this.setState({
            modalTitle: "PAYMENT SUCCESSFUL",

            firstText: `Your payment is successful`,
            secondText:
            `If you have any question or concern call +2348183888999, send us email at support@netpluspay.com or chat with us on our website.`,
            threeText: `For complaints please include the following information (Registered Name, Registered Email, Amount Paid, Sreenshot if any)`,
            buttonText: "Close",
            modal: !this.state.modal,
            isComplete: true,
            isLoading: false,
            windowsClosed: false,
            amount: "",
          });
        } else if (response && response.status === 400 ) {
          this.setState({
            modalTitle: "PAYMENT FAILED",
            firstText:
              "Your payment has failed and your transaction didn't go through.",
            secondText:
              `If you have any question or concern call +2348183888999, send us email at support@netpluspay.com or chat with us on our website.`,
            threeText: `For complaints please include the following information (Registered Name, Registered Email, Amount Paid, Sreenshot if any)`,
            buttonText: "Try again?",
            modal: !this.state.modal,
            isComplete: true,
            isLoading: false,
            windowsClosed: false,
            amount: "",
          });
        }
        else if (
          response &&
          response.status === 400
          // response.data.reason !==
          // "request cannot be processed now because payment transaction result is not 'success'"
        ) {
          this.setState({
            modalTitle: "PAYMENT FAILED",
            firstText:
              "Your payment was not successful. ",
            secondText:
            `If you have any question or concern call +2348183888999, send us email at support@netpluspay.com or chat with us on our website.`,
            threeText: `For complaints please include the following information (Registered Name, Registered Email, Amount Paid, Sreenshot if any)`,
            buttonText: "Close",
            modal: !this.state.modal,
            isComplete: true,
            isLoading: false,
            windowsClosed: false,
            amount: "",
          });
        } else {
          this.setState({
            modalTitle: "PAYMENT FAILED",
            firstText:
              "Your payment was not successful. ",
            secondText:
            `If you have any question or concern call +2348183888999, send us email at support@netpluspay.com or chat with us on our website.`,
            threeText: `For complaints please include the following information (Registered Name, Registered Email, Amount Paid, Sreenshot if any)`,
            buttonText: "Close",
            modal: !this.state.modal,
            isComplete: true,
            isLoading: false,
            windowsClosed: false,
            amount: "",
          });
        }
      },4000);
    });
     }else if (serviceId === "Development Levy") {
      payServiceFee({ transfer_reference: params, serviceId, duration: durationInMs, devLevy: parseFloat(params.amount)-50 }).then((response) => {
        this.state.removeTimeOut = setTimeout(() => {
        if (response && response.status === 200) {
          this.setState({
            modalTitle: "PAYMENT SUCCESSFUL",

            firstText: `Your payment is successful`,
            secondText:
            `If you have any question or concern call +2348183888999, send us email at support@netpluspay.com or chat with us on our website.`,
            threeText: `For complaints please include the following information (Registered Name, Registered Email, Amount Paid, Sreenshot if any)`,
            buttonText: "Close",
            modal: !this.state.modal,
            isComplete: true,
            isLoading: false,
            windowsClosed: false,
            amount: "",
          });
        } else if (response && response.status === 400 ) {
          this.setState({
            modalTitle: "PAYMENT FAILED",
            firstText:
              "Your payment has failed and your transaction didn't go through.",
            secondText:
              `If you have any question or concern call +2348183888999, send us email at support@netpluspay.com or chat with us on our website.`,
            threeText: `For complaints please include the following information (Registered Name, Registered Email, Amount Paid, Sreenshot if any)`,
            buttonText: "Try again?",
            modal: !this.state.modal,
            isComplete: true,
            isLoading: false,
            windowsClosed: false,
            amount: "",
          });
        }
        else if (
          response &&
          response.status === 400
          // response.data.reason !==
          // "request cannot be processed now because payment transaction result is not 'success'"
        ) {
          this.setState({
            modalTitle: "PAYMENT FAILED",
            firstText:
              "Your payment was not successful. ",
            secondText:
            `If you have any question or concern call +2348183888999, send us email at support@netpluspay.com or chat with us on our website.`,
            threeText: `For complaints please include the following information (Registered Name, Registered Email, Amount Paid, Sreenshot if any)`,
            buttonText: "Close",
            modal: !this.state.modal,
            isComplete: true,
            isLoading: false,
            windowsClosed: false,
            amount: "",
          });
        } else {
          this.setState({
            modalTitle: "PAYMENT FAILED",
            firstText:
              "Your payment was not successful. ",
            secondText:
            `If you have any question or concern call +2348183888999, send us email at support@netpluspay.com or chat with us on our website.`,
            threeText: `For complaints please include the following information (Registered Name, Registered Email, Amount Paid, Sreenshot if any)`,
            buttonText: "Close",
            modal: !this.state.modal,
            isComplete: true,
            isLoading: false,
            windowsClosed: false,
            amount: "",
          });
        }
      });
    }, 4000);
     } else if (serviceId === "fine") {
      payServiceFee({ transfer_reference: params, serviceId, duration: durationInMs, fine: parseFloat(params.amount)-50 }).then((response) => {
        console.log("even here");
        this.state.removeTimeOut = setTimeout(() => {
        if (response && response.status === 200) {
          this.setState({
            modalTitle: "PAYMENT SUCCESSFUL",

            firstText: `Your payment is successful`,
            secondText:
            `If you have any question or concern call +2348183888999, send us email at support@netpluspay.com or chat with us on our website.`,
            threeText: `For complaints please include the following information (Registered Name, Registered Email, Amount Paid, Sreenshot if any)`,
            buttonText: "Close",
            modal: !this.state.modal,
            isComplete: true,
            isLoading: false,
            windowsClosed: false,
            amount: "",
          });
        } else if (response && response.status === 400 ) {
          this.setState({
            modalTitle: "PAYMENT FAILED",
            firstText:
              "Your payment has failed and your transaction didn't go through.",
            secondText:
              `If you have any question or concern call +2348183888999, send us email at support@netpluspay.com or chat with us on our website.`,
            threeText: `For complaints please include the following information (Registered Name, Registered Email, Amount Paid, Sreenshot if any)`,
            buttonText: "Try again?",
            modal: !this.state.modal,
            isComplete: true,
            isLoading: false,
            windowsClosed: false,
            amount: "",
          });
        }
        else if (
          response &&
          response.status === 400
          // response.data.reason !==
          // "request cannot be processed now because payment transaction result is not 'success'"
        ) {
          this.setState({
            modalTitle: "PAYMENT FAILED",
            firstText:
              "Your payment was not successful. ",
            secondText:
            `If you have any question or concern call +2348183888999, send us email at support@netpluspay.com or chat with us on our website.`,
            threeText: `For complaints please include the following information (Registered Name, Registered Email, Amount Paid, Sreenshot if any)`,
            buttonText: "Close",
            modal: !this.state.modal,
            isComplete: true,
            isLoading: false,
            windowsClosed: false,
            amount: "",
          });
        } else {
          this.setState({
            modalTitle: "PAYMENT FAILED",
            firstText:
              "Your payment was not successful. ",
            secondText:
            `If you have any question or concern call +2348183888999, send us email at support@netpluspay.com or chat with us on our website.`,
            threeText: `For complaints please include the following information (Registered Name, Registered Email, Amount Paid, Sreenshot if any)`,
            buttonText: "Close",
            modal: !this.state.modal,
            isComplete: true,
            isLoading: false,
            windowsClosed: false,
            amount: "",
          });
        }
      });
    }, 4000);
     }
    //   payServiceFee({ transfer_reference: params, serviceId, duration: durationInMs, serviceCharge: params.amount }).then((response) => {
    //     console.log("even here");
    //     this.state.removeTimeOut = setTimeout(() => {
    //     if (response && response.status === 200) {
    //       this.setState({
    //         modalTitle: "PAYMENT SUCCESSFUL",

    //         firstText: `Your payment is successful`,
    //         secondText:
    //         `If you have any question or concern call +2348183888999, send us email at support@netpluspay.com or chat with us on our website.`,
    //         threeText: `For complaints please include the following information (Registered Name, Registered Email, Amount Paid, Sreenshot if any)`,
    //         buttonText: "Close",
    //         modal: !this.state.modal,
    //         isComplete: true,
    //         isLoading: false,
    //         windowsClosed: false,
    //         amount: "",
    //       });
    //     } else if (response && response.status === 400 ) {
    //       this.setState({
    //         modalTitle: "PAYMENT FAILED",
    //         firstText:
    //           "Your payment has failed and your transaction didn't go through.",
    //         secondText:
    //           `If you have any question or concern call +2348183888999, send us email at support@netpluspay.com or chat with us on our website.`,
    //         threeText: `For complaints please include the following information (Registered Name, Registered Email, Amount Paid, Sreenshot if any)`,
    //         buttonText: "Try again?",
    //         modal: !this.state.modal,
    //         isComplete: true,
    //         isLoading: false,
    //         windowsClosed: false,
    //         amount: "",
    //       });
    //     }
    //     else if (
    //       response &&
    //       response.status === 400
    //       // response.data.reason !==
    //       // "request cannot be processed now because payment transaction result is not 'success'"
    //     ) {
    //       this.setState({
    //         modalTitle: "PAYMENT FAILED",
    //         firstText:
    //           "Your payment was not successful. ",
    //         secondText:
    //         `If you have any question or concern call +2348183888999, send us email at support@netpluspay.com or chat with us on our website.`,
    //         threeText: `For complaints please include the following information (Registered Name, Registered Email, Amount Paid, Sreenshot if any)`,
    //         buttonText: "Close",
    //         modal: !this.state.modal,
    //         isComplete: true,
    //         isLoading: false,
    //         windowsClosed: false,
    //         amount: "",
    //       });
    //     } else {
    //       this.setState({
    //         modalTitle: "PAYMENT FAILED",
    //         firstText:
    //           "Your payment was not successful. ",
    //         secondText:
    //         `If you have any question or concern call +2348183888999, send us email at support@netpluspay.com or chat with us on our website.`,
    //         threeText: `For complaints please include the following information (Registered Name, Registered Email, Amount Paid, Sreenshot if any)`,
    //         buttonText: "Close",
    //         modal: !this.state.modal,
    //         isComplete: true,
    //         isLoading: false,
    //         windowsClosed: false,
    //         amount: "",
    //       });
    //     }
    //   });
    // }, 4000);


  }
  }

  handleNteplusPay(event) {
    console.log("hi");
    event.preventDefault();
    function hi() { return 0x64; }
    let showError = document.getElementById("show-error");
    let finalAmount = 0;
    const { amount } = this.state;
    // if (amount === "" || amount < 200) {
    //   this.setState({
    //     amount: "",
    //   });
    //   return;
    // } else {
    // if (amount !== null && amount >= 300) {
    //   showError.style.display = "none";
    // }

    finalAmount = this.state.amountToPay;
    console.log("finalAmount", finalAmount);
      // ? parseInt(amount, 10) + hi()
      // : this.state.amount;

    this.setState({
      isLoading: true,
    });
  
    if(this.state.service === "fine") {
      const values = { // Values to give netpluspay class
        success: (message) => {
          this.handleTopUps(message);
          console.log("success", message)
        },
        failed: (message) => {
          console.log("failed", message)
          this.handleTopUps(message);
        },
        cancel: (message) => {
          console.log(message)
          // netplusRequestCompleted = true;
        },
        data: {
          merchantId: `${process.env.REACT_APP_MERCHANT_ID}`,
          name: `${decodeJwt().email}`, //Don't make it long so amount can show
          email: `${decodeJwt().email}`,
          narration: "Services Payment",
          amount: this.state.fine,
          currency: "NGN",
          orderId: `SERVICES${shortid.generate()}`,
        }
      }

      if (this.state.netplusPayReady) { // Check if netpluspay script is loaded
        console.log("loaded");
        let netplusPay = window.NetplusPay; // Call the netpluspay function
        let newNetplusPay = new netplusPay(values) // Create a new netpluspay class
        newNetplusPay.beginTransaction(); // Call the beginTransaction function
        this.setState({
          isLoading: false,
        });
      }
  //   } else {
  //     setTimeout(() => {
  //       this.setState({
  //         isLoading: false,
  //       });
  //       let showMsg = document.getElementById("show-error");
  //       showMsg.innerHTML = "Opps! Server or network error";
  //       showMsg.style.display = "block";
  //     }, 100);
  //   }
  // })
  // .catch(function (error) {
    else {
    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
      let showMsg = document.getElementById("show-error");
      showMsg.innerHTML = "There was a server error. This could be caused by a slow network connection or the service not responding to request at the moment. Please try again later";
      showMsg.style.display = "block";
    }, 100);
  }
    }else {
      const values = { // Values to give netpluspay class
        success: (message) => {
          this.handleTopUps(message);
          console.log("success", message)
        },
        failed: (message) => {
          console.log("failed", message)
          this.handleTopUps(message);
        },
        cancel: (message) => {
          console.log(message)
          // netplusRequestCompleted = true;
        },
        data: {
          merchantId: `${process.env.REACT_APP_MERCHANT_ID}`,
          name: `${decodeJwt().email}`, //Don't make it long so amount can show
          email: `${decodeJwt().email}`,
          narration: "Services Payment",
          amount: this.state.status==="occupied" ? (finalAmount + 50) : ((finalAmount+50)/2),
          currency: "NGN",
          orderId: `SERVICES${shortid.generate()}`,
        }
      }

      if (this.state.netplusPayReady) { // Check if netpluspay script is loaded
        console.log("loaded");
        let netplusPay = window.NetplusPay; // Call the netpluspay function
        let newNetplusPay = new netplusPay(values) // Create a new netpluspay class
        newNetplusPay.beginTransaction(); // Call the beginTransaction function
        this.setState({
          isLoading: false,
        });
      }
  //   } else {
  //     setTimeout(() => {
  //       this.setState({
  //         isLoading: false,
  //       });
  //       let showMsg = document.getElementById("show-error");
  //       showMsg.innerHTML = "Opps! Server or network error";
  //       showMsg.style.display = "block";
  //     }, 100);
  //   }
  // })
  // .catch(function (error) {
    else {
    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
      let showMsg = document.getElementById("show-error");
      showMsg.innerHTML = "There was a server error. This could be caused by a slow network connection or the service not responding to request at the moment. Please try again later";
      showMsg.style.display = "block";
    }, 100);
  }
    }
   
      //     const values = { // Values to give netpluspay class
      //       success: (message) => {
      //         this.handleTopUps(message);
      //         console.log("success", message)
      //       },
      //       failed: (message) => {
      //         console.log("failed", message)
      //         this.handleTopUps(message);
      //       },
      //       cancel: (message) => {
      //         console.log(message)
      //         // netplusRequestCompleted = true;
      //       },
      //       data: {
      //         merchantId: `${process.env.REACT_APP_MERCHANT_ID}`,
      //         name: `${decodeJwt().email}`, //Don't make it long so amount can show
      //         email: `${decodeJwt().email}`,
      //         narration: "Services Payment",
      //         amount: this.state.status==="occupied" ? (finalAmount) : (finalAmount/2),
      //         currency: "NGN",
      //         orderId: `SERVICES${shortid.generate()}`,
      //       }
      //     }

      //     if (this.state.netplusPayReady) { // Check if netpluspay script is loaded
      //       console.log("loaded");
      //       let netplusPay = window.NetplusPay; // Call the netpluspay function
      //       let newNetplusPay = new netplusPay(values) // Create a new netpluspay class
      //       newNetplusPay.beginTransaction(); // Call the beginTransaction function
      //       this.setState({
      //         isLoading: false,
      //       });
      //     }
      // //   } else {
      // //     setTimeout(() => {
      // //       this.setState({
      // //         isLoading: false,
      // //       });
      // //       let showMsg = document.getElementById("show-error");
      // //       showMsg.innerHTML = "Opps! Server or network error";
      // //       showMsg.style.display = "block";
      // //     }, 100);
      // //   }
      // // })
      // // .catch(function (error) {
      //   else {
      //   setTimeout(() => {
      //     this.setState({
      //       isLoading: false,
      //     });
      //     let showMsg = document.getElementById("show-error");
      //     showMsg.innerHTML = "There was a server error. This could be caused by a slow network connection or the service not responding to request at the moment. Please try again later";
      //     showMsg.style.display = "block";
      //   }, 100);
      // }
      // });
    // }
  }

  render() {
    const { isLoading, windowsClosed, amount, duration, serviceCharge, service, services, disableFields, amountToPay } = this.state;
    return (
      <div className="content">
        <div id="paymentFrame" class="m-0 mx-auto"></div>
        <Row>
          <Col md={10} className="mx-auto">
            <Card className="demo-icons">
              <CardHeader>
                <CardTitle className="text-plus">Pay For Services</CardTitle>
              </CardHeader>
              <CardBody className="all-icons">
                <Form onSubmit={ (e) => this.handleNteplusPay(e) }>
                {/* <Form onSubmit={ (e) => this.handleTopUps(e) }> */}
                  <h3 id="show-error"></h3>

                  <FormGroup>
                    <Label for="meterNumb">Select Service</Label>
                    <select
                    class="form-control custom-select"
                    id="exampleFormControlSelect1"
                    name="service"
                    // placeholder="SELECT NETWORK"
                    onChange={this.handleChangeService}
                    value={service}
                    disabled={isLoading}
                    //multiple={true}
                    required
                  >
                    <option>SELECT SERVICE</option>
                   {
                      services.map((user)=> (
                        <option value={user.name}>{user.name}</option>
                      ))
                    }
                    <option value="fine">Fine</option>
                  </select>
                  </FormGroup>

                  {
                    this.state.service === "fine" ? (
                      
                      <div>
                  <FormGroup>
                    <Label for="service_charge">Amount To Pay</Label>
                    <Input
                      type="text"
                      disabled="true"
                      name="amountToPay"
                      // disabled={isLoading}
                      placeholder={this.state.fine}
                      // value={this.state.status==="occupied"? (amountToPay): (amountToPay/2)}
                      value={this.state.fine}
                      id="service_charge"
                      // onChange={this.handleChange}
                    />
                  </FormGroup>
                  {/* <FormGroup>
                    <Label for="topupAmount">Amount</Label>
                    <Input
                      type="text"
                      name="amount"
                      required
                      id="topupAmount"
                      value={this.state.status==="occupied"? (amount): (amount/2)}
                      placeholder="Enter amount"
                      onChange={this.handleChange}
                      disabled={ disableFields}
                      // value={ amount }
                      valid={ this.state.validate.amountState === 'has-success' }
                      invalid={ this.state.validate.amountState === 'has-danger' }
                      onChange={ (e) => {
                            this.validateAmount(e)
                            this.handleChange(e)
                      } }
                    />

               <FormFeedback valid>
                
              </FormFeedback>
              <FormFeedback>
              Amount is less than 300
              </FormFeedback>
                  </FormGroup> */}
                   <FormGroup>
                    <Label for="service_charge">Convenience Fee</Label>
                    <Input
                      type="text"
                      disabled="true"
                      name="service_charge"
                      placeholder="₦50"
                      value={serviceCharge}
                      id="service_charge"
                    />
                  </FormGroup>
                      </div>
                    ) : (
                      <div>
                        {
                        this.state.service === "Development Levy" ? (
                          <div>
                             <FormGroup>
                    <Label for="topupAmount">Amount</Label>
                    <Input
                      type="text"
                      name="amount"
                      required
                      id="topupAmount"
                      value={amount}
                      placeholder="Enter amount"
                      onChange={this.handleChange}
                      disabled={ disableFields}
                      // value={ amount }
                      valid={ this.state.validate.amountState === 'has-success' }
                      invalid={ this.state.validate.amountState === 'has-danger' }
                      onChange={ (e) => {
                            this.validateAmount(e)
                            this.handleChange(e)
                      } }
                    />

               <FormFeedback valid>
                
              </FormFeedback>
              <FormFeedback>
              Amount is less than 300
              </FormFeedback>
                  </FormGroup>

                  <FormGroup>
                    <Label for="meterNumb">Please Select Number of Months</Label>
                    <select
                    class="form-control custom-select"
                    id="exampleFormControlSelect1"
                    name="duration"
                    // placeholder="SELECT NETWORK"
                    onChange={this.handleChangeDuration}
                    value={duration}
                    disabled={isLoading}
                    //multiple={true}
                    required
                  >
                  <option>SELECT MONTH</option>
                    <option value="1">ONE MONTH</option>
                    <option value="2">TWO MONTHS</option>
                    <option value="3">THREE MONTHS</option>
                    <option value="4">FOUR MONTHS</option>
                    <option value="5">FIVE MONTHS</option>
                    <option value="6">SIX MONTHS</option>
                    <option value="7">SEVEN MONTHS</option>
                    <option value="8">EIGHT MONTHS</option>
                    <option value="9">NINE MONTHS</option>
                    <option value="10">TEN MONTHS</option>
                    <option value="11">ELEVEN MONTHS</option>
                    <option value="12">TWELVE MONTHS</option>
                    
                  </select>
                  </FormGroup>
                  <FormGroup>
                    <Label for="service_charge">Amount To Pay</Label>
                    <Input
                      type="text"
                      disabled="true"
                      name="amountToPay"
                      disabled={ disableFields}
                      // placeholder="₦100"
                      value={amountToPay}
                      id="service_charge"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="service_charge">Convenience Fee</Label>
                    <Input
                      type="text"
                      disabled="true"
                      name="service_charge"
                      placeholder="₦50"
                      value={serviceCharge}
                      id="service_charge"
                    />
                  </FormGroup>
                          </div>
                        ) : 
                        (
                          <div>
                            <FormGroup>
                    <Label for="topupAmount">Amount</Label>
                    <Input
                      type="text"
                      name="amount"
                      required
                      id="topupAmount"
                      value={this.state.status==="occupied"? (amount): (amount/2)}
                      placeholder="Enter amount"
                      onChange={this.handleChange}
                      disabled={ disableFields}
                      // value={ amount }
                      valid={ this.state.validate.amountState === 'has-success' }
                      invalid={ this.state.validate.amountState === 'has-danger' }
                      onChange={ (e) => {
                            this.validateAmount(e)
                            this.handleChange(e)
                      } }
                    />

               <FormFeedback valid>
                
              </FormFeedback>
              <FormFeedback>
              Amount is less than 300
              </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="meterNumb">Please Select Number of Months</Label>
                    <select
                    class="form-control custom-select"
                    id="exampleFormControlSelect1"
                    name="duration"
                    // placeholder="SELECT NETWORK"
                    onChange={this.handleChangeDuration}
                    value={duration}
                    disabled={isLoading}
                    //multiple={true}
                    required
                  >
                  <option>SELECT MONTH</option>
                    <option value="1">ONE MONTH</option>
                    <option value="2">TWO MONTHS</option>
                    <option value="3">THREE MONTHS</option>
                    <option value="4">FOUR MONTHS</option>
                    <option value="5">FIVE MONTHS</option>
                    <option value="6">SIX MONTHS</option>
                    <option value="7">SEVEN MONTHS</option>
                    <option value="8">EIGHT MONTHS</option>
                    <option value="9">NINE MONTHS</option>
                    <option value="10">TEN MONTHS</option>
                    <option value="11">ELEVEN MONTHS</option>
                    <option value="12">TWELVE MONTHS</option>
                    
                  </select>
                  </FormGroup>
                  <FormGroup>
                    <Label for="service_charge">Amount To Pay</Label>
                    <Input
                      type="text"
                      disabled="true"
                      name="amountToPay"
                      disabled={ disableFields}
                      // placeholder="₦100"
                      value={this.state.status==="occupied"? (amountToPay): (amountToPay/2)}
                      id="service_charge"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="service_charge">Convenience Fee</Label>
                    <Input
                      type="text"
                      disabled="true"
                      name="service_charge"
                      placeholder="₦50"
                      value={serviceCharge}
                      id="service_charge"
                    />
                  </FormGroup>

                          </div>
                        )
                      }

                  {/* <FormGroup>
                    <Label for="topupAmount">Amount</Label>
                    <Input
                      type="text"
                      name="amount"
                      required
                      id="topupAmount"
                      value={this.state.status==="occupied"? (amount): (amount/2)}
                      placeholder="Enter amount"
                      onChange={this.handleChange}
                      disabled={ disableFields}
                      // value={ amount }
                      valid={ this.state.validate.amountState === 'has-success' }
                      invalid={ this.state.validate.amountState === 'has-danger' }
                      onChange={ (e) => {
                            this.validateAmount(e)
                            this.handleChange(e)
                      } }
                    />

               <FormFeedback valid>
                
              </FormFeedback>
              <FormFeedback>
              Amount is less than 300
              </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="meterNumb">Please Select Number of Months</Label>
                    <select
                    class="form-control custom-select"
                    id="exampleFormControlSelect1"
                    name="duration"
                    // placeholder="SELECT NETWORK"
                    onChange={this.handleChangeDuration}
                    value={duration}
                    disabled={isLoading}
                    //multiple={true}
                    required
                  >
                  <option>SELECT MONTH</option>
                    <option value="1">ONE MONTH</option>
                    <option value="2">TWO MONTHS</option>
                    <option value="3">THREE MONTHS</option>
                    <option value="4">FOUR MONTHS</option>
                    <option value="5">FIVE MONTHS</option>
                    <option value="6">SIX MONTHS</option>
                    <option value="7">SEVEN MONTHS</option>
                    <option value="8">EIGHT MONTHS</option>
                    <option value="9">NINE MONTHS</option>
                    <option value="10">TEN MONTHS</option>
                    <option value="11">ELEVEN MONTHS</option>
                    <option value="12">TWELVE MONTHS</option>
                    
                  </select>
                  </FormGroup>
                  <FormGroup>
                    <Label for="service_charge">Amount To Pay</Label>
                    <Input
                      type="text"
                      disabled="true"
                      name="amountToPay"
                      disabled={ disableFields}
                      // placeholder="₦100"
                      value={this.state.status==="occupied"? (amountToPay): (amountToPay/2)}
                      id="service_charge"
                    />
                  </FormGroup> */}


                      </div>
                    )
                  }


                  {/* <FormGroup>
                    <Label for="topupAmount">Amount</Label>
                    <Input
                      type="text"
                      name="amount"
                      required
                      id="topupAmount"
                      value={this.state.status==="occupied"? (amount): (amount/2)}
                      placeholder="Enter amount"
                      onChange={this.handleChange}
                      disabled={ disableFields}
                      // value={ amount }
                      valid={ this.state.validate.amountState === 'has-success' }
                      invalid={ this.state.validate.amountState === 'has-danger' }
                      onChange={ (e) => {
                            this.validateAmount(e)
                            this.handleChange(e)
                      } }
                    />

               <FormFeedback valid>
                
              </FormFeedback>
              <FormFeedback>
              Amount is less than 300
              </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="meterNumb">Please Select Number of Months</Label>
                    <select
                    class="form-control custom-select"
                    id="exampleFormControlSelect1"
                    name="duration"
                    // placeholder="SELECT NETWORK"
                    onChange={this.handleChangeDuration}
                    value={duration}
                    disabled={isLoading}
                    //multiple={true}
                    required
                  >
                  <option>SELECT MONTH</option>
                    <option value="1">ONE MONTH</option>
                    <option value="2">TWO MONTHS</option>
                    <option value="3">THREE MONTHS</option>
                    <option value="4">FOUR MONTHS</option>
                    <option value="5">FIVE MONTHS</option>
                    <option value="6">SIX MONTHS</option>
                    <option value="7">SEVEN MONTHS</option>
                    <option value="8">EIGHT MONTHS</option>
                    <option value="9">NINE MONTHS</option>
                    <option value="10">TEN MONTHS</option>
                    <option value="11">ELEVEN MONTHS</option>
                    <option value="12">TWELVE MONTHS</option>
                    
                  </select>
                  </FormGroup>
                  <FormGroup>
                    <Label for="service_charge">Amount To Pay</Label>
                    <Input
                      type="text"
                      disabled="true"
                      name="amountToPay"
                      disabled={ disableFields}
                      // placeholder="₦100"
                      value={this.state.status==="occupied"? (amountToPay): (amountToPay/2)}
                      id="service_charge"
                    />
                  </FormGroup> */}


                  <FormGroup>
                    <div class="text-center">
                      <Button
                        className="btn btn-lg bg-green text-uppercase"
                        type="submit"
                        disabled={isLoading}
                      >
                        Pay
                        {/* {isLoading ? `Processing...  ` : "Pay"} */}
                        {windowsClosed && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                      </Button>
                    </div>
                  </FormGroup>
                </Form>
              </CardBody>
              {/* {this.state.formType === "Sign in" && ( */}
              <div>
                <Modal
                  centered={true}
                  isOpen={this.state.modal}
                  toggle={this.toggle}
                  scrollable={true}
                  backdrop={"static"}
                >
                  <ModalHeader className="modal-title" toggle={this.toggle}>
                    <p>
                      {this.state.modalTitle === "PAYMENT FAILED" ? (
                        <img
                          className="img-fluid mx-auto d-block"
                          src={failedIcon}
                          alt="Failed icon"
                        />
                      ) :
                        this.state.modalTitle === "PAYMENT SUCCESSFUL" ? (
                          <img
                            className="img-fluid mx-auto d-block"
                            src={successIcon}
                            alt="Success icon"
                          />
                        ) :
                          this.state.modalTitle === "PAYMENT PENDING" ? (
                            <span className="align-middle"><FontAwesomeIcon icon={"circle-notch"} /> </span>
                          )
                            :
                            "Server error"
                      }
                    </p>
                    <p>{this.state.modal && this.state.modalTitle}</p>
                  </ModalHeader>
                  <ModalBody
                    style={{
                      backgroundColor: "white",
                      margin: "30px",
                      borderRadius: "5px",
                    }}
                  >
                    <p>{this.state.modal && this.state.firstText}</p>
                    <p>{this.state.modal && this.state.secondText}</p>
                    <p>{this.state.modal && this.state.threeText}</p>
                  </ModalBody>
                  <ModalFooter
                    style={{
                      alignSelf: "center",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                    }}
                  >
                    <div class="text-center">
                      <Button
                        className="bg-green"
                        style={{
                          color: "white",
                          textTransform: "none",
                          fontSize: "1.5em",
                        }}
                        onClick={this.toggle}
                      >
                        {this.state.modal && this.state.buttonText}
                      </Button>
                    </div>
                  </ModalFooter>
                </Modal>
              </div>
            </Card>
          </Col>
        </Row>

        {/* </div>
        )} */}
        <div className="Chatbot">
        {this.state.showBot && ( <Chatbot  config ={config} messageParser = {MessageParser} actionProvider = {ActionProvider} /> )}
        </div>
        <button className="chatbot-button" onClick={(e) => this.setState({showBot: !this.state.showBot}) } style = {{backgroundColor: this.state.showBot ? '#28a745' : 'white'}}>
          {/*<img src="https://uploads.codesandbox.io/uploads/user/7a2de391-f332-46d7-87b3-6f582bb6f731/Yko8-Icon.png"/>*/}
          <img src={chatbot}  alt="botIcon" className ="chatbot-icon"/>
        </button>
      </div>
    );
  }
}

export default PayForServices;