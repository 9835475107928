import React from "react";

class DstvBill extends React.Component {
	constructor(props) {
		super(props);
	}

	saveAndContinue = e => {
		e.preventDefault();

		if (
			this.props.values.phone.length > 11 ||
			this.props.values.phone.length < 11
		) {
			let showMsg = document.getElementById("dstv-msg");
			showMsg.innerHTML = "Check Phone Number";
			showMsg.style.display = "block";
			showMsg.style.fontSize = "18px";
			showMsg.style.color = "red";
		} else if (this.props.values.billPackage == "") {
			let showMsg = document.getElementById("dstv-msg");
			showMsg.innerHTML = "Please Choose a Package";
			showMsg.style.display = "block";
			showMsg.style.fontSize = "18px";
			showMsg.style.color = "red";
		} 
		else {
			return this.props.nextStep();
		}
	};

	back = e => {
		e.preventDefault();
		this.props.prevStep();
	};

	render() {
		const { values } = this.props;

		return (
			<React.Fragment>
				<form className="airtime-form" onSubmit={this.saveAndContinue}>
					<p id="dstv-msg"></p>
					<span className="fee">{"Convenience Fee: " + values.payload}</span>
					<div class="form-group airtime-form-input">
						<input
							type="email"
							class="form-control"
							id="exampleInputEmail1"
							aria-describedby="emailHelp"
							placeholder="EMAIL ADDRESS"
							onChange={this.props.handleChange("email")}
							defaultValue={values.email}
							required
						/>
					</div>
					<div class="form-group airtime-form-input">
						<input
							type="tel"
							class="form-control"
							placeholder="PHONE NUMBER"
							onChange={this.props.handleChange("phone")}
							defaultValue={values.phone}
							required
						/>
					</div>

					<div class="form-group airtime-form-input">
						<select
							class="form-control custom-select"
							id="exampleFormControlSelect1"
							placeholder="SELECT NETWORK"
							onChange={this.props.changebillPackage}
							value={values.selectedBillPackage}
							//multiple={true}
							required
						>
							<option >SELECT PACKAGE</option>
							{values.billPackage.map((e, key) => {
								return <option key={key}>{e.name}</option>;
							})}
						</select>
					</div>

					<button
						type="submit"
						className="btn airtime-form-input btn-style"
						onClick={this.back}
					>
						BACK
					</button>

					<button type="submit" className="btn airtime-form-input btn-style">
						CONTINUE
					</button>
				</form>
			</React.Fragment>
		);
	}
}

export default DstvBill;