import React from "react";
import {
  Row,
  CardText,
  Col,
  Button,
  Form,
  FormGroup,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Label,
  Input,
  Table,
} from "reactstrap";
import { CSVLink } from "react-csv";
import ErrorPage from "../../../../utilities/errorPage";
import { FormatTime } from "../../../../utilities/currency_time_formata";
import {arrTypeCheck} from "../../../../utilities/dataTypeCheck";
import Pagination from "../../../../utilities/Pagination";
import Placeholder from "../../../../utilities/placeholder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./MobileView.css";

import {
  fetchEstateTrHistory,
  fetchEstatesTrSummary,
  searchEstateTrHistory,
  fetchServiceTrHistory,
  fetchDevLevyTrHistory
} from "../../../../utilities/api";

class DevLevyTransactions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: null,
      endDate: null,
      payload: null,
      headers: [],
      value: 10,
      isLoading: true,
      search: false,
      sort: "dsc",
      success: false,
      currentTransactions: [],
      currentPage: null,
      totalPages: null,
      activePage: "",
      name: "",
      meter_number: "",
      transactionSummary: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.getTransactions = this.getTransactions.bind(this);
    this.searchTransactions = this.searchTransactions.bind(this);
    this.getSummary = this.getSummary.bind(this);
    this.onPageChanged = this.onPageChanged.bind(this);
  }

  componentWillMount() {
    this.getTransactions();
    // this.getSummary();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.payload !== this.state.payload) {
      this.getSummary();
    }
  }

  onPageChanged = (data) => {
    const { payload } = this.state;
    const { currentPage, totalPages, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    const currentTransactions = payload.slice(offset, offset + pageLimit);
    this.setState({ currentPage, currentTransactions, totalPages });
  };

  viewResident(e) {
    window.location.reload(true);
  }

  handleChange = (event) => {
    if (event.target.name === "startDate") {
      this.setState({ startDate: event.target.value });
    } else if (event.target.name === "endDate") {
      this.setState({ endDate: event.target.value });
    } else {
      return;
    }
  };

  handleRefresh(event) {
    event.preventDefault();
    let showMsg = document.getElementById("show-error");
    this.searchTransactions({ refresh: true });
  }

  handleSubmit(event) {
    event.preventDefault();
    let showMsg = document.getElementById("show-error");
    event.preventDefault();
    const { startDate, endDate, sort } = this.state;
    this.searchTransactions({ from: startDate, to: endDate, sort: sort });
  }

  getSummary() {
    this.setState({ isLoading: true });
    fetchEstatesTrSummary().then((response) => {
      if (response && response.status == 200 || response && response.status === 304) {
        setTimeout(() => {
          this.setState({
            transactionSummary: response.data,
            isLoading: false,
          });
        }, 500);
      } else {
        this.setState({ transactionSummary: response, isLoading: false });
      }
    });
  }

  getTransactions() {
    this.setState({ isLoading: true });
    fetchDevLevyTrHistory().then((response) => {
      if (response && response.status == 200 || response && response.status === 304) {
        setTimeout(() => {
          this.setState({
            payload: response.data,
            isLoading: false,
            headers: [
              { label: "Transaction Value", key: "amount" },
              { label: "Meter Number", key: "meterNumber" },
              { label: "Transaction Date", key: "createdAt" },
              { label: "Transaction Status", key: "transactionStatus" },
              { lable: "Transaction ID", key: "payment_transaction_id" },
            ],
          });
          // let le = document.getElementById("download-option");
          // le.style.display = "block";
        }, 500);
      } else {
        this.setState({ payload: response, isLoading: false });
      }
    });
  }

  searchTransactions(params) {
    this.setState({ isLoading: true });
    searchEstateTrHistory(params).then((response) => {
      if (response && response.status == 200 || response && response.status === 304) {
        setTimeout(() => {
          this.setState({
            payload: response.data.array,
            isLoading: false,
            headers: [
              { label: "Transaction Value", key: "amount" },
              { label: "Resident name", key: "user_name" },
              { label: "Meter Number", key: "meter_number" },
              { label: "Transaction Date", key: "createdAt" },
              { label: "Transaction Status", key: "transactionStatus" },
              { label: "Transaction Type", key: "transaction_type" },
              { label: "Transaction Ref", key: "topupRetryReference" },
              { label: "Token Status", key: "topupStatus" },
              { label: "Token", key: "token" },
              { lable: "Transaction ID", key: "payment_transaction_id" },
            ],
          });
          // let le = document.getElementById("download-option");
          // le.style.display = "block";
        }, 500);
      } else {
        this.setState({ payload: response, isLoading: false });
      }
    });
  }


  menuToggle = (event) => {
    console.log("toggled", event.target.value );
  }

  render() {
    const { props } = this.props;
    const {
      payload,
      isLoading,
      search,
      currentTransactions,
      transactionSummary,
    } = this.state;
    return (
      <div className="p-5 bg-white">
        {!isLoading && !payload ? (
          <div>
            <ErrorPage />
          </div>
        ) : (
          <div>
            {isLoading ? (
              <div>
                <Placeholder />
              </div>
            ) : (
              <div id ="bigger">

                <Row xs="1" sm="2" md="5" className="bg-green p-3">
                  <Col className="remove_shadow">
                    <div className="text-font-bold">
                      <span className="text-font-bold-white">
                        TOTAL NUMBER OF SUCCESSFUL
                      </span>
                      <span className="text-font-bold btn-span">
                        TRANSACTIONS
                      </span>
                      <span className="text-number-white btn-span">
                        {!transactionSummary
                          ? " - "
                          : transactionSummary.total_number_successful_transactions}
                      </span>
                    </div>
                  </Col>
                  <Col>
                    <div className="text-font-bold">
                      <span className="text-font-bold-white">
                        TOTAL VALUE OF SUCCESSFUL
                      </span>
                      <span className="text-font-bold btn-span">
                        TRANSACTIONS
                      </span>
                      <span className="text-number-white btn-span">
                        {!transactionSummary
                          ? " - "
                          : transactionSummary.total_successful_transactions_value}
                      </span>
                    </div>
                  </Col>
                  <Col>
                    <div className="text-font-bold">
                      <span className="text-font-bold-white">
                      TOTAL NUMBER OF
                      </span>
                      <span className="text-font-bold btn-span">
                      METERS
                      </span>
                      <span className="text-number-white btn-span">
                        {!transactionSummary
                          ? " - "
                          : transactionSummary.total_meter_numbers}
                      </span>
                    </div>
                  </Col>
                  <Col className="remove_shadow">
                    <div className="text-font-bold">
                      <span className="text-font-bold-white">
                        TOTAL NUMBER OF FAILED
                      </span>
                      <span className="text-font-bold btn-span">
                        TRANSACTIONS
                      </span>
                      <span className="text-number-white btn-span">
                        {!transactionSummary
                          ? " - "
                          : transactionSummary.total_number_failed_transactions}
                      </span>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <p>
                        <span className="text-font-bold-white">
                          LAST TRANSACTION{" "}
                        </span>
                        <span className="text-font-bold btn-span">DATE</span>
                        <span className="text-white font-weight-bolder mt-2 btn-span">
                          {!transactionSummary
                            ? " - "
                            : 
                              transactionSummary.last_transaction_date
                              }
                        </span>
                      </p>
                    </div>
                  </Col>
                </Row>
                <Form className="mt-5" onSubmit={this.handleSubmit}>
                  <h3 id="show-error">Server error, please try again</h3>
                  <Row id="tContainer" form xs="3" sm="3" md="3">
                    <Col id="firstCol" md={2} className="my-auto">
                      <FormGroup>
                        <UncontrolledButtonDropdown>
                          <DropdownToggle
                            className="bg-white text-body rounded border"
                            caret
                          >
                            SORT BY
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={(e) => this.setState({ sort: "asc" })}
                            >
                              NEWEST
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem
                              onClick={(e) => this.setState({ sort: "dsc" })}
                            >
                              OLDEST
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledButtonDropdown>
                      </FormGroup>
                    </Col>
                    <Col id="secondCol" md={3} className="mt-2 my-auto mr-2">
                      <FormGroup>
                        <div className="wrap mt-1">
                          <div className="search">
                            <Label className="searchButtonFrom">FROM</Label>
                            <Input
                              type="date"
                              name="startDate"
                              value={this.state.startDate}
                              required
                              className="searchTerm"
                              placeholder="date placeholder"
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        {/* <Input
                      type="date"
                      name="date"
                      id="exampleDate"
                      placeholder="date placeholder"
                      onChange={this.handleChange}
                    /> */}
                      </FormGroup>
                    </Col>
                    <Col id="thirdCol" md={3} className="mt-2 mr-5 my-auto">
                      <FormGroup>
                        <div className="wrap mt-1">
                          <div className="search">
                            <Label className="searchButtonTo">TO</Label>
                            <Input
                              type="date"
                              name="endDate"
                              value={this.state.endDate}
                              required
                              className="searchTerm"
                              placeholder="date placeholder"
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col id="fourthCol" md={2}>
                      <FormGroup>
                        <Button
                          type="submit"
                          style={{
                            color: "white",
                            backgroundColor: "#1DE054",
                            textTransform: "none",
                          }}
                          className="border"
                        >
                          SEARCH
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                <div className="mt-5">
                  <Button
                    onClick={this.handleRefresh}
                    type="button"
                    style={{
                      color: "white",
                      backgroundColor: "black",
                      textTransform: "none",
                    }}
                    className="border"
                  >
                    <FontAwesomeIcon icon={"sync"} />
                    &nbsp;Refresh
                  </Button>
                </div>
                <Table id="tableSmaller" size="md" bordered={true} responsive ={true} className="mt-2 grey-primary table-hover">
                  <thead className="bg-green">
                    <tr>
                      
                      <th>METER NUMBER</th>
                      <th>AMOUNT</th>
                      
                      <th>STATUS</th>
                      <th>TRANSACTION ID</th>
                      <th>DATE</th>
                    </tr>
                  </thead>
                  {currentTransactions.map((transaction) => (
                    <tbody>
                      <tr
                        // onClick={() => {
                        //   localStorage.setItem("resident", transaction.user_id);
                        //   this.viewResident();
                        // }}
                        key={transaction.id}
                        role="row"
                        className="odd"
                      >
                       
                        <td>{transaction.meterNumber}</td>
                        <td>{`₦${transaction.amount}`}</td>
                        {/* <td>{transaction.result}</td> */}

                        <td>{transaction.netpluspay_message}</td>
                        
                        <td>{transaction.payment_transaction_id}</td>
                        
                        <td>{FormatTime(transaction.createdAt)}</td>
                      </tr>
                    </tbody>
                  ))}
                </Table>

                <div className="mt-5 d-flex">
                  <div className="d-flex">
                    {!isLoading && payload && (
                      <div
                        style={{
                          color: "blue",
                          fontWeight: "bolder",
                          fontSize: "larger",
                        }}
                      >
                        {!isLoading && arrTypeCheck(payload) ? (
                          <CSVLink
                            data={this.state.payload}
                            headers={this.state.headers}
                            filename={"Transactions.csv"}
                            className="text-body small font-weight-bold"
                          >
                            <FontAwesomeIcon icon={"file-export"} />
                            &nbsp;EXPORT
                          </CSVLink>
                        ) : null}
                      </div>
                    )}
                  </div>
                  {!isLoading && arrTypeCheck(payload) && (
                    <div className="ml-auto">
                      {
                        <Pagination
                          totalRecords={payload.length}
                          pageLimit={this.state.value}
                          pageNeighbours={1}
                          onPageChanged={this.onPageChanged}
                        />
                      }
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default DevLevyTransactions;
