import React from "react";
import DatePicker from "react-date-picker";
import { Form, FormGroup, Label, Input, Row, Col,  } from "reactstrap";
import Select from "react-select";
import { Formik } from "formik";
import * as Yup from "yup";
import { createServiceAdmin, createService, fetchEstates, createConvenienceFee } from "../../utilities/api";
import "./CreateNewFee.css";

const CreateForm = function (estates, handleEstateChange) {
  console.log("esss", estates);
  return (
    <Formik
      initialValues={{
        service_name: "",
        amount: "",
        estate: ""
      }}
      onSubmit={(values, { setSubmitting }, props) => {
        setTimeout(() => {
          createConvenienceFee({
            serviceName: values.service_name,
            amount: values.amount,
            estate: values.estate
          }).then((response) => {
            let showError = document.getElementById("show-error");
            if (response && response.status === 200 || response && response.status === 304) {
              showError.style.display = "block";
              showError.style.color = "green";
              showError.innerHTML = "Service created";
              setTimeout(() => {
                window.location.reload(true);
              }, 0);
            } else if (response && response.status === 400) {
              showError.style.display = "block";
              showError.innerHTML = "This service name already exist";
              setSubmitting(false);
            } else {
              showError.style.display = "block";
              showError.innerHTML = "Opps! Server error";
              setSubmitting(false);
            }
          });
        }, 1000);
      }}
      validationSchema={Yup.object().shape({
        service_name: Yup.string()
          .trim()
          .matches(
            /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!@-_?&%]{2,}$/,
            "At least two letters are expected"
          )
          .required("service name is required"),

          amount: Yup.string()
          .trim()
          .matches(
            /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!@-_?&%]{2,}$/,
            "At least two letters are expected"
          )
          .required("amount is required"),
      })}
    >
      
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        console.log("vvv", estates, estates.estates);
        return (
         
          // <div className="login-container">
          <Form onSubmit={handleSubmit} className="login-form">
            <h3 id="show-error">Input error</h3>
            <FormGroup>
            {/* <Select
                        type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="estate"
                          options={estates.estates.map((obj) => {
                            let optionObj = {
                              value: obj.id,
                              label: obj.name,
                            };
                            return optionObj;
                          })}
                          // value={estates.estates.filter(
                          //   (obj) => obj.value === values.estate
                          // )}
                          // onChange={this.handleEstateSelect}
                          placeholder="SELECT ESTATE"
                        /> */}

                        <select
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="estate"
                        value= {values.estate}
                        >
                        <option value="">SELECT ESTATE</option>
							
                          {
                            estates.estates.map((obj) => (    
							               <option value={obj.id}>{obj.name}</option>
                            ))
                          }
                        </select>
              {/* {errors.service_name && touched.service_name && (
                <div
                  style={{ color: "red", fontSize: ".8em" }}
                  className="input-feedback"
                >
                  {errors.service_name}
                </div>
              )} */}
            </FormGroup>
            <FormGroup>
              <Input
                name="service_name"
                type="text"
                placeholder="Enter Convenience Fee name"
                value={values.service_name}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.service_name && touched.service_name && "error"
                }
              />
              {errors.service_name && touched.service_name && (
                <div
                  style={{ color: "red", fontSize: ".8em" }}
                  className="input-feedback"
                >
                  {errors.service_name}
                </div>
              )}
            </FormGroup>
            <FormGroup>
              <Input
                name="amount"
                type="text"
                placeholder="Amount (Monthly)"
                value={values.amount}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.amount && touched.amount && "error"
                }
              />
              {errors.amount && touched.amount && (
                <div
                  style={{ color: "red", fontSize: ".8em" }}
                  className="input-feedback"
                >
                  {errors.amount}
                </div>
              )}
            </FormGroup>
            <button
              className="btn bg-green text-uppercase float-right"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Loading…" : "CREATE"}
            </button>
          </Form>
          // </div>
        );
      }}
    </Formik>
  );
};

class CreateNewFee extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
    payload: []
    }
  }

  componentDidMount = ()=> {
    this.getEstates();
  } 


  getEstates = ()=> {
    this.setState({ isLoading: true });
    fetchEstates().then((response) => {
      if (response && response.status == 200 || response && response.status === 304) {
        console.log("respp", response.data);
        setTimeout(() => {
          this.setState({
            payload: response.data,
            isLoading: false,
          });
          // let le = document.getElementById("download-option");
          // le.style.display = "block";
        }, 500);
        console.log("bbb", this.state.payload);
      } else {
        this.setState({ payload: response, isLoading: false });
      }
    });
  }


  handleChange = input => e => {
    console.log("I'm handling");
		e.preventDefault();

        // setTimeout(() => {
            this.setState({ [input]: e.target.value });
        // }, 500)
    
    };


  render() {

    
    return (
      <div>
        {/*--------------------------------------------------------------------------------*/}
        {/*Start Inner Div*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*Row*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* <div id="paymentFrame" class="m-0 mx-auto"></div> */}
        <Row id="resizeform2" className="bg-white center-div">
          <Col md={12} className="p-5 rounded">
            <h6>CREATE NEW FEE</h6>
            <CreateForm 
            estates={this.state.payload}
            handleEstateChange={this.handleChange}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default CreateNewFee;
