import React from "react";

class ConfirmUser extends React.Component {
	constructor(props) {
		super(props);
	}

	back = e => {
		//e.preventDefault();
		this.props.prevStep();
	};

	render() {
		const { values } = this.props;

		return (
			<React.Fragment>
				<form className="" onSubmit={this.props.billPayment}>

					<p className="show-msg">{values.showmsg}</p>
					<p className="show-msg-success">{values.showmsgSuccess}</p>
					<div class="form-group landing-form-input">
						<select
							class="landing-form-control form-control custom-select"
							id="exampleFormControlSelect1"
							placeholder="SELECT NETWORK"
							onChange={this.props.changeProvider}
							value={values.selectedProvider}
							disabled={values.isLoading}
							//multiple={true}
							required
						>
							<option>SELECT BILLER</option>
							{values.provider.map((e, key) => {
								return <option key={key}>{e.name}</option>;
							})}
						</select>
					</div>

{/* <div className="form-group landing-form-input">
						<input
							type="text"
							class="form-control landing-form-control"
							// placeholder="ACCOUNT NAME"
							//onChange={this.props.handleChange("accountName")}
							value={values.selectedProvider}
							disabled= {true}
							required
						/>
					</div> */}

					<div class="form-group landing-form-input">
						<select
							class="form-control landing-form-control custom-select"
							id="exampleFormControlSelect1"
							placeholder="SELECT NETWORK"
							onChange={this.props.changebillPackage}
							value={values.selectedBillPackage}
							disabled={values.isLoading}
							//multiple={true}
							required
						>
							<option>SELECT PACKAGE</option>
							{values.billPackage.map((e, key) => {
								return <option key={key}>{e.name}</option>;
							})}
							
						</select>
					</div>
					

					<div className="form-group landing-form-input">
						<input
							type="text"
							class="form-control landing-form-control"
							placeholder="ACCOUNT NAME"
							//onChange={this.props.handleChange("accountName")}
							defaultValue={values.billAccountIdDetails}
							disabled= {true}
							required
						/>
					</div>

					<div className="form-group landing-form-input">
						<input
							type="text"
							className="form-control landing-form-control"
							placeholder="SMARTCARD NUMBER"
							//onChange={this.props.handleChange("destinationAccount")}
							defaultValue={values.billAccountId}
							disabled= {true}
							required
						/>
					</div>

					<div className="form-group landing-form-input">
						<input
							type="email"
							className="form-control landing-form-control"
							id="exampleInputEmail1"
							aria-describedby="emailHelp"
							placeholder="EMAIL ADDRESS"
							onChange={this.props.handleChange("email")}
							defaultValue={values.email}
							disabled={values.isLoading}
							required
						/>
					</div>
					<span className="landing-fee">{"Convenience Fee: " + values.payload}</span>
					
					{/* <button
						type="submit"
						className="btn btn-dark landing-form-input"
						disabled={values.isLoading}
						onClick= {this.back}
					>
						{values.isLoading ? "LOADING…" : "BACK"}
					</button> */}

					<button
						type="submit"
						className="btn btn-dark btn-block landing-form-input"
						disabled={values.isLoading}
					>
						{values.isLoading ? "LOADING…" : "PAY"}
					</button>
				</form>
			</React.Fragment>
		);
	}
}

export default ConfirmUser;
