import React from "react";
import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Label,
  Input,
  Table,
  
} from "reactstrap";
import { CSVLink } from "react-csv";
import ErrorPage from "../../../utilities/errorPage";
import { FormatTime} from "../../../utilities/currency_time_formata";
import Pagination from "../../../utilities/Pagination";
import Placeholder from "../../../utilities/placeholder";
import { arrTypeCheck } from "../../../utilities/dataTypeCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  searchResidents,
  fetchEstateTrHistory,
  fetchAdminResidentDashboard,
  fetchAdminResidentHistory
} from "../../../utilities/api";
import PowerVending from "./AdminTransactions/PowerVending";
import DevLevy from "./AdminTransactions/DevLevy";
import ServicePayment from "./AdminTransactions/ServicePayment";
import "./AdminTransactions/MobileView.css";

class ResidentTransaction extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: null,
      endDate: null,
      payload: null,
      headers: [],
      value: 10,
      isLoading: false,
      search: false,
      viewTextInput: true,
      success: false,
      currentTransactions: [],
      currentPage: null,
      totalPages: null,
      activePage: "",
      name: "",
      meter_number: "",
      transactionSummary: {},
      userId: null,
      toggleView: "power vending"
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.menuToggle = this.menuToggle.bind(this);
    // this.handleSearch = this.handleSearch.bind(this);
    this.getTransactions = this.getTransactions.bind(this);
    this.getSummary = this.getSummary.bind(this);
    this.onPageChanged = this.onPageChanged.bind(this);
    // this.viewHistory = this.viewHistory.bind(this);
  }

  // viewHistory() {
  //   localStorage.removeItem("resi");
  //   window.location.reload();
  // }

  componentDidMount() {
    console.log("oti mount");
    this.getTransactions();
  }

  handletoggleView =()=> {
    switch (this.state.toggleView) {
      case "power vending":
        return <PowerVending />;
      case "dev levy":
        return <DevLevy />;
        case "service":
        return <ServicePayment />;
     
    }
  }


  	componentDidUpdate(prevProps, prevState) {
      if (prevState.userId !== this.state.userId && this.state.userId.users[0].id) {
        this.setState({
          isLoading: true
        });
        this.getTransactions({id: this.state.userId.users[0].id});
        this.getSummary({userId: this.state.userId.users[0].id});
      }
	}

  onPageChanged = (data) => {
    const { payload } = this.state;
    let sortedPayload = payload.transactions.slice().sort(function(a, b) {
      a = new Date(a.createdAt);
      b = new Date(b.createdAt);
      return a > b ? -1 : a < b ? 1 : 0;
    });
    const { currentPage, totalPages, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    const currentTransactions = sortedPayload.slice(offset, offset + pageLimit);
    this.setState({ currentPage, currentTransactions, totalPages });
  };

  menuToggle(e) {
    this.setState({
      viewTextInput: !this.state.viewTextInput,
    });
  }

  handleChange = (event) => {
    if (event.target.name === "name") {
      this.setState({ name: event.target.value });
    } else if (event.target === "endDate") {
      this.setState({ endDate: event.target.value });
    } else {
      return;
    }
  };

  handleSearch(params) {
    return searchResidents(params)
      .then((response) => {
        if (response && response.status === 200 && response.data.users[0].id ) {
          setTimeout(() => {
            const initialState = {
              userId: response.data,
              isLoading: false,
              search: false,
              value: 10,
              count: 0,
              currentTransactions: [],
              currentPage: null,
              totalPages: null,
              success: true,
            };
            this.setState(initialState);
            // let le = document.getElementById("download-option");
            // le.style.display = "block";
            // if (arrTypeCheck(response.data)) {
            //   document.getElementById("csv-download").style.display = "inline";
            // }
          }, 1000);
        } else {
          let showMsg = document.getElementById("show-error");
          showMsg.innerHTML = "User not found, please check and try again";
          showMsg.style.display = "block";
        }
      })
      .catch(function (error) {
        setTimeout(() => {
          let showMsg = document.getElementById("show-error");
          showMsg.innerHTML = "There was a server error. This could be caused by a slow network connection or the service not responding to request at the moment. Please try again later";
          showMsg.style.display = "block";
        }, 1000);
      });
  }

  handleSubmit(event) {
    event.preventDefault();
    // TODO
    let showMsg = document.getElementById("show-error");
    event.preventDefault();
    const { name} = this.state;
    if (name === "") {
      showMsg.innerHTML = "Please enter a name or email to search";
      showMsg.style.display = "block";
    } else {
      this.handleSearch({term: name});
    }
  }

  getSummary(param) {
    this.setState({ isLoading: true });
    fetchAdminResidentDashboard(param).then((response) => {
      if (response && response.status == 200 || response && response.status === 304) {
        setTimeout(() => {
          this.setState({
            transactionSummary: response.data,
            isLoading: false,
          });
        }, 500);
      } else {
        this.setState({ transactionSummary: response, isLoading: false });
      }
    });
  }

  getTransactions(params) {
    this.setState({ isLoading: true });
    fetchAdminResidentHistory(params).then((response) => {
      if (response && response.status == 200 || response && response.status === 304) {
        setTimeout(() => {
          console.log("resp", response.data);
          this.setState({
            payload: response.data,
            isLoading: false,
            headers: [
              { label: "Transaction Value", key: "amount" },
              { label: "Resident name", key: "residentName" },
              { label: "Meter Number", key: "meter_number" },
              { label: "Transaction Date", key: "createdAt" },
              { label: "Transaction Status", key: "transactionStatus" },
              { label: "Transaction Type", key: "transactionType" },
              { lable: "REF", key: "payment_transaction_id" },
            ],
          });
          // let le = document.getElementById("download-option");
          // le.style.display = "block";
        }, 500);
      } else {
        this.setState({ payload: response, isLoading: false });
      }
    });
  }

  // componentWillUnmount() {
  //   localStorage.removeItem("resi");
  // }

  render() {
    const { props } = this.props;
    const {
      payload,
      isLoading,
      search,
      currentTransactions,
      transactionSummary,
      currentPage,
      totalPages,
      estateArr,
    } = this.state;
    return (
      <div className="p-5 bg-white">

        <div>
          {/* <p className="lead">Search Residents</p> */}
       
            <Form className="mt-5" onSubmit={this.handleSubmit}>
              <h3 id="show-error">Server error, please try again</h3>
              {/* <Col md={2} className="my-auto">
                <FormGroup>
                  <UncontrolledButtonDropdown>
                    <DropdownToggle caret>SEARCH BY</DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={(e) =>
                          this.setState({ viewTextInput: "name" })
                        }
                      >
                        Name
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem
                        onClick={(e) =>
                          this.setState({ viewTextInput: "meter_number" })
                        }
                      >
                        Meter number
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                </FormGroup>
              </Col> */}
              <Row form xs="3" sm="3" md="3">
                {/* <Col md={7} className="mt-2 ml-3 my-auto"> */}
                {/* {estateArr  && (
                  <Select
                    isLoading={isLoading}
                    isSearchable={true}
                    name="Available estate"
                    options={estateArr.map((obj) => {
                      let optionObj = {
                        value: obj.id,
                        label: obj.name,
                      };
                      return optionObj;
                    })}
                    // value={estate_id .filter(
                    //   (obj) => obj.value === this.state.property_id
                    // )}
                    onChange={this.handleEstateSelect}
                    placeholder="SELECT ESTATE"
                    required
                  />
                )} */}
                    {/* <FormGroup>
                      <Input
                        type="text"
                        name="name"
                        onChange={this.handleChange}
                        id="exampleState"
                        placeholder="Resident name or email"
                      />
                    </FormGroup> */}
                {/* </Col> */}
                {/* <Col md={2}>
                  <FormGroup>
                    <Button
                      type="submit"
                      disabled={isLoading}
                      style={{
                        color: "white",
                        backgroundColor: "#1DE054",
                        textTransform: "none",
                      }}
                    >
                      {isLoading ? "SEARCHING.." : "SEARCH"}
                    </Button>
                  </FormGroup>
                </Col> */}
              </Row>
            </Form>
        </div>
        
        {!isLoading && !payload ? (
          <div>
            Enter a search term to find a resident
          </div>
        ) : (
          <div>
            {isLoading ? (
              <div>
                <Placeholder />
              </div>
            ) : (
              <div>
                <Row className="bg-dark pl-5 pr-5 d-flex justify-content-between">
                  <h5 className="text-font-bold-white mt-3">
                    <div
                      className="text-success mr-2 inline-div"
                      onClick={this.viewHistory}
                    >
                      <FontAwesomeIcon icon={"backward"} />
                    </div>
                    TRANSACTION HISTORY
                  </h5>
                  <UncontrolledButtonDropdown id="theDropDown">
                    <DropdownToggle
                      className="bg-white text-body rounded border"
                      caret
                    >
                      POWER VENDING
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem  onClick={(e) =>
                      this.setState({ toggleView: "power vending" })
                    }>
                        POWER VENDING
                      </DropdownItem>
                      <DropdownItem  onClick={(e) =>
                      this.setState({ toggleView: "service" })
                    }>
                       SERVICE CHARGE
                      </DropdownItem>
                      <DropdownItem  onClick={(e) =>
                      this.setState({ toggleView: "dev levy" })
                    }>
                       DEVELOPMENT LEVY
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                </Row>

                {this.handletoggleView()}

                {/* <Row xs="1" sm="2" md="5" className="bg-green p-3">
                  <Col className="remove_shadow">
                    <div className="text-font-bold">
                      <span className="text-font-bold-white">
                        TOTAL NUMBER OF SUCCESSFUL
                      </span>
                      <span className="text-font-bold btn-span">
                        TRANSACTIONS
                      </span>
                      <span className="text-number-white btn-span">
                        {!transactionSummary.transaction
                          ? " - "
                          : transactionSummary.transaction.numberOfSuccessfulTransactions}
                      </span>
                    </div>
                  </Col>
                  <Col>
                    <div className="text-font-bold">
                      <span className="text-font-bold-white">
                        TOTAL VALUE OF SUCCESSFUL
                      </span>
                      <span className="text-font-bold btn-span">
                        TRANSACTIONS
                      </span>
                      <span className="text-number-white btn-span">
                        {!transactionSummary.transaction
                          ? " - "
                          : transactionSummary.transaction.totalSuccessfulTransaction}
                      </span>
                    </div>
                  </Col>
                  <Col>
                    <div className="text-font-bold">
                      <span className="text-font-bold-white">
                        TOTAL VALUE OF
                      </span>
                      <span className="text-font-bold btn-span">
                       LAST TRANSACTIONS
                      </span>
                      <span className="text-number-white btn-span">
                        {!transactionSummary.transaction
                          ? " - "
                          : transactionSummary.transaction.lastTransactionValue}
                      </span>
                    </div>
                  </Col>
                  <Col className="remove_shadow">
                    <div className="text-font-bold">
                      <span className="text-font-bold-white">
                        TOTAL NUMBER OF FAILED
                      </span>
                      <span className="text-font-bold btn-span">
                        TRANSACTIONS
                      </span>
                      <span className="text-number-white btn-span">
                        {!transactionSummary.transaction
                          ? " - "
                          : transactionSummary.transaction.numberOfFailedTransactions}
                      </span>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <p>
                        <span className="text-font-bold-white">
                          LAST TRANSACTION{" "}
                        </span>
                        <span className="text-font-bold btn-span">DATE</span>
                        <span className="text-white font-weight-bolder mt-2 btn-span">
                          {!transactionSummary.transaction
                            ? " - "
                            : FormatTime(
                              transactionSummary.transaction.lastTransactionDate
                              )}
                        </span>
                      </p>
                    </div>
                  </Col>
                </Row> */}

                {/* <Form className="mt-5" onSubmit={this.handleSubmit}>
                  <h3 id="show-error">Server error, please try again</h3>
                  <Row form xs="3" sm="3" md="3">
                    <Col md={2} className="my-auto">
                      <FormGroup>
                        <UncontrolledButtonDropdown>
                          <DropdownToggle
                            className="bg-white text-body rounded border"
                            caret
                          >
                            SORT BY
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem onClick={this.menuToggle}>
                              ACTIVE
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem onClick={this.menuToggle}>
                              INACTIVE
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledButtonDropdown>
                      </FormGroup>
                    </Col>
                    <Col md={3} className="mt-2 my-auto mr-2">
                      <FormGroup>
                        <div className="wrap mt-1">
                          <div className="search">
                            <Label className="searchButtonFrom">FROM</Label>
                            <Input
                              type="date"
                              name="date"
                              className="searchTerm"
                              placeholder="date placeholder"
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={3} className="mt-2 mr-5 my-auto">
                      <FormGroup>
                        <div className="wrap mt-1">
                          <div className="search">
                            <Label className="searchButtonTo">To</Label>
                            <Input
                              type="date"
                              name="date"
                              className="searchTerm"
                              placeholder="date placeholder"
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Button
                          type="submit"
                          style={{
                            color: "white",
                            backgroundColor: "#1DE054",
                            textTransform: "none",
                          }}
                          className="border"
                        >
                          SEARCH
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form> */}
                {/* <Table className="mt-5 grey-bg p-5 table-hover table-bordered">
                  <thead>
                    <tr>
                      <th className="pl-4">RESIDENT NAME</th>
                      <th>METER NUMBER</th>
                      <th>TRANSACTION VALUE</th>
                      <th>STATUS</th>
                      <th>TOKEN</th>
                      <th>TYPE</th>
                      <th>BANK RESPONSE</th>
                      <th>RETRY REF</th>
                      <th>DATE</th>
                    </tr>
                  </thead>
                  {currentTransactions.map((transaction) => (
                    <tbody>
                      <tr key={transaction.id} role="row" className="odd">
                        <td className="sorting_1 pl-4">{transaction.user_name}</td>
                        <td>{transaction.meter_number}</td>
                        <td>{`₦${transaction.amount}`}</td>
                        <td>{transaction.netpluspay_message}</td>
                        <td>{transaction.token ? transaction.token : "-"}</td>
                        <td>{transaction.transaction_type}</td>
                        <td>{transaction.netplus_result}</td>
                        <td>{transaction.topupRetryReference ? transaction.topupRetryReference : "-"}</td>
                        <td>{FormatTime(transaction.createdAt)}</td>
                      </tr>
                    </tbody>
                  ))}
                </Table> */}

                {/* <div className="pagination-wrapper mt-5 d-flex"> */}
                  {/* <div
                    className="dataTables_info"
                    id="myTable_info"
                    role="status"
                    aria-live="polite"
                  >
                    {!isLoading && payload && (
                      <div
                        style={{
                          color: "blue",
                          fontWeight: "bolder",
                          fontSize: "larger",
                        }}
                      >
                        {!isLoading && payload ? (
                          <CSVLink
                            data={this.state.payload}
                            headers={this.state.headers}
                            filename={"Transactions.csv"}
                            className="text-body small font-weight-bold"
                          >
                            <FontAwesomeIcon icon={"file-export"} />
                            &nbsp;EXPORT
                          </CSVLink>
                        ) : null}
                      </div>
                    )}
                  </div> */}
                  {/* {!isLoading && payload && (
                    <div className="ml-auto">
                      {
                        <Pagination
                          totalRecords={payload.transactions.length}
                          pageLimit={this.state.value}
                          pageNeighbours={1}
                          onPageChanged={this.onPageChanged}
                        />
                      }
                    </div>
                  )} */}
                {/* </div> */}

              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default ResidentTransaction;
