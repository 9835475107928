/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React from "react";

import { Row, Col, Container, Button } from "reactstrap";
import emailImage from "../../assets/img/emailImage.png";

const EmailPage = (props) => {
  const goHome = () => {
    localStorage.clear();
    window.location.replace("/");
  }
  return (
    <div className="h-100 d-flex justify-content-center align-items-center email-bg h-100">
      {/* <Row className="bg-white rounded d-flex"> */}
      <Container className="themed-container" fluid={true}>
        <Row>
          {/* <Col container-xl> */}
          <Col sm="4" className="remove_shadow">
            <p>
              <img
                class="img-fluid mx-auto d-block"
                src={emailImage}
                alt="User avatar"
              />
            </p>
          </Col>
          <Col sm="6" className="remove_shadow">
            <di className="show-error"></di>
            <h6 className="text-number-green">REGISTRATION SUCCESSFUL</h6>
            <p className="text-font-bold">
              {`We emailed verification link to you`}
            </p>
            <p className="lead header-ul">{` Make sure to check your spam folder`}</p>
            <p className="text-font-bold">{`Didn't get an email?`}</p>
            <p className="lead header-ul">{`It's okay. You can still Sign In and update your email later`}</p>
            <Button
                className="btn btn-link text-success text-uppercase"
                type="submit"
                onClick={goHome}
              >
                <i className="nc-icon  nc-minimal-left" />
                Go back to Sign in page
              </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EmailPage;
