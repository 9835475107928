import React from "react";
import {
  Button,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Card,
  CustomInput,
  CardBody,
  Table,
  CardHeader,
} from "reactstrap";
import ErrorPage from "../../utilities/errorPage";


const token = localStorage.getItem("token");
class LeviesView extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
        databaseData: [],
        fetchError: false
      };

      this.errorHandler = this.errorHandler.bind(this);
  }
  
  componentDidMount() {
    fetch (`${process.env.REACT_APP_BASE_URL_ESTATES}/api/electricity-service/get-dev-levy`,{
      headers: {
        'Authorization': 'Bearer ' + token,
      },
    })
    .then(res => res.json())
    .then(
      (jsonData) => {
        this.setState({ databaseData: jsonData });
       // console.log(process.env);
        console.log(this.state.databaseData)
      },
      (error) => {
        alert(error);
        this.errorHandler()
      }
    )
  }

  errorHandler() {
    this.setState({
      fetchError: true
    });
  }

  render () {
    if (this.state.fetchError == false){

    return (
      <div className="content">
        <Card>
          <CardHeader className="w-100 bg-dark text-green p-3">DEVELOPMENT LEVIES</CardHeader>
          <CardBody>
            <Table bordered ={true} responsive ={true}>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Meter Number</th>
                  <th>Amount</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                </tr>
              </thead>
              <tbody>
                {this.state.databaseData.map(customer => 
                  <tr>
                    <td>{customer.id}</td>
                    <td>{customer.meterNumber}</td>
                    <td>{customer.amount}</td>
                    <td>{customer.createdAt}</td>
                    <td>{customer.updatedAt}</td>
                  </tr> )}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </div>
    );
                }
                else if (this.state.fetchError == true) {
                  return (
                    <div className="content">
                    <ErrorPage />
                    </div>

                  )
                }
  }
}

export default LeviesView
