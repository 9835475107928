/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from "react";
import { decodeJwt } from "../../../utilities/processJWT";
import { Formik } from "formik";
import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  CardText,
  CardImg,
  Container,
} from "reactstrap";
import { registerUser } from "../../../utilities/api";

let userObject = localStorage.getItem("userObject");

const Basic = () => (
  <div>
    <Formik
      initialValues={{ email: "", password: "" }}
      validate={(values) => {
        const errors = {};
        if (!values.email) {
          errors.email = "Required";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = "Invalid email address";
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 400);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <FormGroup row>
            <Col className="">
              <Input
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              {errors.email && touched.email && errors.email}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col className="">
              <Input
                type="password"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
              {errors.password && touched.password && errors.password}
            </Col>
          </FormGroup>
          <Button type="submit" disabled={isSubmitting} className="float-right">
            Submit
          </Button>
        </form>
      )}
    </Formik>
  </div>
);

const EmailPage = (props) => {
  return (
    <div className="h-100 d-flex justify-content-center align-items-center email-bg">
      <Col
        lg={{ size: "4" }}
        className="bg-green d-flex flex-column justify-content-center pb-5 rounded"
      >
        <p className="text-plus text-white align-self-end">NetPlusPay</p>
        <di className="show-error"></di>
        <h6 className="text-white pb-2 text-center">ADMIN</h6>
        <Basic />
      </Col>
    </div>
  );
};

export default EmailPage;
// id="outPopUp"
