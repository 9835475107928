
class MessageParser {
    constructor(actionProvider, state) {
      this.actionProvider = actionProvider;
      this.state = state;
    }
  
    parse(message) {

      const lowercase = message.toLowerCase()
    

      if(lowercase.includes("hello") || lowercase.includes("hi")) {
         this.actionProvider.helloHandler()
      } else if (lowercase.includes("help")) {
          this.actionProvider.helpHandler()
      } else if (lowercase.includes("debit")) {
        this.actionProvider.debitHandler()
      }

    }

  }
  
  export default MessageParser;