import React from "react";
import {
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  DropdownItem,
} from "reactstrap";
import RequestNewEstate from "./RequestNewEstate";
import EnableService from "./RequestService";
import { getServices } from "../../utilities/api";

class Estates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      headerTitle: "",
      viewEstate: "REQUEST NEW SERVICE",
      payload: []
    };

    this.menuToggle = this.menuToggle.bind(this);
  }

  // componentDidMount = () => {
  //   this.handleGetAllServices();
  // }

  

  menuToggle() {
    switch (this.state.viewEstate) {
      case "REQUEST NEW SERVICE":
        return <EnableService
        // options = {this.state.payload}
        />;
      case "REQUEST NEW ESTATE":
        return <RequestNewEstate />;
      default:
        return <EnableService />;
    }
  }

  

  render() {
    return (
      <div className="content">
        <div>
          <UncontrolledDropdown>
            <DropdownToggle caret>Menu</DropdownToggle>
            <DropdownMenu
              modifiers={{
                setMaxHeight: {
                  enabled: true,
                  order: 890,
                  fn: (data) => {
                    return {
                      ...data,
                      styles: {
                        ...data.styles,
                        overflow: "auto",
                        maxHeight: "100px",
                      },
                    };
                  },
                },
              }}
            >
              <DropdownItem
                onClick={(e) =>
                  this.setState({ viewEstate: "REQUEST NEW SERVICE" })
                }
              >
                Request New Service
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={(e) =>
                  this.setState({ viewEstate: "REQUEST NEW ESTATE" })
                }
              >
                Request New Estate
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        {/* <hr />
      <p>Link Based</p> */}
        <Row>
          <Col md={12}>{this.menuToggle()}</Col>
        </Row>
      </div>
    );
  }
}

export default Estates;
