import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, Redirect } from "react-router-dom";

import Header from "../../components/Header/Header.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Sidebar from "../../components/Sidebar/Sidebar.jsx";

import dashboardRoutes from "../../routes/dashboard";
import {getPropertyLogo} from "../../utilities/api";
import "./DashboardMobileView.css";


var ps;

class Dashboard extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      backgroundColor: "white",
      activeColor: "success",
      property_logo: localStorage.getItem('logo'),
      current_logo: null
    }
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
    if (!this.state.property_logo) {
      this.handleGetLogo()
    }
    this.setState({ current_logo: localStorage.getItem('logo')});
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      this.refs.mainPanel.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }
  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  }
  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  }

  handleGetLogo = () => {
    return getPropertyLogo()
      .then((response) => {
        if (response && response.status === 200 || response && response.status === 304) {
          setTimeout(() => {
            this.setState({ current_logo: response.data.property_logo});
            localStorage.setItem('logo',response.data.property_logo);
          }, 500);
        } else {
          this.setState({ property_logo: null});
        }
      })
      .catch(function (error) {
        this.setState({ property_logo: null });
      });
  }

  render() {
    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          routes={dashboardRoutes}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
          logo={this.state.property_logo || this.state.current_logo}
        />
        <div id="mainPanelView" className="main-panel" ref="mainPanel">
          <Header {...this.props} />
          <Switch>
            {dashboardRoutes.map((prop, key) => {
              // if (prop.name === "LOGOUT") {
              
              // }
              if (prop.redirect) {
                return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
              }
              return (
                <Route path={prop.path} component={prop.component} key={key} />
              );
            })}
          </Switch>
          <Footer fluid />
        </div>
      </div>
    );
  }
}

export default Dashboard;