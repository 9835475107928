import React from "react";
import { Table, Button } from "reactstrap";
import ResidentDetails from "./EstateResDetails";
import ErrorPage from "../../utilities/errorPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
class VewResident extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: false,
      user: null,
    };

    this.menuToggle = this.menuToggle.bind(this);
  }

  menuToggle() {
    this.setState({
      activePage: !this.state.activePage,
    });
  }

  render() {
    const { props } = this.props;
    return (
      <div>
        {!props ? (
          <div>
            <ErrorPage />
          </div>
        ) : (
          <div>
                        <div>
              <Button
                onClick={() => window.location.reload(true)}
                type="button"
                style={{
                  color: "black",
                  textTransform: "none",
                  float: "right",
                  // display: "none",
                }}
                id="invalidRefreshBtn"
                className="border btn-link"
              >
                <FontAwesomeIcon icon={"sync"} />
                &nbsp;Refresh
              </Button>
            </div>
            {this.state.activePage ? (
              <ResidentDetails props={this.state.user} />
            ) : (
              <Table hover responsive>
                <thead>
                  {this.props.props.users.map((user) => (
                    <tr className="table-active">
                      <th>
                        <p className="text-body font-weight-bolder ml-3 pt-3">{`${user.first_name} ${user.last_name}`}</p>
                      </th>
                      <th>
                        <Button
                          style={{
                            color: "white",
                            backgroundColor: "#1DE054",
                            textTransform: "none",
                          }}
                          onClick={() => {
                            localStorage.setItem("resi", user);
                            this.setState({ user: user });
                            this.menuToggle();
                          }}
                        >
                          DETAILS
                        </Button>
                      </th>
                    </tr>
                  ))}
                </thead>
              </Table>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default VewResident;
