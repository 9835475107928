import "./Options.css";
import React from "react";

function Options (props) {
    const options = [
        {text: "Token Issues", handler: props.actionProvider.tokenHandler, id: 1},
        {text: "Payment Issues", handler: props.actionProvider.paymentHandler, id: 2},
    //    {text: "Transaction", handler: props.actionProvider.transactionHandler, id: 3},
    ];

    const buttonsMarkup = options.map((option) => (
        <button key={option.id} onClick = {option.handler} className = "option-button">
            {option.text}
        </button>
    ));
    return <div className="option-container"> {buttonsMarkup}</div>;
};

export default Options;