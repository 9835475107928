import React from "react";
import ReactDOM from "react-dom";
// import { createBrowserHistory } from "history";
import { Router, Route, Switch, HashRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faWhatsappSquare } from "@fortawesome/free-brands-svg-icons";
import {
  faCheckSquare,
  faCoffee,
  faHome,
  faExternalLinkAlt,
  faUserPlus,
  faBolt,
  faQuestionCircle,
  faFileExport,
  faBackward,
  faExclamationTriangle,
  faSync,
  faUpload,
  faBars,
  faMobileAlt,
  faReceipt,
  faGlobe,
  faSpinner,
  faCircleNotch,
  faScroll,
  faPhone,
  faAt
} from "@fortawesome/free-solid-svg-icons";

import "./assets/index.scss";

// import "estates-netplus-frontend/src/assets/scss/paper-dashboard.scss";
// import "estates-netplus-frontend/src/assets/demo/demo.css";

import indexRoutes from "./routes/index";
import Home from "./components/HomePage/Home";
import LandingPage from "./components/LandingPage/LandingPage/LandingPage";
import SignInForm from "./components/modals/SignInForm";

import { verifyJwt } from "./utilities/processJWT";
import Emailpage from "./components/modals/EmailPage";
import ForgotPasswordPage from "./components/modals/ForgotPassword";
import NetplusAdmin from "./layouts/admin/netplus/LoginAdmin";
import VerfiedLogin from "./components/HomePage/LoginFromVerified";
import ResetPassword from "./components/HomePage/ResetPassword";
import Airtime from "./components/HomePage/Airtime";
import Bills from "./components/HomePage/Bills";
import Data from "./components/HomePage/Data";
import contact from "./components/modals/ContactModal";
library.add(
  faCheckSquare,
  faCoffee,
  faHome,
  faExternalLinkAlt,
  faUserPlus,
  faBolt,
  faQuestionCircle,
  faFileExport,
  faBackward,
  faExclamationTriangle,
  faSync,
  faUpload,
  faBars,
  faMobileAlt,
  faReceipt,
  faGlobe,
  faSpinner,
  faCircleNotch,
  faScroll,
  faPhone,
  faWhatsappSquare,
  faAt,
);
let loggedIn = localStorage.getItem("isLoggedIn");
// const hist = createBrowserHistory();
let hp = window.location.href;
const url = new URL(hp);

ReactDOM.render(
  <HashRouter>
    <Switch>
      <Route path="/admin" component={NetplusAdmin} />
      <Route path="/login" component={VerfiedLogin} />
      <Route path="/forgot-password" component={ForgotPasswordPage} />
      <Route path="/confirmEmail" component={Emailpage} />
      <Route path="/airtime" component={Airtime} />
      <Route path="/pay-bills" component={Bills} />
      <Route path="/data" component={Data} />
      <Route path="/contact" component={contact} />
      
      {indexRoutes.map((prop, key) => {
        return loggedIn === true || verifyJwt() ? (
          <Route path={prop.path} key={key} component={prop.component} />
        ) : (
          url.hash.includes("reset-password")? <Route exact path="/reset-password/:token" component={ResetPassword} /> :
          <LandingPage />
        );
        // return <Route path={prop.path} key={key} component={prop.component} />;
      })}
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);
