/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React from "react";
import { filterObj } from "../../utilities/filterObj";
import {
  Button,
  Modal,
  Card,
  CardHeader,
  CardText,
  CardBody,
  Row,
  Col,
  Input,
  Label,
  Form,
  FormGroup,
} from "reactstrap";
import { decodeJwt } from "../../utilities/processJWT";
import { resetPassword, updateProfile } from "../../utilities/api";

class ModalExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      newPassword: "",
      oldPassword: "",
      confirmPassword: "",
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      isLoading: false,
    };

    this.toggle = this.toggle.bind(this);
    this.handlePassSubmit = this.handlePassSubmit.bind(this);
    this.handleProfileSubmit = this.handleProfileSubmit.bind(this);
    this.handleOldPassword = this.handleOldPassword.bind(this);
    this.handleConfirmPassword = this.handleConfirmPassword.bind(this);
    this.handleNewPassword = this.handleNewPassword.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleNewPassword = (event) => {
    this.setState({
      newPassword: event.target.value,
    });
  };

  handleOldPassword = (event) => {
    this.setState({
      oldPassword: event.target.value,
    });
  };

  handleConfirmPassword = (event) => {
    this.setState({
      confirmPassword: event.target.value,
    });
  };

  handleChange = (event) => {
    if (event.target.name === "first_name") {
      this.setState({
        first_name: event.target.value,
      });
    }
    if (event.target.name === "last_name") {
      this.setState({
        last_name: event.target.value,
      });
    }

    if (event.target.name === "email") {
      this.setState({
        email: event.target.value,
      });
    }
    if (event.target.name === "phone") {
      this.setState({
        phone: event.target.value,
      });
    }
    return;
  };

  handlePassSubmit = (event) => {
    event.preventDefault();
    const { oldPassword, confirmPassword, newPassword } = this.state;
    if (newPassword !== confirmPassword || oldPassword === "") {
      let showError = document.getElementById("show-error");
      showError.style.display = "block";
      return;
    } else {
      this.setState({
        isLoading: true,
      });
      setTimeout(() => {
        resetPassword({
          newPassword: newPassword,
          oldPassword: oldPassword,
          userId: decodeJwt().userId,
        }).then((response) => {
          let showError = document.getElementById("show-error");
          if (response && response.status === 200) {
            showError.style.display = "block";
            showError.style.color = "#17a2b8";
            showError.style.fontWeight = "bolder"
            showError.innerHTML = "Password reset successful";
            this.setState({
              isLoading: false,
              newPassword: "",
              oldPassword: "",
              confirmPassword: ""
            });
            setTimeout(() => {
              localStorage.setItem("redirect", false);
              window.location.reload(true);
            }, 1000);
          } else if (response && response.status === 400) {
            showError.style.display = "block";
            showError.innerHTML = "Password does not match";
            this.setState({
              isLoading: false,
              newPassword: "",
              oldPassword: "",
              confirmPassword: ""
            });
          } else {
            showError.style.display = "block";
            showError.innerHTML = "Server or network error!";
            this.setState({
              isLoading: false,
              newPassword: "",
              oldPassword: "",
              confirmPassword: ""
            });
          }
        });
      }, 2000);
    }
  };

  handleProfileSubmit = (event) => {
    event.preventDefault();
    this.setState({
      isLoading: true,
    });
    const { first_name, last_name, email, phone } = this.state;
    let EditedInput = filterObj({
      first_name: first_name,
      last_name: last_name,
      email: email,
      phone: phone,
    });
    if (EditedInput === "empty") {
      let showError = document.getElementById("show-error");
      showError.style.display = "block";
      showError.innerHTML = "You've not made changes yet";
      this.setState({
        isLoading: false,
      });
    } else {
      setTimeout(() => {
        updateProfile(EditedInput).then((response) => {
          let showError = document.getElementById("show-error");
          if (response && response.status === 200) {
            this.setState({
              isLoading: false,
            });
            localStorage.setItem("redirect", false);
            window.location.reload(true);
          } else if (response && response.status === 400) {
            showError.style.display = "block";
            this.setState({
              isLoading: false,
            });
          } else {
            showError.style.display = "block";
            showError.innerHTML = "Server error or network error";
            this.setState({
              isLoading: false,
            });
          }
        });
      }, 2000);
    }
  };

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }
  render() {
    const { buttonLabel, className, background, userObj } = this.props;
    return (
      <div>
        <Button
          style={{ color: "white", backgroundColor: background }}
          onClick={this.toggle}
        >
          {buttonLabel}
        </Button>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={className}
          backdrop="static"
          keyboard="true"
        >
          {/* <Row className="bg-white rounded"> */}
            {/* <Col sm="6"> */}
              <Card className="card-user remove_shadow">
                <CardHeader>
                  <button
                    className="close"
                    style={{ float: "left", marginBottom: "20px" }}
                    onClick={this.toggle}
                  >
                    &times;
                  </button>
                </CardHeader>
                <CardBody>
                  <CardText className="font-weight-bolder lead">
                    {buttonLabel === "Update Profile"
                      ? "Edit Profile"
                      : "Change Password"}
                  </CardText>
                  {buttonLabel === "Update Profile" ? (
                    <Form
                      className="form-profile"
                      onSubmit={this.handleProfileSubmit}
                    >
                      <div>
                        <h3 id="show-error">Opps! Update not successful</h3>
                        <FormGroup>
                          <Label for="exampleFirst">FIRST NAME</Label>
                          <Input
                            type="text"
                            onChange={this.handleChange}
                            name="first_name"
                            id="exampleFirst"
                            placeholder={userObj.data.user.first_name}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="exampleLast">LAST NAME</Label>
                          <Input
                            type="text"
                            name="last_name"
                            onChange={this.handleChange}
                            id="exampleLast"
                            placeholder={userObj.data.user.last_name}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="exampleEmail">EMAIL</Label>
                          <Input
                            type="email"
                            name="email"
                            onChange={this.handleChange}
                            id="exampleEmail"
                            placeholder={userObj.data.user.email}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="examplePhone">PHONE</Label>
                          <Input
                            type="text"
                            name="phone"
                            onChange={this.handleChange}
                            id="examplePhone"
                            placeholder={userObj.data.user.phone}
                          />
                        </FormGroup>
                      </div>

                      <Row>
                        <div className="update">
                          <Button
                            type="submit"
                            className="bg-green ml-3"
                            round
                          >
                            {this.state.isLoading ? "..Loading" : "Save"}
                          </Button>
                        </div>
                      </Row>
                    </Form>
                  ) : (
                    <div>
                      <h3 id="show-error">Opps! Update not successful</h3>
                      <Form
                        className="form-profile"
                        onSubmit={this.handlePassSubmit}
                      >
                        <FormGroup>
                          <Label for="oldPassword">ENTER OLD PASSWORD</Label>
                          <Input
                            type="password"
                            name="password"
                            required
                            value={this.state.oldPassword}
                            onChange={this.handleOldPassword}
                            id="oldPassword"
                            placeholder="old password"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="newPassword">ENTER NEW PASSWORD</Label>
                          <Input
                            type="password"
                            name="password"
                            id="newPassword"
                            required
                            value={this.state.newPassword}
                            onChange={this.handleNewPassword}
                            placeholder="new password"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="confirmPassword">
                            RECONFIRM NEW PASSWORD
                          </Label>
                          <Input
                            type="password"
                            name="password"
                            value={this.state.confirmPassword}
                            required
                            onChange={this.handleConfirmPassword}
                            id="confirmPassword"
                            placeholder="confirm password"
                          />
                        </FormGroup>
                        <Row>
                          <div className="update">
                            <Button
                              type="submit"
                              className="bg-green ml-3"
                              round
                            >
                              {this.state.isLoading ? "..Loading" : "Save"}
                            </Button>
                          </div>
                        </Row>
                      </Form>
                    </div>
                  )}
                </CardBody>
              </Card>
            {/* </Col> */}
          {/* </Row> */}
        </Modal>
      </div>
    );
  }
}

export default ModalExample;
