import jwt from "jsonwebtoken";

export function verifyJwt() {
  let decoded = jwt.decode(localStorage.getItem("token"));
  if (!decoded) {
    return false;
  }
  if (decoded && Date.now() >= decoded.exp * 1000) {
    window.location.replace("/");
    localStorage.removeItem("token");
    return false;
  }
  return true;
}

export function decodeJwt(token = localStorage.getItem("token")) {
  let decoded = jwt.decode(token);
  if (!decoded) {
    return false;
  }
  if (decoded && Date.now() >= decoded.exp * 1000) {
    window.location.replace("/");
    localStorage.removeItem("token");
    return false;
  }
  return decoded;
}
