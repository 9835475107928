import React from "react";
import { Transition } from "react-transition-group";
import woman from "../../../assets/img/Woman_img.jpg";
import phone from "../../../assets/img/Phone.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import callcenter from "../../../assets/img/call-center.png";
import Logo from "../../../assets/img/Logo.png";
import DataContainer from "../BuyData/DataContainer";
import FormContainer from "../BuyPowerEstates/FormContainer";
import MainBills from "../PayBills/MainBills";
import BuyPowerPublicContainer from "../BuyPowerPublic/BuyPowerPublicContainer";
import LandingPageContent from "./LandingPageContent";
import AirtimeDataContainer from "../BuyAirtime/AirtimeDataContainer";
import SignInForm from "../BuyPowerEstates/SignInForm";
import Menu from "./Menu";

class LandingPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			toggleView: "Buy Power Estates",
			button: true,
			bgColor: "",
			bool: false
		};
	}

	toggle = () => {
		this.setState(prevState => ({
			bool: !prevState.bool
		}));
	};

	handleToggleView = () => {
		switch (this.state.toggleView) {
			// case "Landing Page Content":
			// 	return <LandingPageContent />;
			case "Airtime Form":
				return <AirtimeDataContainer />;
			case "Data Form":
				return <DataContainer />;
			case "Buy Power Estates":
				return <FormContainer />;
			case "Main Bills":
				return <MainBills />;
			case "Buy Power Public":
				return <BuyPowerPublicContainer />;
		}
	};

	render() {
		const {
			toggle,
			state: { bool }
		} = this;
		return (
			<React.Fragment>
				<div id="paymentFrame" class="m-0 mx-auto"></div>
				<div id="netplusFrame" class="m-0 mx-auto"></div>
                <Menu />
				<div className="container landing-container toggle-container">
					<div className="landing-child-container">
						<div className="content-inside">
							<img src={Logo} className="net-logo"></img>

							<div className="nav-container">
								<nav class="navbar navbar-expand-lg">
									{/* <button
										class="navbar-toggler"
										type="button"
										data-toggle="collapse"
										data-target="#navbarSupportedContent"
										aria-controls="navbarSupportedContent"
										aria-expanded="false"
										aria-label="Toggle navigation"
									>
										<span class="navbar-toggler-icon">
											<FontAwesomeIcon icon={"bars"} />
										</span>
									</button> */}
									{/* <div
										class="collapse navbar-collapse"
										id="navbarSupportedContent"
									>
										<a class="navbar-brand " href="#"></a> */}
		                            {/* <img src={Logo} className="net-logo"></img> */}
										<ul class=" menu-container navbar-nav mr-auto ">
											{/* <div className="left-menu"> */}
											<li className="nav-item ">
												<button
													type="button"
													className="btn nav-item-style "
													onClick={e =>
														this.setState({ toggleView: "Buy Power Estates" })
														
													}
												>
													<FontAwesomeIcon
														icon={"bolt"}
														size="3x"
														className="fa-icon"
													/>

													{/* </div> */}

													<p className="menu-option">BUY POWER </p>
													<p className="menu-tag">(PRIVATE ESTATES) </p>

													{/* <ModalForm
											backgroundColor="#1DE054"
											modalTitle=""
                                            formType="Sign in"
                                            buttonLabel="Buy Power"
											color="black"
										/> */}
												</button>
											</li>
											{/* <div className="animate__animated animate__slideInRight">
								{this.handleToggleView()}
							</div> */}
											<li className="nav-item ">
												<button
													type="button"
													className="btn nav-item-style "
													style={{ backgroundColor: this.state.bgColor }}
													onClick={
														e => this.setState({ toggleView: "Airtime Form" })
														//this.btnClick();
													}
												>
													<FontAwesomeIcon
														icon={"mobile-alt"}
														size="3x"
														className="fa-icon"
													/>

													<p className="menu-option">Airtime & Data</p>
													<p className="menu-tag">(MTN,GLO,AIRTEL,9MOBILE) </p>
												</button>
											</li>
											<li className="nav-item">
												<button
													type="button"
													className="btn nav-item-style"
													onClick={e =>
														this.setState({ toggleView: "Data Form" })
													}
												>
													<FontAwesomeIcon
														icon={"globe"}
														size="3x"
														className="fa-icon fa-icon-pay"
													/>

													<p className="menu-option">Buy Internet</p>
													<p className="menu-tag">(SPECTRANET,SMILE,SWIFT) </p>
												</button>
											</li>
											{/* </div> 
										<div className="left-menu"> */}
											<li className="nav-item">
												<button
													type="button"
													className="btn nav-item-style "
													onClick={e =>
														this.setState({ toggleView: "Main Bills" })
													}
												>
													<FontAwesomeIcon
														icon={"scroll"}
														size="3x"
														className="fa-icon1"
													/>

													<p className="menu-option">Pay Bills</p>
													<p className="menu-tag">(CABLE TV,TRAVELS) </p>
												</button>
											</li>
											<li className="nav-item">
												<button
													type="button"
													className="btn nav-item-style"
													onClick={e =>
														this.setState({ toggleView: "Buy Power Public" })
													}
												>
													<FontAwesomeIcon
														icon={"bolt"}
														size="3x"
														className="fa-icon"
													/>
													<div className="mo">
														<p className="menu-option">Buy Power</p>
														<p className="menu-tag">(EKEDC,IKEDC,IBEDC) </p>
													</div>
												</button>
											</li>
											<li className="nav-item ">
												<button type="button" className="btn nav-item-style ">
													<FontAwesomeIcon
														icon={"question-circle"}
														size="4x"
														className="fa-icon1"
													/>

													<p className="menu-option">FAQ</p>
												</button>
											</li>
											{/* </div> */}
										</ul>
									{/* </div> */}
								</nav>
							</div>

							<div className="  animate__animated animate__slideInRight">
								{this.handleToggleView()}
							</div>
						</div>

						{/* <img src={callcenter} className="call-center" /> */}
						<div className="landing-footer">
							<div className="row">
								<div className="col">
									<a className="footer-item">ABOUT |</a>
									{/* <a className="footer-item">USE THIS SERVICE |</a> */}
									<a className="footer-item">PRIVACY POLICY |
									</a>
									<a className="footer-item text-info">
									<Link className="text-info font-weight-bold" to="/contact">CONTACT</Link>
									</a>
								</div>
								{/* <div className="col">
									<img src={callcenter} className="call-center" />
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default LandingPage;
