
class ActionProvider {
    constructor(
     createChatBotMessage,
     setStateFunc,
     createClientMessage,
     stateRef,
     createCustomMessage,
     ...rest
   ) {
     this.createChatBotMessage = createChatBotMessage;
     this.setState = setStateFunc;
     this.createClientMessage = createClientMessage;
     this.stateRef = stateRef;
     this.createCustomMessage = createCustomMessage;
   }

   helloHandler = () => {
       const message =this.createChatBotMessage("Hello, i'm A Chat Bot, How can i help you today?", {
         widget: "options"
       });
       this.setChatbotMessage(message);
   };

   paymentHandler = () => {
      const message =this.createChatBotMessage("What kind of payment issues are you encountering",{
        widget: "paymentissues"
      });
      this.setChatbotMessage(message);
   };

   transactionHandler = () => {
      const message =this.createChatBotMessage("Tansaction issues?");

      this.setChatbotMessage(message);
   }

   noMeterNumberhandler = () => {
     const message = this.createChatBotMessage("Your meter number hasn't be registered by the service admin");
     this.setChatbotMessage(message);
   }



   tokenHandler = () => {
       const message = this.createChatBotMessage("Please select from the following options", {
         widget: "tokenissues"
       }) 
       this.setChatbotMessage(message);
   };

   pastHandler = () => {
     const message = this.createChatBotMessage("Please provide your transaction error code")
     this.setChatbotMessage(message);
   }

   tokenNotReceivedHandler = () => {
    const message = this.createChatBotMessage("Please send your proof of payment to tosin.oludare@netpos.com.ng ")
    this.setChatbotMessage(message);
   }


   setChatbotMessage = (message) => {
       this.setState((state) => ({...state, messages:[...state.messages, message]}))
   }

 }
 
 export default ActionProvider;