import React from "react";


class PayBills extends React.Component {
	constructor(props) {
		super(props);
	}

	saveAndContinue = e => {
		e.preventDefault();

		if (this.props.values.service== "") {
			let showMsg = document.getElementById("bills-msg");
			showMsg.innerHTML = "Please Fill Form";
			showMsg.style.display = "block";
			showMsg.style.fontSize = "18px";
			showMsg.style.color = "red";
		} else if (this.props.values.provider == "") {
			let showMsg = document.getElementById("bills-msg");
			showMsg.innerHTML = "Please Fill Form";
			showMsg.style.display = "block";
			showMsg.style.fontSize = "18px";
			showMsg.style.color = "red";
		} else {
			return this.props.nextStep();
		}
	};

	
	render() {
		const { values } = this.props;
		return (
			<React.Fragment>
				<form
					className="needs-validation airtime-form"
					onSubmit={this.saveAndContinue}
				>
					<p id="bills-msg"></p>
					<div className="form-group airtime-form-input">
			

						<select
							className="custom-select"
							placeholder="Country"
							value={values.selectedCategory}
							onChange={this.props.changeService}
							required
						>
							<option>CHOOSE CATEGORY</option>
							{values.category.map((e, key) => {
								return <option key={key}>{e.name}</option>;
							})}
						</select>
					</div>

					<div className="form-group airtime-form-input">
						

						<select
							className="custom-select"
							value={values.selectedProvider}
							onChange={this.props.changeProvider}
							required
						>
							<option>CHOOSE PRODUCT</option>
							{values.provider.map((e, key) => 
							{
								return <option key={key}>{e}</option>;
							})}
						</select>
					</div>

					<button type="submit" className="btn airtime-form-input btn-style">
						CONTINUE
					</button>
				</form>
			</React.Fragment>
		);
	}
}

export default PayBills;
