import React from "react";
import Power1 from "../../../assets/img/public_Power.jpg";
import BuyPowerPublic from "./BuyPowerPublic";


class BuyPowerPublicContainer extends React.Component {
	constructor(props) {
		super(props);
	}

    render() {
        return(
            <React.Fragment>
                <div className="row animate__animated animate__slideInRight">
					<div className="col left-col">
						<img className="power-page-img" src={Power1}></img>
					</div>
					<div className="col">
						<div className="form-container">
							<BuyPowerPublic />
						</div>
					</div>
				</div>
            </React.Fragment>
        )
    }
}

    export default BuyPowerPublicContainer;
    