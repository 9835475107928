import React, { Component } from "react";
import { buyAirtime } from "../../../utilities/api";
import { getFees } from "../../../utilities/api";
import { fetchUserInfo } from "../../../utilities/api";
import shortid from "shortid";
import { netpluspayPOP } from "../../../views/TopUps/netfun";
import Bills from "./Bills";
import CableTv from "./CableTv";
import ConfirmUser from "./ConfirmUser";
import BillsTransactionSummary from "./BillsTransactionSummary";
import BillsTransactionFailure from "./BillsTransactionFailure";

class PayBills extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			step: 1,
			token: "",
			serviceType: "cable_tv",
			showmsg: "",
			showmsgSuccess: "",
			isLoading: false,
			service: "pay_bill",
			payload: null,
			transactionID: null,
			// transaction_type: "bill payment",
			provider: [],
			email: "",
			phone: "",
			billPackage: [],
			firstName: "",
			lastName: "",
			destinationAccount: "",
			accountName: "",
			amount: null,
			category: [],
			storedFee: null,
			storedProvider: null,
			modalResponse: "",
			selectedProvider: "Choose Product",
			selectedCategory: "Choose Category",
			selectedBillPackage: "Choose Package",
			billAccountId: null,
			billAccountDetailsId: "",
			billAccountIdDetails: "",
			billResponse: "",
			formErrors: {
				//category: "",
				provider: "",
				email: "",
				phone: "",
				billPackage: "",
				destinationAccount: "",
				accountName: "",
				amount: null
			}
		};
	}

	componentDidMount = () => {
		this.setState({
			payload: 0,
			provider: [
				{
					name: "DSTV",
					billPackage: [
						{
							name: "PREMIUM - 18400",
							amount: 18400
						},

						{
							name: "COMPACT PLUS - 12400",
							amount: 12400
						},

						{
							name: "COMPACT - 7900",
							amount: 7900
						},

						{
							name: "CONFAM - 4615",
							amount: 4615
						},
						{
							name: "YANGA - 2565",
							amount: 2565
						},
						{
							name: "PADI - 1850",
							amount: 1850
						},
						{
							name: "PREMIUM + XTRAVIEW - 20900",
							amount: 20900
						},
						{
							name: "PREMIUMASIA - 20500",
							amount: 20500
						},
						{
							name: "PREMIUMASIA + XTRAVIEW- 23000",
							amount: 23000
						},
						{
							name: "PREMIUM + FRENCH - 25500",
							amount: 25500
						},
						{
							name: "PREMIUM + FRENCH + XTRAVIEW - 28000",
							amount: 28000
						},
						{
							name: "COMPACTPLUS + ASIA - 18600",
							amount: 18600
						},
						{
							name: "COMPACTPLUS + ASIA + XTRAVIEW - 21100",
							amount: 21100
						},
						{
							name: "COMPACTPLUS + FRENCH TOUCH- 14700",
							amount: 14700
						},
						{
							name: "COMPACTPLUS + FRENCH PLUS- 20500",
							amount: 20500
						},
						{
							name: "COMPACTPLUS + FRENCH PLUS + XTRAVIEW - 23000",
							amount: 23000
						},
						{
							name: "COMPACTPLUS + XTRAVIEW - 14900",
							amount: 14900
						},
						{
							name: "COMPACT + ASIA - 14100",
							amount: 14100
						},
						{
							name: "COMPACT + FRENCH TOUCH- 10200",
							amount: 10200
						},
						{
							name: "COMPACT + XTRAVIEW - 10400",
							amount: 10400
						},
						{
							name: "COMPACT + FRENCHTOUCH + XTRAVIEW - 12700",
							amount: 12700
						},
						{
							name: "COMPACT + ASIA + XTRAVIEW - 16600",
							amount: 16600
						},

						{
							name: "COMPACT + FRENCH PLUS- 16000",
							amount: 16000
						},
						{
							name: "CONFAM + XTRAVIEW - 7115",
							amount: 7115
						},
						{
							name: "YANGA + XTRAVIEW - 5065",
							amount: 5065
						},
						{
							name: "PADI + XTRAVIEW - 4350",
							amount: 4350
						}
					]
				},
				{
					name: "GOTV",
					billPackage: [
						{
							name: "MAX - 3600",
							amount: 3600
						},
						{
							name: "JOLLI - 2460",
							amount: 2460
						},
						{
							name: "JINJA - 1640",
							amount: 1640
						},
						{
							name: "LITE - 410",
							amount: 410
						},
						{
							name: "LITE (QUARTERLY) - 1080",
							amount: 1080
						},
						{
							name: "LITE (ANNUAL) - 3180",
							amount: 3180
						}
					]
				},
				{
					name: "STARTIMES",
					billPackage: [
						{
							name: "DIT NOVA (DAILY) - N90",
							amount: 90
						},
						{
							name: "DIT NOVA (WEEKLY) - N300",
							amount: 300
						},
						{
							name: "DIT NOVA (MONTHLY) - N900",
							amount: 900
						},
						{
							name: "DIT BASIC (DAILY) - N160",
							amount: 160
						},
						{
							name: "DIT BASIC (WEEKLY) - N600",
							amount: 600
						},
						{
							name: "DIT BASIC (MONTHLY) - N1700",
							amount: 1700
						},
						{
							name: "DIT CLASSIC (DAILY) - N320",
							amount: 320
						},
						{
							name: "DIT CLASSIC (WEEKLY) - N1200",
							amount: 1200
						},
						{
							name: "DIT CLASSIC (MONTHLY) - N2500",
							amount: 2500
						},
						{
							name: "DTH NOVA (DAILY) - N90",
							amount: 90
						},
						{
							name: "DTH NOVA (WEEKLY) - N300",
							amount: 300
						},
						{
							name: "DTH NOVA (MONTHLY) - N900",
							amount: 900
						},
						{
							name: "DTH SMART (DAILY) - N200",
							amount: 200
						},
						{
							name: "DTH SMART (WEEKLY) - N700",
							amount: 700
						},
						{
							name: "DTH SMART (MONTHLY) - N2200",
							amount: 2200
						},
						{
							name: "DTH SUPER (DAILY) - N400",
							amount: 400
						},
						{
							name: "DTH SUPER (WEEKLY) - N1500",
							amount: 1500
						},
						{
							name: "DTH SUPER (MONTHLY) - N4200",
							amount: 4200
						},
						{
							name: "COMBO SMART BASIC (DAILY) - N200",
							amount: 180
						},
						{
							name: "COMBO SMART BASIC (WEEKLY) - N700",
							amount: 600
						},
						{
							name: "COMBO SMART BASIC (MONTHLY) - N2200",
							amount: 1900
						},
						{
							name: "COMBO SUPER CLASSIC (DAILY) - N400",
							amount: 360
						},
						{
							name: "COMBO SUPER CLASSIC (WEEKLY) - N1500",
							amount: 1300
						},
						{
							name: "COMBO SUPER CLASSIC (MONTHLY) - N4200",
							amount: 3800
						}
					]
				}
			]
		});

		const script = document.createElement("script");
		script.src = "https://js.netpluspay.com/inline.js";
		script.charset = "utf-8";
		script.async = true;
		script.onload = this.scriptLoaded();

		document.head.appendChild(script);
	};

	scriptLoaded = () => {
		this.setState({ netplusPayReady: true });
	};

	// changeService = e => {
	// 	this.setState({ selectedCategory: e.target.value });
	// 	this.setState({
	// 		provider: this.state.category.find(
	// 			sProvider => sProvider.name === e.target.value
	// 		).provider
	// 	});
	// };

	changeProvider = e => {
		this.setState({ selectedProvider: e.target.value });
		// const pro = this.state.category.find(
		// 	sProvider => sProvider.name === this.state.selectedCategory
		// ).provider;
		this.setState({
			billPackage: this.state.provider.find(
				bpackage => bpackage.name === e.target.value
			).billPackage
		});
		//this.nextStep();
		this.handleGetFees();
	};

	changeProvider1 = e => {
		this.setState({ selectedProvider: e.target.value });
		// const pro = this.state.category.find(
		// 	sProvider => sProvider.name === this.state.selectedCategory
		// ).provider;
		this.setState({
			billPackage: this.state.provider.find(
				bpackage => bpackage.name === e.target.value
			).billPackage
		});
		//this.nextStep();
		this.handleGetFees();
	};

	changebillPackage = e => {
		this.setState({ selectedBillPackage: e.target.value });
		const pro = this.state.provider.find(
			bpackage => bpackage.name === this.state.selectedProvider
		).billPackage;
		// const pack = pro.find(
		// 	bpackage => bpackage.name === this.state.selectedProvider
		// // ).billPackage;
		this.setState({
			amount: pro.find(a => a.name === e.target.value).amount
		});
	};

	nextStep = () => {
		const { step } = this.state;

		if (this.state.payload === 0) {
			this.setState({
				isLoading: false,
				showmsg: "Service Unavailable"
			});
		} 
		else 
		 if (this.state.modalResponse === "FAILED" ) {
			this.setState({
				step: step + 2
			});
		} 
		else if (this.state.modalResponse ===  "CAPTURED" ) {
			this.setState({
				step: step + 1
			});
		}
		else {
			this.setState({
				step: step + 1
			});
		 }
	};

	checkVerifyUser = () => {
		if(this.state.billAccountIdDetails === "" ) {
			this.setState({
				isLoading: false,
				showmsg: "Please Check Card Number"
			});
		} else {
			this.nextStep();
		}
	}

	// nextStep1 = () => {
	// 	const { step } = this.state;
	// 	this.setState({
	// 		step: step + 1
	// 	});
	// 	this.handleGetFees();
	// };

	prevStep = () => {
		const { step } = this.state;
		this.setState({
			step: step - 1
		});
	};

	handleChange = input => e => {
		e.preventDefault();

		this.setState({ [input]: e.target.value });
	};

	handleGetFees = () => {
		this.setState({
			isLoading: true
		});

		setTimeout(() => {
			let data = this.state.selectedProvider;

			if (sessionStorage.getItem("Service") === this.state.provider) {
				this.setState({
					payload: sessionStorage.getItem("Fee"),
					isLoading: false
				});
			} else {
				getFees(data).then(response => {
					if (response && response.status == 200) {
						setTimeout(() => {
							this.setState({
								payload: response.data.fees,
								isLoading: false,
								storedProvider: response.data.provider,
								storedFee: response.data.fees,
								showmsg: ""
							});

							const s = this.state.storedProvider;
							const fee = this.state.storedFee;

							sessionStorage.setItem("Fee", fee);
							sessionStorage.setItem("Service", s);
						}, 500);
					} else {
						this.setState({
							isLoading: false,
							showmsg: "Service Unavailable"
						});
					}
				});
			}
		}, 1000);
	};

	verifyUser = () => {
		this.setState({
			isLoading: true
		});

		let data = {
			destinationAccount: this.state.destinationAccount,
			provider: this.state.selectedProvider
		};

		setTimeout(() => {
			fetchUserInfo(data).then(response => {
				if (response && response.status == 200) {
					setTimeout(() => {
						this.setState({
							billAccountId: response.data.BillAccountId,
							billAccountIdDetails: response.data.BillAccountIdDetails,
							isLoading: false,
							showmsg: ""
						});
						//this.nextStep();
							this.checkVerifyUser();
						
					}, 500);
				} else if (response && response.status == 400) {
					setTimeout(() => {
						this.setState({
							showmsg: "Invalid Number",
							isLoading: false
						});
					}, 500);
				} else {
					this.setState({
						isLoading: false,
						showmsg: "Service Unavailable"
					});
					
				}
			});
		}, 500);
	};

	// handlebillNext = () => {
	// 	if(this.state.payload === 0) {
	// 		this.setState({
	// 			showmsg: "Service Unavailable",
	// 			isLoading: false
	// 		})
	// 	} else {
	// 		this.nextStep();
	// 	}

	// }

	handlePayment = e => {
		e.preventDefault();
		// if (formValid(this.state.formErrors)) {
		if (this.state.payload === 0) {
			this.setState({
				showmsg: "Service Unavailable",
				isLoading: false
			});
		}
		//  else if (this.state.billAccountIdDetails === "" || null) {
		// 	this.setState({
		// 		showmsg: "Invalid Card Number",
		// 		isLoading: false
		// 	});
		// } 
		else {
			const finalamount =
				parseInt(this.state.amount) + parseInt(this.state.payload);

			const values = {
				// Values to give netpluspay class
				success: message => {
					this.setState({
						isLoading: true,
						modalResponse: message.status,
						transactionID: message.transId
					});

					this.handleWindowClose();
				},
				failed: message => {
					this.setState({
						isLoading: true,
						modalResponse: message.status
						// transactionID: message.transId
					});

					this.handleWindowClose();
				},
				cancel: message => {
					this.setState({ isLoading: false });

					this.handleWindowClose();
				},

				data: {
					merchantId: `${process.env.REACT_APP_MERCHANT_ID}`,
					name: this.state.billAccountIdDetails, //Don't make it long so amount can show
					email: this.state.email,
					amount: finalamount,
					currency: "NGN",
					orderId: `POWER${shortid.generate()}`
				}
			};

			if (this.state.netplusPayReady) {
				// Check if netpluspay script is loaded

				let netplusPay = window.NetplusPay; // Call the netpluspay function
				let netplusPay2 = new netplusPay(values); // Create a new netpluspay class

				netplusPay2.beginTransaction();

				this.setState({
					isLoading: false
				});
			} else {
				setTimeout(() => {
					this.setState({
						isLoading: false,
						showmsg: "Oops! Server or Network Error"
					});
				}, 100);
			}

			// netpluspayPOP.setup({
			// 	merchant: `${process.env.REACT_APP_MERCHANT_ID}`,
			// 	customer_name: this.state.billAccountIdDetails,
			// 	email: this.state.email,
			// 	amount: finalamount,
			// 	currency_code: "NGN",
			// 	narration: "Bills Payment",
			// 	order_id: `DATA${shortid.generate()}`,
			// 	container: "paymentFrame",
			// 	callBack: function(resp) {
			// 		if (resp && resp.status === "CAPTURED") {
			// 			this.setState({
			// 				modalResponse: resp.status,
			// 				transactionID: resp.trans_id
			// 			});
			// 		} else if (resp && resp.status === "FAILED") {
			// 			this.setState({
			// 				modalResponse: resp.status,
			// 				transactionID: resp.trans_id
			// 			});
			// 		}
			// 		//this.closeIframe();
			// 	}.bind(this),
			// 	onClose: function() {
			// 		this.setState({ isLoading: true });
			// 		this.handleWindowClose();
			// 	}.bind(this)
			// });
			// netpluspayPOP.prepareFrame();
			// } else {
			// 	let showMsg = document.getElementById("show-msg");
			// 	showMsg.innerHTML = "Invalid Form";
			// 	showMsg.style.display = "block";
			// 	showMsg.style.fontSize = "18px";
			// 	showMsg.style.color = "red";
			// 	this.setState({ isLoading: false });
			// }
			//}
		}
	};

	handleWindowClose = () => {
		if (this.state.modalResponse === "FAILED") {
			this.setState({
				isLoading: false,
				showmsg: "Transaction Failed"
			});
			this.nextStep();
		} else if (this.state.modalResponse === "CAPTURED") {
			this.handleBuyAirtime();
		} else {
			
			this.setState({ isLoading: false });
		}
	};

	handleBuyAirtime = e => {
		//e.preventDefault();

		this.setState({
			isLoading: true,
			showmsg: ""
		});

		const userAmount =
			parseFloat(this.state.amount) + parseInt(this.state.payload);

		let data = {
			destinationAccount: this.state.destinationAccount,
			amount: userAmount,
			service: this.state.service,
			provider: this.state.selectedProvider,
			trans_id: this.state.transactionID,
			serviceType: this.state.serviceType,
			email: this.state.email
		};
	
		setTimeout(() => {
			buyAirtime(data).then(response => {
				if (response && response.status === 200) {
					this.setState({
						showmsg: "",
						isLoading: false,
						showmsgSuccess: "Transaction Successful"
					});
					this.nextStep();
				} else if (response && response.status === 400) {
					this.setState({
						
						isLoading: false,
						showmsg: "Transaction Failed"
					});
					//this.nextStep();
				} else {
					this.setState({
						isLoading: false,
						showmsg: "Server Error"
					});
					//this.nextStep()
				}
			});
		}, 4000);
	};

	render() {
		const { step } = this.state;
		const {
			payload,
			token,
			isLoading,
			category,
			provider,
			email,
			phone,
			billPackage,
			firstName,
			lastName,
			accountName,
			destinationAccount,
			amount,
			service,
			selectedBillPackage,
			selectedProvider,
			selectedCategory,
			billAccountId,
			billAccountDetailsId,
			billAccountIdDetails,
			formErrors,
			showmsg,
			showmsgSuccess
		} = this.state;
		const values = {
			payload,
			isLoading,
			token,
			category,
			provider,
			email,
			phone,
			selectedBillPackage,
			billPackage,
			firstName,
			lastName,
			accountName,
			destinationAccount,
			amount,
			service,
			selectedProvider,
			selectedCategory,
			billAccountId,
			billAccountDetailsId,
			billAccountIdDetails,
			formErrors,
			showmsg,
			showmsgSuccess
		};
		switch (step) {
			case 1:
				return (
					<Bills
						nextStep={this.nextStep}
						handleChange={this.handleChange}
						values={values}
						//toggleDropdown = {this.toggleDropdown}
						changeService={this.changeService}
						changeProvider={this.changeProvider1}
						handleBillNext={this.handleBillNext}
					/>
				);

			case 2:
				return (
					<CableTv
						nextStep={this.nextStep}
						prevStep={this.prevStep}
						handleChange={this.handleChange}
						changeService={this.changeService}
						changeProvider={this.changeProvider}
						values={values}
						changebillPackage={this.changebillPackage}
						billPayment={this.handlePayment}
						verifyUser={this.verifyUser}
					/>
				);

			case 3:
				return (
					<ConfirmUser
						nextStep={this.nextStep}
						prevStep={this.prevStep}
						handleChange={this.handleChange}
						changeService={this.changeService}
						changeProvider={this.changeProvider}
						values={values}
						changebillPackage={this.changebillPackage}
						billPayment={this.handlePayment}
						verifyUser={this.verifyUser}
					/>
				);

				case 4:
				return (
					<BillsTransactionSummary
						
					/>
				);

				case 5:
				return (
					<BillsTransactionFailure
						
					/>
				);
		}
	}
}

export default PayBills;
