import React from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { createServiceAdmin } from "../../utilities/api";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import {includesWord} from '../../utilities/dataTypeCheck';
import getNetplusLogo from "../../utilities/getNetplusLogo";

const RegisterForm = function() {
    return (
      <Formik
        initialValues={{
          estate_name: "",
          email: "",
          first_name: "",
          last_name: "",
          phone: "",
          password: "",
          contact_address: "",
          isLoggedIn: false,
        }}
        onSubmit={(values, { setSubmitting }, props) => {
          setTimeout(() => {
            createServiceAdmin({
              service_ids: [1],
              estate_name: values.estate_name,
              email: values.email,
              first_name: values.first_name,
              last_name: values.last_name,
              phone: values.phone,
              contact_address: values.contact_address,
              password: values.password,
              property_logo: getNetplusLogo()
            }).then(response => {
              let showError = document.getElementById("show-error");
              if (response && response.status === 200 || response && response.status === 304) {
                showError.style.display = "block";
                showError.style.color = "green";
                showError.innerHTML = "Admin created";
                setTimeout(() => {
                  window.location.reload(true);
                }, 0);
              } else if (response && response.status === 400){
                showError.style.display = "block";
                showError.innerHTML = response.data;
                setSubmitting(false);
                }
                else {
                  showError.style.display = "block";
                  showError.innerHTML = "Opps! Server error";
                  setSubmitting(false);
                }
            });
          }, 1000);
        }}
        validationSchema={Yup.object().shape({
          estate_name: Yup.string()
            .trim()
            .matches(
              /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!@-_?&%]{2,}$/,
              "At least two letters are expected"
            )
            .required("estate name is required"),

            contact_address: Yup.string()
            .trim()
            .required("contact address is required"),

            email: Yup.string()
            .email("Invalid email address")
            .required("email is required"),

          first_name: Yup.string()
            .trim()
            .matches(
              /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!@-_?&%]{2,}$/,
              "At least two letters are expected"
            )
            .required("contact first name is required"),

            last_name: Yup.string()
            .trim()
            .matches(
              /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!@-_?&%]{2,}$/,
              "At least two letters are expected"
            )
            .required("contact last name is required"),

          phone: Yup.number().required(
            "phone number is required"
          ),
          password: Yup.string().required("password is required")
          // .min(8, "Password is too short - should be 8 chars minimum.")
          // .matches(/(?=.*[0-9])/, "Password must contain a number.")
        })}
      >
        {props => {
            const toggle = event => {
              event.preventDefault();
              let showPassword = document.querySelector('#toggle-password');
              let password = document.querySelector('#password');
              if (event.target.className === "fa fa-fw fa-eye field-icon" ) {
                showPassword.className = "fa fa-fw fa-eye-slash field-icon";
                password.type = "text";
              } else {
                showPassword.className = "fa fa-fw fa-eye field-icon";
                password.type = "password";
              }
            }
          const {
            setFieldTouched,
            setFieldValue,
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit
          } = props;
          return (
            // <div className="login-container">
              <Form onSubmit={handleSubmit} className="login-form">
                <h3 id="show-error">Input error</h3>
                <Row form>
                <Col md={6}>
                <FormGroup>
                <Input
                  name="estate_name"
                  type="text"
                  placeholder="Enter estate name"
                  value={values.estate_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={errors.estate_name && touched.estate_name && "error"}
                />
                {errors.estate_name && touched.estate_name && (
                  <div style={{ color: 'red', fontSize: '.8em'}} className="input-feedback">{errors.estate_name}</div>
                )}
                </FormGroup> 
                 </Col> 
                <Col md={6}>
                <FormGroup>
                <Input
                  name="phone"
                  type="text"
                  placeholder="Enter contact person number"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.phone && touched.phone && "error"
                  }
                />
                {errors.phone && touched.phone && (
                  <div style={{ color: 'red', fontSize: '.8em'}} className="input-feedback">
                    {errors.phone === "contact number is required"
                      ? errors.phone
                      : "Letters are not allowed"}
                  </div>
                )}
                </FormGroup>
               </Col>
               </Row>
               <Row form>
                <Col md={6}>
                <FormGroup>
                <Input
                  name="first_name"
                  type="text"
                  placeholder="Enter contact person first name"
                  value={values.first_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={errors.first_name && touched.first_name && "error"}
                />
                {errors.first_name && touched.first_name && (
                  <div style={{ color: 'red', fontSize: '.8em'}} className="input-feedback">{errors.first_name}</div>
                )}
                </FormGroup>
                </Col>
                <Col md={6}>
                <FormGroup>
                <Input
                  name="last_name"
                  type="text"
                  placeholder="Enter contact person last name"
                  value={values.last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={errors.last_name && touched.last_name && "error"}
                />
                {errors.last_name && touched.last_name && (
                  <div style={{ color: 'red', fontSize: '.8em'}} className="input-feedback">{errors.last_name}</div>
                )}
                </FormGroup>
               </Col>
               </Row>
               <Row form>
                <Col md={6}>
                <FormGroup>
                <Input
                  name="email"
                  type="text"
                  placeholder="Enter email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={errors.email && touched.email && "error"}
                />
                {errors.email && touched.email && (
                  <div style={{ color: 'red', fontSize: '.8em'}} className="input-feedback">{errors.email}</div>
                )}
                </FormGroup>
                </Col>
                <Col md={6}>
                <FormGroup>
                <Input
                  name="password"
                  required
                  type="password"
                  placeholder="Enter password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    errors.password && touched.password && "error"
                  }
                />
                {errors.password && touched.password && (
              <div style={{ color: 'red', fontSize: '.8em'}} className="input-feedback">{errors.email}</div>
                )}
                </FormGroup>
               </Col>
               </Row>
               <Row form>
                <Col md={6}>
                <FormGroup>
                <Input
                  name="contact_address"
                  type="text"
                  placeholder="Enter contact address"
                  value={values.contact_address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={errors.contact_address && touched.contact_address&& "error"}
                />
                {errors.contact_address && touched.contact_address && (
                  <div style={{ color: 'red', fontSize: '.8em'}} className="input-feedback">{errors.contact_address}</div>
                )}
                </FormGroup>
                </Col>
                <Col md={6}>
                {/* <FormGroup>
                <Field style={{ color: "black", fontSize: ".8em", padding: "10px", border: '1px solid black' }} name="services_id" as="select" placeholder="SELECT SERVICES">
                  <option value="1">POWER VENDING</option>
                  </Field>
                  </FormGroup> */}
               </Col>
               </Row>
                <button
                  className="btn btn-primary text-uppercase"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Loadingâ€¦' : 'Submit'}
                </button>
              </Form>
            // </div>
          );
        }}
      </Formik>
    );
  };

  

  export default RegisterForm;