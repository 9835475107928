import React from "react";

class BuyPowerPublicForm extends React.Component {
	constructor(props) {
		super(props);
	}

    render() {
		const { values } = this.props;
        return(
            <React.Fragment>
				<form className="" 
				// onSubmit={this.props.verifyUser}
				>
								<p className="show-msg">{values.showmsg}</p>
								<p className="show-msg-success">{values.showmsgSuccess}</p>
								<p className="form-header">BUY POWER</p>
								<div class="form-group landing-form-input">
									<select
										className=" form-control landing-form-control custom-select"
										id="exampleFormControlSelect1"
										placeholder="SELECT CATEGORY"
										name="provider"
										value={values.provider}
										onChange={this.props.handleChangeProvider}
										disabled={values.isLoading}
										required
									>
										<option value="">SELECT SERVICE PROVIDER</option>
										<option value="phcneko">PHCNEKO</option>
										<option value="phcnkano">PHCNKANO</option>
										<option value="phcnjos">PHCNJOS</option>
										<option value="phcnabuja">PHCNABUJA</option>
										<option value="phcnibadan">PHCNIBADAN</option>
										<option value="phcnenugu">PHCNENUGU</option>
									</select>

							{/* <span className="errorMessage">{formErrors.provider}</span> */}
								</div>

								<div class="form-group landing-form-input">
									<input
										type="tel"
										class="form-control landing-form-control"
										id="number1"
										placeholder="ENTER METER NUMBER"
										name="destinationAccount"
										value={values.destinationAccount}
										onChange={this.props.handleChange}
										disabled={values.isLoading}
										required
									/>

									{/* <span className="errorMessage">
										{formErrors.destinationAccount}
									</span> */}
								</div>
								<div class="form-group landing-form-input">
									<input
										type="email"
										class="form-control landing-form-control"
										id="email"
										name="email"
										placeholder="EMAIL ADDRESS"
										value={values.email}
										onChange={this.props.handleChange}
										disabled={values.isLoading}
										required
									/>
								</div>
								<div class="form-group landing-form-input">
									<input
										type="number"
										class="form-control landing-form-control"
										placeholder="AMOUNT"
										name="amount"
										min = "0"
										value={values.amount}
										onChange={this.props.handleChange}
										disabled={values.isLoading}
										required
									/>

									{/* <span className="errorMessage">{formErrors.amount}</span> */}
								</div>
								<span className="landing-fee">
									{"Convenience Fee: " + values.payload}
								</span>
								<button
									type="submit"
									className="btn btn-dark landing-form-input"
									// disabled={values.isLoading}
									disabled={true}
								>
									{values.isLoading ? "LOADING…" : "CONTINUE"}
								</button>
							</form>
            </React.Fragment>
        )
    }
}

    export default BuyPowerPublicForm;
    