import React from "react";
import EstateTransactions from "./EstateMenu/Menu";
import OtherTransactionHistory from "./OtherTransactions";
import ResidentTransHistory from "./EstateMenu/ResidentTransaction";
import { decodeJwt } from "../../utilities/processJWT";
import ErrorPage from "../../utilities/errorPage";

let residentData = localStorage.getItem("resi");

class Dashboard extends React.Component {
  render() {
    return (
      <div className="content">
        {decodeJwt().role === "service-admin" &&  <EstateTransactions />}
        {decodeJwt().role === "service-user" &&  <OtherTransactionHistory />}
        {decodeJwt().role === "service-owner" &&  <OtherTransactionHistory />}
        {decodeJwt().role === "netplus-admin" &&  <ResidentTransHistory />}
      </div>
    );
  }
}

export default Dashboard;
