import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Button,
  Row,
  Col,
} from "reactstrap";

class DashboardMetrics extends React.Component {
  render() {
    const workingCapital = 123;
    return (
      <Row class="d-flex flex-nowrap">
        <Col sm={4}>
          <Card className="remove_shadow">
            <CardBody>
              <CardTitle className="title-color"></CardTitle>
              <div className="dashboard-widget">
                <div>
                  {workingCapital && workingCapital ? (
                    <h6 className="text-number-green">{workingCapital}</h6>
                  ) : (
                    <h6 className="text-number-green">{"-"}</h6>
                  )}
                </div>
                <div className="font-italic">
                  <h6 className="">TOTAL NUMBER OF ESTATES</h6>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm={4}>
          <Card className="remove_shadow">
            <CardBody>
              <CardTitle className="title-color"></CardTitle>
              <div className="dashboard-widget">
                <div>
                  {workingCapital && workingCapital ? (
                    <h6 className="text-number-green">{workingCapital}</h6>
                  ) : (
                    <h6 className="text-number-green">{""}</h6>
                  )}
                </div>
                <div className="font-italic">
                  <h6 className="">ACTIVE ESATES</h6>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm={4}>
          <Card className="remove_shadow">
            <CardBody>
              <CardTitle className="title-color"></CardTitle>
              <div className="dashboard-widget">
                <div>
                  {workingCapital && workingCapital ? (
                    <h6 className="text-number-green">{workingCapital}</h6>
                  ) : (
                    <h6 className="text-number-green">{"-"}</h6>
                  )}
                </div>
                <div className="font-italic">
                  <h6 className="">INACTIVE ESTATES</h6>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default DashboardMetrics;
