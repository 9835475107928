import React from "react";
import woman from "../../../assets/img/LandingPage_img.jpg";

class LandingPageContent extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<React.Fragment>
				<div className="row landing-page-content">
					<div className="col welcome-text-container">
						<p className="welcome-text">Welcome to</p>
						<p className="welcome-text">NetPlusPAY Bills Platform</p>
						<p>
							We are happy to have you on board. Our bills will not only make
							power vending easier but you can also perform the easiest
							transactions such as buy airtime, data, internet, you can also pay
							for your cable TV subscription and so much more at your
							convenience
						</p>
					</div>
					<div className="col">
						<img src={woman} className="landingpage-woman"></img>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default LandingPageContent;
