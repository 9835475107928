export const currencyFormat = (value) =>
  new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
    currencyDisplay: "symbol",
    currencySign: "accounting",
    // minimumFractionDigits: 2,
    // maximumFractionDigits: 2,
  }).format(value);

export const currencyFormatCompact = (value) =>
  new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
    currencyDisplay: "symbol",
    notation: "compact",
    compactDisplay: "long",
    currencySign: "accounting",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);

export const currencyFormatNoFraction = (value) =>
  new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
    currencyDisplay: "symbol",
    notation: "compact",
    compactDisplay: "long",
    currencySign: "accounting",
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  }).format(value);

let options = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  hour12: false,
  // timeZone: 'West Africa Time Zone'
};
export const FormatTimeMinute = (time) =>
  new Intl.DateTimeFormat("en-NG", options).format(new Date(time));

export const FormatTime = (time) =>
  new Intl.DateTimeFormat("en-NG", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  }).format(new Date(time));
