import React from "react";
import LandingPage from "../LandingPage/LandingPage";
import Logo from "../../../assets/img/Logo.png";
import transaction_failed from "../../../assets/img/transaction_failed.jpg";

class TransactionFailure extends React.Component {
	constructor(props) {
		super(props);
	}

	backHome = e => {
		e.preventDefault();
		// <LandingPage />
	};

	render() {
		const { values } = this.props;
		return (
			<React.Fragment>
                            <div className="summary-container">
                                <img src= {transaction_failed} className="summary-failure" />
                                <h5 className="summary-header">Oops! Transaction Failed</h5>
                                <div className="summary-content">
                                    <p>Thank you for using Bills by NetPlusPay.</p>
                                    
                                </div>
                                

								<div className="summary-content">
									<p>Please Try Again Later</p>
									
								</div>

								<button
									type="submit"
									className="btn btn-dark landing-form-input summary-button"
									onClick={() => window.location.reload(false)}
								>
									BACK TO HOME
								</button>

								<div>
									<img src={Logo} className="summary-logo" />
								</div>
							
				</div>
			</React.Fragment>
		);
	}
}

export default TransactionFailure;
