import React, { useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { FormatTime } from "./currency_time_formata";
import Axios from "axios";
import { decodeJwt } from "./processJWT";


const generateReceiptId = (RRN) => {
    let newDate = new Date();
    let date = newDate.toLocaleDateString();
    let time = newDate.toLocaleTimeString();
    console.log(`${date}:${time}`)
    return `NETx${RRN}`;
};

const convertEpochToSpecificTimezone = (timeEpoch, offset=+1) => {
    var d = new Date(timeEpoch);
    var utc = d.getTime() + (d.getTimezoneOffset() * 60000);  //This converts to UTC 00:00
    var nd = new Date(utc + (3600000*offset));
    return nd.toLocaleString();
}


export function ReceiptMarkup(props) {
    const address= localStorage.getItem('address');
    console.log("reeeee", props);
  let newDate = new Date();

let mm = newDate.getMonth() + 1;
let dd = newDate.getDate();
let yy = newDate.getFullYear();
let date = yy + '-' + mm + '-' + dd;
  let time = newDate.toLocaleTimeString();
  const receiptData = props.data;
  const transactionType = props.type;
  
  return (
    <Document>
      <Page style={styles.page}>
        {receiptData ? (
            <View style={styles.receiptContainer}>
              {/* <View style={styles.receiptTitleView}>
                
                <Text style={styles.receiptTitle}>{generateReceiptId(receiptData.RRN)}</Text>
                </View>
                <View style={styles.receiptTitleView}>
                
                <Text style={styles.receiptTitle}>Generated On:- {date}:{time}</Text>
                </View>
                <View style={styles.receiptTitleView}>
                <Text style={styles.receiptTitle}>NETPLUSDOTCOM LAGOS,</Text>
                </View>
                <View style={styles.receiptTitleView}>
                <Text style={styles.receiptTitle}>NIGERIA</Text>
                </View>
                <View style={styles.receiptTitleView}>
                <Text style={styles.receiptTitle}>MID: {receiptData.merchantId}</Text>
                </View>
                <View style={styles.receiptTitleView}>
                <Text style={styles.receiptTitle}>TID: {receiptData.terminalId}</Text>
                <Image source="https://res.cloudinary.com/politic/image/upload/v1613128013/NetPlusDotCom.png" style={styles.logoStyle} />
              </View> */}
              <View style={styles.transView}>
                <Image source="https://res.cloudinary.com/iremiwa/image/upload/v1661944668/Horizon_II_Logo_uyvvxk.png" style = {styles.logoStyle} />
              <Text style={styles.transTextSmall}>Horizon II Residents Association</Text>
              <Text style={styles.transTextSmall}>Horizon 2 Estate, Meadow Hall School Way, Ikate Elegushi, Lekki, Lagos</Text>
                  <Text style={styles.transText}>OFFICIAL RECEIPT</Text>
                  {receiptData[0].netpluspay_code == "00" ? ( <Text style={styles.transTextApp}>Approved</Text>):
                   <Text style={styles.transTextAppFailed}>Failed</Text>
                   }
              </View>
              {/* <View style={styles.tableheaderView}>
                  <Text style={styles.tableCellBold}>DETAILS</Text>
                  </View> */}
                  <View style={styles.tableheaderViewRow}>
                  <Text style={styles.tableCell}>Date</Text>
                  <Text style={styles.tableCell}>{FormatTime(receiptData[0].createdAt)}</Text>
                  </View>
                  <View style={styles.tableheaderViewRow}>
                  <Text style={styles.tableCell}>Apartment Owner</Text>
                  <Text style={styles.tableCell}>{receiptData[0].user_name}</Text>
                  </View>
                  {/* <View style={styles.tableheaderViewRow}>
                  <Text style={styles.tableCell}>Apartment Owner</Text>
                  <Text style={styles.tableCell}>{receiptData.TSI}</Text>
                  </View> */}
                  <View style={styles.tableheaderViewRow}>
                  <Text style={styles.tableCell}>Apartment Number</Text>
                  <Text style={styles.tableCell}>{address}</Text>
                  </View>
                  <View style={styles.tableheaderViewRow}>
                  <Text style={styles.tableCell}>Amount Paid</Text>
                  <Text style={styles.tableCell}>NGN{receiptData[0].amount}</Text>
                  </View>
                 {
                  transactionType === "Power Vending" ? (
                  <View style={styles.tableheaderViewRow}>
                  <Text style={styles.tableCell}>Total Units</Text>
                  <Text style={styles.tableCell}>{((receiptData[0].amount - 50) /process.env.REACT_APP_POWER_CHARGES).toFixed(2)} Units</Text>
                  </View>
                  ) : (null)
                 }
                  <View style={styles.tableheaderViewRow}>
                  <Text style={styles.tableCell}>Money Paid For</Text>
                  <Text style={styles.tableCell}>{transactionType}</Text>
                  </View>
                  {/* <View style={styles.tableheaderViewRow}>
                  <Text style={styles.tableCell}>CARD EXPIRY DATE</Text>
                  <Text style={styles.tableCell}>{receiptData.cardExpiry}</Text>
                  </View>
                  <View style={styles.tableheaderViewRow}>
                  <Text style={styles.tableCell}>RESPONSE MESSAGE</Text>
                  <Text style={styles.tableCell}>{receiptData.responseMessage}</Text>
                  </View> */}
                  {/* <View style={styles.tableheaderViewRow}>
                  <Text style={styles.tableCell}>RESPONSE CODE</Text>
                  <Text style={styles.tableCell}>{receiptData.responseCode}</Text>
                  </View>
                  <View style={styles.tableheaderViewRow}>
                  <Text style={styles.tableCell}>TRANSACTION TYPE</Text>
                  <Text style={styles.tableCell}>{receiptData.transactionType}</Text>
                  </View> */}
                  {/* <View style={styles.tableheaderViewRow}>
                  <Text style={styles.tableCell}>TRANSACTION TIME</Text>
                  <Text style={styles.tableCell}>{convertEpochToSpecificTimezone(Number(receiptData.transactionTimeInMillis))}</Text>
                  </View> */}
                  {/* <View style={styles.footer}>
                  <Text style={styles.footerText1}>I AGREE TO PAY THE ABOVE TOTAL AMOUNT ACCORDING TO CARD ISSUER AGREEMENT</Text>  
                  <Text style={styles.footerText2}>Powered by NetPOS.</Text>
                  </View> */}
          </View>
        ) : (
          ""
        )}
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
    page: {
      height: "100%",
      width: "100%",
      backgroundColor: "#f6f6f5",
      padding: "15px",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    receiptContainer: {
      // display: "flex",
    //   backgroundColor: "#f6f6f5",
      height: "100%",
      width: "100%",
    //   borderWidth: 10,
    //   borderColor: '#92CD00',
    //   borderStyle: 'solid',
      padding: "13px",
      // marginLeft: 5
    },
    receiptTitleView: {
      display: "flex",
      flexDirection: "row",
      justifyContent: 'space-between',
      paddingLeft: '5px'
    },
    receiptTitle: {
      fontSize: 12,
      marginBottom: 3,
    },
    movieOverview: {
      fontSize: 10,
    },
    logoStyle: {
        marginBottom: "20px",
      width:"120px",
      height:"120px"
   },
   transView:{
      display: "flex",
      alignItems: "center",
      flexDirection: "Column",
      marginTop: 20
      //  "text-align":"center"
  },
  transTextSmall: {
    fontSize: 12,
    fontWeight: 'bolder',
    color: '#282c34',
    // fontFamily: 'Courier', 
},
  transText: {
    marginTop: 10,
      fontSize: 28,
      fontWeight: 'bolder',
      color: '#282c34',
      fontFamily: 'Courier-Bold', 
  },
  transTextApp: {
      color:"#92CD00",
      fontFamily: 'Helvetica-Bold'
  },
  transTextAppFailed: {
    color:"#FF0000",
    fontFamily: 'Helvetica-Bold'
},
  tableheaderView: {
      // flexDirection: 'row',
      borderBottomWidth: 1,
      borderBottomColor: '#cfd3cf',
      borderBottomStyle: 'solid',
      // alignItems: 'stretch',
      marginTop: 20
  },
  tableheaderViewRow: {
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderBottomColor: '#cfd3cf',
      borderBottomStyle: 'solid',
      justifyContent: 'space-between',
      // alignItems: 'stretch',
      marginTop: 20
  },
  tableCellBold: {
      padding: 10,
      fontSize: 12,
      fontWeight: 'bold',
      fontFamily: 'Times-Bold'
  },
  tableCell: {
      padding: 10,
      fontSize: 12,
  },
  footer: {
      display: "flex", 
      flexDirection: "column",
      alignItems: 'center',
      marginTop: 8
  
  },
  footerText1 : {
      fontSize: 10
  },
  footerText2 : {
      fontSize: 10,
      fontFamily: 'Helvetica-Bold'
  },
  });
