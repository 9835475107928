import React from "react";
import shortid from "shortid";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormText,
  FormFeedback
} from "reactstrap";
import { decodeJwt } from "../../utilities/processJWT";
import { netpluspayPOP } from "./netfun";
import failedIcon from "../../assets/img/danger-512.png";
import successIcon from "../../assets/img/successIcon.png";
import { topUpPower, pingMommas } from "../../utilities/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Chatbot from "react-chatbot-kit";
import 'react-chatbot-kit/build/main.css';
import "../../components/Chatbot/App.css";
import config from "../../components/Chatbot/config";
import MessageParser from "../../components/Chatbot/MessageParser";
import ActionProvider from "../../components/Chatbot/ActionProvider";
import chatbot from "../../components/Chatbot/assets/chatbot.png";

class TopUps extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      payload: [],
      isLoading: false,
      showBot: false,
      value: "",
      userSummary: [],
      amount: "",
      serviceCharge: 50,
      removeTimeOut: null,
      isComplete: false,
      modal: false,
      transfer_reference: null,
      transferFinalStatus: null,
      formType: {},
      modalTitle: "",
      firstText: "",
      secondText: "",
      threeText: "",
      buttonText: "",
      windowsClosed: false,
      meterNumber: decodeJwt().meter_number,
      validate: {
        amountState: '',
      },
      duration: "",
      owesServiceFee: null
    };

    this.handleTopUps = this.handleTopUps.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleNteplusPay = this.handleNteplusPay.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    // On component mount import netpluspay script
    const script = document.createElement("script");
    script.src = 'https://js.netpluspay.com/inline.js';
    script.charset = "utf-8";
    script.async = true;
    script.onload = this.scriptLoaded();
    document.head.appendChild(script);

    const serviceCharge = localStorage.getItem("serviceCharge");

    const token= localStorage.getItem('token');
    const devAMount = localStorage.getItem('DevLevy');
    const fine = localStorage.getItem('fine');
    const decoded= decodeJwt(token);
    const status = decoded.status;

    // console.log("nobb", duration);
    // if(status === "occupied") {
      

      if(serviceCharge < 0) {
        if(devAMount < -59000 || fine < 0) {
          if(fine > 0) {
            let showMsg = document.getElementById("show-error");
          showMsg.innerHTML = `Please Kindly make Outstanding Fine Payments`;
          showMsg.style.display = "block";
          }
          if(devAMount < -59000) {
            let showMsg = document.getElementById("show-error");
            showMsg.innerHTML = `Please Kindly make Outstanding Development Levy Payments`;
            showMsg.style.display = "block";
          }
          
          this.setState({
            owesServiceFee:true
          })
        } else {
          this.setState({
            owesServiceFee:false
          })
        }
        let showMsg = document.getElementById("show-error");
          showMsg.innerHTML = "Please Kindly make Outstanding Service Charge Payments";
          showMsg.style.display = "block";
        this.setState({
          owesServiceFee: true
        })

        
      } 
      
      else if (serviceCharge >= 0) {
        if(devAMount < -59000 || fine < 0) {
          if(fine > 0 ) {
            let showMsg = document.getElementById("show-error");
          showMsg.innerHTML = "Please Kindly make Outstanding Fine Payments";
          showMsg.style.display = "block";
          }
          if(devAMount < -59000) {
            let showMsg = document.getElementById("show-error");
            showMsg.innerHTML = "Please Kindly make Outstanding Payments";
            showMsg.style.display = "block";
          }
          this.setState({
            owesServiceFee:true
          })
        } else {
          this.setState({
            owesServiceFee:false
          })
        }

      } 
    // } else {
    //   this.setState({
    //     owesServiceFee: true
    //   })
    // }

    // if(devAMount >= -120000) {
    //   this.setState({
    //     owesServiceFee:true
    //   })
    // }
   
  }
  scriptLoaded = () => {
    this.setState({ netplusPayReady: true });
  };

  componentDidUpdate(prevProps, prevState) {

    // if (this.state.windowsClosed) {
    //   this.handleTopUps();
    // }
    if (prevState.isComplete !== this.state.isComplete) {

      clearTimeout(this.state.removeTimeOut); // clear the timeoutID
    }
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  validateAmount(e) {
    const amountRex = /^([2-8][0-9]{2}|9[0-8][0-9]|99[0-9]|[1-8][0-9]{3}|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9]|[1-8][0-9]{4}|9[0-8][0-9]{3}|99[0-8][0-9]{2}|999[0-8][0-9]|9999[0-9]|[1-8][0-9]{5}|9[0-8][0-9]{4}|99[0-8][0-9]{3}|999[0-8][0-9]{2}|9999[0-8][0-9]|99999[0-9]|[1-8][0-9]{6}|9[0-8][0-9]{5}|99[0-8][0-9]{4}|999[0-8][0-9]{3}|9999[0-8][0-9]{2}|99999[0-8][0-9]|999999[0-9]|[1-8][0-9]{7}|9[0-8][0-9]{6}|99[0-8][0-9]{5}|999[0-8][0-9]{4}|9999[0-8][0-9]{3}|99999[0-8][0-9]{2}|999999[0-8][0-9]|9999999[0-9]|100000000)$/i;
    const { validate } = this.state
      if (amountRex.test(e.target.value)) {
        validate.amountState = 'has-success'
      } else {
        validate.amountState = 'has-danger'
      }
      this.setState({ validate })
 }

  handleChange(event) {
    this.setState({ amount: event.target.value });
  }


  handleTopUps (params) {
    // e.preventDefault();
  //   const params = {
        
  //     code: '00',
  //     status: 'CAPTURED',
  //     transId: 'NP09069w3eetds9uvdc87649',
  //     result: 'SUCCESS',
  //     orderId: 'POyhbshnh88987tb',
  //     amount: 30000,
  //     message: 'Approved',
  //     gtb_transaction_number: 351719944
    
  // }
  let amount = this.state.amount;
  let finalAmount = 0;
  finalAmount = this.state.amount
      ? parseInt(amount, 10) 
      : this.state.amount;

    if (typeof params === "object" && params !== null) {
      this.setState({
        isLoading: true,
      });

     
      topUpPower({ transfer_reference: params, type: "power_vending", amount: finalAmount + 50 }).then((response) => {
        this.state.removeTimeOut = setTimeout(() => {
        if (response && response.status === 200) {
          this.setState({
            modalTitle: "PAYMENT SUCCESSFUL",

            firstText: `Your payment is successful and a token has been sent to ${decodeJwt().email}`,
            secondText:
            `If you have any question or concern call +2348183888999, send us email at support@netpluspay.com or chat with us on our website.`,
            threeText: `For complaints please include the following information (Registered Name, Registered Email, Amount Paid, Sreenshot if any)`,
            buttonText: "Close",
            modal: !this.state.modal,
            isComplete: true,
            isLoading: false,
            windowsClosed: false,
            amount: "",
          });
        } else if (response && response.status === 400) {
          this.setState({
            modalTitle: "PAYMENT FAILED",
            firstText:
              "Your payment has failed and your transaction was not processed.",
            secondText:
              `If you have any question or concern call +2348183888999, send us email at support@netpluspay.com or chat with us on our website.`,
            threeText: `For complaints please include the following information (Registered Name, Registered Email, Amount Paid, Sreenshot if any)`,
            buttonText: "Try again?",
            modal: !this.state.modal,
            isComplete: true,
            isLoading: false,
            windowsClosed: false,
            amount: "",
          });
        }
        // else if (
        //   response &&
        //   response.status === 500
        //   // response.data.reason !==
        //   // "request cannot be processed now because payment transaction result is not 'success'"
        // ) {
        //   this.setState({
        //     modalTitle: "PAYMENT PENDING",
        //     firstText:
        //       "Your payment was successful. However, your electricity token request is pending due to a slight network downtime. This would typically be resolved within the hour and you would be emailed with the electricity token and your DASHBOARD updated. We do apologize for the inconvenience.",
        //     secondText:
        //     `If you have any question or concern call +2348183888999, send us email at support@netpluspay.com or chat with us on our website.`,
        //     threeText: `For complaints please include the following information (Registered Name, Registered Email, Amount Paid, Sreenshot if any)`,
        //     buttonText: "Close",
        //     modal: !this.state.modal,
        //     isComplete: true,
        //     isLoading: false,
        //     windowsClosed: false,
        //     amount: "",
        //   });
        // } 
        else if (response && response.status !== 400 && response.status !== 200 && response.status !== 202) {
          this.setState({
            modalTitle: "PAYMENT PENDING",
            firstText:
              "Your payment was not successful. However, if you have been debited please send a proof of the debit along with your bank account name and meter number to info@netpos.com.ng.",
            secondText:
            `If you have any question or concern call +2348183888999, send us email at support@netpluspay.com or chat with us on our website.`,
            threeText: `For complaints please include the following information (Registered Name, Registered Email, Amount Paid, Sreenshot if any)`,
            buttonText: "Close",
            modal: !this.state.modal,
            isComplete: true,
            isLoading: false,
            windowsClosed: false,
            amount: "",
          });
        }
      });
    }, 4000);
  }
  }
  handleNteplusPay(event) {
    event.preventDefault();
    function hi() { return 0x64; }
    let showError = document.getElementById("show-error");
    let finalAmount = 0;
    const { amount } = this.state;
    if (amount === "" ) {
      this.setState({
        amount: "",
      });
      return;
    } else {
    if (amount !== null && amount >= 300) {
      showError.style.display = "none";
    }


    const serviceCharge = localStorage.getItem("serviceCharge");
    const devAMount = localStorage.getItem('DevLevy');
    const fine = localStorage.getItem('fine');
    if (serviceCharge < 0 || devAMount < -59999 || fine > 0) {
      console.log('seea',serviceCharge, devAMount, fine);
      alert('Please make outstanding payments.');
      return;
    }
    // finalAmount = this.state.amount;
    finalAmount = this.state.amount ? parseInt(amount, 10) : this.state.amount;
    this.setState({
      isLoading: true,
    });
    // let netplusResponse = null,
    //   netplusPayStatus = null,
    //   netplusRequestCompleted = false;
    // const handleResponse = () => {
    //   if (netplusResponse === null) {
    //     this.setState({
    //       isLoading: false,
    //     });
    //     return;
    //   }

    //   if (typeof netplusResponse === "object" && netplusResponse !== null) {
    //     this.setState({
    //       transfer_reference: netplusResponse,
    //       transferFinalStatus: netplusPayStatus,
    //     });
    //   }

    //   if (netplusRequestCompleted) {
    //     this.setState({
    //       windowsClosed: netplusRequestCompleted,
    //     });
    //   }
    // };

    return pingMommas({ meter_number: decodeJwt().meter_number })
      .then((response) => {
        if (
          response &&
          response.status === 200 &&
          response.data.isFound === false
        ) {
          setTimeout(() => {
            this.setState({
              isLoading: false,
            });
            showError.style.display = "block";
            showError.innerHTML = "Meter number error, update and try again";
          }, 100);
        } else if (
          response &&
          response.status === 200 &&
          response.data.isFound === true
        ) {
          const values = { // Values to give netpluspay class
            success: (message) => {
              this.handleTopUps(message);
              console.log("success", message)
            },
            failed: (message) => {
              console.log("failed", message)
              this.handleTopUps(message);
            },
            cancel: (message) => {
              console.log(message)
              // netplusRequestCompleted = true;
            },
            data: {
              merchantId: `${process.env.REACT_APP_MERCHANT_ID}`,
              name: `${decodeJwt().email}`, //Don't make it long so amount can show
              email: `${decodeJwt().email}`,
              narration: "Electricity Topup",
              amount: finalAmount + 50,
              currency: "NGN",
              orderId: `POWER${shortid.generate()}`,
            }
          }
          if (this.state.netplusPayReady) { // Check if netpluspay script is loaded
            let netplusPay = window.NetplusPay; // Call the netpluspay function
            let newNetplusPay = new netplusPay(values) // Create a new netpluspay class
            newNetplusPay.beginTransaction(); // Call the beginTransaction function
            this.setState({
              isLoading: false,
            });
          }
        } else {
          setTimeout(() => {
            this.setState({
              isLoading: false,
            });
            let showMsg = document.getElementById("show-error");
            showMsg.innerHTML = "Opps! Server or network error";
            showMsg.style.display = "block";
          }, 100);
        }
      })
      .catch(function (error) {
        setTimeout(() => {
          this.setState({
            isLoading: false,
          });
          let showMsg = document.getElementById("show-error");
          showMsg.innerHTML = "There was a server error. This could be caused by a slow network connection or the service not responding to request at the moment. Please try again later";
          showMsg.style.display = "block";
        }, 100);
      });
    }
  }

  render() {
    const { isLoading, windowsClosed, amount, serviceCharge } = this.state;
    return (
      <div className="content">
        <div id="paymentFrame" class="m-0 mx-auto"></div>
        <Row>
          <Col md={10} className="mx-auto">
            <Card className="demo-icons">
              <CardHeader>
                <CardTitle className="text-plus">Buy Power</CardTitle>
              </CardHeader>
              <CardBody className="all-icons">
                <Form onSubmit={ (e) => this.handleNteplusPay(e) }>
                {/* <Form onSubmit={ (e) => this.handleTopUps(e) }> */}
                  <h3 id="show-error"></h3>
                  <FormGroup>
                    <Label for="topupAmount">Amount</Label>
                    <Input
                      type="text"
                      name="amount"
                      id="topupAmount"
                      value={amount}
                      required
                      placeholder="Enter amount"
                      onChange={this.handleChange}
                      disabled={this.state.isLoading}
                      // valid={ this.state.validate.amountState === 'has-success' }
                      // invalid={ this.state.validate.amountState === 'has-danger' }
                      // onChange={ (e) => {
                      //       // this.validateAmount(e)
                      //       this.handleChange(e)
                      // } }
                    />

               <FormFeedback valid>
                
              </FormFeedback>
              <FormFeedback>
              Amount is less than 300
              </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="meterNumb">Meter Number</Label>
                    <Input
                      type="text"
                      disabled="true"
                      name="meter_number"
                      value={this.state.meterNumber}
                      id="meterNumb"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="service_charge">Convenience Fee</Label>
                    <Input
                      type="text"
                      disabled="true"
                      name="service_charge"
                      placeholder="₦50"
                      value={serviceCharge}
                      id="service_charge"
                    />
                  </FormGroup>
                  <FormGroup>
                    <div class="text-center">
                      <Button
                        className="btn btn-lg bg-green text-uppercase"
                        type="submit"
                        disabled={this.state.owesServiceFee}
                      >
                        {/* Pay */}
                        {isLoading ? `Processing...  ` : "Pay"}
                        {windowsClosed && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                      </Button>
                    </div>
                  </FormGroup>
                </Form>
              </CardBody>
              {/* {this.state.formType === "Sign in" && ( */}
              <div>
                <Modal
                  centered={true}
                  isOpen={this.state.modal}
                  toggle={this.toggle}
                  scrollable={true}
                  backdrop={"static"}
                >
                  <ModalHeader className="modal-title" toggle={this.toggle}>
                    <p>
                      {this.state.modalTitle === "PAYMENT FAILED" ? (
                        <img
                          className="img-fluid mx-auto d-block"
                          src={failedIcon}
                          alt="Failed icon"
                        />
                      ) :
                        this.state.modalTitle === "PAYMENT SUCCESSFUL" ? (
                          <img
                            className="img-fluid mx-auto d-block"
                            src={successIcon}
                            alt="Success icon"
                          />
                        ) :
                          this.state.modalTitle === "PAYMENT PENDING" ? (
                            <span className="align-middle"><FontAwesomeIcon icon={"circle-notch"} /> </span>
                          )
                            :
                            "Server error"
                      }
                    </p>
                    <p>{this.state.modal && this.state.modalTitle}</p>
                  </ModalHeader>
                  <ModalBody
                    style={{
                      backgroundColor: "white",
                      margin: "30px",
                      borderRadius: "5px",
                    }}
                  >
                    <p>{this.state.modal && this.state.firstText}</p>
                    <p>{this.state.modal && this.state.secondText}</p>
                    <p>{this.state.modal && this.state.threeText}</p>
                  </ModalBody>
                  <ModalFooter
                    style={{
                      alignSelf: "center",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                    }}
                  >
                    <div class="text-center">
                      <Button
                        className="bg-green"
                        style={{
                          color: "white",
                          textTransform: "none",
                          fontSize: "1.5em",
                        }}
                        onClick={this.toggle}
                      >
                        {this.state.modal && this.state.buttonText}
                      </Button>
                    </div>
                  </ModalFooter>
                </Modal>
              </div>
            </Card>
          </Col>
        </Row>

        {/* </div>
        )} */}
        <div className="chatbot">
        {this.state.showBot && (<Chatbot  config ={config} messageParser = {MessageParser} actionProvider = {ActionProvider} /> )}
        </div>
        <button className="chatbot-button" onClick={(e) => this.setState({showBot: !this.state.showBot})} style ={{backgroundColor: this.state.showBot ? '#28a745' : 'white'}}>
          {/*<img src="https://uploads.codesandbox.io/uploads/user/7a2de391-f332-46d7-87b3-6f582bb6f731/Yko8-Icon.png"/>*/}
          <img src={chatbot}  alt="botIcon" className ="chatbot-icon"/>
        </button>
      </div>
    );
  }
}

export default TopUps;