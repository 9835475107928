import React from "react";
import {
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  DropdownItem,
} from "reactstrap";
import CreateNewService from "./createNewService";
import EnableService from "./enableService";

class Estates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      headerTitle: "",
      viewEstate: "CREATE NEW ESTATE",
    };

    this.menuToggle = this.menuToggle.bind(this);
  }

  menuToggle() {
    switch (this.state.viewEstate) {
      case "CREATE NEW ESTATE":
        return <CreateNewService />;
      case "ENABLE NEW SERVICE":
        return <EnableService />;
      default:
        return <CreateNewService />;
    }
  }

  render() {
    return (
      <div className="content">
        <div>
          <UncontrolledDropdown>
            <DropdownToggle caret>Menu</DropdownToggle>
            <DropdownMenu
              modifiers={{
                setMaxHeight: {
                  enabled: true,
                  order: 890,
                  fn: (data) => {
                    return {
                      ...data,
                      styles: {
                        ...data.styles,
                        overflow: "auto",
                        maxHeight: "100px",
                      },
                    };
                  },
                },
              }}
            >
              <DropdownItem
                onClick={(e) =>
                  this.setState({ viewEstate: "ENABLE NEW SERVICE" })
                }
              >
                Enable A Service
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={(e) =>
                  this.setState({ viewEstate: "REQUEST NEW ESTATE" })
                }
              >
                Create New Service
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        {/* <hr />
      <p>Link Based</p> */}
        <Row>
          <Col md={12}>{this.menuToggle()}</Col>
        </Row>
      </div>
    );
  }
}

export default Estates;
