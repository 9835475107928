import React, { Component, useEffect, useState, setState } from "react";
import { Form, FormGroup, Row, Col } from "reactstrap";

import Select from "react-select";
import { Formik } from "formik";
import * as Yup from "yup";
import { createServiceAdmin, getServices } from "../../utilities/api";

const CreateForm = function () {
  // const options = this.props.options;
  // console.log("opp", options);
  const [options, setOptions] = useState([]);
  const [service_name, setServiceName] = useState("");
  
  const handleGetAllServices = () => {
    console.log("i'm lost");
    // this.setState({ isLoading: true });
    getServices().then((response) => {
      if (response && response.status == 200 || response && response.status === 304) {
        console.log("resp", response.data);
        setTimeout(() => {
          setOptions(response.data);
        }, 500);
      } else {
        this.setState({ payload: response, isLoading: false });
      }
    });
  }

  useEffect(() => {
    handleGetAllServices();
  }, [])

  // const handleChange = e => {
  //   const {name , value} = e.target
  //   setState( prevState => ({
  //       ...prevState,
  //       [name] : value
  //   }))
  //   // setServiceName(e.target.value)
  // }
  console.log("name", service_name);
  return (
    <Formik
      initialValues={{
        service_name: "",
        // payload: this.state.payload
      }}
      onSubmit={(values, { setSubmitting }, props) => {
        setTimeout(() => {
          createServiceAdmin({
            service_name: service_name,
          }).then((response) => {
            let showError = document.getElementById("show-error");
            if (response && response.status === 200 || response && response.status === 304) {
              showError.style.display = "block";
              showError.style.color = "green";
              showError.innerHTML = "Service created";
              setTimeout(() => {
                window.location.reload(true);
              }, 0);
            } else if (response && response.status === 400) {
              showError.style.display = "block";
              showError.innerHTML = "This service name already exist";
              setSubmitting(false);
            } else {
              showError.style.display = "block";
              showError.innerHTML = "Opps! Server error";
              setSubmitting(false);
            }
          });
        }, 1000);
      }}
      validationSchema={Yup.object().shape({
        service_name: Yup.string()
          .trim()
          .matches(
            /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?!@-_?&%]{2,}$/,
            "At least two letters are expected"
          )
          .required("service name is required"),
      })}
    >
      
      {(props) => {
        
        const {
          values,
          isClearable,
          isSearchable,
          isLoading,
          touched,
          errors,
          isSubmitting,
          // handleChange,
          handleBlur,
          handleSubmit,
          // options
        } = props;
        // const change=handleChange();
        // setOptions(options);
        return (
         
          // <div className="login-container">
          <Form onSubmit={handleSubmit} className="login-form">
            <h3 id="show-error">Input error</h3>
            {console.log("maddd", options)}
            <FormGroup>
              {/* <Select
                className="basic-single"
                classNamePrefix="select"
                isLoading={isLoading}
                isClearable={true}
                isSearchable={true}
                name="Available services"
                options={ options.map((user)=> (
                  <option>{user.name}</option>
                ))}
                placeholder="SELECT SERVICE"
              /> */}

                  <select
										className="basic-single"
										id="exampleFormControlSelect1"
										placeholder="SELECT SERVICE"
										name="Available services"
										value={service_name}
										onChange= {e => setServiceName(e.target.value)}
										disabled={isLoading}
										required
									>
										<option value="">SELECT SERVICE</option>
										{
                      options.map((user)=> (
                        <option value={user.name}>{user.name}</option>
                      ))
                    }
									</select>
            </FormGroup>
            <button
              className="btn bg-green text-uppercase float-right"
              type="submit"
              // disabled={isSubmitting}
            >
              REQUEST
              {/* {isSubmitting ? "Loading…" : "REQUEST"} */}
            </button>
          </Form>
          // </div>
        );
      }}
    </Formik>
  );
};

class EnableService extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      payload: []
    }
  }

  // componentDidMount = ()=> {
  //   this.handleGetAllServices();
  // }

  // handleGetAllServices = () => {
  //   console.log("i'm lost");
  //   // this.setState({ isLoading: true });
  //   getServices().then((response) => {
  //     if (response && response.status == 200 || response && response.status === 304) {
  //       console.log("resp", response.data);
  //       setTimeout(() => {
  //         this.setState({
  //           payload: response.data,
  //           isLoading: false
  //         });
  //       }, 500);
  //     } else {
  //       this.setState({ payload: response, isLoading: false });
  //     }
  //   });
  // }

  render() {
    return (
      <div>
        {/*--------------------------------------------------------------------------------*/}
        {/*Start Inner Div*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*--------------------------------------------------------------------------------*/}
        {/*Row*/}
        {/*--------------------------------------------------------------------------------*/}
        {/* <div id="paymentFrame" class="m-0 mx-auto"></div> */}
        <Row className="bg-white center-div">
          <Col md={12} className="p-5 rounded">
            <h6>REQUEST NEW SERVICE</h6>
            <p>
              Please select an additional service you will like to request for
              your estate and our admin will contact you within 3-5 days.
            </p>
            <CreateForm 
            // options= {this.state.payload}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default EnableService;
